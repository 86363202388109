import {isBrowser, getObjectsWithSpecificKeys, pushDatalayerEvent} from './utils'
import {getCookie} from './storage-utils'
import {isEqual} from 'lodash'

/**
 * Recursively deletes empty values from an object
 * @param {Object} obj - The object to be filtered
 * @returns {Object} - The filtered object
 */
export const deleteMissingValues = (obj) => {
    for (let i in obj) {
        if (i === 'coupon' || i === 'page_referrer') continue
        if (obj[i] === '' || obj[i] === null || obj[i] === undefined) {
            delete obj[i]
        } else if (typeof obj[i] === 'object') {
            deleteMissingValues(obj[i])
        }
    }
    return obj
}

/**
 * Builds an array of product data objects for the data layer
 * @param {Array} productData - an array of product data objects
 * @param {string} listName - the name of the list
 * @param {number} position - the position of the product in the list
 * @returns {Array} an array of product data objects for the data layer
 */
export const buildProductDataForDataLayer = (productData, listName, position) => {
    if (!productData) {
        return
    }

    let items = []
    productData.map((productItem, index) => {
        const product = productItem?.product || productItem
        const quantity = productItem?.quantity
        const productId = product?.master?.master_id || product?.id || product.product_id
        const affiliation = productItem?.affiliation || 'storefront'
        const keysToCheck = ['c_showOnDeliveryPage', 'c_showOnPDP', 'c_showOnPLP']
        const productPromos = getObjectsWithSpecificKeys(
            product?.c_promotionCustomAttributes,
            keysToCheck
        )
        const stockStatus = getProductStockStatusLabel(product)
        const shouldSkipListName = productItem?.skipListName
        const shouldTrackEinstein = productItem?.shouldTrackEinstein
        const isEinsteinReco = productItem?.isEinsteinReco || false
        const imageZoomed = productItem?.imageZoomed
        let promoMessages = []
        productPromos.length &&
            productPromos.map((obj) => {
                if (obj.c_class === 'PRODUCT') {
                    promoMessages.push(obj.c_calloutMsg)
                }
            })

        let item = {}
        item = {
            item_id: productId || '',
            item_name: product?.name || '',
            affiliation: affiliation,
            //@todo - discount value should be provided from BD at product level and used here
            discount:
                product?.prices && product?.prices['whsmith-gbp-saleprices']
                    ? parseFloat(
                          (
                              product?.prices['whsmith-gbp-listprices'] -
                              product?.prices['whsmith-gbp-saleprices']
                          ).toFixed(2)
                      )
                    : 0,
            coupon: promoMessages.length ? promoMessages.join(', ') : undefined,
            index: position || index + 1,
            item_brand: product?.brand || '',
            item_variant: product?.c_colour || '',
            image_zoom_clicked: imageZoomed || '',
            price: product?.price || 0,
            quantity: Number(quantity) || (product?.inventory?.orderable ? 1 : 0),
            // Custom dataLayer variables
            stock_status: stockStatus,
            images: product?.c_images?.length || null,
            original_product_price:
                (product?.prices && product?.prices['whsmith-gbp-listprices']) || 0,
            rating: 0,
            review_availabilitty: 'No',
            description_length:
                product?.long_description?.length || product?.c_descriptionLength || 0,
            promotion_name_present: promoMessages.length ? 'Yes' : 'No',
            item_on_sale:
                product?.prices && product?.price < product?.prices['whsmith-gbp-listprices'],
            item_on_offer: promoMessages.length ? true : false,
            item_list_name: shouldSkipListName ? '' : product?.c_catTree?.item_category || listName,
            product_recommendation: shouldTrackEinstein ? isEinsteinReco : ''
        }
        for (const key in product?.c_catTree) {
            item[key] = product.c_catTree[key]
        }
        items.push(item)
    })
    return deleteMissingValues(items)
}

/**
 * Adds a checkout pages event to the dataLayer
 * @param {string} eventType - the type of event to push to the dataLayer
 */
export const addCheckoutPagesEvent = (eventType) => {
    if (isBrowser()) {
        const dataLayer = window.dataLayer || []
        const dataLayerEvent = {
            event: eventType,
            timestamp: Date.now()
        }
        dataLayer.push(deleteMissingValues(dataLayerEvent))
    }
}

const getConsentUpdateObjectFromOptanonCookie = () => {
    const optanonCookie = getCookie('OptanonConsent')
    const params = new URLSearchParams(optanonCookie)
    const groupsString = params.get('groups')
    if (groupsString) {
        const groupsArray = groupsString.split(',')
        const groupsObject = {}

        groupsArray.forEach((group) => {
            const [key, value] = group.split(':')
            groupsObject[key] = value
        })
        return {
            ad_storage: groupsObject?.C0004 === '1' ? 'granted' : 'denied',
            analytics_storage: groupsObject?.C0002 === '1' ? 'granted' : 'denied',
            functionality_storage: groupsObject?.C0003 === '1' ? 'granted' : 'denied',
            ad_personalization: groupsObject?.C0004 === '1' ? 'granted' : 'denied',
            ad_user_data: groupsObject?.C0004 === '1' ? 'granted' : 'denied'
        }
    }
}

/**
 * Adds default consent to the dataLayer
 */
export const defaultConsentEvent = () => {
    if (isBrowser()) {
        // Define dataLayer and the gtag function.
        const dataLayer = window.dataLayer || []
        function gtag() {
            dataLayer.unshift(arguments)
        }

        const consentUpdateObj = getConsentUpdateObjectFromOptanonCookie()
        const consentDefaultObj = {
            ad_storage: 'denied',
            ad_user_data: 'denied',
            ad_personalization: 'denied',
            analytics_storage: 'denied',
            functionality_storage: 'denied'
        }

        if (!isEqual(consentUpdateObj, consentDefaultObj)) {
            const consentUpdateObj = getConsentUpdateObjectFromOptanonCookie()
            consentUpdateObj && gtag('consent', 'update', consentUpdateObj)
            dataLayer.push({
                event: 'consent_updated'
            })
        }

        gtag('consent', 'default', consentDefaultObj)
    }
}

/**
 * Updates consent event for Google Tag Manager based on cookies enabled status
 * @param {Object} cookiesEnabled - object containing cookies enabled status for different functionalities
 * @param {boolean} cookiesEnabled.targeting - status of targeting cookies
 * @param {boolean} cookiesEnabled.performance - status of performance cookies
 * @returns {Object} - updated cookies enabled status
 */
export const consentUpdateEvent = (cookiesEnabled, isInitialPageLoad) => {
    if (isBrowser() && !isInitialPageLoad) {
        // Define dataLayer and the gtag function.
        const dataLayer = window.dataLayer || []
        function gtag() {
            dataLayer.push(arguments)
        }
        if (getCookie('OptanonAlertBoxClosed')) {
            gtag('consent', 'update', {
                ad_storage: cookiesEnabled.targeting ? 'granted' : 'denied',
                analytics_storage: cookiesEnabled.performance ? 'granted' : 'denied',
                functionality_storage: cookiesEnabled.functional ? 'granted' : 'denied',
                ad_personalization: cookiesEnabled.targeting ? 'granted' : 'denied',
                ad_user_data: cookiesEnabled.targeting ? 'granted' : 'denied'
            })
            dataLayer.push({
                event: 'consent_updated'
            })
        }
    }
    return cookiesEnabled
}

/**
 * Adds account creation and newsletter event to the dataLayer
 * @param {string} eventType - the type of the event
 * @param {string} method - the method used for the event
 */
export const addAccountAndNewsletterEvent = (eventType, method) => {
    if (isBrowser()) {
        const dataLayer = window.dataLayer || []
        const dataLayerEvent = {
            event: eventType,
            method: method,
            timestamp: Date.now()
        }
        dataLayer.push(deleteMissingValues(dataLayerEvent))
    }
}

/**
 * Adds a store_check_interaction event to the dataLayer
 * @param {string} eventType - the type of event to push to the dataLayer
 * @param {string} itemId - id of the product to push to the dataLayer
 * @param {string} itemName - name of the product to push to the dataLayer
 */
export const addStoreCheckInteractionEvent = (eventType, itemId, itemName) => {
    if (isBrowser()) {
        const dataLayer = window.dataLayer || []
        const dataLayerEvent = {
            event: eventType,
            item_id: itemId,
            item_name: itemName,
            timestamp: Date.now()
        }
        dataLayer.push(deleteMissingValues(dataLayerEvent))
    }
}

/**
 * Adds a generate_lead event to the dataLayer.
 */
export const addGenerateLeadEvent = () => {
    if (isBrowser()) {
        const dataLayer = window.dataLayer || []
        const dataLayerEvent = {
            event: 'generate_lead',
            method: 'Homepage',
            timestamp: Date.now()
        }
        dataLayer.push(deleteMissingValues(dataLayerEvent))
    }
}

/**
 * Adds a pdp_interaction event to the dataLayer
 * @param {string} eventType - the type of event to push to the dataLayer
 * @param {string} interactionType - type of PDP interaction to push to the dataLayer
 * @param {string} itemId - id of the product to push to the dataLayer
 * @param {string} itemName - name of the product to push to the dataLayer
 */

export const addPDPInteractionEvent = (eventType, interactionType, itemId, itemName) => {
    if (isBrowser()) {
        const dataLayer = window.dataLayer || []
        const dataLayerEvent = {
            event: eventType,
            interaction_type: interactionType,
            item_id: itemId,
            item_name: itemName,
            timestamp: Date.now()
        }
        dataLayer.push(deleteMissingValues(dataLayerEvent))
    }
}
/**
* Push 'select_item' event to the dataLayer
* @param {Object} product - the product object
* @param {Object} category - the category object
* @param {boolean} isSuggestedProduct - whether the product is a suggested product
* @param {string} query - the query string
* @param {number} position - the position of the product in the list
* @param {boolean} isEinsteinReco - whether the product is an Einstein recommendation
*/
export const addSelectItemEvent = (
    product,
    category,
    isSuggestedProduct,
    query,
    position,
    isEinsteinReco
) => {
    if (isBrowser()) {
        const dataLayer = window.dataLayer || []
        const itemListCat = product?.c_catTree?.item_category
        const listName = category?.name || query || category
        const items = buildProductDataForDataLayer(
            [{product, skipListName: true, shouldTrackEinstein: true, isEinsteinReco}],
            listName,
            position
        )
        const dataLayerEvent = {
            event: 'select_item',
            market: 'UK',
            timestamp: Date.now(),
            ecommerce: {
                item_list_id: product?.primary_category_id || category?.id || '',
                item_list_name: isSuggestedProduct ? itemListCat : listName,
                suggested_product: isSuggestedProduct || false,
                items: items
            },
            _clear: true
        }
        dataLayer.push(deleteMissingValues(dataLayerEvent))
    }
}

/**
 * Add a navigation interaction event to the dataLayer
 * @param {string} navPosition - the navigation position
 * @param {string} navCategory - the navigation category
 * @param {string} navType - the navigation type
 */
export const addNavigationInteractionEvent = (navPosition, navCategory, navType) => {
    if (isBrowser()) {
        const dataLayer = window.dataLayer || []
        const dataLayerEvent = {
            event: 'navigation_interaction',
            navigation_position: navPosition,
            navigation_category: navCategory,
            navigation_type: navType,
            timestamp: Date.now()
        }
        dataLayer.push(deleteMissingValues(dataLayerEvent))
    }
}

/**
 * Add a promotion code interaction event to the dataLayer
 * @param {string} eventType - the type of event to push to the dataLayer
 * @param {string} promoCode - the promo code to push to the dataLayer
 * @param {string} couponApplied - if promo code was applied successfully or not
 */
export const addPromotionCodeInteractionEvent = (eventType, promoCode, couponApplied) => {
    if (isBrowser()) {
        const dataLayer = window.dataLayer || []
        const dataLayerEvent = {
            event: eventType,
            coupon_code: promoCode,
            code_applied: couponApplied,
            timestamp: Date.now()
        }
        dataLayer.push(deleteMissingValues(dataLayerEvent))
    }
}

/**
 * Add a search event to the dataLayer
 * @param {string} eventType - the type of event to push to the dataLayer
 * @param {string} searchTerm - the search term to push to the dataLayer
 * @param {string} pageType - page type to push to the datalayer
 * @param {string} numberOfSearchResults - number of search results to push to the datalayer
 */
export const addSearchEvent = (eventType, searchTerm, pageType, numberOfSearchResults) => {
    if (isBrowser()) {
        const dataLayer = window.dataLayer || []
        const dataLayerEvent = {
            event: eventType,
            search_term: searchTerm,
            page_type: pageType,
            number_of_search_results: numberOfSearchResults,
            timestamp: Date.now()
        }
        dataLayer.push(deleteMissingValues(dataLayerEvent))
    }
}

/**
 * Add a search event to the dataLayer
 * @param {string} eventType - the type of event to push to the dataLayer
 * @param {string} interactionType - the type of interaction to push to the dataLayer
 * @param {string} contentType - the type of content to push to the dataLayer
 * @param {string} contentId - the content id to push to the dataLayer
 * @param {string} errorCode - the error code to push to the dataLayer
 * @param {string} pageType - the page type to push to the dataLayer
 */
export const addInteractionErrorEvent = (
    eventType,
    interactionType,
    contentType,
    contentId,
    errorCode,
    pageType
) => {
    if (isBrowser()) {
        const dataLayer = window.dataLayer || []
        const dataLayerEvent = {
            event: eventType,
            interaction_type: interactionType,
            content_type: contentType,
            content_id: contentId,
            error_code: errorCode,
            page_type: pageType,
            timestamp: Date.now()
        }
        dataLayer.push(deleteMissingValues(dataLayerEvent))
    }
}

/**
 * Checks the product in the basket and builds the item data for datalayer events
 * @param {Array} products - Array of products
 * @param {Object} basketDetails - Basket details
 * @returns {Array} - Array of product data
 */
export const checkProductInBasketAndBuildItemData = (products, basketDetails) => {
    const productAffiliation = basketDetails?.channel_type
    const items = products.map((product, index) => {
        let productQuantity = 0
        if (basketDetails?.product_items?.length) {
            const data = basketDetails.product_items.find((data) => data.product_id === product.id)
            if (data) {
                productQuantity = data.quantity
            }
        }

        return buildProductDataForDataLayer(
            [{product, quantity: productQuantity, affiliation: productAffiliation}],
            '',
            index + 1
        )[0]
    })
    return items
}

/**
 * Returns the display name of the product stock status
 * @param {Object} product - the product object
 * @returns {string} - the stock status of the product
 */
export const getProductStockStatusLabel = (product) => {
    let stockStatus = ''
    if (product?.inventory?.orderable) {
        if (product?.inventory?.backorderable) {
            stockStatus = 'Back-Orderable'
        } else if (product?.inventory?.preorderable) {
            stockStatus = 'Pre-Orderable'
        } else {
            stockStatus = 'In Stock'
        }
    } else {
        stockStatus = 'Out Of Stock'
    }
    return stockStatus
}

/**
 * Handles the click of a promotion and sends GA4 event
 * @param {string} name - The name of the promotion
 * @param {string} position - The position of the promotion
 */
export const handlePromoClick = (name, position, isEinsteinReco) => {
    pushDatalayerEvent({
        event: 'select_promotion',
        market: 'UK',
        timestamp: Date.now(),
        ecommerce: {
            items: [
                {
                    promotion_name: name,
                    creative_name: name,
                    creative_slot: position,
                    product_recommendation: isEinsteinReco || false
                }
            ]
        },
        _clear: true
    })
}

/**
* Push an add to cart GA4 event to the dataLayer
* @param {Object} product - the product object
* @param {number} quantity - the quantity of the product
* @param {string} actionType - the type of action (add, remove, etc.)
* @param {number} index - the index of the product in the cart
*/
export const addToCartEvent = (product, quantity, actionType, index, isEinsteinReco) => {
    if (isBrowser()) {
        window.dataLayer = window.dataLayer || []
        const items = buildProductDataForDataLayer([{product, quantity, shouldTrackEinstein: true, isEinsteinReco}], '', index)
        const itemsWithBonus = items.map((item) => {
            return {
                ...item,
                bonus_product: false
            }
        })

        const dataLayerEvent = {
            event: 'add_to_cart',
            currency: product.currency,
            add_to_cart_type: actionType,
            market: 'UK',
            timestamp: Date.now(),
            ecommerce: {
                items: itemsWithBonus
            },
            _clear: true
        }
        window.dataLayer.push(deleteMissingValues(dataLayerEvent))
    }
}
