import {isBrowser} from '../../utils/utils'
import React from 'react'

export const URL_MAPPING = {
    '/back-to-school/': 'Back to School',
    '/stationery/': 'Stationery',
    '/office/': 'Office',
    '/art-and-craft/': 'Art & Craft',
    '/toys-games-and-jigsaws/': 'Toys, Games & Jigsaws',
    '/books/': 'Books',
    '/gifts-cards-and-home/': 'Gifts, Cards & Home',
    '/magazine-subscriptions/': 'Magazine Subscriptions',
    '/music-film-and-television/': 'Music, Film & Television',
    '/sale-and-offers/': 'Sale & Offers'
}

export const pushNavInteraction = (elementName) => {
    if (isBrowser()) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            event: 'navInteraction',
            navElement: elementName
        })
    }
}


export const ShowLoaderOnPage = () => {
    return (
        <div role="progressbar" className="loader-bg" style={{height: '3350px'}}>
            <div className="loader" style={{top: '250px'}}>
                <div className="loader-indicator"></div>
            </div>
        </div>
    )
}