/*Product Tile INFO Component*/
//This contains the details of the product such as Name, Attributes, Availability Status, Price etc...//
//Consists of child components - Product Tile Details and Product Tile Bottom, in order.//

import React, {Fragment} from 'react'
import ProductTileDetails from './productTileDetails'

const ProductTileInfo = ({
    productInfo,
    masterProductInfo,
    cssClass,
    category,
    categoryData,
    plpCssClass,
    isFaceout,
    chartNumber,
    position,
    queryString,
    faceoutTitle,
    showSearchSuggestion,
    onSearchClose,
    triggerSuggestInteraction,
    isSearchListing,
    isEinsteinReco
}) => {
    return (
        <Fragment>
            <div className={`${showSearchSuggestion ? 'product-tile-info-search text-align-search' : 'product-tile-info text-align'}`}>
                <ProductTileDetails
                    productDetails={productInfo}
                    masterProductInfo={masterProductInfo}
                    cssClass={cssClass}
                    productId={masterProductInfo.id}
                    category={category}
                    categoryData={categoryData || category}
                    plpCssClass={plpCssClass}
                    isFaceout={isFaceout}
                    chartNumber={chartNumber}
                    position={position}
                    queryString={queryString}
                    faceoutTitle={faceoutTitle}
                    showSearchSuggestion={showSearchSuggestion}
                    onSearchClose={onSearchClose}
                    triggerSuggestInteraction={triggerSuggestInteraction}
                    isSearchListing={isSearchListing}
                    isEinsteinReco={isEinsteinReco}
                />
            </div>
        </Fragment>
    )
}

export default ProductTileInfo
