import React from 'react'

import {getAssetUrl} from 'progressive-web-sdk/dist/ssr/universal/utils'
import Link from 'progressive-web-sdk/dist/components/link'
import ErrorFooter from './error-footer'

const ErrorMaintenance = () => {
    return (
        <div className="error-wrapper m-404">
            <div className="error-background not-found">
                <picture>
                    <source
                        media="(max-width: 767px)"
                        srcSet={getAssetUrl('static/img/errors/error-maintenance-bg-mobile.png')}
                    />
                    <source
                        media="(min-width: 768) and (max-width: 1023px)"
                        srcSet={getAssetUrl('static/img/errors/error-maintenance-bg.png')}
                    />
                    <img
                        alt="error background"
                        srcSet={getAssetUrl('static/img/errors/error-maintenance-bg.png')}
                        title="error background"
                        className="background-image maintenance-image"
                        style={{height: '87vh'}}
                    />
                </picture>
            </div>
            <div className="error-message error-message-top">
                <div className="not-found-logo">
                    <a href="/" title="WHSmith Home" className="header-logo-link">
                        <img
                            alt="WHSmith Home"
                            srcSet={getAssetUrl('static/img/errors/logo.svg')}
                            className="site-error-logo"
                        />
                        <span className="visually-hidden">WHSmith</span>
                    </a>
                </div>
                <div className="error-message-body" style={{textAlign: 'center'}}>
                    <h1 className="error-message-title">Sorry, we are down for maintenance</h1>
                    <p className="error-message-text">We will be back shortly.</p>
                </div>
            </div>
            <ErrorFooter />
        </div>
    )
}

export default ErrorMaintenance
