import React from 'react'
import PropTypes from 'prop-types'

// import ContentAsset from '../../components/content-asset'
import Link from 'progressive-web-sdk/dist/components/link'
const AgeVerificationModal = ({close}) => (
    <div
        tabIndex="-1"
        role="dialog"
        className="ui-dialog ui-corner-all ui-widget ui-widget-content ui-front"
        aria-describedby="dialog-container"
        aria-labelledby="ui-id-1"
    >
        <div className="ui-dialog-content_wrapper">
            <button
                aria-label="close"
                type="button"
                className="ui-button ui-corner-all ui-widget ui-button-icon-only ui-dialog-titlebar-close"
                onClick={close}
            >
                <span className="ui-button-icon ui-icon ui-icon-closethick"></span>
                <span className="ui-button-icon-space"> </span>
            </button>
            <div id="dialog-container" className="ui-dialog-content ui-widget-content">
                <div className="content-page">
                    {/* <ContentAsset contentAssetId="login-benefit" /> */}
                    <div className="content-asset">
                        During age verification our data vendor, TransUnion, will use your date of
                        birth to try to verify you are over 18 and able to purchase restricted
                        products.{' '}
                        <Link href="/help/shopping-with-whsmith/age-verification/hel000026/">
                            Click here for full details
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

AgeVerificationModal.propTypes = {
    close: PropTypes.func
}

export default AgeVerificationModal
