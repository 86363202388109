import React, {Fragment, useState, useEffect, useRef} from 'react'
import {Swiper, SwiperSlide} from 'swiper/react/swiper-react'
import {Autoplay, Pagination, Navigation} from 'swiper'
import SkeletonBlock from 'progressive-web-sdk/dist/components/skeleton-block'
import ProductTileSkeleton from '../product-tile-skeleton'
import classNames from 'classnames'
import {isBrowser, largest} from '../../utils/utils'
//Error on  404 error page
//import {setInterval} from 'core-js'

const Slider = (props) => {
    const {
        data,
        slidesPerView,
        SlideComp,
        products,
        masterProducts,
        showPagination,
        faceoutType,
        faceoutTitle,
        index
    } = props

    const [isFaceoutLoadedFully, setIsFaceoutLoadedFully] = useState(false)
    const timer = useRef(null)
    const [columnGutterSpace, setColumnGutterSpace] = useState(10)

    const getLoopLogic = () => {
        if (products.length > slidesPerView) {
            return true
        }
        return false
    }
    const showNavigation = () => {
        if (
            products.length > slidesPerView &&
            (faceoutType !== 'ContentAsset' || (isBrowser() && window.innerWidth > 767))
        ) {
            return true
        } else {
            return false
        }
    }
    let skeletonBlock = '*'
    skeletonBlock = skeletonBlock.repeat(slidesPerView)
    skeletonBlock = Array.from(skeletonBlock)

    const adjustHeightOfTile = () => {
        let listOfClasses = [
            'product-tile-info',
            'availability-msg'
            // 'product-name',
            // 'contributor-tile',
            // 'product-pricing',
            // 'product-format-tile',
        ]

        listOfClasses.forEach((className) => {
            let listOfElements = [...document.getElementsByClassName(className)]
            if (listOfElements?.length) {
                const requiredHeight = largest(listOfElements.map((ele) => ele.offsetHeight))
                listOfElements.forEach((element) => {
                    element.style.maxHeight = `${requiredHeight}px`
                    element.style.height = `${requiredHeight}px`
                })
            }
        })
    }

    useEffect(() => {
        timer.current = setInterval(() => {
            adjustHeightOfTile()
        }, 100)
        return () => {
            clearInterval(timer.current)
        }
    }, [])

    useEffect(() => {
        if (isBrowser() && window.innerWidth > 1364) {
            setColumnGutterSpace(10)
        } else if (isBrowser() && window.innerWidth > 1023 && window.innerWidth < 1365) {
            setColumnGutterSpace(12)
        } else if (isBrowser() && window.innerWidth > 767 && window.innerWidth < 1024) {
            setColumnGutterSpace(11)
        } else if (isBrowser() && window.innerWidth < 768) {
            setColumnGutterSpace(10)
        }
    })

    return (
        <Fragment>
            {products &&
            products.length > 0 &&
            data &&
            data.length &&
            data.length > 0 &&
            masterProducts &&
            masterProducts.length > 0 &&
            products.length === masterProducts.length ? (
                <>
                    <Swiper
                        className={classNames({
                            active: isFaceoutLoadedFully,
                            'single-banner ': showPagination,
                            ...(faceoutType === 'ContentAsset'
                                ? {
                                      'content-asset-navigation': showPagination
                                  }
                                : {})
                        })}
                        onSlideChange={() => {
                            clearInterval(timer.current)
                            adjustHeightOfTile()
                        }}
                        // autoHeight={true}
                        spaceBetween={columnGutterSpace}
                        loop={getLoopLogic()}
                        pagination={
                            showPagination
                                ? {
                                      clickable: true
                                  }
                                : false
                        }
                        lazy={true}
                        allowTouchMove={
                            products.map((e) => e && e.name).length <= slidesPerView ? false : true
                        }
                        navigation={showNavigation()}
                        slidesPerView={slidesPerView}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true
                        }}
                        onInit={() => {
                            setIsFaceoutLoadedFully(true)
                            adjustHeightOfTile()
                            // setIsLoadedFully(true)
                        }}
                        modules={[Autoplay, Navigation, Pagination]}
                    >
                        {/*<div className="t-product-list__container-items">*/}
                        {data &&
                            data.length > 0 &&
                            data.map((dataId, index) => {
                                if (
                                    dataId &&
                                    (products[index] ||
                                        (masterProducts[index] && !masterProducts[index].c_images))
                                ) {
                                    return (
                                        <SwiperSlide
                                            key={
                                                typeof dataId === 'object'
                                                    ? dataId.id + '-' + index
                                                    : index
                                            }
                                            className={`swiper-slide`}
                                        >
                                            <SlideComp
                                                key={`product_${dataId}`}
                                                product={products[index]}
                                                masterProduct={masterProducts[index]}
                                                dataId={dataId}
                                                contentAssetId={dataId}
                                                faceoutTitle={faceoutTitle}
                                                index={index}
                                            />
                                        </SwiperSlide>
                                    )
                                } else {
                                    return null
                                }
                            })}
                        {/*</div>*/}
                    </Swiper>
                </>
            ) : null}
            {!isFaceoutLoadedFully && (
                <div className="skeleton-block-container">
                    {skeletonBlock.map((item, index) => {
                        return (
                            <div key={`skb-${index}`} className="skeleton-block">
                                {faceoutType === 'ContentAsset' ? (
                                    <SkeletonBlock width={'1403px'} height={'591px'} />
                                ) : (
                                    <ProductTileSkeleton />
                                )}
                            </div>
                        )
                    })}
                </div>
            )}
        </Fragment>
    )
}

Slider.defaultProps = {
    products: [],
    faceoutTitle: '',
    loop: true
}

export default Slider
