import React, {useEffect} from 'react'
import {Helmet} from 'react-helmet'
import PropTypes from 'prop-types'
import {getAssetUrl} from 'progressive-web-sdk/dist/ssr/universal/utils'
import Faceout from '../faceout'
import FaceoutCategory from '../faceout/contentFaceout'
import EinsteinRecommender from '../einstein/einsteinRecommender'
import ChartTile from '../chart-tile'
import Swiper, {Grid, Autoplay} from 'swiper'
import ContentAssetWithData from '../content-asset-with-data'
import LazyLoad from '../../components/lazyLoader-product'
import {useLocation} from 'react-router'

const PageTemplateWithData = (props) => {
    const {
        meta,
        pageDataPushed,
        pageStructure,
        allSlotsData,
        category,
        slidesPerView,
        canonicalUrl,
        isError
    } = props
    const defaultSlidesPerView = {
        forLargeDesktop: slidesPerView?.forLargeDesktop || 5,
        forDesktop: slidesPerView?.forDesktop || 4,
        forTablet: slidesPerView?.forTablet || 3,
        forMobile: slidesPerView?.forMobile || 2
    }
    const contentAssetSlidesPerView = {forDesktop: 1, forTablet: 1, forMobile: 1}
    const location = useLocation()
    const slidesPerViewForMostPopularSection = () => {
        if (typeof window !== 'undefined') {
            if (window.innerWidth >= 768 && window.innerWidth < 1024) {
                return 2.4266666666666667
            } else if (window.innerWidth >= 411 && window.innerWidth < 768) {
                return 1.3033333333333333
            } else if (window.innerWidth >= 320 && window.innerWidth < 410) {
                return 1.2
            }
        }
    }

    const slidesPerGroupForMostPopularSection = () => {
        if (typeof window !== 'undefined') {
            if (window.innerWidth >= 768 && window.innerWidth < 1024) {
                return 2
            } else if (window.innerWidth >= 320 && window.innerWidth < 768) {
                return 1
            }
        }
    }

    useEffect(() => {
        try {
            const serviceStripTimer = setInterval(() => {
                if (
                    typeof window !== 'undefined' &&
                    document.getElementsByClassName('service-strip-container')?.length
                ) {
                    if (
                        document.getElementsByClassName('service-strip-container')[0]?.scrollWidth >
                        window?.innerWidth
                    ) {
                        new Swiper('.service-strip-container', {
                            spaceBetween: 0,
                            allowTouchMove: true,
                            slidesPerView: 'auto',
                            autoplay: {
                                delay: 3000,
                                disableOnInteraction: false
                            },
                            loop: true,
                            modules: [Autoplay]
                        })
                    } else {
                        document.getElementsByClassName(
                            'service-strip-wrapper'
                        )[0].style.justifyContent = 'center'
                    }
                    clearInterval(serviceStripTimer)
                }
            }, 1000)

            const mostPopularSectionTimer = setInterval(() => {
                if (
                    typeof window !== 'undefined' &&
                    document.getElementsByClassName('most-popular-section-swiper-container')?.length
                ) {
                    if (
                        document.getElementsByClassName('most-popular-section-swiper-container')[0]
                            ?.scrollWidth > window?.innerWidth &&
                        window.innerWidth < 1024
                    ) {
                        new Swiper('.most-popular-section-swiper-container', {
                            spaceBetween: 0,
                            allowTouchMove: true,
                            slidesPerView: slidesPerViewForMostPopularSection(),
                            slidesPerGroup: slidesPerGroupForMostPopularSection()
                        })
                    }
                    clearInterval(mostPopularSectionTimer)
                }
            }, 1000)

            const recommendationSectionTimer = setInterval(() => {
                if (
                    typeof window !== 'undefined' &&
                    document.getElementsByClassName('recommendation-section-container')?.length
                ) {
                    if (
                        document.getElementsByClassName('recommendation-section-container')[0]
                            ?.scrollWidth > window?.innerWidth &&
                        window?.innerWidth < 1024
                    ) {
                        new Swiper('.recommendation-section-container', {
                            spaceBetween: 0,
                            allowTouchMove: true,
                            slidesPerView: slidesPerViewForMostPopularSection(),
                            slidesPerGroup: slidesPerGroupForMostPopularSection(),
                            grid: {
                                rows: 2
                            },
                            modules: [Grid]
                        })
                    }
                    clearInterval(recommendationSectionTimer)
                }
            }, 1000)

            const discoverOurBrandsTimer = setInterval(() => {
                if (
                    typeof window !== 'undefined' &&
                    document.getElementsByClassName('discover-brands-section-swiper-container')
                        ?.length
                ) {
                    if (
                        document.getElementsByClassName(
                            'discover-brands-section-swiper-container'
                        )[0]?.scrollWidth > window?.innerWidth &&
                        window.innerWidth < 1024
                    ) {
                        new Swiper('.discover-brands-section-swiper-container', {
                            spaceBetween: 10,
                            allowTouchMove: true,
                            slidesPerView: 'auto'
                        })
                    }
                    clearInterval(discoverOurBrandsTimer)
                }
            }, 1000)
        } catch (error) {
            console.error('Error', error)
        }
    }, [])

    // Enable 404 error page redirect for anchor tags
    function redirectAnchorForError(e) {
        var anchor = e.target.closest('a')
        if (anchor !== null) {
            let redirectUrl = anchor.getAttribute('href')
            window.location.replace(redirectUrl)
        }
    }

    useEffect(() => {
        if (isError) {
            document.addEventListener('click', redirectAnchorForError)
        }
    }, [])

    return (
        <div className="pt_storefront wrapper">
            <Helmet
                htmlAttributes={{
                    lang: 'en-US',
                    'data-reactroot': ''
                }}
            >
                <title itemProp="name">{meta?.title || process.env.PAGE_TITLE}</title>
                {canonicalUrl ? (
                    <link rel="canonical" itemProp="url" href={`${canonicalUrl}`} />
                ) : (
                    <link
                        rel="canonical"
                        itemProp="url"
                        href={`${process.env.HOSTNAME}${location.pathname.toLowerCase()}`}
                    />
                )}

                <meta
                    property="og:image"
                    content={meta?.image_path || getAssetUrl('static/meta/meta-image-fallback.png')}
                />
                <meta property="og:type" content={process.env.OG_TYPE} />
                <meta property="og:site_name" content={process.env.OG_SITE_NAME} />
                <meta property="og:title" content={meta?.title || process.env.PAGE_TITLE} />
                {typeof window !== 'undefined' ? (
                    <meta property="og:url" content={window.location.href} />
                ) : (
                    <meta property="og:url" content={process.env.OG_URL} />
                )}
                <meta
                    property="og:description"
                    content={meta?.description || process.env.OG_DESCRIPTION}
                />
                <link
                    rel="image_src"
                    href={meta?.image_path || getAssetUrl('static/meta/meta-image-fallback.png')}
                />
                <meta name="twitter:card" content={process.env.TWITTER_CARD} />
                <meta name="twitter:site" content={process.env.TWITTER_SITE} />
                <meta name="twitter:creator" content={process.env.TWIITTER_CREATOR} />
                <meta name="twitter:title" content={meta?.title || process.env.TWITTER_TITLE} />
                <meta
                    name="twitter:description"
                    content={meta?.description || process.env.TWITTER_DESCRIPTION}
                />
                <meta
                    name="twitter:image"
                    content={meta?.image_path || getAssetUrl('static/meta/meta-image-fallback.png')}
                />
                <meta
                    name="description"
                    itemProp="description"
                    content={meta?.description || process.env.META_DESCRIPTION}
                />
                <meta
                    name="keywords"
                    itemProp="keywords"
                    content={meta?.keywords || process.env.META_KEYWORDS}
                />
            </Helmet>
            <div
                className={
                    pageStructure?.layout === 'homepage' ? `lay-${pageStructure?.layout}` : ``
                }
            >
                <div className={`catlanding lay-${pageStructure?.layout}`}>
                    {allSlotsData?.map((e, idx) => {
                        const pageStrObj = e.pageStructure || e.productListPageStructure
                        if (pageStrObj?.contentType === 'Faceout') {
                            return (
                                <div
                                    className={`content-holder-padding ${
                                        pageStructure.layout ? `${pageStructure.layout}-mar-30` : ''
                                    }`}
                                    key={`${pageStrObj.categoryId}-${idx}`}
                                >
                                    {pageStrObj.faceoutType === 'ContentAsset' ? (
                                        <LazyLoad>
                                            <FaceoutCategory
                                                faceoutCategoryId={pageStrObj.categoryId}
                                                dataIds={pageStrObj.data}
                                                products={pageStrObj.data}
                                                faceoutType={pageStrObj.faceoutType}
                                                loop={true}
                                                faceoutTitle={pageStrObj.title}
                                                masterProducts={pageStrObj.data}
                                                faceoutLink={pageStrObj.link}
                                                slidesPerView={
                                                    pageStrObj.faceoutType === 'ContentAsset'
                                                        ? contentAssetSlidesPerView
                                                        : pageStrObj.slidesPerView !== undefined
                                                        ? pageStrObj.slidesPerView
                                                        : defaultSlidesPerView
                                                }
                                                reference={'3'}
                                                showPagination={
                                                    pageStrObj.faceoutType === 'ContentAsset'
                                                }
                                                pageDataPushed={pageDataPushed}
                                            />
                                        </LazyLoad>
                                    ) : (
                                        <Faceout
                                            faceoutCategoryId={pageStrObj.categoryId}
                                            dataIds={pageStrObj.data}
                                            products={pageStrObj.data}
                                            faceoutType={pageStrObj.contentType}
                                            loop={true}
                                            faceoutTitle={pageStrObj.title}
                                            masterProducts={e?.data}
                                            data={e?.data}
                                            faceoutLink={pageStrObj.link}
                                            slidesPerView={
                                                pageStrObj.slidesPerView !== undefined
                                                    ? pageStrObj.slidesPerView
                                                    : defaultSlidesPerView
                                            }
                                            reference={'3'}
                                            showPagination={false}
                                            pageDataPushed={pageDataPushed}
                                            category={category}
                                        />
                                    )}
                                </div>
                            )
                        }
                        if (pageStrObj.contentType === 'ChartTile') {
                            return (
                                <div className="content-holder-padding" key={`ChartTile-${idx}`}>
                                    <LazyLoad>
                                        <ChartTile productIds={e.data} />
                                    </LazyLoad>
                                </div>
                            )
                        }
                        if (pageStrObj.contentType === 'Recommender') {
                            return (
                                <>
                                    <EinsteinRecommender
                                        key={`Recommender-${idx}`}
                                        title={pageStrObj.title}
                                        viewMoreText={pageStrObj.viewMoreText}
                                        viewMoreLink={pageStrObj.viewMoreLink}
                                        recommenders={[e.data]}
                                    />
                                </>
                            )
                        }
                        if (pageStrObj.contentType === 'ContentAsset') {
                            return (
                                <div
                                    className={`content-holder-padding ${
                                        pageStructure.layout ? `${pageStructure.layout}-mar-30` : ''
                                    }`}
                                    key={`ContentAsset-${idx}`}
                                >
                                    <LazyLoad>
                                        <ContentAssetWithData contentAssetData={e.data} />
                                    </LazyLoad>
                                </div>
                            )
                        }
                    })}
                </div>
            </div>
        </div>
    )
}

PageTemplateWithData.propTypes = {
    pageStructure: PropTypes.object,
    allSlotsData: PropTypes.any,
    title: PropTypes.string,
    meta: PropTypes.object,
    pageDataPushed: PropTypes.any,
    slidesPerView: PropTypes.object,
    category: PropTypes.object
}

export default PageTemplateWithData