import React from 'react'
import PropTypes from 'prop-types'

const ProductTabItem = ({tabId, tabName, handleTabClick, isTabSelected, tabTitle, children}) => {
    return (
            <div className="tabs-item">
                <input
                    type="radio"
                    name={tabName}
                    id={tabId}
                    onClick={handleTabClick}
                    defaultChecked={isTabSelected}
                    className="tabs-item__switcher"
                />
                <div className="tabs-item__content">
                    <h3
                        className="toggle-icon js-toggle accordion-title visually-hidden"
                        data-tabswitch-id={tabId}
                    >
                        {tabTitle}
                    </h3>
                    <div className="tab-content-info">{children}</div>
                </div>
            </div>
    )
}

ProductTabItem.propTypes = {
    tabId: PropTypes.string,
    tabName: PropTypes.string,
    handleTabClick: PropTypes.func,
    isTabSelected: PropTypes.bool,
    tabTitle: PropTypes.string,
    children: PropTypes.node
}

export default ProductTabItem
