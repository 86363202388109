import {getConnector, CLIENT_ID} from '../../connector'
import {pushDatalayerEvent} from '../../utils/utils'
import {addPaymentToBasket} from '../my-basket/helper'
import {months} from '../checkout-shipping/helper'
import CryptoJS from 'crypto-js'

export const validateCreditCard = (value) => {
    var val = value.replace(/ /g, '')
    const regexp = /^(?:(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11}))$/

    if (!val) {
        return 'Please enter a valid card number'
    } else if (regexp.test(val)) {
        return ''
    } else {
        return 'Please enter a valid card number'
    }
}

export function creditCardType(cc) {
    let amex = new RegExp('^3[47][0-9]{13}$')
    let visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$')
    let cup1 = new RegExp('^62[0-9]{14}[0-9]*$')
    let cup2 = new RegExp('^81[0-9]{14}[0-9]*$')
    let mastercard = new RegExp('^5[1-5][0-9]{14}$')
    let mastercard2 = new RegExp('^2[2-7][0-9]{14}$')
    let disco1 = new RegExp('^6011[0-9]{12}[0-9]*$')
    let disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$')
    let disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$')
    let diners = new RegExp('^3[0689][0-9]{12}[0-9]*$')
    let jcb = new RegExp('^35[0-9]{14}[0-9]*$')
    if (visa.test(cc)) {
        return 'VISA'
    }
    if (amex.test(cc)) {
        return 'AMEX'
    }
    if (mastercard.test(cc) || mastercard2.test(cc)) {
        return 'MASTERCARD'
    }
    if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
        return 'DISCOVER'
    }
    if (diners.test(cc)) {
        return 'DINERS'
    }
    if (jcb.test(cc)) {
        return 'JCB'
    }
    if (cup1.test(cc) || cup2.test(cc)) {
        return 'CHINA_UNION_PAY'
    }
    return ''
}
export const validateCardName = (value) => {
    if (!value) return 'Please enter the name as shown on the card'
    else return ''
}
export const validateSecurityCode = (value) => {
    if (!value) return 'Please enter the security code'
    else if (value.length != 4 && value.length != 3)
        return 'Security code must be 3 or 4 digits long'
    else return ''
}
export const validateExpirationDate = (value) => {
    if (!value) return 'Please enter a valid expiry date (MM/YY)'
    else return ''
}

export const validateEmail = (value) => {
    // const emailRegex = /^[\\w.%+-]+@[\\w.-]+\\.[\\w]{2,6}$/
    const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!value) return 'This field is required.'
    else if (!emailRegex.test(value)) return 'Please make sure your email format is correct'
    else return ''
}

export const validatePasswordLength = (value) => {
    if (!value) return 'This field is required.'
    return value.length < 8 ? 'Please enter at least 8 characters.' : ''
}

export const validatePassword = (value) => {
    if (value.length < 8) return false
    else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]*$/.test(value)) return false
    else return true
}

export const checkPasswordsMatch = (password, confirmPassword) => {
    return password !== confirmPassword ? 'Please make sure your passwords match' : ''
}

export const checkIfUserExists = async (email) => {
    const checkUserExists = await fetch(`/customersAuthTrustedsystem/${email}`)
    return checkUserExists?.status === 200 ? await checkUserExists.json() : false
}

export const checkIfAgeVerified = async (
    customerId,
    userDetails,
    addressData,
    houseNumber,
    houseName,
    streetName,
    abodeNumber,
    isQafUsed
) => {
    let postCode = ''
    if (addressData && addressData !== 'undefined' && Object.keys(addressData).length > 0) {
        postCode = addressData.postal ? addressData.postal : ''
    }
    // else {
    //     return true
    // }
    let month_in_dob = months
        .indexOf(userDetails.month)
        .toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false})
    let body = {
        Person: {
            Title: userDetails.title,
            Forename: userDetails.firstName,
            Surname: userDetails.lastName,
            Date_Of_Birth: `${userDetails.year}-${month_in_dob}-${userDetails.day}`,
            SecondName: ''
        },
        Address: [
            {
                HouseNumber: houseNumber?.length
                    ? houseNumber
                    : findValueForAgeVerificationKeys('HouseNumber', addressData, isQafUsed),
                HouseName: houseName?.length
                    ? houseName
                    : findValueForAgeVerificationKeys('HouseName', addressData, isQafUsed),
                AbodeNumber: abodeNumber?.length
                    ? abodeNumber
                    : findValueForAgeVerificationKeys('AbodeNumber', addressData, isQafUsed),
                AddressLine1: streetName?.length
                    ? streetName
                    : findValueForAgeVerificationKeys('AddressLine1', addressData, isQafUsed),
                AddressLine2: addressData?.city,
                PostCode: postCode
            }
        ],
        RequestDetail: {
            ClientTag: userDetails.email
        }
    }

    let response = await fetch(`/age-verification`, {
        headers: {
            'content-type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(body)
    })

    let responseObject = await response.json()
    return responseObject.Summary_FinalResult
}

const getAbodeNumber = (address1) => {
    let firstNum = address1.match(/(\d+)/g)[0]
    let ind = address1.search(address1.match(/(\d+)/g)[0])
    let textAfterNumber = address1.slice(ind + firstNum.length)
    let strArray = textAfterNumber.split(' ')
    return strArray[0] === ''
        ? address1.slice(0, ind + firstNum.length).trim()
        : address1.slice(0, ind + firstNum.length) + strArray[0].trim()
}

const getStringAfterFirstNumber = (address) => {
    let firstNum = address.match(/(\d+)/g)[0]
    let ind = address.search(address.match(/(\d+)/g)[0])
    let textAfterNumber = address.slice(ind + firstNum.length)
    let strArray = textAfterNumber.split(' ')
    strArray.shift()
    return strArray.join(' ')
}

const findValueForAgeVerificationKeys = (key, addressData, isQafUsed) => {
    if (isQafUsed) return ''
    let {address1 = '', address2 = ''} = addressData
    let isBothAddressPresent = address1 && address2
    let hasOnlyAddress1 = address1 && !address2

    if (key === 'AbodeNumber') {
        if (isBothAddressPresent) {
            if (address1.match(/(\d+)/g)) {
                return getAbodeNumber(address1)
            } else return ''
        }
        return ''
    } else if (key === 'HouseName') {
        if (isBothAddressPresent) {
            if (address1.match(/(\d+)/g)) {
                if (parseInt(address1)) {
                    let firstNum = address1.match(/(\d+)/g)[0]
                    let ind = address1.search(address1.match(/(\d+)/g)[0])
                    let textAfterNumber = address1.slice(ind + firstNum.length)
                    let strArr = textAfterNumber.split(' ')
                    strArr.shift()
                    return strArr.join(' ')
                } else {
                    let firstNum = address1.match(/(\d+)/g)[0]
                    let ind = address1.search(address1.match(/(\d+)/g)[0])
                    let textAfterNumber = address1.slice(ind + firstNum.length)
                    return textAfterNumber.replace(/[^a-zA-Z ]/g, '').trim()
                }
            }
            return address1
        }
        return ''
    } else if (key === 'HouseNumber') {
        if (isBothAddressPresent) {
            if (address2.match(/(\d+)/g)) {
                if (parseInt(address2)) {
                    let strArray = address2.split(' ')
                    return strArray?.[0] || ''
                }
                return ''
            } else return ''
        } else if (hasOnlyAddress1) {
            if (address1.match(/(\d+)/g)) {
                if (parseInt(address1)) {
                    let strArray = address1.split(' ')
                    return strArray?.[0] || ''
                }
                return ''
            }
            return ''
        } else return ''
    } else {
        ///key=AddressLine1
        if (isBothAddressPresent) {
            if (address2.match(/(\d+)/g)) {
                if (parseInt(address2)) {
                    return getStringAfterFirstNumber(address2)
                }
                return address2
            }
            return address2
        } else if (hasOnlyAddress1) {
            if (address1.match(/(\d+)/g)) {
                if (parseInt(address1)) {
                    return getStringAfterFirstNumber(address1)
                }
                return address1
            }
            return address1
        }
    }
}

export const cancelOrder = async (orderId, decisionResult) => {
    let connector = getConnector()
    let response = await fetch(
        `/cancelOrder/${orderId}?decision_result=${decisionResult || 'NONE'}`,
        {
            headers: {
                ...connector.client.defaultHeaders,
                'content-type': 'application/json'
            },
            method: 'GET'
        }
    )
    let responseObject = response.json()

    return responseObject
}

export const confirmOrder = async (
    orderId,
    decisionResult,
    transactionId,
    transactionStatus,
    transactionType,
    paymentStatus
) => {
    let connector = getConnector()
    let response = await fetch(
        `/confirmOrder/${orderId}?decision_result=${decisionResult ||
            'NONE'}&transactionId=${transactionId}&transactionStatus=${transactionStatus}&transactionType=${transactionType}&paymentStatus=${paymentStatus}`,
        {
            headers: {
                ...connector.client.defaultHeaders,
                'content-type': 'application/json'
            },
            method: 'GET'
        }
    )
    let responseObject = await response.json()
    return responseObject
}

export const checkoutEvent = (step, option) => {
    pushDatalayerEvent({
        event: 'checkoutOption',
        ecommerce: {
            checkout_option: {
                actionField: {
                    step: step,
                    option: option
                }
            }
        }
    })
}

export const pushAnalyticPaymentMethod = (paymentType) => {
    pushDatalayerEvent({
        event: 'checkoutOption',
        ecommerce: {
            checkout_option: {
                actionField: {
                    step: '4',
                    option: paymentType
                }
            }
        }
    })
}

const updatePaymentInstrumentIdInSessionStorage = (paymentInstrument, giftcard) => {
    const cardsString = sessionStorage.getItem('gift_cards')
    const cardsJsonString = JSON.parse(cardsString)
    const cardsJson = cardsJsonString.map((card) => {
        card.coverValue = parseFloat(card.coverValue)
        return card
    })

    for (var q in cardsJson) {
        if (giftcard.number === cardsJson[q].number) {
            cardsJson[q].payment_instrument_id = paymentInstrument.payment_instrument_id
        }
    }

    sessionStorage.setItem('gift_cards', JSON.stringify(cardsJson))
}

export const addGiftCardToPaymentInstruments = (cardsUsed) => {
    let connector = getConnector()
    let basketID = localStorage.getItem('basket_id')

    return new Promise((resolutionFunc, rejectionFunc) => {
        function updateDone() {
            resolutionFunc()
        }

        let i = 0,
            limit = cardsUsed.length
        function loopCheck() {
            i++
            if (i < limit) {
                loop(i)
            } else {
                updateDone()
            }
        }
        async function loop(i) {
            let item = cardsUsed[i]
            const paymentBody = {
                amount: parseFloat(parseFloat(item.coverValue).toFixed(2)),

                // gift_certificate_code: item.number,

                // payment_card: {
                //     credit_card_token: item.pin
                // },
                payment_method_id: 'GIFT_CERTIFICATE'
            }
            let basket = await addPaymentToBasket(basketID, paymentBody)
            let paymentInstrument = basket.payment_instruments
            let lastpaymentInstrument = paymentInstrument[paymentInstrument.length - 1]
            updatePaymentInstrumentIdInSessionStorage(lastpaymentInstrument, item)
            loopCheck()
        }
        if (limit > 0) {
            loop(i)
        } else {
            updateDone()
        }
    })
}

export const addNote = async (orderId, subject, text) => {
    let connector = getConnector()

    let body = {
        c_order_note: [
            {
                subject: subject,
                text: text
            }
        ]
    }
    let url = `/updateOrderNotes/${orderId}`

    let response = await fetch(url, {
        headers: {
            ...connector.client.defaultHeaders,
            'content-type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(body)
    })

    let json = await response.json()

    return json
}

export const transactionsNotification = async (orderId, subject, text) => {
    let connector = getConnector()
    const basicAuth =
        'Basic ' + global.btoa(`${process.env.PAY360_USERNAME}:${process.env.PAY360_PASSWORD}`)
    const url = `https://api.mite.pay360.com/acceptor/rest/transactions/${process.env.PAY360_INTALLATIONID}/${req.params.transactionId}/payment`

    let response = await fetch(url, {
        headers: {
            ...connector.client.defaultHeaders,
            'content-type': 'application/json',
            Authorization: basicAuth
        },
        method: 'POST',
        body: JSON.stringify({})
    })
    let json = await response.json()
}

export const getNotesMessage = (type, data) => {
    let msg = ''

    switch (type) {
        case 'paypal_message_1':
            msg = `
            PayPal Redirect Request
            ==================General==================================
            General status of transaction: ${data?.outcome?.status}
            General reason code: ${data?.outcome?.reasonCode}
            General reason message: ${data?.outcome?.reasonMessage}
            ================Transaction Details======================
            Transaction ID: ${data?.transaction?.transactionId}
            Order ID: ${data?.transaction?.merchantRef}
            Transaction Status: ${data?.transaction?.status}
            Transaction type: ${data?.transaction?.type}
            Transaction Time: ${data?.transaction?.transactionTime}
            Transaction Received Time: ${data?.transaction?.receivedTime}`
            break

        case 'paypal_message_2':
            msg = `
            ================Transaction=========================
            Type of transaction: ${data?.transaction?.type}
            General status of transaction: ${data?.transaction?.status}
            Description: ${data?.transaction?.merchantDescription}
            Amount: ${data?.transaction?.amount}
            Transaction Time: ${data?.transaction?.transactionTime}
            Received Time: ${data?.transaction?.receivedTime}
            ================AUTH Response======================
            Acquirer Name: ${data?.processing?.authResponse?.acquirerName}
            Gateway Message: COMPLETED
            ================Fraud Decision=====================
            Decision Result: ${data?.processing?.decision?.decisionResult}
            Decision Source: ${data?.processing?.decision?.decisionSource}
            Requested Type: ${data?.processing?.decision?.requestedType}
            Decided Type: ${data?.processing?.decision?.decidedType}`
            break

        case 'paypal_message_3':
            msg = `Pay360 Payment Notes PayPal Resume Request
            ==================General==================================
            General status of transaction: ${data?.outcome?.status}
            General reason code: ${data?.outcome?.reasonCode}
            General reason message: ${data?.outcome?.reasonMessage}
            ================Detail Information=======================
            Detail Gateway Reference: ${data?.processing?.authResponse?.gatewayReference}
            Detail Gateway Message: ${data?.processing?.authResponse?.gatewayMessage}
            ================Transaction Details======================
            Transaction ID: ${data?.transaction?.transactionId}
            Order ID: ${data?.transaction?.merchantRef}
            Transaction Status: ${data?.transaction?.status}
            Transaction type: ${data?.transaction?.type}
            Transaction Time: ${data?.transaction?.transactionTime}
            Transaction Received Time: ${data?.transaction?.receivedTime}`
            break
        case 'gift_card_message':
            msg = `SVS redemption  Response
                ==================Response==================================
                Return Code: ${data?.returnCode[0].returnCode[0]}
                Return Message: ${data?.returnCode[0].returnDescription[0]}
                Authorization Code: ${data?.authorizationCode[0]}
                ==================Approved Amount==================================
                Amount: ${data?.approvedAmount[0].amount[0]}
                Currency: ${data?.approvedAmount[0].currency[0]}
                ==================Card==================================
                Card Number: ${data?.card[0].cardNumber[0]}
                Card Currency: ${data?.card[0].cardCurrency[0]}
                EOV Date: ${data?.card[0].eovDate[0]}
                ==================Tecnical Information==================================
                Stan: ${data?.stan[0]}
                TransactionID: ${data?.transactionID[0]}`
            break

        case 'card_message':
            msg = `Pay360 Transaction Notes
                ================Transaction=========================
                Type of transaction: ${data?.transaction?.type}
                General status of transaction: ${data?.transaction?.status}
                Amount: ${data?.transaction?.amount}
                Transaction Time: ${data?.transaction?.transactionTime}
                Received Time: ${data?.transaction?.transactionTime}
                ================AUTH Response======================
                Auth status of transaction: ${data?.processing.authResponse.status}
                Acquirer Name: ${data?.processing.authResponse.acquirerName}
                Gateway Message: ${data?.processing.authResponse.gatewayMessage}
                AVS Address Check: ${data?.processing.authResponse.avsAddressCheck}
                CV2 Check: ${data?.processing.authResponse.cv2Check}
                AVS Address Check: ${data?.processing.authResponse.avsAddressCheck}
                ================Fraud Decision=====================
                Decision Result: ${data?.processing?.decision?.decisionResult}
                Decision Source: ${data?.processing?.decision?.decisionSource}
                Requested Type: ${data?.processing?.decision?.requestedType}
                Decided Type: ${data?.processing?.decision?.decidedType}`
            break

        case 'storecredit_message':
            msg = `Order paid with Store Credit. Amount used :${parseFloat(data.amount).toFixed(2)}`
            break
        case 'revolut':
            msg = `Revolut Payment Notes
            ==================Transaction Details=================================
            Transaction ID: ${data?.id}
            Transaction type: ${data?.type}
            Order ID: ${data?.merchant_order_ext_ref}
            Payment Method: ${data?.payments?.[0]?.payment_method?.type}
            Amount: ${
                data?.order_amount?.value
                    ? data?.order_amount?.value / 100
                    : data?.order_amount?.value
            }
            Currency: ${data?.order_amount?.currency}
            Transaction Time: ${data?.created_at}
            Transaction Received Time: ${data?.completed_at}`
            break
        case 'paybybankapp':
            msg = `Pay By Bank Payment Notes
            ==================Transaction Details=================================
            Transaction ID: ${data?.id}
            Transaction type: ${data?.transactionType}
            Order ID: ${data?.invoiceId}
            Payment Method: ${data?.paymentMethod}
            Amount: ${data?.amount}
            Payout Amount: ${data?.payoutAmount}
            Currency: ${data?.currency}`
            break
        case 'laybuy':
            msg = `Laybuy Payment Notes
            ==================Transaction Details=================================
            Transaction ID: ${data?.orderId}
            Result: ${data?.result}`
            break
        default:
            break
    }

    return msg
}

export const createOrderInRevolut = async (
    amount,
    orderDetails,
    sentShippingAddress,
    isHotProductPresent
) => {
    let shipping_obj = {}
    if (sentShippingAddress) {
        ;(shipping_obj.country_code = orderDetails?.billing_address?.country_code),
            (shipping_obj.postcode = orderDetails?.billing_address?.postal_code),
            (shipping_obj.street_line_1 = orderDetails?.billing_address?.address1),
            (shipping_obj.street_line_2 = orderDetails?.billing_address?.address2),
            (shipping_obj.city = orderDetails?.billing_address?.city)
    }
    let shipping_obj_len = Object.keys(shipping_obj).length

    let payload = {
        amount: amount,
        currency: 'GBP',
        merchant_order_ext_ref: orderDetails?.order_no,
        email: orderDetails?.customer_info?.email,
        shipping_address: sentShippingAddress && shipping_obj_len > 0 ? shipping_obj : undefined,
        enforce_challenge: isHotProductPresent ? 'FORCED' : 'AUTOMATIC'
    }
    let encPayload = CryptoJS.AES.encrypt(
        JSON.stringify(payload),
        process.env.SECRET_KEY
    ).toString()

    let response = await fetch('/create-revolut-order', {
        headers: {
            'content-type': 'text/plain'
        },
        method: 'POST',
        body: encPayload
    })
    let json = await response.json()
    if (json.status === 500) {
        return false
    }
    return json
}

export const retrieveRevolutOrder = async (orderID) => {
    let response = await fetch(`/retrieve-revolut-order?revOrderId=${orderID}`, {
        headers: {
            'content-type': 'application/json'
        },
        method: 'GET'
    })
    let json = await response.json()
    return json
}

export const updateRevolutDetailsInBm = async (orderId, revolutOrderId, revolutPublicId) => {
    let connector = getConnector()
    let response = await fetch(
        `/updateRevolutDataInBm/${orderId}?revolutOrderId=${revolutOrderId}&revolutPublicId=${revolutPublicId}`,
        {
            headers: {
                ...connector.client.defaultHeaders,
                'content-type': 'application/json'
            },
            method: 'GET'
        }
    )
    let responseObject = await response.json()
    return responseObject
}

export const searchBmOrderWithPublicId = async (revolutPublicId) => {
    let connector = getConnector()
    let response = await fetch(`/searchOrderWithPublicId/${revolutPublicId}`, {
        headers: {
            ...connector.client.defaultHeaders,
            'content-type': 'application/json'
        },
        method: 'GET'
    })
    let responseObject = await response.json()
    return responseObject
}

export const getAuthorizationTokenPBB = async (invoiceId, amount) => {
    const connector = getConnector()
    const url = '/get-pbb-authorization'
    let response = await fetch(url, {
        headers: {
            ...connector.client.defaultHeaders,
            'content-type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({
            invoiceId: CryptoJS.AES.encrypt(invoiceId, process.env.SECRET_KEY).toString(),
            amount: CryptoJS.AES.encrypt(JSON.stringify(amount), process.env.SECRET_KEY).toString()
        })
    })
    let responseObject = await response.json()
    return responseObject
}
export const createPBBOrder = async (orderId, amount, email, token) => {
    const connector = getConnector()
    const url = '/create-pbb-order'

    const encryptedOrderId = CryptoJS.AES.encrypt(orderId, process.env.SECRET_KEY).toString()
    const encryptedAmount = CryptoJS.AES.encrypt(
        JSON.stringify(amount),
        process.env.SECRET_KEY
    ).toString()
    const encryptedEmail = CryptoJS.AES.encrypt(email, process.env.SECRET_KEY).toString()

    let response = await fetch(url, {
        headers: {
            ...connector.client.defaultHeaders,
            'content-type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({
            orderId: encryptedOrderId,
            amount: encryptedAmount,
            email: encryptedEmail,
            token: token
        })
    })
    let responseObject = await response.json()
    return responseObject
}
