import fetch from 'cross-fetch'
import {addInteractionErrorEvent} from '../../utils/gtmUtils';
// import {HTTPError} from 'progressive-web-sdk/dist/ssr/universal/errors'

export const getContentAsset = async (connector, contentAssetId) => {
    try {
        if (!contentAssetId) {
            console.error('contentAssetId not found.')
            return await Promise.resolve([])
        }
        if (contentAssetId.length > 0 && contentAssetId.length !== 'undefined') {
            let responseData = await fetch(
                `${process.env.HOSTNAME}/get-content/?contentAssetId=${contentAssetId}`,
                {
                    headers: {
                        ...connector.client.defaultHeaders,
                        'content-type': 'application/json'
                    },
                    method: 'GET'
                }
            )
            if (responseData && responseData.ok) return await responseData.json()
        }
    } catch (err) {
        console.error('Error fetching content asset.', err)
    }
}

export const getContentAssets = async (connector, assetIds) => {
    try {
        let assets = []
        for (let i = 0; i < assetIds.length; i++) {
            const d = assetIds[i]
            const asset = await getContentAsset(connector, d)
            if (asset.c_body) {
                assets = assets.concat(asset)
            }
        }
        return assets
    } catch (err) {
        console.error('Error fetching content assets. ', err)
    }
}
