import fetch from 'cross-fetch'
import {getConnector, CLIENT_ID} from '../../connector'
import {getCookie} from '../../utils/storage-utils'

export const getDataBearerToken = async () => {
    const basicAuth = 'Basic ' + window.btoa(`${CLIENT_ID}:${process.env.ACDW_PASSWORD}`)
    const url = `/mobify/proxy/acdw/dw/oauth2/access_token?client_id=${CLIENT_ID}`
    let connector = getConnector()

    let response = await fetch(url, {
        headers: {
            ...connector.client.defaultHeaders,
            'content-type': 'application/x-www-form-urlencoded',
            Authorization: basicAuth
        },
        method: 'POST',
        body: new URLSearchParams({
            grant_type: 'client_credentials'
        })
    })
    let responseObject = response.json()
    return responseObject
}

export const fetchCustomObject = async ({token, email}) => {
    const url = `/mobify/proxy/api/s/-/dw/data/v21_3/sites/whsmith/custom_objects/MarketingOptionsSubscription/${email}`
    let response = await fetch(url, {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        method: 'GET'
    })
    let responseObject = response.json()
    return responseObject
}
export const updateCustomObject = async ({email, token, data, isCustomObjectAvailable}) => {
    const url = `/mobify/proxy/api/s/-/dw/data/v21_3/sites/whsmith/custom_objects/MarketingOptionsSubscription/${email}`
    let response = await fetch(url, {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        method: isCustomObjectAvailable ? 'PATCH' : 'PUT',
        body: JSON.stringify(data)
    })
    let responseObject = response.json()
    return responseObject
}

export const fetchAddresses = async () => {
    let connector = getConnector()

    const customerId = getCookie('customerId')
    const response = await fetch(`/fetchAddresses/${customerId}`, {
        headers: {
            ...connector.client.defaultHeaders,
            'content-type': 'application/json'
        },
        method: 'GET'
    })
    return response
}

export const fetchUserData = async (authHeader, customerId = null) => {
    let connector = getConnector()

    const response = await fetch(
        `/fetchUserData/${customerId !== null ? customerId : getCookie('customerId')}`,
        {
            headers: {
                ...connector.client.defaultHeaders,
                'content-type': 'application/json',
                Authorization: authHeader
            },
            method: 'GET'
        }
    )
    return response
}

export const getShipmentDetails = async (basketId, shipmentId) => {
    let connector = getConnector()
    let response = await fetch(`/shiftment_methods?basketId=${basketId}&shipmentId=${shipmentId}`, {
        headers: {
            ...connector.client.defaultHeaders,
            'content-type': 'application/json'
        },
        method: 'GET'
    })
    // refreshBasketToken(connector)
    return response.json()
}

export const updateShipmentDetails = async (basketId, shipmentId, body) => {
    let connector = getConnector()

    let response = await fetch(`/updateShipmentDetails/${basketId}/${shipmentId}`, {
        headers: {
            ...connector.client.defaultHeaders,
            'content-type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(body)
    })

    return response.json()
}

export const updateCustomerDetailsInBasket = async (basketId, body) => {
    let connector = getConnector()
    let response = await fetch(`/updateCustomerDetailsInBasket/${basketId}`, {
        headers: {
            ...connector.client.defaultHeaders,
            'content-type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(body)
    })

    // refreshBasketToken(connector)
    return response.json()
}

export const updateShipmentMethod = async (basketId, shipmentId, body) => {
    let connector = getConnector()

    if (body.id !== '') {
        let response = await fetch(`/updateShipmentMethod/${basketId}/${shipmentId}`, {
            headers: {
                ...connector.client.defaultHeaders,
                'content-type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(body)
        })
        return response.json()
    } else {
        return false
    }
}

export const updateBillingAddress = async (basketId, body) => {
    let connector = getConnector()
    let response = await fetch(`/updateBillingAddress/${basketId}`, {
        headers: {
            ...connector.client.defaultHeaders,
            'content-type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(body)
    })

    // refreshBasketToken(connector)
    return response.json()
}

export const updateOrderTotal = async (basketId, body) => {
    let connector = getConnector()
    let response = await fetch(`/updateOrderTotal/${basketId}`, {
        headers: {
            ...connector.client.defaultHeaders,
            'content-type': 'application/json'
        },
        method: 'PATCH',
        body: JSON.stringify(body)
    })
    // refreshBasketToken(connector)
    return response.json()
}

export const getCustomerDetails = async (customerId) => {
    try {
        let connector = getConnector()
        let response = await fetch(`/getCustomerDetails/${customerId}`, {
            headers: {
                ...connector.client.defaultHeaders,
                'content-type': 'application/json'
            },
            method: 'GET'
        })
        if (response.status === 200) return await response.json()
        else return false
    } catch (error) {
        return false
    }
}

export const updateUserPhoneNumber = async (body, customerId) => {
    const bodyData = {
        phone_mobile: body?.phone,
        c_isEmailRequired: false
    }
    try {
        const res = await updateCustomerDetails(getCookie('customerId'), bodyData)

        return res.json()
    } catch (error) {
        console.error('Error updating user phone >>>', error)
    }
}

export const updateUserData = async (body, customerId) => {
    const bodyData = {
        email: body?.email,
        first_name: body?.firstName,
        last_name: body?.lastName,
        login: body?.email,
        phone_mobile: body?.phone,
        title: body?.title
    }
    try {
        const res = await fetch('/ocapi-fetch', {
            body: JSON.stringify({
                headers: {
                    'content-type': 'application/json'
                },
                method: 'PATCH',
                body: bodyData,
                url: `/customers/${customerId}`
            }),
            method: 'POST',
            credentials: 'include'
        })
        return res.json()
    } catch (error) {
        console.error('Error updating user data >>>', error)
    }
}

export const updateCustomerDetails = async (customerId, body) => {
    let connector = getConnector()
    let response = await fetch(`/updateCustomerDetails/${customerId}`, {
        headers: {
            ...connector.client.defaultHeaders,
            'content-type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(body)
    })
    return response.json()
}

export const handleAdditionToAddressBook = async (
    countryCode,
    addressData,
    userData,
    shippingAddress,
    history
) => {
    const connector = getConnector()
    const customerId = getCookie('customerId')

    // Fetch all exising addresses

    let path = `/getCustomerAddresses/${customerId}`
    let updateCustomerAddressesPath = `/updateCustomerAddresses/${customerId}`
    let response = await fetch(path, {
        headers: {
            ...connector.client.defaultHeaders,
            'content-type': 'application/json'
        },
        method: 'GET'
    })
    let storeAddress = await response.json()

    let savedAddress = storeAddress.data
    let count = 0
    let addressObj = {
        address1: '',
        address2: '',
        city: '',
        state: '',
        postal: '',
        address_id: ''
    }
    if (addressData.address1 && addressData.city && addressData.postal) {
        let addressIdArr = []
        savedAddress &&
            savedAddress.some((data) => {
                addressIdArr.push(data.address_id)
                if (
                    data.address1 === addressData.address1 &&
                    data.city === addressData.city &&
                    data.postal_code === addressData.postal &&
                    count < 1
                ) {
                    count++
                }
                const result = addressIdArr.reduce(
                    (json, val) => ({...json, [val]: (json[val] | 0) + 1}),
                    {}
                )
                addressObj = {
                    address1: addressData.address1,
                    address2: addressData.address2,
                    city: addressData.city,
                    county: addressData.county,
                    postal: addressData.postal,
                    address_id:
                        result && result[addressData.city] && result[addressData.city] > 0
                            ? addressData.city + '-' + result[addressData.city]
                            : addressData.city
                }
            })
    } else {
        if (shippingAddress.address1 && shippingAddress.city && shippingAddress.postal) {
            let addressIdArr = []
            savedAddress &&
                savedAddress.some((data) => {
                    addressIdArr.push(data.address_id)
                    let saveAdd1 = data.address1 + ',' + data.city + ',' + data.postal_code
                    let saveAdd2 =
                        shippingAddress.address1 +
                        ',' +
                        shippingAddress.city +
                        ',' +
                        shippingAddress.postal
                    if (saveAdd1 === saveAdd2) {
                        count++
                    }
                })
            const result = addressIdArr.reduce(
                (json, val) => ({...json, [val]: (json[val] | 0) + 1}),
                {}
            )
            addressObj = {
                address1: shippingAddress.address1,
                address2: shippingAddress.address2,
                city: shippingAddress.city,
                county: shippingAddress.county,
                postal: shippingAddress.postal,
                address_id:
                    result && result[shippingAddress.city] && result[shippingAddress.city] > 0
                        ? shippingAddress.city + '-' + result[shippingAddress.city]
                        : shippingAddress.city
            }
        }
    }
    const bodyData = {
        salutation: userData.title,
        first_name: userData.firstName,
        last_name: userData.lastName,
        country_code: countryCode,
        address1: addressObj.address1,
        address2: addressObj.address2,
        city: addressObj.city,
        state_code: addressObj.county,
        postal_code: addressObj.postal,
        address_id: addressObj.address_id
    }
    if (count < 1) {
        let address_res = await fetch(updateCustomerAddressesPath, {
            headers: {
                ...connector.client.defaultHeaders,
                'content-type': 'application/json',
                Authorization: basicAuth
            },
            method: 'POST',
            body: JSON.stringify(bodyData)
        })
        if (address_res.status == 401 || address_res.status == 400) {
            history.push({pathname: '/login/', state: {path: location.pathname}})
        }
    }
}

export const titleOptions = [
    'Select',
    'Mr',
    'Mrs',
    'Miss',
    'Ms',
    'Mx',
    'Ind',
    'Misc',
    'Dr',
    'Prof',
    'Prefer not to say'
]

export const counties = [
    'Select a county',
    'Aberdeenshire',
    'Anglesey',
    'Angus',
    'Antrim',
    'Co. Armagh',
    'Avon',
    'Argyle and Bute',
    'Argyll',
    'Ayrshire',
    'Bedfordshire',
    'Berkshire',
    'Buckinghamshire',
    'Cambridgeshire',
    'Channel Islands',
    'Cheshire',
    'Cleveland',
    'Clwyd',
    'Co Durham',
    'Cornwall',
    'County Durham',
    'Cumbria',
    'Derbyshire',
    'Devon',
    'Dorset',
    'Dumfries and Galloway',
    'Dyfed',
    'East Dunbartonshire',
    'East Riding of Yorkshire',
    'East Sussex',
    'East Yorkshire',
    'Edinburgh',
    'Essex',
    'Fife',
    'Flintshire',
    'Glasgow',
    'Gloucestershire',
    'Greater London',
    'Greater Manchester',
    'Gwynedd',
    'Hampshire',
    'Herefordshire',
    'Hertfordshire',
    'Hertsfordshire',
    'Inverness-shire',
    'Isle Of Man',
    'Isle of Wight',
    'Kent',
    'Lanarkshire',
    'Lancashire',
    'Lancaster',
    'Leicestershire',
    'Lincolnshire',
    'London',
    'Londonderry',
    'Manchester',
    'Merseyside',
    'Mid Glamorgan',
    'Middlesex',
    'Midlothian',
    'Monmouthshire',
    'Morayshire',
    'Newport',
    'Norfolk',
    'North Humberside',
    'North Lincolnshire',
    'North Yorkshire',
    'Northamptonshire',
    'Northumberland',
    'Nottinghamshire',
    'Oxfordshire',
    'Oxon',
    'Pembrokeshire',
    'Perth and Kinross',
    'Perthshire',
    'Powys',
    'Renfrewshire',
    'Roxburghshire',
    'Royal Berkshire',
    'Selkirkshire',
    'Shropshire',
    'Somerset',
    'South Glamorgan',
    'South Humberside',
    'South Lanarkshire',
    'South Tyneside',
    'South Yorkshire',
    'Staffordshire',
    'Stirlingshire',
    'Stockport',
    'Strathclyde',
    'Suffolk',
    'Surrey',
    'Torfaen',
    'Tyne and Wear',
    'Vale of Glamorgan',
    'Wales',
    'Warwickshire',
    'West Glamorgan',
    'West Lothian',
    'West Midlands',
    'West Sussex',
    'West Yorkshire',
    'Wiltshire',
    'Worcestershire',
    'Wrexham',
    'Yorkshire'
]

export const days = [
    'Select',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31'
]

export const months = [
    'Select',
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
]

export const years = [
    'Select',
    '2021',
    '2020',
    '2019',
    '2018',
    '2017',
    '2016',
    '2015',
    '2014',
    '2013',
    '2012',
    '2011',
    '2010',
    '2009',
    '2008',
    '2007',
    '2006',
    '2005',
    '2004',
    '2003',
    '2002',
    '2001',
    '2000',
    '1999',
    '1998',
    '1997',
    '1996',
    '1995',
    '1994',
    '1993',
    '1992',
    '1991',
    '1990',
    '1989',
    '1988',
    '1987',
    '1986',
    '1985',
    '1984',
    '1983',
    '1982',
    '1981',
    '1980',
    '1979',
    '1978',
    '1977',
    '1976',
    '1975',
    '1974',
    '1973',
    '1972',
    '1971',
    '1970',
    '1969',
    '1968',
    '1967',
    '1966',
    '1965',
    '1964',
    '1963',
    '1962',
    '1961',
    '1960',
    '1959',
    '1958',
    '1957',
    '1956',
    '1955',
    '1954',
    '1953',
    '1952',
    '1951',
    '1950',
    '1949',
    '1948',
    '1947',
    '1946',
    '1945',
    '1944',
    '1943',
    '1942',
    '1941',
    '1940',
    '1939',
    '1938',
    '1937',
    '1936',
    '1935',
    '1934',
    '1933',
    '1932',
    '1931',
    '1930',
    '1929',
    '1928',
    '1927',
    '1926',
    '1925',
    '1924',
    '1923',
    '1922',
    '1921'
]

export const countries = [
    {
        value: 'AF',
        label: 'Afghanistan'
    },
    {
        value: 'AX',
        label: 'Åland Islands'
    },
    {
        value: 'AL',
        label: 'Albania'
    },
    {
        value: 'DZ',
        label: 'Algeria'
    },
    {
        value: 'AS',
        label: 'American Samoa'
    },
    {
        value: 'AD',
        label: 'Andorra'
    },
    {
        value: 'AO',
        label: 'Angola'
    },
    {
        value: 'AI',
        label: 'Anguilla'
    },
    {
        value: 'AQ',
        label: 'Antarctica'
    },
    {
        value: 'AG',
        label: 'Antigua and Barbuda'
    },
    {
        value: 'AR',
        label: 'Argentina'
    },
    {
        value: 'AM',
        label: 'Armenia'
    },
    {
        value: 'AW',
        label: 'Aruba'
    },
    {
        value: 'AU',
        label: 'Australia'
    },
    {
        value: 'AT',
        label: 'Austria'
    },
    {
        value: 'AZ',
        label: 'Azerbaijan'
    },
    {
        value: 'BS',
        label: 'Bahamas'
    },
    {
        value: 'BH',
        label: 'Bahrain'
    },
    {
        value: 'BD',
        label: 'Bangladesh'
    },
    {
        value: 'BB',
        label: 'Barbados'
    },
    {
        value: 'BY',
        label: 'Belarus'
    },
    {
        value: 'BE',
        label: 'Belgium'
    },
    {
        value: 'BZ',
        label: 'Belize'
    },
    {
        value: 'BJ',
        label: 'Benin'
    },
    {
        value: 'BM',
        label: 'Bermuda'
    },
    {
        value: 'BT',
        label: 'Bhutan'
    },
    {
        value: 'BO',
        label: 'Bolivia, Plurinational State of Bolivia'
    },
    {
        value: 'BQ',
        label: 'Bonaire, Sint Eustatius and Saba'
    },
    {
        value: 'BA',
        label: 'Bosnia and Herzegovina'
    },
    {
        value: 'BW',
        label: 'Botswana'
    },
    {
        value: 'BV',
        label: 'Bouvet Island'
    },
    {
        value: 'BR',
        label: 'Brazil'
    },
    {
        value: 'IO',
        label: 'British Indian Ocean Territory'
    },
    {
        value: 'BN',
        label: 'Brunei Darussalam'
    },
    {
        value: 'BG',
        label: 'Bulgaria'
    },
    {
        value: 'BF',
        label: 'Burkina Faso'
    },
    {
        value: 'BI',
        label: 'Burundi'
    },
    {
        value: 'CV',
        label: 'Cabo Verde'
    },
    {
        value: 'KH',
        label: 'Cambodia'
    },
    {
        value: 'CM',
        label: 'Cameroon'
    },
    {
        value: 'CA',
        label: 'Canada'
    },
    {
        value: 'KY',
        label: 'Cayman Islands'
    },
    {
        value: 'CF',
        label: 'Central African Republic'
    },
    {
        value: 'TD',
        label: 'Chad'
    },
    {
        value: 'CL',
        label: 'Chile'
    },
    {
        value: 'CN',
        label: 'China'
    },
    {
        value: 'CX',
        label: 'Christmas Island'
    },
    {
        value: 'CC',
        label: 'Cocos (Keeling) Islands'
    },
    {
        value: 'CO',
        label: 'Colombia'
    },
    {
        value: 'KM',
        label: 'Comoros'
    },
    {
        value: 'CG',
        label: 'Congo'
    },
    {
        value: 'CD',
        label: 'Congo, the Democratic Republic of the'
    },
    {
        value: 'CK',
        label: 'Cook Islands'
    },
    {
        value: 'CR',
        label: 'Costa Rica'
    },
    {
        value: 'CI',
        label: "Côte d'Ivoire"
    },
    {
        value: 'HR',
        label: 'Croatia'
    },
    {
        value: 'CU',
        label: 'Cuba'
    },
    {
        value: 'CW',
        label: 'Curaçao'
    },
    {
        value: 'CY',
        label: 'Cyprus'
    },
    {
        value: 'CZ',
        label: 'Czechia'
    },
    {
        value: 'DK',
        label: 'Denmark'
    },
    {
        value: 'DJ',
        label: 'Djibouti'
    },
    {
        value: 'DM',
        label: 'Dominica'
    },
    {
        value: 'DO',
        label: 'Dominican Republic'
    },
    {
        value: 'EC',
        label: 'Ecuador'
    },
    {
        value: 'EG',
        label: 'Egypt'
    },
    {
        value: 'SV',
        label: 'El Salvador'
    },
    {
        value: 'GQ',
        label: 'Equatorial Guinea'
    },
    {
        value: 'ER',
        label: 'Eritrea'
    },
    {
        value: 'EE',
        label: 'Estonia'
    },
    {
        value: 'ET',
        label: 'Ethiopia'
    },
    {
        value: 'FK',
        label: 'Falkland Islands (Malvinas)'
    },
    {
        value: 'FO',
        label: 'Faroe Islands'
    },
    {
        value: 'FJ',
        label: 'Fiji'
    },
    {
        value: 'FI',
        label: 'Finland'
    },
    {
        value: 'FR',
        label: 'France'
    },
    {
        value: 'GF',
        label: 'French Guiana'
    },
    {
        value: 'PF',
        label: 'French Polynesia'
    },
    {
        value: 'TF',
        label: 'French Southern Territories'
    },
    {
        value: 'GA',
        label: 'Gabon'
    },
    {
        value: 'GM',
        label: 'Gambia'
    },
    {
        value: 'GE',
        label: 'Georgia'
    },
    {
        value: 'DE',
        label: 'Germany'
    },
    {
        value: 'GH',
        label: 'Ghana'
    },
    {
        value: 'GI',
        label: 'Gibraltar'
    },
    {
        value: 'GR',
        label: 'Greece'
    },
    {
        value: 'GL',
        label: 'Greenland'
    },
    {
        value: 'GD',
        label: 'Grenada'
    },
    {
        value: 'GP',
        label: 'Guadeloupe'
    },
    {
        value: 'GU',
        label: 'Guam'
    },
    {
        value: 'GT',
        label: 'Guatemala'
    },
    {
        value: 'GG',
        label: 'Guernsey'
    },
    {
        value: 'GN',
        label: 'Guinea'
    },
    {
        value: 'GW',
        label: 'Guinea-Bissau'
    },
    {
        value: 'GY',
        label: 'Guyana'
    },
    {
        value: 'HT',
        label: 'Haiti'
    },
    {
        value: 'HM',
        label: 'Heard Island and McDonald Islands'
    },
    {
        value: 'VA',
        label: 'Holy See (Vatican City State)'
    },
    {
        value: 'HN',
        label: 'Honduras'
    },
    {
        value: 'HK',
        label: 'Hong Kong'
    },
    {
        value: 'HU',
        label: 'Hungary'
    },
    {
        value: 'IS',
        label: 'Iceland'
    },
    {
        value: 'IN',
        label: 'India'
    },
    {
        value: 'ID',
        label: 'Indonesia'
    },
    {
        value: 'IR',
        label: 'Iran, Islamic Republic of'
    },
    {
        value: 'IQ',
        label: 'Iraq'
    },
    {
        value: 'IE',
        label: 'Ireland'
    },
    {
        value: 'IM',
        label: 'Isle of Man'
    },
    {
        value: 'IL',
        label: 'Israel'
    },
    {
        value: 'IT',
        label: 'Italy'
    },
    {
        value: 'JM',
        label: 'Jamaica'
    },
    {
        value: 'JP',
        label: 'Japan'
    },
    {
        value: 'JE',
        label: 'Jersey'
    },
    {
        value: 'JO',
        label: 'Jordan'
    },
    {
        value: 'KZ',
        label: 'Kazakhstan'
    },
    {
        value: 'KE',
        label: 'Kenya'
    },
    {
        value: 'KI',
        label: 'Kiribati'
    },
    {
        value: 'KW',
        label: 'Kuwait'
    },
    {
        value: 'KG',
        label: 'Kyrgyzstan'
    },
    {
        value: 'LA',
        label: "Lao People's Democratic Republic"
    },
    {
        value: 'LV',
        label: 'Latvia'
    },
    {
        value: 'LB',
        label: 'Lebanon'
    },
    {
        value: 'LS',
        label: 'Lesotho'
    },
    {
        value: 'LR',
        label: 'Liberia'
    },
    {
        value: 'LY',
        label: 'Libya'
    },
    {
        value: 'LI',
        label: 'Liechtenstein'
    },
    {
        value: 'LT',
        label: 'Lithuania'
    },
    {
        value: 'LU',
        label: 'Luxembourg'
    },
    {
        value: 'MO',
        label: 'Macao'
    },
    {
        value: 'MK',
        label: 'Macedonia, the former Yugoslav Republic of Macedonia'
    },
    {
        value: 'MG',
        label: 'Madagascar'
    },
    {
        value: 'MW',
        label: 'Malawi'
    },
    {
        value: 'MY',
        label: 'Malaysia'
    },
    {
        value: 'MV',
        label: 'Maldives'
    },
    {
        value: 'ML',
        label: 'Mali'
    },
    {
        value: 'MT',
        label: 'Malta'
    },
    {
        value: 'MH',
        label: 'Marshall Islands'
    },
    {
        value: 'MQ',
        label: 'Martinique'
    },
    {
        value: 'MR',
        label: 'Mauritania'
    },
    {
        value: 'MU',
        label: 'Mauritius'
    },
    {
        value: 'YT',
        label: 'Mayotte'
    },
    {
        value: 'MX',
        label: 'Mexico'
    },
    {
        value: 'FM',
        label: 'Micronesia, Federated States of Micronesia'
    },
    {
        value: 'MD',
        label: 'Moldova, Republic of'
    },
    {
        value: 'MC',
        label: 'Monaco'
    },
    {
        value: 'MN',
        label: 'Mongolia'
    },
    {
        value: 'ME',
        label: 'Montenegro'
    },
    {
        value: 'MS',
        label: 'Montserrat'
    },
    {
        value: 'MA',
        label: 'Morocco'
    },
    {
        value: 'MZ',
        label: 'Mozambique'
    },
    {
        value: 'MM',
        label: 'Myanmar'
    },
    {
        value: 'NA',
        label: 'Namibia'
    },
    {
        value: 'NR',
        label: 'Nauru'
    },
    {
        value: 'NP',
        label: 'Nepal'
    },
    {
        value: 'NL',
        label: 'Netherlands'
    },
    {
        value: 'NC',
        label: 'New Caledonia'
    },
    {
        value: 'NZ',
        label: 'New Zealand'
    },
    {
        value: 'NI',
        label: 'Nicaragua'
    },
    {
        value: 'NE',
        label: 'Niger'
    },
    {
        value: 'NG',
        label: 'Nigeria'
    },
    {
        value: 'NU',
        label: 'Niue'
    },
    {
        value: 'NF',
        label: 'Norfolk Island'
    },
    {
        value: 'MP',
        label: 'Northern Mariana Islands'
    },
    {
        value: 'KP',
        label: "North Korea (Democratic People's Republic of Korea)"
    },
    {
        value: 'NO',
        label: 'Norway'
    },
    {
        value: 'OM',
        label: 'Oman'
    },
    {
        value: 'PK',
        label: 'Pakistan'
    },
    {
        value: 'PW',
        label: 'Palau'
    },
    {
        value: 'PS',
        label: 'Palestine, State of Palestine'
    },
    {
        value: 'PA',
        label: 'Panama'
    },
    {
        value: 'PG',
        label: 'Papua New Guinea'
    },
    {
        value: 'PY',
        label: 'Paraguay'
    },
    {
        value: 'PE',
        label: 'Peru'
    },
    {
        value: 'PH',
        label: 'Philippines'
    },
    {
        value: 'PN',
        label: 'Pitcairn'
    },
    {
        value: 'PL',
        label: 'Poland'
    },
    {
        value: 'PT',
        label: 'Portugal'
    },
    {
        value: 'PR',
        label: 'Puerto Rico'
    },
    {
        value: 'QA',
        label: 'Qatar'
    },
    {
        value: 'RE',
        label: 'Réunion'
    },
    {
        value: 'RO',
        label: 'Romania'
    },
    {
        value: 'RU',
        label: 'Russian Federation'
    },
    {
        value: 'RW',
        label: 'Rwanda'
    },
    {
        value: 'BL',
        label: 'Saint Barthélemy'
    },
    {
        value: 'SH',
        label: 'Saint Helena, Ascension and Tristan da Cunha'
    },
    {
        value: 'KN',
        label: 'Saint Kitts and Nevis'
    },
    {
        value: 'LC',
        label: 'Saint Lucia'
    },
    {
        value: 'MF',
        label: 'Saint Martin (French part)'
    },
    {
        value: 'PM',
        label: 'Saint Pierre and Miquelon'
    },
    {
        value: 'VC',
        label: 'Saint Vincent and the Grenadines'
    },
    {
        value: 'WS',
        label: 'Samoa'
    },
    {
        value: 'SM',
        label: 'San Marino'
    },
    {
        value: 'ST',
        label: 'Sao Tome and Principe'
    },
    {
        value: 'SA',
        label: 'Saudi Arabia'
    },
    {
        value: 'SN',
        label: 'Senegal'
    },
    {
        value: 'RS',
        label: 'Serbia'
    },
    {
        value: 'SC',
        label: 'Seychelles'
    },
    {
        value: 'SL',
        label: 'Sierra Leone'
    },
    {
        value: 'SG',
        label: 'Singapore'
    },
    {
        value: 'SX',
        label: 'Sint Maarten (Dutch part)'
    },
    {
        value: 'SK',
        label: 'Slovakia'
    },
    {
        value: 'SI',
        label: 'Slovenia'
    },
    {
        value: 'SB',
        label: 'Solomon Islands'
    },
    {
        value: 'SO',
        label: 'Somalia'
    },
    {
        value: 'ZA',
        label: 'South Africa'
    },
    {
        value: 'GS',
        label: 'South Georgia and the South Sandwich Islands'
    },
    {
        value: 'KR',
        label: 'South Korea (Republic of Korea)'
    },
    {
        value: 'SS',
        label: 'South Sudan'
    },
    {
        value: 'ES',
        label: 'Spain'
    },
    {
        value: 'LK',
        label: 'Sri Lanka'
    },
    {
        value: 'SD',
        label: 'Sudan'
    },
    {
        value: 'SR',
        label: 'Suriname'
    },
    {
        value: 'SJ',
        label: 'Svalbard and Jan Mayen'
    },
    {
        value: 'SZ',
        label: 'Swaziland'
    },
    {
        value: 'SE',
        label: 'Sweden'
    },
    {
        value: 'CH',
        label: 'Switzerland'
    },
    {
        value: 'SY',
        label: 'Syrian Arab Republic'
    },
    {
        value: 'TW',
        label: 'Taiwan, Province of China'
    },
    {
        value: 'TJ',
        label: 'Tajikistan'
    },
    {
        value: 'TZ',
        label: 'Tanzania, United Republic of Tanzania'
    },
    {
        value: 'TH',
        label: 'Thailand'
    },
    {
        value: 'TL',
        label: 'Timor-Leste'
    },
    {
        value: 'TG',
        label: 'Togo'
    },
    {
        value: 'TK',
        label: 'Tokelau'
    },
    {
        value: 'TO',
        label: 'Tonga'
    },
    {
        value: 'TT',
        label: 'Trinidad and Tobago'
    },
    {
        value: 'TN',
        label: 'Tunisia'
    },
    {
        value: 'TR',
        label: 'Turkey'
    },
    {
        value: 'TM',
        label: 'Turkmenistan'
    },
    {
        value: 'TC',
        label: 'Turks and Caicos Islands'
    },
    {
        value: 'TV',
        label: 'Tuvalu'
    },
    {
        value: 'UG',
        label: 'Uganda'
    },
    {
        value: 'UA',
        label: 'Ukraine'
    },
    {
        value: 'AE',
        label: 'United Arab Emirates'
    },
    {
        value: 'GB',
        label: 'United Kingdom'
    },
    {
        value: 'US',
        label: 'United States'
    },
    {
        value: 'UM',
        label: 'United States Minor Outlying Islands'
    },
    {
        value: 'UY',
        label: 'Uruguay'
    },
    {
        value: 'UZ',
        label: 'Uzbekistan'
    },
    {
        value: 'VU',
        label: 'Vanuatu'
    },
    {
        value: 'VE',
        label: 'Venezuela, Bolivarian Republic of Venezuela'
    },
    {
        value: 'VN',
        label: 'Viet Nam'
    },
    {
        value: 'VG',
        label: 'Virgin Islands, British'
    },
    {
        value: 'VI',
        label: 'Virgin Islands, U.S.'
    },
    {
        value: 'WF',
        label: 'Wallis and Futuna'
    },
    {
        value: 'EH',
        label: 'Western Sahara'
    },
    {
        value: 'YE',
        label: 'Yemen'
    },
    {
        value: 'ZM',
        label: 'Zambia'
    },
    {
        value: 'ZW',
        label: 'Zimbabwe'
    }
]

export const usa_states = [
    {
        value: 'AL',
        label: 'Alabama'
    },
    {
        value: 'AK',
        label: 'Alaska'
    },
    {
        value: 'AS',
        label: 'American Samoa'
    },
    {
        value: 'AZ',
        label: 'Arizona'
    },
    {
        value: 'AR',
        label: 'Arkansas'
    },
    {
        value: 'CA',
        label: 'California'
    },
    {
        value: 'CO',
        label: 'Colorado'
    },
    {
        value: 'CT',
        label: 'Connecticut'
    },
    {
        value: 'DE',
        label: 'Delaware'
    },
    {
        value: 'DC',
        label: 'District of Columbia'
    },
    {
        value: 'FL',
        label: 'Florida'
    },
    {
        value: 'GA',
        label: 'Georgia'
    },
    {
        value: 'GU',
        label: 'Guam'
    },
    {
        value: 'HI',
        label: 'Hawaii'
    },
    {
        value: 'ID',
        label: 'Idaho'
    },
    {
        value: 'IL',
        label: 'Illinois'
    },
    {
        value: 'IN',
        label: 'Indiana'
    },
    {
        value: 'IA',
        label: 'Iowa'
    },
    {
        value: 'KS',
        label: 'Kansas'
    },
    {
        value: 'KY',
        label: 'Kentucky'
    },
    {
        value: 'LA',
        label: 'Louisiana'
    },
    {
        value: 'ME',
        label: 'Maine'
    },
    {
        value: 'MD',
        label: 'Maryland'
    },
    {
        value: 'MA',
        label: 'Massachusetts'
    },
    {
        value: 'MI',
        label: 'Michigan'
    },
    {
        value: 'MN',
        label: 'Minnesota'
    },
    {
        value: 'MS',
        label: 'Mississippi'
    },
    {
        value: 'MO',
        label: 'Missouri'
    },
    {
        value: 'MT',
        label: 'Montana'
    },
    {
        value: 'NE',
        label: 'Nebraska'
    },
    {
        value: 'NV',
        label: 'Nevada'
    },
    {
        value: 'NH',
        label: 'New Hampshire'
    },
    {
        value: 'NJ',
        label: 'New Jersey'
    },
    {
        value: 'NM',
        label: 'New Mexico'
    },
    {
        value: 'NY',
        label: 'New York'
    },
    {
        value: 'NC',
        label: 'North Carolina'
    },
    {
        value: 'ND',
        label: 'North Dakota'
    },
    {
        value: 'OH',
        label: 'Ohio'
    },
    {
        value: 'OK',
        label: 'Oklahoma'
    },
    {
        value: 'OR',
        label: 'Oregon'
    },
    {
        value: 'PA',
        label: 'Pennsylvania'
    },
    {
        value: 'PR',
        label: 'Puerto Rico'
    },
    {
        value: 'RI',
        label: 'Rhode Island'
    },
    {
        value: 'SC',
        label: 'South Carolina'
    },
    {
        value: 'SD',
        label: 'South Dakota'
    },
    {
        value: 'TN',
        label: 'Tennessee'
    },
    {
        value: 'TX',
        label: 'Texas'
    },
    {
        value: 'UT',
        label: 'Utah'
    },
    {
        value: 'VT',
        label: 'Vermont'
    },
    {
        value: 'VI',
        label: 'Virgin Islands'
    },
    {
        value: 'VA',
        label: 'Virginia'
    },
    {
        value: 'WA',
        label: 'Washington'
    },
    {
        value: 'WV',
        label: 'West Virginia'
    },
    {
        value: 'WI',
        label: 'Wisconsin'
    },
    {
        value: 'WY',
        label: 'Wyoming'
    },
    {
        value: 'AE',
        label: 'Armed Forces Africa'
    },
    {
        value: 'AA',
        label: 'Armed Forces America (exc. Canada)'
    },
    {
        value: 'AE',
        label: 'Armed Forces Canada'
    },
    {
        value: 'AE',
        label: 'Armed Forces Europe'
    },
    {
        value: 'AE',
        label: 'Armed Forces Middle East'
    },
    {
        value: 'AP',
        label: 'Armed Forces Pacific'
    },
    {
        value: 'OTHER',
        label: 'Non-US/Other'
    }
]

export const canada_states = [
    {
        value: 'ON',
        label: 'Ontario'
    },
    {
        value: 'QC',
        label: 'Quebec'
    },
    {
        value: 'NS',
        label: 'Nova Scotia'
    },
    {
        value: 'NB',
        label: 'New Brunswick'
    },
    {
        value: 'MB',
        label: 'Manitoba'
    },
    {
        value: 'BC',
        label: 'British Columbia'
    },
    {
        value: 'PE',
        label: 'Prince Edward Island'
    },
    {
        value: 'SK',
        label: 'Saskatchewan'
    },
    {
        value: 'AB',
        label: 'Alberta'
    },
    {
        value: 'NL',
        label: 'Newfoundland and Labrador'
    },
    {
        value: 'NU',
        label: 'Nunavut'
    },
    {
        value: 'NT',
        label: 'Northwest Territories'
    },
    {
        value: 'YT',
        label: 'Yukon'
    }
]
