/* eslint-disable react/no-find-dom-node */
/* Content Asset Component */
import React, {Fragment, useState, useEffect, useRef, useContext} from 'react'
import ReactDOM from 'react-dom'
import {withRouter} from 'react-router'
import PropTypes from 'prop-types'
import {useHistory} from 'react-router-dom'
import SkeletonBlock from 'progressive-web-sdk/dist/components/skeleton-block'
import {getConnector} from '../../connector'
import {getContentAsset} from './helper'
import {GlobalStateContext} from '../../components/global-state'
import DOMPurify from 'isomorphic-dompurify'
import {findCategoryBySlug} from '../../pages/isomorphic/globalFunctions'

const ContentAsset = (props) => {
    const {
        key,
        contentAssetId,
        sendData,
        cssClass,
        contentAssetFromPayPal = false,
        contentAssetData
    } = props
    const globalState = useContext(GlobalStateContext)
    const connector = getConnector()
    const [contentAsset, setContentAsset] = useState([])
    const [clickHandlerAttached, setClickHandlerAttached] = useState(false)
    const [imgHandlerAttached, setImgHandlerAttached] = useState(false)
    const assetRef = useRef()
    const contentAssetRef = useRef()
    const history = useHistory()
    const fetchContentAsset = async () => {
        let data = await getContentAsset(connector, contentAssetId)
        data.c_body =
            (data?.c_body || '').replace(
                /https:\/\/assets.whsmith.co.uk\//g,
                '/mobify/caching/assets/'
            ) || data.c_body
        sendData && sendData(data)
        setContentAsset({...data})
    }

    useEffect(() => {
        !contentAssetFromPayPal && fetchContentAsset()

        return () => {
            setContentAsset([])
        }
    }, [contentAssetId])

    useEffect(() => {
        let anchor = contentAssetRef.current.getElementsByTagName('a')
        if (anchor.length > 0 && !clickHandlerAttached) {
            Array.from(anchor).forEach((element) => {
                if (history.location.pathname.includes('/register')) {
                    element.onclick = (e) => {
                        e.preventDefault()
                        let url = new URL(element?.href)
                        const pathToScroll = document.getElementsByName(url?.hash.split('#')[1])
                        pathToScroll[0].scrollIntoView()
                        history.push({
                            pathname: /\/$/.test(url?.pathname)
                                ? `${url?.pathname}`
                                : `${url?.pathname}/`,
                            hash: `${url?.hash}`
                        })
                    }
                } else {
                    if (
                        element?.href !== undefined &&
                        element?.href !== null &&
                        element?.href !== '' &&
                        !element?.href.includes('mailto') &&
                        !element?.href.includes('tel:') &&
                        !element?.href.includes('www.kobo.com') &&
                        !element?.href.includes('www.whsmithplc.co.uk') &&
                        !element?.href.includes('www.whsmithcareers.co.uk')
                    ) {
                        let url = new URL(element?.href)
                        element.onclick = (e) => {
                            e.preventDefault()
                            if (element?.href.includes('#top')) {
                                window.scrollTo(0, 0)
                            } else if (element?.href.includes('#')) {
                                let url = new URL(element?.href)
                                let category = {}
                                globalState?.menuCategories?.some((cat) => {
                                    let foundCategory = findCategoryBySlug(
                                        cat,
                                        `${url?.pathname}${url?.hash}`
                                    )
                                    if (foundCategory) {
                                        category = foundCategory
                                        return true
                                    }
                                })
                                if (url?.pathname?.includes('help')) {
                                    history.push({
                                        pathname: /\/$/.test(url?.pathname)
                                            ? `${url?.pathname}`
                                            : `${url?.pathname}/`,
                                        hash: `${url?.hash}`
                                    })
                                } else {
                                    history.push({
                                        pathname: /\/$/.test(url?.pathname)
                                            ? `${url?.pathname}`
                                            : `${url?.pathname}/`,
                                        hash: `${url?.hash}`,
                                        state: {
                                            skeleton:
                                                (Object.values(category).length > 0 &&
                                                    category?.c_template) ||
                                                ''
                                        }
                                    })
                                }
                            } else if (element?.href.includes(`${process.env.HOSTNAME}`)) {
                                let category = {}
                                globalState?.menuCategories?.some((cat) => {
                                    let foundCategory = findCategoryBySlug(
                                        cat,
                                        `${url?.pathname}${url?.search}${url?.hash}`
                                    )
                                    if (foundCategory) {
                                        category = foundCategory
                                        return true
                                    }
                                })

                                history.push({
                                    pathname: /\/$/.test(url?.pathname)
                                        ? `${url?.pathname}`
                                        : `${url?.pathname}/`,
                                    search: `${url?.search}`,
                                    hash: `${url?.hash}`,
                                    state: {
                                        skeleton:
                                            (Object.values(category).length > 0 &&
                                                category?.c_template) ||
                                            ''
                                    }
                                })
                            } else if (element?.href.includes('javascript:')) {
                                return
                            } else {
                                window.open(url?.href, '_blank')
                            }
                        }
                    }
                }
                if (!element?.href.includes(`${process.env.HOSTNAME}`)) {
                    element.rel = 'noreferrer'
                }
                setClickHandlerAttached(true)
            })
        }

        let img = contentAssetRef.current.getElementsByTagName('img')
        if (img.length > 0 && !imgHandlerAttached) {
            Array.from(img).forEach((element) => {
                ReactDOM.findDOMNode(element).setAttribute('loading', 'lazy')
                setImgHandlerAttached(true)
            })
        }
    }, [contentAsset, contentAssetData])

    useEffect(() => {
        const timer = setInterval(() => {
            const visibleElements = document.getElementsByClassName('accordion-wrap')
            if (visibleElements.length > 0) {
                const arr = []
                for (let i = 0; i < visibleElements.length; i++) {
                    const element = visibleElements[i]
                    arr.push(true)
                    ReactDOM.findDOMNode(element).addEventListener('click', (e) => {
                        const tag = e.target.tagName
                        if (tag != 'A') {
                            ReactDOM.findDOMNode(element).classList.toggle('expanded', arr[i])
                        }
                        arr[i] = !arr[i]
                    })
                }
            }

            const visibleElements2 = document.getElementsByClassName('shclick')
            const hasVisibleEls2 = visibleElements2.length > 0
            if (hasVisibleEls2) {
                $('a.shclick')
                    .off('click')
                    .on('click', function() {
                        $('div.shcontent').toggle(800)
                        $('a.shclick').text(
                            $('a.shclick').text() == 'Read More \u25BC'
                                ? 'Show Less \u25B2'
                                : 'Read More \u25BC'
                        )
                    })
            }

            if (hasVisibleEls2) {
                clearInterval(timer)
            } else {
                setTimeout(() => {
                    clearInterval(timer)
                }, 45000)
            }
        }, 1000)
        setTimeout(function() {
            var b = document.getElementById('sbiframehook'),
                a = document.createElement('script')
            if (b) {
                a.setAttribute('data-iframe', 'https://connect.studentbeans.com/v4/whsmith/uk')
                a.setAttribute('data-load', 'connect')
                a.setAttribute('id', 'stb_root')
                a.async = !0
                a.src = 'https://cdn.studentbeans.com/third-party/all.js'
                b.appendChild(a)
            }
        }, 3000)
    }, [contentAssetId])

    return (
        <div ref={contentAssetRef}>
            {contentAssetFromPayPal
                ? contentAssetData &&
                  contentAssetData.c_body && (
                      <Fragment>
                          <div
                              key={key}
                              ref={assetRef}
                              className={cssClass}
                              dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(contentAssetData.c_body, {
                                      FORBID_TAGS: ['script'],
                                      FORCE_BODY: true,
                                      ADD_TAGS: ['use', 'iframe'],
                                      ADD_ATTR: [
                                          'allow',
                                          'allowfullscreen',
                                          'frameborder',
                                          'scrolling'
                                      ] //or //or ALLOWED_ATR
                                  })
                              }}
                          ></div>
                      </Fragment>
                  )
                : contentAsset &&
                  contentAsset.c_body && (
                      <Fragment>
                          <div
                              key={key}
                              ref={assetRef}
                              className={cssClass}
                              dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(contentAsset.c_body, {
                                      FORBID_TAGS: ['script'],
                                      FORCE_BODY: true,
                                      ADD_TAGS: ['use', 'iframe'],
                                      ADD_ATTR: [
                                          'allow',
                                          'allowfullscreen',
                                          'frameborder',
                                          'scrolling'
                                      ] //or //or ALLOWED_ATR
                                  })
                              }}
                          ></div>
                      </Fragment>
                  )}
            {!contentAssetData ||
                !contentAssetData.c_body ||
                (!contentAsset | !contentAsset.c_body && (
                    <SkeletonBlock key={key} width={'1403px'} height={'591px'} />
                ))}
        </div>
    )
}

ContentAsset.propTypes = {
    contentAssetId: PropTypes.string,
    dataId: PropTypes.string,
    sendData: PropTypes.func,
    cssClass: PropTypes.string
}

export default withRouter(ContentAsset)
