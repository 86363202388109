import React from 'react'
import { Helmet } from 'react-helmet'
import Link from 'progressive-web-sdk/dist/components/link'
import { getAssetUrl } from 'progressive-web-sdk/dist/ssr/universal/utils'

import ErrorFooter from './error-footer'

const Error500 = () => {
    return (
        <>
            <Helmet
                htmlAttributes={{
                    lang: "en-US",
                    "data-reactroot": ""
                }}
            >
                    <title>Sites-whsmith-Site</title>
                    <meta name="description" content='WHSmith Page Not Found' />
                    <meta
                        property="og:image"
                        content={getAssetUrl('static/img/errors/error-500-bg-mobile.jpg')}
                    />
                    <meta property="og:site_name" content="WHSmith" />
                    <meta property="og:title" content='Sites-whsmith-Site' />
                    <meta property="og:description" content='WHSmith Page Not Found' />
                    <meta name="keywords" itemProp="keywords" content=" WHSmith" />
                    <link
                        rel="image_src"
                        href={getAssetUrl('static/img/errors/error-500-bg-mobile.jpg')}
                    />
                </Helmet>
            <div className="error-wrapper m-404">
                <div className="error-background not-found">
                    <picture>
                        <source
                            media="(max-width: 767px)"
                            srcSet={getAssetUrl('static/img/errors/error-500-bg-mobile.jpg')}
                        />
                        <source
                            media="(min-width: 768) and (max-width: 1023px)"
                            srcSet={getAssetUrl('static/img/errors/error-500-bg-tablet.jpg')}
                        />
                        <source
                            media="(min-width: 1620px) and (max-width: 1620px)"
                            srcSet={getAssetUrl('static/img/errors/error-500-bg-tablet.jpg')}
                        />
                        <img
                            alt="error background"
                            srcSet={getAssetUrl('static/img/errors/error-500-bg.png')}
                            title="error background"
                            className="background-image"
                        />
                    </picture>
                </div>
                <div className="error-message error-message-top">
                    <div className="not-found-logo">
                        <a href="/" title="WHSmith Home" className="header-logo-link">
                            <img
                                alt="WHSmith Home"
                                srcSet={getAssetUrl('static/img/errors/logo.svg')}
                                className="site-error-logo"
                            />
                            <span className="visually-hidden">WHSmith</span>
                        </a>
                    </div>
                    <div className="error-message-body">
                        <h1 className="error-message-title">Internal Server Error</h1>
                        <p className="error-message-text">
                            Sorry, an internal server error on our side is spoiling all the fun!
                        <br />
                        We&apos;re working hard to get things back to normal quickly, but you may
                        need to come back later if you continue to see this message.
                        <br />
                        In the meantime, you can go to our <a href="/">Homepage</a> or browse our{' '}
                            <a href="#">Site Map</a> to help find what you&apos;re looking for.
                    </p>
                    </div>
                </div>
                <ErrorFooter />
            </div>
        </>
    )
}

export default Error500
