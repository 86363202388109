import React from 'react'

import SkeletonBlock from 'progressive-web-sdk/dist/components/skeleton-block'
import SkeletonText from 'progressive-web-sdk/dist/components/skeleton-text'
import Faceout from '../../../components/faceout'
import {
    Desktop,
    Mobile,
    Tablet,
    TabletOrHigher,
    TabletOrSmaller,
    LargeDesktop,
    DesktopOrSmaller
} from '../../../components/media-queries'
import Circles from './circles'

const NewHomePageVariant1 = ({className}) => {
    return (
        <>
            <div className="service-strip-container" style={{marginBottom: 0}}>
                <div
                    className="service-strip-wrapper swiper-wrapper"
                    style={{display: 'flex', justifyContent: 'center'}}
                >
                    <div className="service-strip ">
                        <span className="icon-wrapper">
                            <svg className="icon-shipping icon-set"></svg>
                        </span>
                        <span className="service-strip-text">
                            <SkeletonText width="200px" />
                        </span>
                    </div>
                    <div className="service-strip ">
                        <span className="icon-wrapper">
                            <svg className="icon-shipping icon-set"></svg>
                        </span>
                        <span className="service-strip-text">
                            <SkeletonText width="200px" />
                        </span>
                    </div>
                    <div className="service-strip ">
                        <span className="icon-wrapper">
                            <svg className="icon-shipping icon-set"></svg>
                        </span>
                        <span className="service-strip-text">
                            <SkeletonText width="200px" />
                        </span>
                    </div>
                    <div className="service-strip ">
                        <span className="icon-wrapper">
                            <svg className="icon-shipping icon-set"></svg>
                        </span>
                        <span className="service-strip-text">
                            <SkeletonText width="200px" />
                        </span>
                    </div>
                    <div className="service-strip ">
                        <span className="icon-wrapper">
                            <svg className="icon-shipping icon-set"></svg>
                        </span>
                        <span className="service-strip-text">
                            <SkeletonText width="200px" />
                        </span>
                    </div>
                </div>
            </div>
            <div className={`content-width catlanding lay-homepage-new ${className}`}>
                <div className="content-holder-padding homepage-new-mar-30">
                    <div className="lazyload-wrapper ">
                        <div>
                            <div>
                                <div className="homepage-highlights-asset-variant">
                                    <div className="hero-slide">
                                        <SkeletonBlock
                                            className="banner-1"
                                            width="1345px"
                                            height="300px"
                                        ></SkeletonBlock>
                                    </div>

                                    {/* <Circles showTabletPart />
                                    <h2 className="h2 free-delivery-title">
                                        <SkeletonText width="300px" />
                                    </h2> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="carousel-content">
                        <SkeletonText width="50px" height="10px" />
                    </div>

                    <div className="content-holder-padding homepage-new-mar-30">
                        <Faceout
                            slidesPerView={{
                                forLargeDesktop: 5,
                                forDesktop: 4,
                                forTablet: 3,
                                forMobile: 2
                            }}
                            reference={'2'}
                        />
                    </div>
                </div>

                {/* <div className="content-holder-padding homepage-new-mar-30">
                    <div className="lazyload-wrapper ">
                        <div>
                            <div>
                                <div>
                                    <div className="hp-banner-alt">
                                        <div className="item1">
                                            <figure>
                                                <SkeletonBlock className="block-item"  />
                                            </figure>
                                        </div>
                                        <div className="item2">
                                            <figure>
                                                <SkeletonBlock className="block-item"  />
                                            </figure>
                                        </div>
                                        <div className="item3">
                                            <div>
                                                <SkeletonBlock className="block-item" />
                                            </div>
                                        </div>
                                        <div className="item4">
                                            <div>
                                                <SkeletonBlock className="block-item" />
                                            </div>
                                        </div>
                                        <div className="item5">
                                            <div>
                                                <SkeletonBlock className="block-item" />
                                            </div>
                                        </div>
                                        <div className="item6">
                                            <SkeletonBlock className="block-item" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="content-holder-padding homepage-new-mar-30">
                    <div className="lazyload-wrapper ">
                        <div>
                            <div>
                                <div className="most-popular-section-container">
                                    <div>
                                        <div>
                                            <div>
                                                <div className="hp-banner-alt">
                                                    <TabletOrHigher>
                                                        <div className="item1">
                                                            <SkeletonBlock
                                                                className="hp-banner-alt "
                                                                height={'100%'}
                                                            />
                                                        </div>
                                                    </TabletOrHigher>
                                                    <Mobile>
                                                        <SkeletonText width="100px" height="20px" />
                                                    </Mobile>

                                                    <div className="item2">
                                                        <SkeletonBlock
                                                            className="hp-banner-alt "
                                                            height={'100%'}
                                                        />
                                                    </div>
                                                    <div className="item3">
                                                        <SkeletonBlock
                                                            className="hp-banner-alt "
                                                            height={'100%'}
                                                        />
                                                    </div>
                                                    <div className="item4">
                                                        <SkeletonBlock
                                                            className="hp-banner-alt "
                                                            height={'100%'}
                                                        />
                                                    </div>
                                                    <div className="item5">
                                                        <SkeletonBlock
                                                            className="hp-banner-alt "
                                                            height={'100%'}
                                                        />
                                                    </div>
                                                    <div className="item6">
                                                        <SkeletonBlock
                                                            className="hp-banner-alt "
                                                            height={'100%'}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content-holder-padding homepage-new-mar-30">
                    <div className="lazyload-wrapper ">
                        <div>
                            <div>
                                <div className="most-popular-section-container">
                                    <div className="lay-homepage-new most-popular-section">
                                        <h2 className="h2 offers-title">
                                            <SkeletonText width="100px" />
                                        </h2>
                                        <p className="offers-description">
                                            <SkeletonText width="300px" />
                                            <br />
                                            <SkeletonText width="200px" />
                                        </p>
                                    </div>
                                    <div>
                                        <div>
                                            <div>
                                                <div className="hp-banner-alt-1">
                                                    <LargeDesktop>
                                                        <div className="item1">
                                                            <SkeletonBlock
                                                                className="hp-banner-alt "
                                                                height={'100vw'}
                                                            />
                                                        </div>
                                                    </LargeDesktop>
                                                    <Desktop>
                                                        <div className="item1">
                                                            <SkeletonBlock
                                                                className="hp-banner-alt "
                                                                height={'100%'}
                                                            />
                                                        </div>
                                                    </Desktop>
                                                    <TabletOrSmaller>
                                                        <div className="item1">
                                                            <SkeletonBlock
                                                                className="hp-banner-alt "
                                                                height={'100%'}
                                                            />
                                                        </div>
                                                    </TabletOrSmaller>

                                                    <div className="item2">
                                                        <SkeletonBlock
                                                            className="hp-banner-alt "
                                                            height={'100%'}
                                                        />
                                                    </div>
                                                    <div className="item3">
                                                        <SkeletonBlock
                                                            className="hp-banner-alt "
                                                            height={'100%'}
                                                        />
                                                    </div>
                                                    <div className="item4">
                                                        <SkeletonBlock
                                                            className="hp-banner-alt "
                                                            height={'100%'}
                                                        />
                                                    </div>
                                                    <div className="item5">
                                                        <SkeletonBlock
                                                            className="hp-banner-alt "
                                                            height={'100%'}
                                                        />
                                                    </div>
                                                    <div className="item6">
                                                        <SkeletonBlock
                                                            className="hp-banner-alt "
                                                            height={'100%'}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="homepage-highlights-asset-variant">
                    <h2 className="h2 carousel-title 11" style={{textAlign: 'center'}}>
                        <SkeletonText width="50px" height="10px" />
                    </h2>
                    <Circles />
                    <div className={`content-width catlanding lay-homepage-new ${className}`}>
                        <div className="content-holder-padding homepage-new-mar-30">
                            <div className="lazyload-wrapper ">
                                <div>
                                    <div>
                                        <div className="homepage-highlights-asset-variant">
                                            <TabletOrSmaller>
                                                <div className="feature-tile">
                                                    <div
                                                        className="tile-wrap"
                                                        style={{marginTop: 20}}
                                                    >
                                                        {[0, 1, 2, 3, 4, 5].map(() => {
                                                            return (
                                                                <SkeletonBlock className="small-tile-2"></SkeletonBlock>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </TabletOrSmaller>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content-holder-padding homepage-new-mar-30">
                    <div className="lazyload-wrapper ">
                        <div>
                            <div>
                                <div className="most-popular-section-container">
                                    <div>
                                        <div>
                                            <div>
                                                <div className="hp-banner-alt-2">
                                                    <LargeDesktop>
                                                        <div className="item1">
                                                            <SkeletonBlock
                                                                className="hp-banner-alt "
                                                                height={'100vw'}
                                                            />
                                                        </div>
                                                    </LargeDesktop>
                                                    <Desktop>
                                                        <div className="item1">
                                                            <SkeletonBlock
                                                                className="hp-banner-alt "
                                                                height={'100%'}
                                                            />
                                                        </div>
                                                    </Desktop>
                                                    <TabletOrSmaller>
                                                        <div className="item1">
                                                            <SkeletonBlock
                                                                className="hp-banner-alt "
                                                                height={'100%'}
                                                            />
                                                        </div>
                                                    </TabletOrSmaller>

                                                    <div className="item2">
                                                        <SkeletonBlock
                                                            className="hp-banner-alt "
                                                            height={'100%'}
                                                        />
                                                    </div>
                                                    <div className="item3">
                                                        <SkeletonBlock
                                                            className="hp-banner-alt "
                                                            height={'100%'}
                                                        />
                                                    </div>
                                                    <div className="item4">
                                                        <SkeletonBlock
                                                            className="hp-banner-alt "
                                                            height={'100%'}
                                                        />
                                                    </div>
                                                    <div className="item5">
                                                        <SkeletonBlock
                                                            className="hp-banner-alt "
                                                            height={'100%'}
                                                        />
                                                    </div>
                                                    <div className="item6">
                                                        <SkeletonBlock
                                                            className="hp-banner-alt "
                                                            height={'100%'}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="content-width catlanding lay-homepage-new">
                        <div className="content-holder-padding homepage-new-mar-30">
                            <div className="subscription-form-container-2-skeleton">
                                <SkeletonBlock
                                    className="subscription-form-container-2-skeleton"
                                    height={'100%'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="discover-brands-section brand homepage-roundels-section">
                    <h2 className="h2 offers-title-alt">
                        <SkeletonText width="300px" />
                    </h2>
                    <div className="discover-brands-section-swiper-container swiper-initialized swiper-horizontal swiper-pointer-events">
                        <div className="banners-grid-list swiper-wrapper">
                            <Desktop>
                                <span className="lay-homepage-new discover-brands-item swiper-slide">
                                    <SkeletonBlock
                                        className="discover-brands-item"
                                        height={'100%'}
                                    />
                                </span>
                                <span className="lay-homepage-new discover-brands-item swiper-slide">
                                    <SkeletonBlock
                                        className="discover-brands-item"
                                        height={'100%'}
                                    />
                                </span>
                                <span className="lay-homepage-new discover-brands-item swiper-slide">
                                    <SkeletonBlock
                                        className="discover-brands-item"
                                        height={'100%'}
                                    />
                                </span>
                                <span className="lay-homepage-new discover-brands-item swiper-slide">
                                    <SkeletonBlock
                                        className="discover-brands-item"
                                        height={'100%'}
                                    />
                                </span>
                                <span className="lay-homepage-new discover-brands-item swiper-slide">
                                    <SkeletonBlock
                                        className="discover-brands-item"
                                        height={'100%'}
                                    />
                                </span>
                                <span className="lay-homepage-new discover-brands-item swiper-slide">
                                    <SkeletonBlock
                                        className="discover-brands-item"
                                        height={'100%'}
                                    />
                                </span>
                            </Desktop>
                            <TabletOrSmaller>
                                <span
                                    className="lay-homepage-new discover-brands-item swiper-slide swiper-slide-active"
                                    style={{marginRight: 10}}
                                >
                                    <SkeletonBlock className="discover-brands-item" height={100} />
                                </span>

                                <span
                                    className="lay-homepage-new discover-brands-item swiper-slide swiper-slide-next"
                                    style={{marginRight: 10}}
                                >
                                    <SkeletonBlock className="discover-brands-item" height={100} />
                                </span>

                                <span
                                    className="lay-homepage-new discover-brands-item swiper-slide"
                                    style={{marginRight: 10}}
                                >
                                    <SkeletonBlock className="discover-brands-item" height={100} />
                                </span>

                                <span
                                    className="lay-homepage-new discover-brands-item swiper-slide"
                                    style={{marginRight: 10}}
                                >
                                    <SkeletonBlock className="discover-brands-item" height={100} />
                                </span>

                                <span
                                    className="lay-homepage-new discover-brands-item swiper-slide"
                                    style={{marginRight: 10}}
                                >
                                    <SkeletonBlock className="discover-brands-item" height={100} />
                                </span>

                                <span
                                    className="lay-homepage-new discover-brands-item swiper-slide"
                                    style={{marginRight: 10}}
                                >
                                    <SkeletonBlock className="discover-brands-item" height={100} />
                                </span>
                            </TabletOrSmaller>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NewHomePageVariant1
