import React, {Fragment, useState, useEffect} from 'react'
import {getAnalyticsManager} from '../../analytics'
import {PAGEVIEW} from 'progressive-web-sdk/dist/analytics-integrations/types'
import ProductTile from '../product-tile'
import {getConnector} from '../../connector'
import {getProduct, getMasterProduct} from './helper'
import {breadcrumbArray} from '../../utils/utils'
import {getFromLocal, getFromSession, getCookie} from '../../utils/storage-utils'

const ChartTileIndex = ({productIds}) => {
    const [products, setProducts] = useState([])
    const [masterProducts, setMasterProducts] = useState([])
    const connector = getConnector()
    const analyticsManager = getAnalyticsManager()
    /*const productIds = [
        '0602498192979', '0602547567628', '0602498878934', '0602567006572','5051961066029', '0602577427626',
        '0603497855612', '0190295403034', '0603497848966', '0602577428753', '0602527610399', '0602577473074',
        '0190295938482', '0602577798726', '0190295427887', '5060463417222','0190758157429', '0602577490286',
        '0889854597825', '0190295337834', '0602577928680', '0194397051223', '0190295859039', '0081227967789'
    ]*/

    useEffect(() => {
        fetchAllProduct()
        fetchMasterProducts()
        let analyticbasketInfo = ''
        if (typeof window !== 'undefined') {
            analyticbasketInfo = getFromLocal('analyticBasket')
            if (analyticbasketInfo != undefined && analyticbasketInfo.length) {
                analyticbasketInfo = JSON.parse(analyticbasketInfo)
            } else {
                analyticbasketInfo = {
                    cartValue: 0,
                    cartItemsNum: 0,
                    cartUniqueItemsNum: 0
                }
            }
        } else {
            analyticbasketInfo = {
                cartValue: 0,
                cartItemsNum: 0,
                cartUniqueItemsNum: 0
            }
        }
        const analyticBreadcrumb = breadcrumbArray()
        const analyticData = {
            event: 'pageDataPushed',
            templateName: 'chart-page',
            pageType: 'chart',
            pageURL: window.location.href,
            originalLocation:
                getFromSession('originalLocation') !== ''
                    ? getFromSession('originalLocation')
                    : window.location.href,
            visitorStatus:
                getCookie('isUserLoggedIn') && getCookie('isUserLoggedIn') === 'true'
                    ? 'regular-logged'
                    : 'guest',
            userId: getCookie('customerId') ? getCookie('customerId') : null,
            currencyCode: 'GBP',
            cartValue: analyticbasketInfo.cartValue,
            cartItemsNum: analyticbasketInfo.cartItemsNum,
            cartUniqueItemsNum: analyticbasketInfo.cartUniqueItemsNum
        }
        if (Object.keys(analyticBreadcrumb).length > 0) {
            analyticData['breadcrumb'] = analyticBreadcrumb
        }
        analyticsManager.track(PAGEVIEW, analyticData)
    }, [])

    const fetchMasterProducts = async () => {
        let allMasterProducts = []
        _.each(productIds, (id) => {
            allMasterProducts.push(getMasterProduct(connector, id))
        })
        Promise.all(allMasterProducts)
            .then((response) => {
                setMasterProducts(response)
            })
            .catch((e) => {
                console.error('error', e)
            })
    }

    const fetchAllProduct = async () => {
        let allProducts = []
        _.each(productIds, (id) => {
            allProducts.push(getProduct(connector, id))
        })
        Promise.all(allProducts)
            .then((response) => {
                setProducts(response)
            })
            .catch((e) => {
                console.error('error', e)
            })
    }

    return (
        <Fragment>
            <div className="grid-tile">
                {productIds.map((productId, index) => {
                    if (index >= 0) {
                        const masterProduct = masterProducts[index]
                        return (
                            masterProduct && (
                                <ProductTile
                                    dataId={productId}
                                    product={products[index]}
                                    masterProduct={masterProduct}
                                    cssClass="product-chart"
                                    chartNumber={index + 1}
                                    showOnLazyLoad={false}
                                    position={index + 1}
                                />
                            )
                        )
                    } else return null
                })}
            </div>
        </Fragment>
    )
}

export default ChartTileIndex
