/* Content Asset Component */
import React, {useEffect, useRef, useState, useContext} from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import {useHistory} from 'react-router-dom'
import {getConnector} from '../../connector'
import {handleSocialShare} from './helper'
import {findCategoryBySlug} from '../../pages/isomorphic/globalFunctions'
import {GlobalStateContext} from '../../components/global-state'
import {getMenuCategories} from '../menu/helper'
import {HTTPError} from 'progressive-web-sdk/dist/ssr/universal/errors'
import DOMPurify from 'isomorphic-dompurify'

const ContentAssetWithData = (props) => {
    const {contentAssetData, dataId} = props
    const globalState = useContext(GlobalStateContext)

    const [clickHandlerAttached, setClickHandlerAttached] = useState(false)
    const [imgHandlerAttached, setImgHandlerAttached] = useState(false)
    const assetRef = useRef()
    const connector = getConnector()
    const contentAssetRef = useRef()
    const history = useHistory()

    useEffect(() => {
        if (contentAssetData?.id === 'help-our-policies-privacy-policy-asset') {
            window.addEventListener('OneTrustLoaded', function() {
                window.OneTrust.initializeCookiePolicyHtml()
            })
        }
        return () => {
            window.removeEventListener('OneTrustLoaded', function() {
                window.OneTrust.initializeCookiePolicyHtml()
            })
        }
    }, [contentAssetData])

    const goToHelpCategoryPage = async (href) => {
        const menuCategories = (await getMenuCategories(connector, 'HEL00000', 3)).categories
        let category = {}
        let categoryId = {}
        menuCategories.some((cat) => {
            return cat?.categories?.some((e) => {
                if (`/${e?.c_pageUrl}/`?.toLowerCase()?.includes(href)) {
                    categoryId = e?.id?.toLowerCase()
                    category = e
                    return true
                } else {
                    return e?.categories?.some((el2, index) => {
                        if (`/${el2?.c_pageUrl}/`?.toLowerCase()?.includes(href)) {
                            categoryId = el2?.id?.toLowerCase()
                            category = el2
                            return true
                        }
                    })
                }
            })
        })
        if (category) {
            history.push(`/${category.c_pageUrl?.toLowerCase()}/`)
        } else {
            throw new HTTPError(404, 'Wrong Page called ')
        }
    }
    useEffect(() => {
        const path = window.location.pathname
        let anchor = contentAssetRef.current.getElementsByTagName('a')

        if (anchor.length > 0 && !clickHandlerAttached) {
            Array.from(anchor).forEach((element) => {
                if (
                    !element?.href.includes('mailto') &&
                    !element?.href.includes('tel:') &&
                    !element?.href.includes('www.kobo.com') &&
                    !element?.href.includes('www.whsmithplc.co.uk') &&
                    !element?.href.includes('www.whsmithcareers.co.uk')
                ) {
                    element.onclick = (e) => {
                        e.preventDefault()
                        if (element?.href.includes('#top')) {
                            window.scrollTo(0, 0)
                        } else if (element?.href.includes('#')) {
                            let url = new URL(element?.href)
                            let category = {}
                            globalState?.menuCategories?.some((cat) => {
                                let foundCategory = findCategoryBySlug(
                                    cat,
                                    `${url?.pathname}${url?.hash}`
                                )
                                if (foundCategory) {
                                    category = foundCategory
                                    return true
                                }
                            })

                            if (url?.pathname?.includes('help')) {
                                history.push({
                                    pathname: /\/$/.test(url?.pathname)
                                        ? `${url?.pathname}`
                                        : `${url?.pathname}/`,
                                    hash: `${url?.hash}`
                                })
                            } else {
                                history.push({
                                    pathname: /\/$/.test(url?.pathname)
                                        ? `${url?.pathname}`
                                        : `${url?.pathname}/`,
                                    hash: `${url?.hash}`,
                                    state: {
                                        skeleton:
                                            (Object.values(category).length > 0 &&
                                                category?.c_template) ||
                                            ''
                                    }
                                })
                            }
                        } else if (element?.href.includes('help/')) {
                            goToHelpCategoryPage(element?.pathname)
                        } else if (element?.href.includes(`${process.env.HOSTNAME}`)) {
                            let url = new URL(element?.href)
                            let category = {}
                            globalState?.menuCategories?.some((cat) => {
                                let foundCategory = findCategoryBySlug(
                                    cat,
                                    `${url?.pathname}${url?.search}${url?.hash}`
                                )
                                if (foundCategory) {
                                    category = foundCategory
                                    return true
                                }
                            })
                            const products =
                                globalState?.categoryProductData?.[
                                    `${url?.pathname}${url?.search}${url?.hash}`
                                ] || []
                            const getValue = Object.keys(products || {})?.filter(
                                (el) => products[el].length
                            )
                            const maxValue = Math.min(...(getValue.length > 0 ? getValue : [0]))
                            const queryParams = new URLSearchParams(url?.search)
                            if (maxValue) {
                                queryParams.set('p', maxValue)
                            }
                            history.push({
                                pathname: /\/$/.test(url?.pathname)
                                    ? `${url?.pathname}`
                                    : `${url?.pathname}/`,
                                search: `${queryParams.toString()}`,
                                hash: `${url?.hash}`,
                                state: {
                                    skeleton:
                                        (Object.values(category).length > 0 &&
                                            category?.c_template) ||
                                        ''
                                }
                            })
                        } else if (element?.href.includes('javascript:')) {
                            return
                        } else {
                            let url = new URL(element?.href)
                            window.open(url?.href, '_blank')
                        }
                    }
                }
                if (!element?.href.includes(`${process.env.HOSTNAME}`)) {
                    element.rel = 'noreferrer'
                }
                setClickHandlerAttached(true)
            })
        }

        let img = contentAssetRef.current.getElementsByTagName('img')
        if (img.length > 0 && !imgHandlerAttached) {
            Array.from(img).forEach((element) => {
                ReactDOM.findDOMNode(element).setAttribute('loading', 'lazy')
                setImgHandlerAttached(true)
            })
        }
    }, [contentAssetData])

    useEffect(() => {
        if (contentAssetData !== undefined && contentAssetData.c_body) {
            const timer = setInterval(() => {
                const visibleElements = document.getElementsByClassName(
                    'toggle-icon js-toggle accordion-title '
                )
                const visibleElementsExpanded = document.getElementsByClassName('accordion-wrap')

                if (visibleElements.length > 0) {
                    const arr = []
                    for (let i = 0; i < visibleElements.length; i++) {
                        const element = visibleElements[i]
                        const elementExpanded = visibleElementsExpanded[i]
                        arr.push(true)
                        ReactDOM.findDOMNode(element).addEventListener('click', (e) => {
                            const tag = e.target.tagName
                            if (tag != 'A') {
                                ReactDOM.findDOMNode(elementExpanded).classList.toggle(
                                    'expanded',
                                    arr[i]
                                )
                            }
                            arr[i] = !arr[i]
                        })
                    }
                    clearInterval(timer)
                }
            }, 1000)
            var b = document.getElementById('sbiframehook'),
                a = document.createElement('script')
            if (b) {
                b.innerHTML = ''
                a.setAttribute('data-iframe', 'https://connect.studentbeans.com/v4/whsmith/uk')
                a.setAttribute('data-load', 'connect')
                a.setAttribute('id', 'stb_root')
                a.async = !0
                a.src = 'https://cdn.studentbeans.com/third-party/all.js'
                b.appendChild(a)
            }
        }
    }, [contentAssetData])

    useEffect(() => {
        if (contentAssetData && contentAssetData.id == 'footer-social-network') {
            const socialNetworkList = document.getElementsByClassName('social-network-list')[0]
                .children
            if (socialNetworkList.length > 0) {
                for (let i = 0; i < socialNetworkList.length; i++) {
                    const element = socialNetworkList[i].getElementsByTagName('a')[0]

                    let socialNetwork = 'facebook'
                    if (
                        element.getElementsByTagName('svg')[0].classList.contains('icon-facebook')
                    ) {
                        socialNetwork = 'facebook'
                    }
                    if (element.getElementsByTagName('svg')[0].classList.contains('icon-twitter')) {
                        socialNetwork = 'twitter'
                    }
                    if (
                        element.getElementsByTagName('svg')[0].classList.contains('icon-pinterest')
                    ) {
                        socialNetwork = 'pinterest'
                    }
                    if (
                        element.getElementsByTagName('svg')[0].classList.contains('icon-instagram')
                    ) {
                        socialNetwork = 'instagram'
                    }
                    if (element.getElementsByTagName('svg')[0].classList.contains('icon-youtube')) {
                        socialNetwork = 'youtube'
                    }
                    ReactDOM.findDOMNode(element).addEventListener('click', () => {
                        handleSocialShare(socialNetwork)
                    })
                }
            }
        }
    }, [contentAssetData])

    return (
        <div ref={contentAssetRef}>
            {contentAssetData && (
                <div
                    ref={assetRef}
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(contentAssetData.c_body, {
                            FORBID_TAGS: ['script'],
                            FORCE_BODY: true,
                            ADD_TAGS: ['use', 'iframe'],
                            ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'] //or //or ALLOWED_ATR
                        })
                    }}
                ></div>
            )}
            {dataId && (
                <div
                    ref={assetRef}
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(dataId.c_body, {
                            FORBID_TAGS: ['script'],
                            FORCE_BODY: true,
                            ADD_TAGS: ['use', 'iframe'],
                            ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'] //or //or ALLOWED_ATR
                        })
                    }}
                ></div>
            )}
        </div>
    )
}

ContentAssetWithData.propTypes = {
    contentAssetData: PropTypes.any,
    contentAsset: PropTypes.object,
    dataId: PropTypes.any
}

export default ContentAssetWithData
