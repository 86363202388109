import {HTTPError} from 'progressive-web-sdk/dist/ssr/universal/errors'
import {getProducts, getProductsFromCategory} from '../../components/faceout/helper'
import {uniq} from '../../utils/utils'
import {getContentAsset} from '../../components/content-asset/helper'
import {callSupplierPrecedence} from '../../pages/isomorphic/globalFunctions'
import {getAssetUrl} from 'progressive-web-sdk/dist/ssr/universal/utils'

export const getPageStructure = async (connector, contentAssetId) => {
    const maxProductsCount = 8
    const categoryDataStructure = await getContentAsset(connector, contentAssetId)

    var meta = {
        type: 'website',
        title:
            categoryDataStructure.page_title || 'Books, stationery, gifts and much more | WHSmith',
        description:
            categoryDataStructure.page_description ||
            'Welcome to WHSmith. Here you can find stationery, books, toys and games and many more products. Shop online or find out more about us.',
        image_path: getAssetUrl('static/meta/meta-image-fallback.png'),
        keywords: categoryDataStructure.page_keywords
    }

    if (
        categoryDataStructure &&
        categoryDataStructure.fault &&
        categoryDataStructure.fault.type === 'ContentAssetNotFoundException'
    ) {
        return {pageStructureTemp: undefined, metaTemp: meta}
    }
    if (
        categoryDataStructure &&
        categoryDataStructure.fault &&
        categoryDataStructure.fault.type === 'SiteOfflineException'
    ) {
        throw new HTTPError(503)
    }

    let pageStructure = {}
    try {
        pageStructure =
            categoryDataStructure &&
            categoryDataStructure.c_body &&
            JSON.parse(categoryDataStructure.c_body)
    } catch (el) {
        pageStructure = {}
    }

    let allSlotsData = []
    for (let i = 0; i < pageStructure?.contentSlots?.length; i++) {
        const slot = pageStructure?.contentSlots[i]

        if (slot.contentType === 'ContentAsset') {
            const contentData = await getContentAsset(connector, slot.data)
            contentData.c_body =
                (contentData?.c_body || '').replace(
                    /https:\/\/assets.whsmith.co.uk\//g,
                    '/mobify/caching/assets/'
                ) || contentData.c_body
            allSlotsData.push({
                pageStructure: slot,
                data: contentData
            })
        }
        if (slot.contentType === 'Faceout') {
            const products = await getProducts(connector, slot.data)
            let variantDetailsData = []
            let slotData = []
            let variantIds = {}
            for (let i = 0; i < products?.data?.length; i++) {
                const item = products?.data[i]
                if (item.price && item.inventory.orderable) {
                    variantIds[item.id] = item?.variants.map((newItem) => {
                        return newItem.product_id
                    })
                }
            }

            const ids = Object.values(variantIds).flat()

            let productIndexes = []
            let index = 1
            for (let i = 0; i < ids.length; i++) {
                if (!productIndexes[index - 1]) {
                    productIndexes[index - 1] = []
                }
                if (i < index * 23) {
                    productIndexes[index - 1].push(ids[i])
                } else {
                    productIndexes[index] = []
                    productIndexes[index].push(ids[i])
                    index = index + 1
                }
            }
            let variantDetails = []
            for (let i = 0; i < productIndexes.length; i++) {
                const productIds = productIndexes[i]
                variantDetails = variantDetails.concat(
                    (await getProducts(connector, productIds))?.data || []
                )
            }

            Object.keys(variantIds).map((el) => {
                const variants = variantDetails?.filter((e) => e.id.includes(el))

                variantDetailsData = variantDetailsData.concat(
                    callSupplierPrecedence(variants || [])
                )
            })
            slotData = variantDetailsData.map((el) => el.id)
            variantDetailsData = variantDetailsData.slice(0, maxProductsCount)

            if (variantDetailsData.length < maxProductsCount) {
                const categoryResponse = await getProductsFromCategory(
                    connector,
                    slot.categoryId,
                    maxProductsCount - variantDetailsData.length
                )
                if (categoryResponse && categoryResponse?.hits) {
                    let newIds = categoryResponse?.hits?.map((e) => {
                        if (e.product_id && e.c_topPriorityVariation) {
                            return e.c_topPriorityVariation.id
                        }
                    })
                    newIds = uniq(newIds)
                    slotData = [...slotData, ...newIds]
                    slotData = uniq(slotData)

                    variantDetailsData = (await getProducts(connector, slotData))?.data || []
                    variantDetailsData = variantDetailsData.map((item) => {
                        if (item.price && item.inventory.orderable) return item
                    })
                }
            }

            allSlotsData.push({
                pageStructure: {...slot, data: slotData},
                data: variantDetailsData
            })
        }
    }

    return {
        pageStructureTemp: pageStructure,
        allSlotsDataTemp: allSlotsData,
        metaTemp: meta,
        loaded: pageStructure ? Object.values(pageStructure).length > 0 : false
    }
}
