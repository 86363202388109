import React, {useEffect, Fragment, useContext} from 'react'
import PropTypes from 'prop-types'

import {TabletOrHigher} from '../../components/media-queries'
import Loader from '../loader'
import {isBrowser, pushDatalayerEvent} from '../../utils/utils'
import ProductTile from '../product-tile'
import ShowSearchSuggestions from './showSearchSuggestions'

const HeaderSearchDropdown = (props) => {
    const {
        display,
        isSearchTyped,
        products = [],
        searchPhraseSuggestions,
        selectRef,
        isProductsLoading,
        topLevelCategory,
        topLevelCategoryId,
        handleSearchClick,
        isError,
        showSearchSuggestion,
        handleInputChange,
        onInputClick,
        onPressEnter,
        onSearchClose
    } = props

    const baseUrl = isBrowser() ? window.location.origin : 'https://www.whsmith.co.uk'

    useEffect(() => {
        if (display == 'none') {
            document
                .querySelector('#header-wrapper .container-fluid .overlay')
                .classList.remove('active')
        } else {
            document
                .querySelector('#header-wrapper .container-fluid .overlay')
                .classList.add('active')
        }
    }, [display])

    const isDesktop = () => {
        return window.innerWidth >= 1384
    }

    useEffect(() => {
        if (isDesktop()) {
            setTimeout(() => {
                const searchBarWidth = $('.header-search-container').innerWidth()
                $('#search-content-left, #product-suggestions-container, .product-suggestions').css(
                    {width: searchBarWidth + 'px'}
                )
            }, 1000)
        }
    }, [])

    useEffect(()=>{
        let item = document.getElementById('search-phase-label-light-box') || false
        if(products?.length > 0 && item && !isProductsLoading){
            item.scrollIntoView({behavior: 'smooth',block:'center'})
        }
    },[products,isProductsLoading])

    const triggerSuggestInteraction = (section, suggestion, url) => {
        pushDatalayerEvent({
            event: 'suggestInteraction',
            suggestionUrl: `${baseUrl}/${url}/`,
            suggestionSection: section,
            suggestionText: suggestion || isSearchTyped
        })
    }

    return (
        <div ref={selectRef}>
            <Fragment>
                <TabletOrHigher>
                    <></>
                    <div
                        id="search-suggestions"
                        className="search-suggestions"
                        style={{display: `${display}`}}
                    >
                        <div className="search-suggestion-wrapper full">
                            <div className="product-suggestions">
                                {isProductsLoading ? (
                                    <div>
                                        <Loader />
                                    </div>
                                ) : (
                                    <Fragment >
                                        <ShowSearchSuggestions
                                            searchPhraseSuggestions={searchPhraseSuggestions}
                                            products={products}
                                            isSearchTyped={isSearchTyped}
                                            handleInputChange={handleInputChange}
                                            onInputClick={onInputClick}
                                            onPressEnter={onPressEnter}
                                            handleSearchClick ={handleSearchClick}
                                            isError={isError}
                                            topLevelCategoryId={topLevelCategoryId || ""}
                                            topLevelCategory={topLevelCategory || "All"}
                                            triggerSuggestInteraction={triggerSuggestInteraction}
                                            onSearchClose={onSearchClose}
                                        />
                                    </Fragment>
                                )
                                }
                                {isProductsLoading ? (
                                    <div />
                                ) : products.length > 0 ? (
                                    <div id='grid-tile-container-id' className="grid-tile-container" >
                                        <span className="search-phase-text" id='search-phase-label-light-box'>
                                            Suggested Products :
                                        </span>
                                        {products.map((product, index) => (
                                            <div
                                                key={product.product_id}
                                                className={
                                                    showSearchSuggestion
                                                        ? 'grid-tile'
                                                        : 'product-suggestion'
                                                }
                                            >
                                                <ProductTile
                                                    dataId={product.product_id}
                                                    product={product?.c_topPriorityVariation}
                                                    masterProduct={product}
                                                    cssClass="product-chart"
                                                    queryString={isSearchTyped}
                                                    showOnLazyLoad={true}
                                                    isFaceout={false}
                                                    showSearchSuggestion={showSearchSuggestion}
                                                    onSearchClose={onSearchClose}
                                                    triggerSuggestInteraction={triggerSuggestInteraction}
                                                    isSearchListing={isSearchTyped}
                                                    position={index + 1}
                                                />

                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="no-products-phrase">{`No search results for "${isSearchTyped}" were found`}</div>
                                )}
                            </div>
{(products?.length>2) && !isProductsLoading }
                        </div>
                        {products.length > 0 && !isProductsLoading && (
                            <div className="light-box-btn-container">
                                <button className="light-box-btn"
                                  onClick={() => {
                                    handleSearchClick()
                                    onSearchClose()
                                    document.getElementById('q').blur()
                                }}
                                >
                                    <span className="see-all-search-text">
                                        SEE ALL SEARCH RESULTS
                                    </span>
                                </button>
                            </div>
                        )}
                    </div>
                </TabletOrHigher>
                <div className='search-suggestions-mobile'>
                    <div
                        id="search-suggestions"
                        className="search-suggestions"
                        style={{display: `${display}`}}
                    >
                        <div className="search-suggestion-wrapper full" >
                            <div className="product-suggestions">
                                {isProductsLoading ? (
                                    <div>
                                        <Loader />
                                    </div>
                                )
                                :
                                (
                                    searchPhraseSuggestions &&
                                    searchPhraseSuggestions.length > 0 && products.length > 0 && (
                                        <div>
                                            <Fragment>
                                                <ShowSearchSuggestions
                                                    searchPhraseSuggestions={searchPhraseSuggestions}
                                                    products={products}
                                                    isSearchTyped={isSearchTyped}
                                                    handleInputChange={handleInputChange}
                                                    onInputClick={onInputClick}
                                                    onPressEnter={onPressEnter}
                                                    handleSearchClick ={handleSearchClick}
                                                    isError={isError}
                                                    topLevelCategoryId={topLevelCategoryId || ""}
                                                    topLevelCategory={topLevelCategory || "All"}
                                                    triggerSuggestInteraction={triggerSuggestInteraction}
                                                    onSearchClose={onSearchClose}
                                                />
                                            </Fragment>
                                        </div>
                                    )
                                )}
                                {isProductsLoading ? (
                                    <div />
                                ) :
                                    products.length > 0 ? (
                                    <div id='grid-tile-container-id' className="grid-tile-container" >
                                        <span className="search-phase-text" id='search-phase-label-light-box'>
                                            Suggested Products :
                                        </span>

                                        {products.map((product, index) => (
                                            <div
                                                key={product.product_id}
                                                className={
                                                    showSearchSuggestion
                                                        ? 'grid-tile'
                                                        : 'product-suggestion'
                                                }
                                            >
                                                <ProductTile
                                                    dataId={product.product_id}
                                                    product={product?.c_topPriorityVariation}
                                                    masterProduct={product}
                                                    cssClass="product-chart"
                                                    showOnLazyLoad={true}
                                                    isFaceout={false}
                                                    showSearchSuggestion={showSearchSuggestion}
                                                    onSearchClose={onSearchClose}
                                                    triggerSuggestInteraction={triggerSuggestInteraction}
                                                    position={index + 1}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="no-products-phrase">{`No search results for "${isSearchTyped}" were found`}</div>
                                )}
                            </div>
{(products?.length >2) && !isProductsLoading}
                        </div>
                        {products.length > 0 && !isProductsLoading && (
                            <div className="light-box-btn-container">
                                <button className="light-box-btn"
                                    onClick={() => {
                                        handleSearchClick()
                                        onSearchClose()
                                        document.getElementById('q').blur()
                                    }}
                                >
                                    SEE ALL SEARCH RESULTS
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </Fragment>
        </div>
    )
}

HeaderSearchDropdown.propTypes = {
    display: PropTypes.string,
    isSearchTyped: PropTypes.string,
    products: PropTypes.array,
    searchPhraseSuggestions: PropTypes.array,
    brandSuggestions: PropTypes.array
}

export default HeaderSearchDropdown
