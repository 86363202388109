import React from 'react'
import loadable from '@loadable/component'
import SkeletonBlock from 'progressive-web-sdk/dist/components/skeleton-block'

const fallback = <SkeletonBlock height="100%" width="100%" />

const MyProfile = loadable(() => import('./pages/my-profile'), {fallback})
const MyOrders = loadable(() => import('./pages/my-orders'), {fallback})
const MySecurity = loadable(() => import('./pages/my-security'), {fallback})
const MyPreferences = loadable(() => import('./pages/my-preferences'), {fallback})
const MyOrderDetails = loadable(() => import('./pages/order-details'), {fallback})
const Home = loadable(() => import('./pages/home'), {fallback})
const ProductList = loadable(() => import('./pages/product-list'), {fallback})
const SearchList = loadable(() => import('./pages/search-list'), {fallback})
// const ProductDetails = loadable(() => import('./pages/product-details'), {fallback})
const Product = loadable(() => import('./pages/product'), {fallback})
const Account = loadable(() => import('./pages/account'), {fallback})
const AddressBook = loadable(() => import('./pages/address-book'), {fallback})
const MyWallet = loadable(() => import('./pages/my-wallet'), {fallback})

const Login = loadable(() => import('./pages/login'), {fallback})
const Register = loadable(() => import('./pages/register'), {fallback})
const ResetPassword = loadable(() => import('./pages/set-new-password'), {fallback})
const AccountNavigation = loadable(() => import('./pages/account-navigation'), {fallback})
const TermsConditions = loadable(() => import('./pages/terms-conditions'), {fallback})
const DefaultUrl = loadable(() => import('./pages/default-url'), {fallback})
const StoreLocator = loadable(() => import('./pages/store-locator'), {fallback})
const StoreDetails = loadable(() => import('./pages/store-details'), {fallback})
const Store = loadable(() => import('./pages/stores'), {fallback})
const PasswordReset = loadable(() => import('./pages/password-reset'), {fallback})
const PasswordResetConfirmation = loadable(() => import('./pages/password-reset-confirmation'), {
    fallback
})
// const Charts = loadable(() => import('./pages/charts'), {fallback})
const Help = loadable(() => import('./pages/help'), {fallback})

const CustomerService = loadable(() => import('./pages/help/customer-service'), {fallback})

// const StudentsDiscount = loadable(() => import('./pages/students'), {fallback})

const NoResultsFound = loadable(() => import('./pages/no-results-found'), {fallback})

const DynamicPage = loadable(() => import('./pages/dynamic-page'), {fallback})

// const CategoryLandingPage = loadable(() => import('./pages/category-landing'), {fallback})
const MyBasket = loadable(() => import('./pages/my-basket'), {fallback})
const OrderConfirmation = loadable(() => import('./pages/order-confirmation'), {fallback})
const TrackYourOrder = loadable(() => import('./pages/track-order'), {fallback})
const Checkout = loadable(() => import('./pages/checkout'), {fallback})
const CheckoutShipping = loadable(() => import('./pages/checkout-shipping'), {fallback})
const CheckoutProcessPayment = loadable(() => import('./pages/checkout-process-payment'), {
    fallback
})
const CheckoutPayment = loadable(() => import('./pages/checkout-payment'), {fallback})

// Temporary Error Pages for Testing
const Error410 = loadable(() => import('./pages/error-test/error-410'), {fallback})
const Error500 = loadable(() => import('./pages/error-test/error-500'), {fallback})
const ErrorMaintenance = loadable(() => import('./pages/error-test/error-maintenance'), {fallback})
const ErrorOverload = loadable(() => import('./pages/error-test/ErrorOverload'), {fallback})

// const AgeVerification = loadable(() => import('./pages/help/age-verification'), {fallback})

const routes = [
    {
        path: '/search/',
        component: SearchList,
        // exact: true
        strict: false
    },
    {
        path: '/checkout-process-payment/',
        component: CheckoutProcessPayment,
        exact: true,
        strict: false
    },
    {
        path: '/checkout-login/',
        component: Checkout,
        exact: true,
        strict: false
    },
    {
        path: '/checkout-shipping/',
        component: CheckoutShipping,
        exact: true,
        strict: false
    },
    {
        path: '/checkout-payment/',
        component: CheckoutPayment,
        exact: true,
        strict: false
    },
    {
        path: '/shopping-basket/',
        component: MyBasket,
        strict: false
    },
    {
        path: '/checkout-confirm/',
        component: OrderConfirmation,
        exact: true,
        strict: false
    },
    {
        path: '/track-your-order/',
        component: TrackYourOrder,
        exact: true,
        strict: false
    },
    // {
    //     path: '/charts',
    //     component: Charts
    // },
    {
        path: '/error-410/',
        component: Error410,
        strict: false
    },
    {
        path: '/error-500/',
        component: Error500,
        strict: false
    },
    {
        path: '/error-maintenance/',
        component: ErrorMaintenance,
        strict: false
    },
    {
        path: '/error-overload/',
        component: ErrorOverload,
        strict: false
    },
    {
        path: '/no-results-found/',
        component: NoResultsFound,
        exact: true,
        strict: false
    },
    // {
    //     path: '/help/shopping-with-whsmith/:age-verification/:pageId',
    //     component: AgeVerification
    // },
    {
        path: '/help/:catId/:subCatId/:subSubCatId/:pageId/',
        component: CustomerService,
        strict: false
    },
    {
        path: '/help/:catId/:subCatId/:pageId/',
        component: CustomerService,
        strict: false
    },
    {
        path: '/help/:catId/:pageId/',
        component: CustomerService,
        strict: false
    },
    // {
    //     path: '/students/',
    //     component: StudentsDiscount,
    //     strict: false
    // },
    {
        path: '/help/',
        component: Help,
        strict: false
    },
    {
        path: '/store-locator/',
        component: StoreLocator,
        strict: false
    },
    // {
    //     path: '/stores/:countyValue/:storeId/',
    //     component: StoreDetails,
    //     strict: false
    // },
    {
        path: '/stores/',
        component: Store,
        strict: false,
        exact: true
    },
    {
        path: '/stores/:details/',
        component: Store,
        strict: false,
        exact: true
    },
    {
        path: '/default-url/',
        component: DefaultUrl,
        strict: false
    },
    {
        path: '/my-wallet/',
        component: MyWallet,
        strict: false
    },
    {
        path: '/account-editpassword/',
        component: MySecurity,
        strict: false
    },
    {
        path: '/account-preference-centre/',
        component: MyPreferences,
        strict: false
    },
    {
        path: '/my-profile/',
        component: MyProfile,
        strict: false
    },
    {
        path: '/orders/',
        component: MyOrders,
        strict: false
    },
    {
        path: '/orders-details/',
        component: MyOrderDetails,
        strict: false
    },
    {
        path: '/account/',
        component: Account,
        strict: false
    },
    {
        path: '/address-book/',
        component: AddressBook,
        strict: false
    },
    {
        path: '/products/:category/:subCategory/:subCategory1/:productId',
        component: Product,
        exact: true,
        strict: false
    },
    {
        path: '/products/:category/:subCategory/:productId',
        component: Product,
        exact: true,
        strict: false
    },
    {
        path: '/products/:category/:productId',
        component: Product,
        exact: true,
        strict: false
    },
    {
        path: '/products/:category/:authorId/:productType/:productId',
        component: Product,
        exact: true,
        strict: false
    },
    {
        path: '/products/:category/:authorId1/:authorId2/:productType/:productId',
        component: Product,
        exact: true,
        strict: false
    },
    // {
    //     path: '/products/:productId',
    //     component: ProductDetails
    // },
    {
        path: '/login/',
        component: Login,
        exact: true,
        strict: false
    },
    {
        path: '/register/',
        component: Register,
        strict: false
    },
    {
        path: '/set-password/',
        component: ResetPassword,
        strict: false
    },
    {
        path: '/passwordreset/',
        component: PasswordReset,
        strict: false
    },
    {
        path: '/forgotten-password/',
        component: PasswordResetConfirmation,
        strict: false
    },
    {
        path: '/account-navigation/',
        component: AccountNavigation,
        strict: false
    },
    {
        path: '/website-terms-and-conditions/',
        component: TermsConditions,
        strict: false
    },
    {
        path: '/content-navigation/:pageId/',
        component: DynamicPage,
        strict: false
    },
    {
        path: '/:catUrl/:categoryId/',
        component: ProductList,
        exact: true
    },
    // {
    //     path: '/:catUrl/:subCategory/:subSubCategory/:categoryId/:productType',
    //     component: ProductList,
    //     exact: true
    // },
    {
        path: '/charts/',
        component: ProductList,
        exact: true,
        strict: false
    },
    {
        path: '/charts/:categoryId/',
        component: ProductList,
        exact: true,
        strict: false
    },
    {
        path: '/:catUrl/:subCategory/:subCategory1/:categoryId/',
        component: ProductList,
        exact: true,
        strict: false
    },
    {
        path: '/:catUrl/:subCategory/:subCategory1/:categoryId/:refineBy/',
        component: ProductList,
        exact: true,
        strict: false
    },
    {
        path: '/:catUrl/:subCategory/:categoryId',
        component: ProductList,
        exact: true,
        strict: false
    },
    {
        path: '/:catUrl/',
        component: ProductList,
        exact: true,
        strict: false
    },
    {
        path: '/',
        component: Home,
        exact: true,
        strict: false
    }
]

export default routes
