import {SalesforceConnector} from '@mobify/commerce-integrations/dist/connectors/sfcc'

const HOST_TO_CLIENT_ID = {
    'test.whsmith.co.uk': `${process.env.DEVELOPMENT_CLIENT_ID}`,
    'staging-eu01-whsmith.demandware.net': `${process.env.STAGING_CLIENT_ID}`,
    'prod.whsmith.co.uk': `${process.env.PRODUCTION_CLIENT_ID}`,
    default: `${process.env.CLIENT_ID}`
}

const HOST_TO_ORGANISATION_ID = {
    'test.whsmith.co.uk': `${process.env.DEVELOPMENT_ORGANISATION_ID}`,
    'staging-eu01-whsmith.demandware.net': `${process.env.STAGING_ORGANISATION_ID}`,
    'prod.whsmith.co.uk': `${process.env.PRODUCTION_ORGANISATION_ID}`,
    default: `${process.env.ORGANISATION_ID}`
}

const HOST_TO_SHORT_CODE = {
    'test.whsmith.co.uk': `${process.env.DEVELOPMENT_SHORT_CODE}`,
    'staging-eu01-whsmith.demandware.net': `${process.env.STAGING_SHORT_CODE}`,
    'prod.whsmith.co.uk': `${process.env.PRODUCTION_SHORT_CODE}`,
    default: `${process.env.SHORT_CODE}`
}

const HOST_TO_SITE_ID = {
    'test.whsmith.co.uk': `${process.env.DEVELOPMENT_SITE_ID}`,
    'staging-eu01-whsmith.demandware.net': `${process.env.STAGING_SITE_ID}`,
    'prod.whsmith.co.uk': `${process.env.PRODUCTION_SITE_ID}`,
    default: `${process.env.SITE_ID}`
}

const HOST_TO_LAYBUY_URL = {
    'test.whsmith.co.uk': `${process.env.SANDBOX_LAYBUY_URL}`,
    'staging-eu01-whsmith.demandware.net': `${process.env.LIVE_LAYBUY_URL}`,
    'prod.whsmith.co.uk': `${process.env.LIVE_LAYBUY_URL}`,
    default: `${process.env.SANDBOX_LAYBUY_URL}`
}

const HOST_TO_PAY360_URL = {
    'test.whsmith.co.uk': `${process.env.SANDBOX_PAY360_URL}`,
    'staging-eu01-whsmith.demandware.net': `${process.env.LIVE_PAY360_URL}`,
    'prod.whsmith.co.uk': `${process.env.LIVE_PAY360_URL}`,
    default: `${process.env.SANDBOX_PAY360_URL}`
}

const HOST_TO_SVS_URL = {
    'test.whsmith.co.uk': `${process.env.SANDBOX_SVS_URL}`,
    'staging-eu01-whsmith.demandware.net': `${process.env.LIVE_SVS_URL}`,
    'prod.whsmith.co.uk': `${process.env.LIVE_SVS_URL}`,
    default: `${process.env.SANDBOX_SVS_URL}`
}

const HOST_TO_VCO_URL = {
    'test.whsmith.co.uk': `${process.env.SANDBOX_VCO_URL}`,
    'staging-eu01-whsmith.demandware.net': `${process.env.LIVE_VCO_URL}`,
    'prod.whsmith.co.uk': `${process.env.LIVE_VCO_URL}`,
    default: `${process.env.SANDBOX_VCO_URL}`
}

const HOST_TO_ENV = {
    'test.whsmith.co.uk': `development`,
    'staging-eu01-whsmith.demandware.net': `staging`,
    'prod.whsmith.co.uk': `production`,
    default: `development`
}

const HOST_TO_PAYMENT_TRANS_URL = {
    'test.whsmith.co.uk': `test.whsmith.co.uk`,
    'staging-eu01-whsmith.demandware.net': `staging-eu01-whsmith.demandware.net`,
    'prod.whsmith.co.uk': `prod.whsmith.co.uk`,
    default: `test.whsmith.co.uk`
}

const HOST_TO_REVOLUT_URL = {
    'test.whsmith.co.uk': `${process.env.SANDBOX_REVOLUT_URL}`,
    'staging-eu01-whsmith.demandware.net': `${process.env.LIVE_REVOLUT_URL}`,
    'prod.whsmith.co.uk': `${process.env.LIVE_REVOLUT_URL}`,
    default: `${process.env.SANDBOX_REVOLUT_URL}`
}

export const CLIENT_ID = (function getClientId() {
    // Note: Temporary workaround
    const mobify = WEBPACK_PACKAGE_JSON_MOBIFY

    //const { host } = getProxyConfigs().length > 0 ? getProxyConfigs().find(c => c.path === "api") : {host: mobify.ssrParameters.proxyConfigs[0].host}

    // Note: Should be done like this
    // const { host } = getProxyConfigs().find(c => c.path === "api")

    // when above line is added
    // getting this exception: TypeError: Cannot destructure property 'host' of 'Object(...)(...).find(...)' as it is undefined.
    if (mobify.ssrParameters) {
        return (
            HOST_TO_CLIENT_ID[mobify.ssrParameters.proxyConfigs[0].host] ||
            HOST_TO_CLIENT_ID['default']
        )
    }
})()
const mobify = WEBPACK_PACKAGE_JSON_MOBIFY

export const GET_ORG_ID = mobify.ssrParameters
    ? HOST_TO_ORGANISATION_ID[mobify.ssrParameters.proxyConfigs[0].host] ||
      HOST_TO_ORGANISATION_ID['default']
    : HOST_TO_ORGANISATION_ID['default']
export const GET_SHORT_CODE = mobify.ssrParameters
    ? HOST_TO_SHORT_CODE[mobify.ssrParameters.proxyConfigs[0].host] || HOST_TO_SHORT_CODE['default']
    : HOST_TO_ORGANISATION_ID['default']
export const GET_SITE_ID = mobify.ssrParameters
    ? HOST_TO_SITE_ID[mobify.ssrParameters.proxyConfigs[0].host] || HOST_TO_SITE_ID['default']
    : HOST_TO_SITE_ID['default']

export const GET_LAYBUY_URL = mobify.ssrParameters
    ? HOST_TO_LAYBUY_URL[mobify.ssrParameters.proxyConfigs[0].host] || HOST_TO_LAYBUY_URL['default']
    : HOST_TO_LAYBUY_URL['default']

export const GET_PAY360_URL = mobify.ssrParameters
    ? HOST_TO_PAY360_URL[mobify.ssrParameters.proxyConfigs[0].host] || HOST_TO_PAY360_URL['default']
    : HOST_TO_PAY360_URL['default']

export const GET_SVS_URL = mobify.ssrParameters
    ? HOST_TO_SVS_URL[mobify.ssrParameters.proxyConfigs[0].host] || HOST_TO_SVS_URL['default']
    : HOST_TO_SVS_URL['default']

export const GET_VCO_URL = mobify.ssrParameters
    ? HOST_TO_VCO_URL[mobify.ssrParameters.proxyConfigs[0].host] || HOST_TO_VCO_URL['default']
    : HOST_TO_VCO_URL['default']

export const GET_ENV = mobify.ssrParameters
    ? HOST_TO_ENV[mobify.ssrParameters.proxyConfigs[0].host] || HOST_TO_ENV['default']
    : HOST_TO_ENV['default']

export const GET_HOST_URL = mobify.ssrParameters
    ? HOST_TO_PAYMENT_TRANS_URL[mobify.ssrParameters.proxyConfigs[0].host] ||
      HOST_TO_PAYMENT_TRANS_URL['default']
    : HOST_TO_PAYMENT_TRANS_URL['default']

export const GET_REVOLUT_URL = mobify.ssrParameters
    ? HOST_TO_REVOLUT_URL[mobify.ssrParameters.proxyConfigs[0].host] ||
      HOST_TO_REVOLUT_URL['default']
    : HOST_TO_REVOLUT_URL['default']

// Replace Mobify's demo config with the correct values for your backend:
export const getConnector = () => {
    return SalesforceConnector.fromConfig({
        basePath: `${
            typeof window === 'undefined' ? process.env.APP_ORIGIN : ''
        }/mobify/proxy/api/s/${process.env.SITE_NAME}/dw/shop/${process.env.API_VERSION}`,
        defaultHeaders: {
            'x-dw-client-id': CLIENT_ID
        }
    })
}
export const getDataConnector = () => {
    return SalesforceConnector.fromConfig({
        basePath: `${
            typeof window === 'undefined' ? process.env.APP_ORIGIN : ''
        }/mobify/proxy/api/s/-/dw/data/${process.env.API_VERSION}`,
        defaultHeaders: {
            'x-dw-client-id': CLIENT_ID
        }
    })
}

// Return any connector-specific constants for root category ids, etc.
export const getRootCategoryId = () => {
    return 'root'
}

export const getCarouselImageSizeType = () => {
    return 'large'
}

export const getCarouselImagePropertyVariation = () => {
    return 'color'
}
