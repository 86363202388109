import React from 'react'

import SkeletonBlock from 'progressive-web-sdk/dist/components/skeleton-block'
import SkeletonText from 'progressive-web-sdk/dist/components/skeleton-text'
import {Desktop, Mobile, Tablet} from '../../components/media-queries'
import {useLocation} from 'react-router-dom'

const ProductListSkeleton = ({list = []}) => {
    const location = useLocation()
    return (
        <>
            <Desktop>
                <div className="breadcrumbs">
                    <div
                        className="breadcrumb content-width content-holder-padding"
                        style={{marginBottom: 20}}
                    >
                        <SkeletonText width="100px" style={{marginRight: 20}} />
                        <SkeletonText width="150px" style={{marginRight: 20}} />
                        <SkeletonText width="80px" style={{marginRight: 20}} />
                        <SkeletonText width="130px" style={{marginRight: 20}} />
                    </div>
                </div>
            </Desktop>

            <div className="clearfix content-width content-holder-padding">
                <div className="skeleton-container">
                    <div className="sidebar-skeleton">
                        {list.map((el) => (
                            <div className="product-tile-bottom" key={el}>
                                <div className="product-pricing">
                                    <SkeletonBlock width="300px" height={'40px'} />
                                    <br />
                                    <SkeletonText width="100%" />
                                    <br />
                                    <br />
                                </div>
                                <div className="product-pricing">
                                    <SkeletonText width="300px" height={'40px'} />
                                    <br />
                                </div>
                                <div className="product-pricing">
                                    <SkeletonBlock width="80px" height={'10px'} />
                                    <br />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="content-skeleton">
                        {location.pathname.includes('search') && (
                            <>
                                <SkeletonBlock
                                    height="10px"
                                    style={{marginLeft: 30, marginRight: 30, width: undefined}}
                                />
                                <SkeletonBlock
                                    height="40px"
                                    style={{margin: 30, width: undefined}}
                                />
                            </>
                        )}
                        {!location.pathname.includes('search') && (
                            <SkeletonBlock
                                width="149px"
                                height={'20px'}
                                className={'filter-skeleton-1st'}
                                style={{marginRight: 2, marginBottom: 20, marginLeft: 30}}
                            />
                        )}

                        <Tablet>
                            <div className="paginator-skeleton">
                                <section className="center-pagination-skeleton">
                                    <SkeletonBlock
                                        width="200px"
                                        height={'40px'}
                                        className={'filter-skeleton-3rd'}
                                        style={{marginRight: 10}}
                                    />
                                    <SkeletonBlock
                                        width="200px"
                                        height={'40px'}
                                        className={'filter-skeleton-3rd'}
                                        // style={{marginRight: 2}}
                                    />
                                </section>
                                {/* <section className="right">
                            <SkeletonBlock width="20px" height={'20px'} />
                            <SkeletonBlock width="20px" height={'20px'} />
                            <SkeletonBlock width="20px" height={'20px'} />
                            <SkeletonBlock width="20px" height={'20px'} />
                        </section> */}
                            </div>
                        </Tablet>
                        <Mobile>
                            <div className="paginator-skeleton">
                                <section className="center-pagination-skeleton">
                                    <SkeletonBlock
                                        width="120px"
                                        height={'40px'}
                                        className={'filter-skeleton-3rd'}
                                        style={{marginRight: 10}}
                                    />
                                    <SkeletonBlock
                                        width="120px"
                                        height={'40px'}
                                        className={'filter-skeleton-3rd'}
                                        // style={{marginRight: 2}}
                                    />
                                </section>
                                {/* <section className="right">
                            <SkeletonBlock width="20px" height={'20px'} />
                            <SkeletonBlock width="20px" height={'20px'} />
                            <SkeletonBlock width="20px" height={'20px'} />
                            <SkeletonBlock width="20px" height={'20px'} />
                        </section> */}
                            </div>
                        </Mobile>
                        <div className="filter-skeleton">
                            <SkeletonBlock
                                width="149px"
                                height={'40px'}
                                className={'filter-skeleton-1st'}
                                style={{marginRight: 2}}
                            />
                            <SkeletonBlock
                                width="149px"
                                height={'40px'}
                                className={'filter-skeleton-2nd'}
                            />
                            <div className="text-skeleton">
                                <SkeletonBlock width="300px" height={'40px'} />
                            </div>
                        </div>

                        <div className="container-skeleton-product-list">
                            {list.map((_, index) => (
                                <>
                                    <div className="product-tile-skeleton">
                                        <SkeletonBlock
                                            width="150px"
                                            height={'150px'}
                                            style={{marginLeft: 'auto', marginRight: 'auto'}}
                                        />
                                        <SkeletonText width="50%" />
                                        <SkeletonText width="40%" />
                                        <SkeletonText width="10%" />
                                        <SkeletonText width="80%" />
                                        <SkeletonText width="40%" />
                                        <SkeletonBlock width="100%" height="20px" />
                                        <SkeletonText width="30%" />
                                        <SkeletonBlock width={'100%'} height={'30px'} />
                                    </div>
                                    {/* Mobile Devices PLP and SLP Pages */}
                                    {index !== list?.length - 1 &&
                                        (index + 1) % 2 == 0 &&
                                        typeof window !== 'undefined' &&
                                        window.innerWidth <= 767 && (
                                            <hr
                                                style={{
                                                    width: '100%',
                                                    marginTop: '30px',
                                                    marginBottom: '30px'
                                                }}
                                            />
                                        )}

                                    {/* Mobile Devices PLP and SLP Pages Last Line */}
                                    {index === list?.length - 1 &&
                                        typeof window !== 'undefined' &&
                                        window.innerWidth <= 767 && (
                                            <hr
                                                style={{
                                                    width: '100%',
                                                    marginTop: '30px',
                                                    marginBottom: '30px'
                                                }}
                                            />
                                        )}

                                    {/* Tablet and Higher Devices PLP and SLP Pages */}
                                    {index !== list?.length - 1 &&
                                        (index + 1) % 3 == 0 &&
                                        typeof window !== 'undefined' &&
                                        window.innerWidth >= 768 && (
                                            <hr
                                                style={{
                                                    width: '100%',
                                                    marginTop: '30px',
                                                    marginBottom: '30px'
                                                }}
                                            />
                                        )}

                                    {/* Tablet and Higher Devices PLP and SLP Pages Last Line */}
                                    {index === list?.length - 1 &&
                                        typeof window !== 'undefined' &&
                                        window.innerWidth >= 768 && (
                                            <hr
                                                style={{
                                                    width: '100%',
                                                    marginTop: '30px',
                                                    marginBottom: '30px'
                                                }}
                                            />
                                        )}
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductListSkeleton
