import React from 'react'
import {Helmet} from 'react-helmet'

export const OneTrust = () => {
    return (
        <>
            <Helmet>
                {/* <!-- OneTrust Cookies Consent Notice start for whsmith.co.uk --> */}
                <script
                    src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
                    type="text/javascript"
                    charset="UTF-8"
                    data-domain-script={process.env.ONESTRUST_SCRIPTID}
                ></script>
                <script type="text/javascript">{`
                    function OptanonWrapper() {
                        const optanonEvent = new Event('optanonWrapperLoaded');
                        document.dispatchEvent(optanonEvent);

                        // move the allow all at the bottom of the list only on mobile
                        if (window.innerWidth < 768) {
                            const cookieSettingsBtn = document.getElementById("onetrust-pc-btn-handler");
                            const acceptBtn = document.getElementById("onetrust-accept-btn-handler");
                            cookieSettingsBtn.after(acceptBtn);
                        }
                    }
                `}</script>
                {/* <!-- OneTrust Cookies Consent Notice end for whsmith.co.uk --> */}
                <script type="text/javascript">{`function getCookie(name) {var value = "; " + document.cookie;var parts = value.split("; " + name + "=");if (parts.length == 2) {return true;}}function reloadOTBanner() { var otConsentSdk = document.getElementById("onetrust-consent-sdk");if (otConsentSdk) {otConsentSdk.remove();}if (window.OneTrust != null) {OneTrust.Init();setTimeout(function() {OneTrust.LoadBanner();var toggleDisplay = document.getElementsByClassName("ot-sdk-show-settings");for (var i = 0; i < toggleDisplay.length; i++) {toggleDisplay[i].onclick = function(event) {event.stopImmediatePropagation();window.OneTrust.ToggleInfoDisplay();};}}, 1000);}}`}</script>
            </Helmet>
        </>
    )
}

export default OneTrust
