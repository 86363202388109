/*Component for managing the display of the Product Image and its name as a link*/

import React, {Fragment, useEffect, useContext} from 'react'
import {getAssetUrl} from 'progressive-web-sdk/dist/ssr/universal/utils'
import Link from 'progressive-web-sdk/dist/components/link'
import Image from 'progressive-web-sdk/dist/components/image'
import {
    GlobalDispatchContext,
    // GlobalStateContext,
    SET_PRODUCT
} from '../../components/global-state'
import {getURL} from '../../utils/utils'

const ProductImage = ({
    image,
    imageSizes,
    chartNumber,
    pdtName,
    cssClass,
    product,
    masterProduct,
    type = '',
    isFaceout,
    showSearchSuggestion,
    onSearchClose,
    triggerSuggestInteraction = () => {}
}) => {
    let cPageURL, masterProductId
    const dispatch = useContext(GlobalDispatchContext)
    if (Object.values(masterProduct).length) {
        cPageURL = masterProduct?.c_page_url || masterProduct?.c_pageURL
        masterProductId = masterProduct?.master?.master_id || masterProduct?.product_id || masterProduct?.id
    }

    imageSizes =
        imageSizes?.map((el) => ({
            ...el,
            url:
                (el?.url || '').replace(
                    /https:\/\/assets.whsmith.co.uk\//g,
                    '/mobify/caching/assets/'
                ) || el.url,
            zoom:
                (el?.zoom || '').replace(
                    /https:\/\/assets.whsmith.co.uk\//g,
                    '/mobify/caching/assets/'
                ) || el.zoom,
            srcset:
                (el.srcset || '').replace(
                    /https:\/\/assets.whsmith.co.uk\//g,
                    '/mobify/caching/assets/'
                ) || el.srcset
        })) || imageSizes

    const URLParser = (productUrl) => {
        let i = productUrl.search(/product-image/i)
        let imageUrl = productUrl.substring(i, productUrl.length)
        return 'https://assets.whsmith.co.uk/' + imageUrl
    }

    const goToProductDetails = () => {
        if (!masterProduct) {
            return
        }

        if (!cPageURL || !masterProductId) {
            return
        }

        masterProductId.includes('MAG') || masterProductId.includes('mag')
            ? masterProductId.toUpperCase()
            : masterProductId.toLowerCase()

        if (cPageURL !== 'null') return `${getURL(cPageURL)}/${masterProductId}.html`
        else return `/products/null/${masterProductId}.html`
    }

    return (
        <Fragment>
            <div className={`${cssClass} product-image`}>
                <Link
                    onClick={() => {
                        onSearchClose()
                        triggerSuggestInteraction(
                            'Alternative',
                            product.name,
                            `${getURL(cPageURL)}/${masterProductId?.toLowerCase()}.html`
                        )
                        dispatch({type: SET_PRODUCT, payload: {product, masterProduct}})

                    }}
                    className={`${cssClass} product-image thumb-link image-alignment`}
                    title={
                        imageSizes && imageSizes.length > 0
                            ? imageSizes[0].title
                            : image && image.length > 0
                            ? image[0].title
                            : pdtName
                    }
                    href={goToProductDetails()}
                >
                    <Image
                        onImageError={() => getAssetUrl('static/img/global/noimageExtraMedium.png')}
                        className={`image ${showSearchSuggestion ? 'm-cmp_loaded-search' : 'm-cmp_loaded'} img-wrapper`}
                        wrapperStyle={{display: 'block'}}
                        loadingIndicator={
                            <img alt="" src={getAssetUrl('static/img/global/noimagelarge.png')} />
                        }
                        src={
                            imageSizes && imageSizes.length > 0
                                ? URLParser(
                                      imageSizes[0]?.url.split('/large/').join('/extra-small/')
                                  )
                                : getAssetUrl('static/img/global/noimagelarge.png')
                        }
                        alt={
                            imageSizes && imageSizes.length > 0
                                ? imageSizes[0].alt
                                : image && image.length > 0
                                ? image[0].alt
                                : pdtName
                        }
                        shouldLazyLoad={!isFaceout || typeof window === 'undefined'}
                        // this value must be false, we are controlling lazy load from parent component
                        // lazyLoadThreshold={200}
                    />
                    <meta
                        itemProp="image"
                        content={
                            imageSizes && imageSizes.length > 0
                                ? imageSizes[0].url
                                : image && image.length > 0
                                ? image[0].alt
                                : getAssetUrl('static/img/global/noimagelarge.png')
                        }
                    ></meta>
                </Link>
            </div>
            {chartNumber && <span className="product-chart-number">{chartNumber}</span>}
        </Fragment>
    )
}

export default ProductImage
