import React, {useState, useEffect, useContext, useCallback, useRef, Fragment} from 'react'
import PropTypes from 'prop-types'
import {getMasterProduct} from '../../components/faceout/helper'
import {getConnector} from '../../connector'
import {datalayerAddToCart} from '../../utils/utils'
import {getBasket} from '../../pages/my-basket/helper'
import {
    GlobalStateContext,
    GlobalDispatchContext,
    SET_CART_ITEMS,
    REFRESH_BASKET,
    BASKET_BUSY
} from '../global-state'
import {getFromLocal} from '../../utils/storage-utils'
import {getAssetUrl} from 'progressive-web-sdk/dist/ssr/universal/utils'
import {useLocation} from 'react-router-dom'
import {useEinstein} from '../../hooks/useEinstein'
import {useBloomreach} from '../../hooks/useBloomreach'
import {addInteractionErrorEvent, addToCartEvent} from '../../utils/gtmUtils'

const AddToBasket = ({
    productToBasket,
    masterProductInfo,
    showSearchSuggestion,
    position,
    isEinsteinReco
}) => {
    const globalState = useContext(GlobalStateContext)
    const dispatch = useContext(GlobalDispatchContext)
    const [isInvalid, setIsInvalid] = useState(false)
    const buttonStatusTimer = useRef(0)
    const [buttonText, setButtonText] = useState(
        productToBasket?.inventory?.orderable &&
            productToBasket?.inventory?.preorderable &&
            productToBasket?.inventory?.stock_level === 0
            ? 'Pre-Order'
            : 'Add to Basket'
    )
    const [buttonAction, setButtonAction] = useState(false)

    const [buttonStyle, setButtonStyle] = useState('button-reg button-bg-default')
    const [outOfStockMessage, setOutOfStockMessage] = useState(false)
    const einstein = useEinstein()
    const bloomreach = useBloomreach()

    const location = useLocation()
    const toogleAddToBasketButton = (action) => {
        clearTimeout(buttonStatusTimer.current)
        switch (action) {
            case 'loading':
                setButtonText('Adding Item')
                setButtonStyle('button-reg add-btn-click')
                setButtonAction(true)
                break
            case 'done':
                setButtonText('Item Added')
                setButtonStyle('button-reg button-bg-success no-btn-hover')
                defaultAddToBasketButton(1500)
                setButtonAction(false)

                break
            case 'error':
                setButtonText('Failed. Please try again')
                setButtonStyle('button-reg button-bg-fail no-btn-hover')
                defaultAddToBasketButton(3000)
                setButtonAction(false)

                break
            default:
                defaultAddToBasketButton(0)
                setButtonAction(false)
                break
        }
    }

    const onClick = useCallback((event) => {
        event.preventDefault()
        validateOrderFulfillment(event)
        setIsInvalid(true)
    }, [])

    useEffect(() => {
        if (productToBasket?.inventory?.stock_status !== undefined) {
            if (!productToBasket?.price) {
                setIsInvalid(true)
            }
        } else if (productToBasket?.inventory?.ats === 0 || !productToBasket?.price) {
            setIsInvalid(true)
        }
    }, [productToBasket])

    // DM-1061 Add to basket button change
    const validateOrderFulfillment = async (e) => {
        e.preventDefault()
        toogleAddToBasketButton('loading')
        setIsInvalid(true)
        let result = false
        let data
        if (productToBasket) {
            let itemQty = 0
            if (getFromLocal('basket_id')) {
                let response = await getBasket(getConnector(), getFromLocal('basket_id'))
                if (response?.product_items?.length > 0) {
                    let item = response?.product_items?.find(
                        (product) => product.product_id === productToBasket.id
                    )
                    if (item) {
                        itemQty = item.quantity
                    } else itemQty = 0
                }
            }

            let record = productToBasket.id
            data = await fetchProductsDetails(record)
            if (data?.inventory?.orderable) {
                if (data.inventory.stock_level > 0) {
                    if (data.inventory.stock_level >= (itemQty || 0) + 1) {
                        result = true
                    } else {
                        result = false
                        setIsInvalid(true)
                    }
                } else if (
                    data.inventory.ats > 0 &&
                    (data.inventory.backorderable || data.inventory.preorderable)
                ) {
                    if (data.inventory.ats >= itemQty + 1) {
                        result = true
                    } else {
                        result = false
                        setIsInvalid(true)
                    }
                }
            } else {
                result = true
            }
        }

        setIsInvalid(false)
        if (result) {
            if (data) {
                dispatch({type: SET_CART_ITEMS, payload: data})

                addToCartEvent(
                    {
                        ...data,
                        id: masterProductInfo?.master?.master_id || data?.id || '',
                        name: masterProductInfo?.name || data?.name || '',
                        primary_category_id:
                            masterProductInfo?.primary_category_id ||
                            masterProductInfo?.c_primary_category_id ||
                            '',
                        currency: data.currency || ''
                    },
                    1,
                    'category',
                    position,
                    isEinsteinReco
                )
                datalayerAddToCart(
                    {
                        ...masterProductInfo,
                        id: masterProductInfo?.product_id,
                        name: masterProductInfo?.product_name,
                        primary_category_id: masterProductInfo?.c_primary_category_id
                    },
                    1,
                    'category'
                )
                einstein.sendAddToCart([productToBasket])
                bloomreach.cartUpdate([productToBasket], 'add', globalState.cartQtys)
            } else {
                const masterProductId =
                    masterProductInfo?.product_id || masterProductInfo?.master?.master_id
                if (globalState?.home?.pageStructure?.pageName === 'Home') {
                    addInteractionErrorEvent(
                        'interaction_error',
                        'Add to Basket Fail',
                        'Product',
                        masterProductId,
                        '404',
                        'homepage'
                    )
                } else {
                    addInteractionErrorEvent(
                        'interaction_error',
                        'Add to Basket Fail',
                        'Product',
                        masterProductId,
                        '404',
                        location.pathname
                    )
                }
                toogleAddToBasketButton('error')
            }
        } else {
            defaultAddToBasketButton(0)
            setOutOfStockMessage(true)
        }
    }

    const fetchProductsDetails = async (productId) => {
        let data = await getMasterProduct(getConnector(), productId)
        return data
    }

    // DM-1061 Add to basket button change
    const defaultAddToBasketButton = (tick) => {
        buttonStatusTimer.current = setTimeout(() => {
            setButtonAction(false)
            setButtonText(
                productToBasket?.inventory?.orderable &&
                    productToBasket?.inventory?.preorderable &&
                    productToBasket?.inventory?.stock_level === 0
                    ? 'Pre-Order'
                    : 'Add to Basket'
            )
            setButtonStyle('button-reg button-bg-default')
            if (location.pathname.includes('shopping-basket')) {
                dispatch({
                    type: REFRESH_BASKET,
                    payload: {shouldBasketRefresh: 'yes'}
                })
            }
        }, tick)
    }

    useEffect(() => {
        return () => {
            clearTimeout(defaultAddToBasketButton)
        }
    }, [])

    useEffect(() => {
        ;(async () => {
            if (globalState.isBasketBusy === 'no') {
                if (buttonText === 'Adding Item') {
                    toogleAddToBasketButton('done')
                }
                dispatch({type: BASKET_BUSY, payload: 'yes'})
            }
        })()
    }, [globalState])

    return (
        <Fragment>
            {outOfStockMessage && (
                <span className="qt-error">
                    We were not able to add any other items to your basket, due to stock
                    limitations.
                </span>
            )}
            <div
                className={`product-add-to-cart ${
                    showSearchSuggestion ? 'cart-btn-flex-search' : 'cart-btn-flex'
                }`}
            >
                <button
                    id={showSearchSuggestion ? 'add-to-cart-btn-search' : ''}
                    style={buttonAction ? {cursor: 'pointer', color: 'white !important'} : {}}
                    className={`${
                        (isInvalid && buttonText === 'Add to Basket') || outOfStockMessage
                            ? 'btn-disabled'
                            : buttonStyle
                    } ${
                        !showSearchSuggestion
                            ? 'btn-wide add-to-cart-btn'
                            : 'btn-wide-search add-to-cart-btn-search'
                    } Button-${productToBasket.id}-btn`}
                    type="submit"
                    name="addToCart"
                    disabled={isInvalid || outOfStockMessage || buttonAction}
                    aria-label="addToCart"
                    onClick={onClick}
                >
                    {buttonText === 'Adding Item' && (
                        <span
                            className={` loader-search ${
                                showSearchSuggestion
                                    ? 'loader-search-small-search'
                                    : 'loader-search-small'
                            }`}
                        ></span>
                    )}
                    {buttonText === 'Item Added' && (
                        <img
                            className={`${
                                showSearchSuggestion
                                    ? 'done-img-align-small-search'
                                    : 'done-img-align-small'
                            }`}
                            alt="Tick"
                            src={getAssetUrl('static/svg/sprite-source/done.svg')}
                            title="Tick"
                        />
                    )}

                    <span
                        className={`${
                            !showSearchSuggestion
                                ? 'js-add-to-cart-text'
                                : 'js-add-to-cart-text-search'
                        }`}
                    >
                        {buttonText}
                    </span>
                </button>
            </div>
        </Fragment>
    )
}

AddToBasket.propTypes = {
    productToBasket: PropTypes.object,
    masterProductInfo: PropTypes.object,
    showSearchSuggestion: PropTypes.bool,
    position: PropTypes.number,
    isEinsteinReco: PropTypes.bool
}

export default AddToBasket
