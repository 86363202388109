import React, {useContext} from 'react'
import {useHistory} from 'react-router-dom'
import PropTypes from 'prop-types'

import {GlobalDispatchContext, SET_PRODUCT} from '../global-state'
import {
    getURL
} from '../../utils/utils'
const ProductTileReadMoreButton = ({productToBasket, cssClass,showSearchSuggestion}) => {
    const history = useHistory()
    const dispatch = useContext(GlobalDispatchContext)

    const checkIfPromoExists = () => {
        const promo = []
        if (Object.keys(productToBasket?.c_promotionCustomAttributes || {}).length > 0) {
            for (
                let i = 0;
                i < Object.keys(productToBasket?.c_promotionCustomAttributes).length;
                i++
            ) {
                const item = Object.keys(productToBasket.c_promotionCustomAttributes)[i]
                if (
                    productToBasket.c_promotionCustomAttributes[item].c_class === 'SHIPPING' &&
                    productToBasket.c_promotionCustomAttributes[item].c_showOnPLP
                ) {
                    promo.push(productToBasket.c_promotionCustomAttributes[item])
                }
            }
        }
        if (promo) return true
    }

    const routeChange = () => {
        dispatch({
            type: SET_PRODUCT,
            payload: {product: productToBasket, masterProduct: productToBasket}
        })
        if (productToBasket?.c_page_url?.length){ 
            history.push(
                `${getURL(productToBasket?.c_page_url?.toLowerCase())}/${productToBasket?.master?.master_id?.toLowerCase()}.html`
            )
        }else if(productToBasket?.c_pageURL?.length){
            history.push(
                `${getURL(productToBasket?.c_pageURL?.toLowerCase())}/${productToBasket?.product_id?.toLowerCase()}.html`
            )
        }
        else{ history.push(`/products/null/${productToBasket?.master?.master_id}.html`)
            }
    }

    return (
        <div
            className={`${cssClass} product-add-cart-wrap-1 ${
                checkIfPromoExists() ? 'has-promo' : ''
            }`}
        >
            <div className={`product-add-to-cart ${showSearchSuggestion ? 'cart-btn-flex-search' : 'cart-btn-flex'}`}>
                <button
                    className={`button-green ${!showSearchSuggestion ? 'btn-wide add-to-cart-btn': 'btn-wide-search add-to-cart-btn-search'}`}
                    name="addToCart"
                    onClick={routeChange}
                    aria-label="read more"
                >
                    <span className="js-add-to-cart-text">Read More</span>
                </button>
            </div>
        </div>
    )
}

ProductTileReadMoreButton.propTypes = {
    productToBasket: PropTypes.object,
    cssClass: PropTypes.string
}

export default ProductTileReadMoreButton
