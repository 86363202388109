
import Icon from 'progressive-web-sdk/dist/components/icon'
import React from 'react'
import {getAssetUrl} from 'progressive-web-sdk/dist/ssr/universal/utils'


export const SearchInputTemplate = ({
    toggleClass,
    isSearchTyped,
    handleInputChange,
    onInputClick,
    onPressEnter,
    handleSearchClick,
    onSearchClose,
    headerId
 }) => {
    return (<>
        {toggleClass ? (<><input
            className="header-search-input search-padding-active"
            type="search"
            id="q"
            name={headerId}
            placeholder="Search for Products, Authors, Brands..."
            // value={value}
            itemProp="query-input"
            autoComplete="off"
            title="Search"
            value={isSearchTyped}
            onChange={handleInputChange}
            onClick={onInputClick}
            //onFocus={() => classToggle(true)}
            onKeyDown={onPressEnter}
        />
            <input type="hidden" name="lang" value="en_GB" />
            <span onClick={onSearchClose} className="clear-text">Clear</span>
            <div className="header-search-button-container search-button-container-active" onClick={() =>{
                handleSearchClick()
                onSearchClose()
            }}>
                <span className="search-text">Search</span>
                <button
                    id='header-search-button'
                    className="pw-button header-search-button"
                    type="button"
                    title="Search"
                >
                    <div className="white-button" id='pw-button__inner'>
                        <div className="pw-icon pw-button__icon header-link">
                    {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 29">
                        <path
                        data-name="Path 1"
                        d="M19.303 24.532c-7.825 2.867-14.382.856-17.724-5.325A13 13 0 0 1 22.045 3.669a12.668 12.668 0 0 1 3.961 9.473 13.354 13.354 0 0 1-4.05 9.385l2.083 2.948a10.227 10.227 0 0 1 .731 1.072 1.591 1.591 0 0 1-2.619 1.8c-.626-.744-1.156-1.568-1.735-2.353-.35-.471-.713-.937-1.113-1.462Zm-6.334-1.772a9.752 9.752 0 1 0-9.715-9.75 9.765 9.765 0 0 0 9.714 9.751Z"
                        fill="#FFFFFF"
                        />
                    </svg> */}
                      <img
                                                        alt="error background"
                                                        
                                                        srcSet={getAssetUrl('static/img/global/Search-icon-white.png')}
                                                        title="error background"
                                                    >

                                                    </img>
                    </div>
                        {/* <Icon
                            className="pw-icon pw-button__icon header-link"
                            name="search"
                        /> */}
                    </div>
                </button>
            </div>
        </>) : (<><input
            className="header-search-input search-padding-inactive"
            type="search"
            id="q"
            name="q"
            placeholder="Search for Products, Authors, Brands..."
            // value={value}
            itemProp="query-input"
            autoComplete="off"
            title="Search"
            value={isSearchTyped}
            onChange={handleInputChange}
            onClick={onInputClick}
            // onFocus={() => classToggle(true)}
            onKeyDown={onPressEnter}
        />
            <input type="hidden" name="lang" value="en_GB" />
            <button
            id='header-search-button'
                className="pw-button header-search-button"
                type="button"
                title="Search"
            >
                <div className=" blue-button" id='pw-button__inner'>
                <div className="pw-icon pw-button__icon header-link">

                {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 29">
                    <path
                    data-name="Path 1"
                    d="M19.303 24.532c-7.825 2.867-14.382.856-17.724-5.325A13 13 0 0 1 22.045 3.669a12.668 12.668 0 0 1 3.961 9.473 13.354 13.354 0 0 1-4.05 9.385l2.083 2.948a10.227 10.227 0 0 1 .731 1.072 1.591 1.591 0 0 1-2.619 1.8c-.626-.744-1.156-1.568-1.735-2.353-.35-.471-.713-.937-1.113-1.462Zm-6.334-1.772a9.752 9.752 0 1 0-9.715-9.75 9.765 9.765 0 0 0 9.714 9.751Z"
                    fill="#496eb3"
                    />
                </svg> */}
                   <img
                                                        alt="error background"
              
                                                        srcSet={getAssetUrl('static/img/global/Search-icon-blue.png')}
                                                        title="error background"
                                                    ></img>
                </div>
                    {/* <Icon
                        className="pw-icon pw-button__icon header-link"
                        name="search"
                    /> */}
                </div>
            </button></>)}
    </>)
}