import React from 'react'
import classNames from 'classnames'
import SkeletonBlock from 'progressive-web-sdk/dist/components/skeleton-block'
import {Desktop, Tablet, Mobile} from '../../../components/media-queries'
import Faceout from '../../../components/faceout'

const Cat1 = ({className}) => {
    return (
        <div style={{marginTop: 40}} className="catlanding clp-page category-container lay-cat1">
            <div id="primary" className="primary-content">
                <div className="pt_storefront wrapper">
                    <div className={`content-width catlanding ${className}`}>
                        <div className="view-categories-container">
                            <SkeletonBlock className="view-categories" />
                            <SkeletonBlock className="view-categories-button" />
                        </div>
                        <SkeletonBlock className="banner-1"></SkeletonBlock>

                        <div className="feature-tile">
                            <div className="feature-tile-heading">
                                <SkeletonBlock width="50px" height="10px" />
                            </div>
                            <div className="feature-tile-wrap">
                                {[0, 1, 2, 3].map((item, i) => {
                                    return (
                                        <div
                                            className={classNames(
                                                'tile-2',
                                                {'tile-full': i === 2},
                                                {'tile-hide': i === 3}
                                            )}
                                            key={item}
                                        >
                                            <SkeletonBlock className="tile-2-image"></SkeletonBlock>
                                            <SkeletonBlock className="tile-2-text"></SkeletonBlock>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="feature-tile">
                            {/* <div className="feature-tile-heading">
                        <SkeletonBlock width="50px" height="10px" />
                    </div> */}
                            <div className="tile-wrap" style={{marginTop: 20}}>
                                {[0, 1, 2, 3, 4, 5, 6, 7].map(() => {
                                    return <SkeletonBlock className="tile-1"></SkeletonBlock>
                                })}
                            </div>
                        </div>
                        <div className="feature-tile">
                            <div className="feature-tile-heading">
                                <SkeletonBlock width="50px" height="10px" />
                            </div>
                        </div>
                        <div className="faceout-wrap">
                            <Faceout
                                slidesPerView={{
                                    forLargeDesktop: 5,
                                    forDesktop: 4,
                                    forTablet: 3,
                                    forMobile: 2
                                }}
                            />
                        </div>
                        <div className="feature-tile">
                            <div className="feature-tile-heading">
                                <SkeletonBlock width="50px" height="10px" />
                            </div>
                            <div className="feature-tile-wrap">
                                {[0, 1, 2, 3, 5, 6].map((item, i) => {
                                    return (
                                        <>
                                            <Desktop>
                                                <div className={classNames('tile-7')}>
                                                    <SkeletonBlock className="tile-2-image"></SkeletonBlock>
                                                    <SkeletonBlock className="tile-2-text"></SkeletonBlock>
                                                </div>
                                            </Desktop>
                                            <Mobile>
                                                <div className={classNames('tile-2')}>
                                                    <SkeletonBlock className="tile-2-image"></SkeletonBlock>
                                                    <SkeletonBlock className="tile-2-text"></SkeletonBlock>
                                                </div>
                                            </Mobile>

                                            <Tablet>
                                                <div className={classNames('tile-5')}>
                                                    <SkeletonBlock className="tile-2-image"></SkeletonBlock>
                                                    <SkeletonBlock className="tile-2-text"></SkeletonBlock>
                                                </div>
                                            </Tablet>
                                        </>
                                    )
                                })}
                            </div>
                            <div className="feature-tile">
                                <div className="feature-tile-heading">
                                    <SkeletonBlock width="50px" height="10px" />
                                </div>
                                <SkeletonBlock height={300} className="tile-2-text"></SkeletonBlock>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cat1
