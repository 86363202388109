import React, {Fragment, useState, useEffect, useContext} from 'react'
import {Mobile, Desktop, Tablet} from '../../components/media-queries'
import ContentAsset from '../content-asset'
import ProductTile from '../product-tile'
import Slider from '../slider'
import Link from 'progressive-web-sdk/dist/components/link'

const Faceout = ({
    dataIds,
    slidesPerView,
    faceoutTitle,
    faceoutLink,
    faceoutType,
    showPagination,
    loop = true,
    index,
    category
}) => {
    const [ids, setIds] = useState([])
    const [products, setProducts] = useState([])
    const [masterProducts, setMasterProducts] = useState([])

    const getProductDetails = () => {
        setProducts(dataIds)
        setMasterProducts(dataIds)
        setIds(dataIds)
    }
    useEffect(() => {
        getProductDetails()
    }, [])

    const productComponent = (props) => {
        return (
            <ProductTile
                {...props}
                showOnLazyLoad={false}
                isFaceout={true}
                categoryData={category}
            />
        )
    }

    let skeletonBlock = '*'
    skeletonBlock = skeletonBlock.repeat(slidesPerView)
    skeletonBlock = Array.from(skeletonBlock)

    return (
        <Fragment>
            <div className="carousel-contents">
                {typeof faceoutTitle !== 'undefined' && (
                    <p className="h2 carousel-title">
                        {faceoutTitle}
                        {faceoutLink !== undefined && (
                            <Link href={faceoutLink} className="link-more">
                                View More
                            </Link>
                        )}
                    </p>
                )}
                <Desktop>
                    <Fragment>
                        <Slider
                            data={faceoutType === 'ContentAsset' ? dataIds : ids}
                            // products={products}
                            products={products}
                            masterProducts={masterProducts}
                            slidesPerView={slidesPerView.forDesktop}
                            SlideComp={
                                faceoutType === 'ContentAsset' ? ContentAsset : productComponent
                            }
                            showPagination={showPagination}
                            faceoutType={faceoutType}
                            faceoutTitle={faceoutTitle}
                            loop={loop}
                            index={index}
                        />
                    </Fragment>
                </Desktop>
                <Tablet>
                    <Fragment>
                        <Slider
                            data={faceoutType === 'ContentAsset' ? dataIds : ids}
                            products={products}
                            masterProducts={masterProducts}
                            slidesPerView={slidesPerView.forTablet}
                            SlideComp={
                                faceoutType === 'ContentAsset' ? ContentAsset : productComponent
                            }
                            showPagination={showPagination}
                            faceoutType={faceoutType}
                            faceoutTitle={faceoutTitle}
                            loop={loop}
                        />
                    </Fragment>
                </Tablet>
                <Mobile>
                    <Fragment>
                        <Slider
                            data={faceoutType === 'ContentAsset' ? dataIds : ids}
                            products={products}
                            masterProducts={masterProducts}
                            slidesPerView={slidesPerView.forMobile}
                            SlideComp={
                                faceoutType === 'ContentAsset' ? ContentAsset : productComponent
                            }
                            showPagination={showPagination}
                            faceoutType={faceoutType}
                            faceoutTitle={faceoutTitle}
                            loop={loop}
                        />
                    </Fragment>
                </Mobile>
            </div>
        </Fragment>
    )
}

export default Faceout
