/*Product Tile Bottom Component*/
//Consists of sub components - ProductPricing and AddToBasket, in order.

import React, {Fragment} from 'react'
import AddToBasket from '../addToBasket/index'
import ProductTileReadMoreButton from '../product-tile-read-more-button'

const ProductTileBottom = ({productBottom, masterProductInfo, cssClass, showSearchSuggestion, position, isEinsteinReco}) => {
    return productBottom ? (
        <Fragment>
            {productBottom?.id &&
            productBottom.c_renderingType === 'magazine-subscription' ? (
                <ProductTileReadMoreButton
                    productToBasket={masterProductInfo}
                    cssClass={cssClass}
                    showSearchSuggestion={showSearchSuggestion}
                />
            ) : (
                <AddToBasket
                    productToBasket={productBottom}
                    masterProductInfo={masterProductInfo}
                    showSearchSuggestion={showSearchSuggestion}
                    position={position}
                    isEinsteinReco={isEinsteinReco}
                />
            )}
        </Fragment>
    ) : null
}

export default ProductTileBottom
