import React, {useState, useEffect} from 'react'
import Link from 'progressive-web-sdk/dist/components/link'
import PromotionalMessage from './promotionalMessage'
import {handlePromoClick} from '../../utils/gtmUtils'

const ProductPromo = ({promo, isEinsteinReco}) => {
    const [promotion, setPromotion] = useState({})

    useEffect(() => {
        let promotionArray = Object.keys(promo?.c_promotionCustomAttributes || {})
            ?.map((key) => {
                return {
                    key,
                    ...promo.c_promotionCustomAttributes[key]
                }
            })
            ?.filter((value) => value.c_class === 'PRODUCT' && value.c_showOnPLP === true)

        let promotionObj = promotionArray.length
            ? promotionArray.reduce((prevPromotion, currPromotion) =>
                  new Date(prevPromotion?.lastModified).getTime() >
                  new Date(currPromotion?.lastModified).getTime()
                      ? prevPromotion
                      : currPromotion
              )
            : {}
        Object.keys(promotionObj)?.length && setPromotion({...promotionObj})
    }, [promo])

    return Object.keys(promotion)?.length ? (
        <div className="product-promo tile-attribute" title={promotion?.c_details} onClick={(e) => {
                e.stopPropagation()
                handlePromoClick(promotion.key, promotion.c_pageUrl, isEinsteinReco)
            }}>
            {promotion?.c_pageUrl ? (
                <Link
                    href={
                        /\/$/.test(promotion?.c_pageUrl)
                            ? `/${promotion?.c_pageUrl?.toLowerCase()}`
                            : `/${promotion?.c_pageUrl?.toLowerCase()}/`
                    }
                >
                    <PromotionalMessage promoMessage={promotion} />
                </Link>
            ) : (
                <PromotionalMessage promoMessage={promotion} />
            )}
        </div>
    ) : null
}

export default ProductPromo
