import React, {useState, useEffect, useContext} from 'react'
import PropTypes from 'prop-types'
import {getAssetUrl} from 'progressive-web-sdk/dist/ssr/universal/utils'
import {Remove} from './icons'
import Modal from 'react-modal'
import AgeVerificationModal from './ageVerificationModal'
import {GlobalDispatchContext, SET_PRODUCT} from '../../components/global-state'
import Link from 'progressive-web-sdk/dist/components/link'
let addQuantityTimer = null
import {getImageSrcFromSrcSet} from '../../utils/utils'
import {useBloomreach} from '../../hooks/useBloomreach'

const CartProduct = (props) => {
    const {
        productFormat,
        renderingType,
        srcSet,
        removeProduct,
        updateQuantity,
        certificate,
        availabilityMessage,
        setShowLoader,
        addQuantityToBasket,
        setIsCartError,
        product,
        basketQty,
        sendRenewalStatus,
        renewalStatus,
        basketLimitReached,
        quantity,
        isAtbModal,
        cartQtys
    } = props

    const {id, name, inventory, variants} = product
    const standardPrice = product?.prices?.['whsmith-gbp-listprices'] || product?.price
    const salesPrice = product?.prices?.['whsmith-gbp-saleprices'] || standardPrice
    const src = getImageSrcFromSrcSet(product?.['c_images']?.[0]?.srcset)
    const dispatch = useContext(GlobalDispatchContext)
    const [orderQuantity, setOrderQuantity] = useState(0)
    const [isRenewal, setIsRenewal] = useState(false)
    const bloomreach = useBloomreach()

    const [openAgeVerifyPopup, setOpenAgeVerifyPopup] = useState(false)
    Modal.setAppElement('body')

    const handleOpenAgeVerifyPopup = () => setOpenAgeVerifyPopup(true)
    const handleCloseAgeVerifyPopup = () => setOpenAgeVerifyPopup(false)

    useEffect(() => {
        quantity > 0 && setOrderQuantity(quantity)
    }, [quantity])

    const remove = () => {
        removeProduct(id)
    }

    useEffect(() => {
        if (!basketLimitReached) {
            setWarningMessage('')
        }
    }, [basketLimitReached])

    useEffect(() => {
        setIsRenewal(renewalStatus)
    }, [renewalStatus])

    const [warningMessage, setWarningMessage] = useState('')

    const findQuantityForInputValue = (inputValue) => {
        let checkInput = false
        let stockLimit = parseInt(inventory.stock_level)
        let atsStockLimit = parseInt(inventory.ats)
        let newQtyValue = inputValue
        let baskQty = parseInt(basketQty) - parseInt(quantity)

        if (
            inventory &&
            inventory.stock_level === 0 &&
            (inventory.backorderable || inventory.preorderable)
        ) {
            checkInput = parseInt(inputValue) >= atsStockLimit ? true : false
            if (checkInput) {
                newQtyValue = parseInt(atsStockLimit)
                if (parseInt(newQtyValue) + parseInt(baskQty) > 999) {
                    newQtyValue = 999 - parseInt(baskQty)
                    setWarningMessage(
                        `Sorry, due to cart size limitations, we were able to add only ${newQtyValue} Item(s) to your basket.`
                    )
                } else {
                    newQtyValue = parseInt(inputValue)
                    if (parseInt(newQtyValue) + parseInt(baskQty) > 999) {
                        newQtyValue = 999 - parseInt(baskQty)
                        setWarningMessage(
                            `Sorry, due to cart size limitations, we were able to add only ${newQtyValue} Item(s) to your basket.`
                        )
                    } else {
                        setWarningMessage('')
                        setOnOrderValue(`Only ${atsStockLimit} Item(s) available`)
                        setIsCartError(true)
                    }
                }
            } else {
                if (parseInt(newQtyValue) + parseInt(baskQty) > 999) {
                    newQtyValue = 999 - parseInt(baskQty)
                    setWarningMessage(
                        `Sorry, due to cart size limitations, we were able to add only ${newQtyValue} Item(s) to your basket.`
                    )
                } else {
                    newQtyValue = parseInt(inputValue)
                    setWarningMessage('')
                }
            }
        } else if (inventory && inventory.stock_level > 0) {
            checkInput = parseInt(inputValue) >= stockLimit ? true : false
            if (checkInput) {
                newQtyValue = parseInt(inputValue)
                if (parseInt(newQtyValue) + parseInt(baskQty) > 999) {
                    newQtyValue = 999 - parseInt(baskQty)
                    setWarningMessage(
                        `Sorry, due to cart size limitations, we were able to add only ${newQtyValue} Item(s) to your basket.`
                    )
                } else {
                    newQtyValue = parseInt(inputValue)
                    setWarningMessage('')
                }
            } else {
                if (parseInt(newQtyValue) + parseInt(baskQty) > 999) {
                    newQtyValue = 999 - parseInt(baskQty)
                    setWarningMessage(
                        `Sorry, due to cart size limitations, we were able to add only ${newQtyValue} Item(s) to your basket.`
                    )
                } else {
                    newQtyValue = parseInt(inputValue)
                    setWarningMessage('')
                }
            }
        }
        return newQtyValue
    }

    const handleQuantityChange = (e) => {
        let inputValue = e.target.value
        if (parseInt(inputValue) === 0) remove()
        else {
            if (!inputValue) inputValue = 1
            else if (inputValue.includes('+')) inputValue = inputValue.replace('+', '')
            else if (inputValue.includes('-')) inputValue = 1
            if (inventory.ats >= inputValue) setIsCartError(false)
            else setIsCartError(true)
            let newQuantity = findQuantityForInputValue(inputValue)

            if (newQuantity && newQuantity !== 'undefined') {
                setOrderQuantity(parseInt(newQuantity))
                updateQuantity(id, parseInt(newQuantity))
                clearTimeout(addQuantityTimer)
                addQuantityTimer = setTimeout(() => {
                    setShowLoader(true)
                    addQuantityToBasket({
                        product_id: id,
                        quantity: parseInt(newQuantity)
                    })
                    const action = newQuantity < orderQuantity ? 'remove' : 'add'
                    const updatedCartQtys = cartQtys.map((item) => {
                        if (item.id === id) {
                            return {
                                ...item,
                                qty: newQuantity
                            }
                        }
                        return item
                    })
                    bloomreach.cartUpdate([product], action, updatedCartQtys)
                }, 1000)
            }
        }
    }

    const handleDecreaseQuantity = () => {
        if (orderQuantity > 1) {
            setOrderQuantity(orderQuantity - 1)
            updateQuantity(id, orderQuantity - 1)
            setWarningMessage('')
            if (inventory.ats >= orderQuantity - 1) setIsCartError(false)
            clearTimeout(addQuantityTimer)
            addQuantityTimer = setTimeout(() => {
                setShowLoader(true)
                addQuantityToBasket({
                    product_id: id,
                    quantity: orderQuantity - 1
                })
                // find the product in cartQtys and remove 1 from the qty property
                const updatedCartQtys = cartQtys.map((cartQty) => {
                    if (cartQty.id === id) {
                        return {
                            ...cartQty,
                            qty: orderQuantity
                        }
                    }
                    return cartQty
                })
                const action = basketQty === 1 ? 'empty' : 'remove'
                bloomreach.cartUpdate([product], action, updatedCartQtys)
            }, 1000)
        } else {
            remove()
        }
    }
    const handleIncreaseQuantity = () => {
        if (!stockErrorCheck && basketQty < 999) {
            if (orderQuantity > 0) {
                setOrderQuantity(orderQuantity + 1)
                updateQuantity(id, orderQuantity + 1)
                clearTimeout(addQuantityTimer)
                if (inventory.ats < orderQuantity - 1) setIsCartError(true)
                addQuantityTimer = setTimeout(() => {
                    setShowLoader(true)
                    addQuantityToBasket({
                        product_id: id,
                        quantity: orderQuantity + 1
                    })
                    // find the product in cartQtys and add 1 to the qty property
                    const updatedCartQtys = cartQtys.map((cartQty) => {
                        if (cartQty.id === id) {
                            return {
                                ...cartQty,
                                qty: cartQty.qty + 1
                            }
                        }
                        return cartQty
                    })
                    bloomreach.cartUpdate([product], 'add', updatedCartQtys)
                }, 1000)
            }
        }
    }

    const [certificateUrl, setCertificateUrl] = useState('')
    const [showRenewalMessage, setShowRenewalMessage] = useState(false)

    useEffect(() => {
        if (certificate) {
            switch (certificate) {
                case '12':
                    setCertificateUrl('static/img/certificates/certificate_12.svg')
                    setShowRenewalMessage(true)
                    break
                case '12a':
                    setCertificateUrl('static/img/certificates/certificate_12A.svg')
                    setShowRenewalMessage(false)
                    break
                case '18':
                    setCertificateUrl('static/img/certificates/certificate_18.svg')
                    setShowRenewalMessage(true)
                    break
                case '15':
                    setCertificateUrl('static/img/certificates/certificate_15.svg')
                    setShowRenewalMessage(true)
                    break
                case 'PG':
                    setCertificateUrl('static/img/certificates/certificate_pg.svg')
                    break
                case 'U':
                    setCertificateUrl('static/img/certificates/certificate_u.svg')
                    break
                default:
                    ''
                    break
            }
        }
    }, [])

    const [pricePerIssueForMagazine, setPricePerIssueForMagazine] = useState('0.00')
    const [pricePerIssueName, setPricePerIssueName] = useState('')
    const [subsciptionLengthForMagazine, setSubsciptionLengthForMagazine] = useState('')
    const [subsciptionLengthName, setSubsciptionLengthName] = useState('')

    useEffect(() => {
        if (renderingType === 'magazine-subscription') {
            let findId = variants?.find((e) => e.product_id === id)
            if (
                findId &&
                findId.variation_values &&
                findId.variation_values.pricePerIssue &&
                findId.variation_values.subscriptionLength
            ) {
                let pricePerIssueValue = findId.variation_values.pricePerIssue
                let subscriptionLengthValue = findId.variation_values.subscriptionLength
                let varAttrMatchPrice = product.variation_attributes.find(
                    (e) => e.id === 'pricePerIssue'
                )
                if (varAttrMatchPrice && varAttrMatchPrice.name && varAttrMatchPrice.values) {
                    let values = varAttrMatchPrice.values
                    setPricePerIssueName(varAttrMatchPrice.name)
                    values.map((item) => {
                        if (item.value === pricePerIssueValue)
                            setPricePerIssueForMagazine(item.name)
                    })
                }
                let varAttrSubscription = product.variation_attributes?.find(
                    (e) => e.id === 'subscriptionLength'
                )
                if (varAttrSubscription && varAttrSubscription.name && varAttrSubscription.values) {
                    let subs = varAttrSubscription.values
                    setSubsciptionLengthName(varAttrSubscription.name)
                    subs.map((item) => {
                        if (item.value === subscriptionLengthValue)
                            setSubsciptionLengthForMagazine(item.name)
                    })
                }
            }
        }
    }, [])

    const [onOrderValue, setOnOrderValue] = useState('')
    const [stockErrorCheck, setStockErrorCheck] = useState(false)
    useEffect(() => {
        if (inventory && inventory.stock_level === 0 && inventory.preorderable) {
            if (quantity > inventory.ats) {
                setOnOrderValue(`Only ${inventory.ats} Item(s) available`)
                setIsCartError(true)
                setStockErrorCheck(true)
            } else {
                setOnOrderValue('Pre-Order')
                setIsCartError(false)
                setStockErrorCheck(false)
            }
        } else if (inventory && inventory.backorderable && inventory.stock_level === 0) {
            if (quantity > inventory.ats) {
                setOnOrderValue(`Only ${inventory.ats} Item(s) available`)
                setIsCartError(true)
                setStockErrorCheck(true)
            } else {
                setOnOrderValue('Back Order')
                setIsCartError(false)
                setStockErrorCheck(false)
            }
        } else if (
            inventory &&
            inventory.orderable &&
            inventory.stock_level > 0 &&
            quantity <= inventory.stock_level
        ) {
            setOnOrderValue('In Stock')
            setIsCartError(false)
            setStockErrorCheck(false)
        } else if (
            inventory &&
            inventory.orderable &&
            inventory.stock_level > 0 &&
            quantity > inventory.stock_level
        ) {
            if (inventory.backorderable && quantity - inventory.stock_level < inventory.ats) {
                setOnOrderValue(
                    `${inventory.stock_level} Item(s) in Stock. The remaining items are available on back order.`
                )
                setIsCartError(false)
                setStockErrorCheck(false)
            } else {
                setOnOrderValue(`Only ${inventory.stock_level} Item(s) available`)
                setIsCartError(true)
                setStockErrorCheck(true)
            }
        } else if (
            inventory &&
            !inventory.orderable &&
            inventory.stock_level === 0 &&
            inventory.ats === 0
        ) {
            setOnOrderValue('Out of Stock')
            setIsCartError(true)
            setStockErrorCheck(true)
        } else {
            setOnOrderValue('')
            setIsCartError(false)
            setStockErrorCheck(false)
        }
    }, [quantity])

    const validateQuantityChange = (e) => {
        let inputValue = e.target.value
        if (inputValue === 0) {
            inputValue = 1
        }
        let regEx = /^[0-9+-]$/
        if (!regEx.test(inputValue)) {
            inputValue = inputValue.replace(/[^0-9+-]/, '')
        }
        setOrderQuantity(inputValue)
    }

    const renderAgeVerifyPopup = () => (
        <Modal
            isOpen={openAgeVerifyPopup}
            onAfterOpen={() => window.scrollTo(0, 0)}
            shouldReturnFocusAfterClose={false}
            onRequestClose={handleCloseAgeVerifyPopup}
            shouldCloseOnOverlayClick={true}
            contentLabel="Benefits of creating an account"
            className="ageVerifyPopup"
        >
            <AgeVerificationModal close={handleCloseAgeVerifyPopup} />
        </Modal>
    )

    const handleRenewalClick = () => {
        sendRenewalStatus(!isRenewal, id)
        setIsRenewal(!isRenewal)
    }

    const ageRestrictionBlock = (
        <div className="age-restricted-msg">
            <span className="age-restricted-info">
                This product requires age verification for purchase.
            </span>
            <span
                onClick={handleOpenAgeVerifyPopup}
                title="How does it work?"
                aria-hidden="true"
                className="age-restricted-link m-cmp_loaded"
            >
                How does it work?
            </span>
        </div>
    )

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            e.target.blur()
        }
    }

    return (
        <div
            className={`product-item my-basket-container js-product-impression ${
                (onOrderValue && onOrderValue === 'Out of Stock') ||
                onOrderValue.includes('Item(s) available') ||
                onOrderValue.includes('The remaining items are available on back order')
                    ? 'product-item-notavailable'
                    : ''
            }`}
        >
            <div className="product-item-wrap">
                <div className="product-item-image">
                    <img
                        className="image m-cmp_loaded lazyautosizes lazyloaded"
                        srcSet={srcSet}
                        src={src}
                        alt={name}
                    />
                    <meta itemProp="image" content={src} />

                    <div
                        className={`product-item-actions ${!isAtbModal && 'is-tablet is-desktop'}`}
                    >
                        <div
                            className="js-delete-cart-item product-remove"
                            value="Remove"
                            name="cart_shipments_deleteProduct"
                            onClick={remove}
                        >
                            <Remove />
                        </div>
                    </div>
                </div>

                <div className="product-item-content">
                    <div className="product-item-details">
                        <div className="product-list-item">
                            <div className="name">
                                <Link
                                    className="product-name"
                                    href={`/${product.c_page_url}/${product.master?.master_id ??
                                        product.ean}.html`}
                                    onClick={() => {
                                        dispatch({
                                            type: SET_PRODUCT,
                                            payload: {
                                                product,
                                                masterProduct: product
                                            }
                                        })
                                    }}
                                    title={`Go to Product: ${name}`}
                                >
                                    {name}
                                </Link>
                                <span className="bbfc-rating-badge">{productFormat}</span>
                            </div>
                            <div className="sku">
                                <span className="label">Item No.: </span>
                                <span className="value">{id}</span>
                            </div>
                        </div>
                        {certificate && (
                            <div className="product-list-item">
                                <span className="bbfc-rating-badge">
                                    {`Certificate: `}
                                    {certificateUrl ? (
                                        <img
                                            alt="bbfc-rating-badge"
                                            className="bbfc-rating-badge-cert"
                                            src={getAssetUrl(certificateUrl)}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </span>
                                {showRenewalMessage && ageRestrictionBlock}
                            </div>
                        )}
                        {product.c_minimumAgeToPurchase && !certificate && (
                            <div className="product-list-item">
                                <dl className="age-restricted-badge">
                                    <dt>Age Restriction:</dt>
                                    <dd>
                                        <span>{product.c_minimumAgeToPurchase}+</span>
                                    </dd>
                                </dl>
                                {ageRestrictionBlock}
                            </div>
                        )}
                        {pricePerIssueName && pricePerIssueForMagazine && (
                            <div className="product-list-item">
                                <div className="attribute">
                                    <span className="price-per-issue-name">
                                        {`${pricePerIssueName}: `}
                                        <span className="price-per-issue-value">
                                            {`${pricePerIssueForMagazine}`}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        )}
                        {subsciptionLengthName && subsciptionLengthForMagazine && (
                            <div className="product-list-item">
                                <div className="attribute">
                                    <span className="price-per-issue-name">
                                        {`${subsciptionLengthName}: `}
                                        <span className="price-per-issue-value">
                                            {`${subsciptionLengthForMagazine}`}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="product-item-price">
                        <div className={`${!isAtbModal && 'is-tablet is-desktop'} cart-item-title`}>
                            Item Price
                        </div>

                        <div className="price-promotion">
                            {standardPrice && standardPrice !== salesPrice && (
                                <span className="price-standard">£{standardPrice.toFixed(2)}</span>
                            )}
                            <span
                                className={`${standardPrice ? 'price-sales' : 'price-sales-only'}`}
                            >
                                £{salesPrice.toFixed(2)}
                            </span>
                        </div>
                    </div>

                    <div className="product-item-data">
                        {!isAtbModal && (
                            <div className="product-item-quantity">
                                <div className="is-tablet is-desktop cart-item-title">Quantity</div>

                                <div className="quantity-stepper m-cmp_loaded">
                                    <span
                                        className="js-minus-quantity quantity-decrease"
                                        onClick={handleDecreaseQuantity}
                                        aria-hidden="true"
                                    >
                                        <span className="quantity-icon"></span>
                                    </span>

                                    <input
                                        type="text"
                                        name="quantity"
                                        maxLength={3}
                                        pattern="^[0-9\+-]{0,3}$"
                                        min="1"
                                        value={orderQuantity}
                                        onChange={validateQuantityChange}
                                        onBlur={handleQuantityChange}
                                        aria-label="quantity"
                                        onKeyDown={handleKeyDown}
                                        className="js-qty-input input-text product-quantity-input"
                                        id="quantity"
                                    />

                                    <span
                                        className={`js-plus-quantity quantity-increase`}
                                        onClick={handleIncreaseQuantity}
                                        aria-hidden="true"
                                    >
                                        <span className="quantity-icon"></span>
                                    </span>
                                </div>
                            </div>
                        )}
                        {!isAtbModal && (
                            <div className="product-item-total">
                                <div className="is-tablet is-desktop cart-item-title">Subtotal</div>

                                <span className="price-total">
                                    £{(orderQuantity * salesPrice).toFixed(2)}
                                </span>
                            </div>
                        )}

                        <div className="product-item-availability ">
                            <div className="product-availability-list">
                                {onOrderValue ? (
                                    onOrderValue !== 'Out of Stock' ? (
                                        <span
                                            className={
                                                onOrderValue !== 'In Stock' &&
                                                onOrderValue !== 'Pre-Order' &&
                                                onOrderValue !== 'Back Order'
                                                    ? 'not-available-stock'
                                                    : 'is-in-stock'
                                            }
                                        >
                                            {onOrderValue}
                                        </span>
                                    ) : (
                                        <span className="not-available-item">
                                            This item is currently not available
                                        </span>
                                    )
                                ) : null}
                                {warningMessage && (
                                    <span className="not-available-stock">{warningMessage}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    {!isAtbModal && (
                        <div className="product-item-actions is-mobile">
                            <button
                                aria-label="remove"
                                className="product-remove js-delete-cart-item"
                                type="button"
                                value="Remove"
                                name="delete-product"
                                onClick={remove}
                            >
                                <Remove />
                            </button>
                        </div>
                    )}
                    {renderingType === 'magazine-subscription' && !isAtbModal && (
                        <div className="product-item-renewal">
                            <div className="form-row m-cmp_loaded">
                                <div className="js-field-wrapper">
                                    <div className="field-container label-inline">
                                        <input
                                            className="js-input-field renewal-checkbox"
                                            type="checkbox"
                                            id={`renewal-${id}`}
                                            onClick={handleRenewalClick}
                                            checked={isRenewal ? 'checked' : ''}
                                        />
                                        <label className="label" htmlFor={`renewal-${id}`}>
                                            <span className="label-text">
                                                Tick if this is a renewal
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="renewal-info">
                                If you already get this subscription a renewal starts after the
                                current one has ended. Minimum notice 4 Weeks. For more information{' '}
                                {` `}
                                <Link
                                    className="renewal-link"
                                    href="/help/shopping-with-whsmith/order-delivery-and-collection/hel00009#magazine"
                                >
                                    click here
                                </Link>
                            </div>
                        </div>
                    )}

                    {availabilityMessage &&
                        onOrderValue !== 'In Stock' &&
                        onOrderValue !== `Only ${inventory.stock_level} Item(s) available` && (
                            <div className="product-item-pobo">{availabilityMessage}</div>
                        )}
                </div>
            </div>
            {renderAgeVerifyPopup()}
        </div>
    )
}

CartProduct.propTypes = {
    srcSet: PropTypes.string,
    availabilityMessage: PropTypes.string,
    promoMessage: PropTypes.string,
    removeProduct: PropTypes.func,
    updateQuantity: PropTypes.func,
    itemNotAvailable: PropTypes.any,
    certificate: PropTypes.any,
    variationAttributes: PropTypes.any,
    basketDetails: PropTypes.any,
    setShowLoader: PropTypes.any,
    addItemsToBasket: PropTypes.any,
    quantity: PropTypes.number,
    isAtbModal: PropTypes.bool,
    cartQtys: PropTypes.array
}

export default CartProduct
