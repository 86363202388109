import React from 'react'

const TileAttributes = ({tileAttributes, visibilityHidden, showSearchSuggestion}) => {
    return (
        <div
            className={`${showSearchSuggestion ? 'tile-attribute-search' : 'tile-attribute'}`}
            style={visibilityHidden ? {visibility: 'hidden'} : {}}
        >
            <p>{tileAttributes}</p>
        </div>
    )
}

export default TileAttributes
