/*Product Tile DETAILS Component*/
//Contains the details of the product such as Name, Attributes and Availability Status//
//Child Components: TileAttributes,
//Tile Attrinutes can be more than one and fieldnames are different for differnt types of products.
//Below are the possible values for Stationery, Toys & Games, Non-Book, Modelzone
//c_productFormat (First)
//brand (Second)
//c_collection (Third)
//Below are the possible values for Books, Magazines, Films, Music etc
//c_contributor
//c_productFormat
//Below are the possible values for Magazine subscription, Magazine Giftpack
//c_productFormat

import React, {Fragment} from 'react'
import TileAttributes from './tileAttributes'
import ProductName from './productName' //ClassName attribute difference here.
import ProductAvailability from './productAvailability'
import ProductTilePromo from './productTilePromo'
import ProductPricing from './productPricing'

const ProductTileDetails = ({
    productDetails,
    masterProductInfo,
    cssClass,
    productId,
    category,
    categoryData,
    plpCssClass,
    chartNumber,
    position,
    queryString,
    faceoutTitle,
    showSearchSuggestion,
    onSearchClose,
    triggerSuggestInteraction,
    isSearchListing,
    isEinsteinReco
}) => {
    return productDetails ? (
        <Fragment>
            <div className="product-tile-details">
                <ProductName
                    name={productDetails.name}
                    product={productDetails}
                    cssClass={cssClass}
                    productId={productId}
                    category={category}
                    categoryData={categoryData || category}
                    masterProduct={masterProductInfo}
                    chartNumber={chartNumber}
                    position={position}
                    queryString={queryString}
                    faceoutTitle={faceoutTitle}
                    showSearchSuggestion={showSearchSuggestion}
                    onSearchClose={onSearchClose}
                    triggerSuggestInteraction={triggerSuggestInteraction}
                    isSearchListing={isSearchListing}
                    isEinsteinReco={isEinsteinReco}
                />
                {productDetails.c_contributor ? (
                    <TileAttributes tileAttributes={productDetails.c_contributor} showSearchSuggestion={showSearchSuggestion}/>
                ) : null}
                <ProductPricing
                    productPrice={productDetails}
                    masterProductInfo={masterProductInfo}
                    plpCssClass={plpCssClass}
                    showSearchSuggestion={showSearchSuggestion}
                />
                {productDetails.c_productFormat && !showSearchSuggestion ? (
                    <TileAttributes tileAttributes={productDetails.c_productFormat} showSearchSuggestion={showSearchSuggestion} />
                ) : null}
               {!showSearchSuggestion && <ProductTilePromo productPromo={productDetails} isEinsteinReco={isEinsteinReco} />}
                {chartNumber && <ProductAvailability pdtAvailability={productDetails} showSearchSuggestion={showSearchSuggestion} />}
            </div>
        </Fragment>
    ) : null
}

export default ProductTileDetails
