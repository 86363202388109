/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, useContext, useMemo } from 'react'
import { getAssetUrl } from 'progressive-web-sdk/dist/ssr/universal/utils'
import Header from '../../components/header'
import PageTemplateWithData from '../../components/page-template-with-data/index'
import PageTemplateSkeleton from '../../pages/home/skeleton'
import { GlobalStateContext } from '../../components/global-state'
import { getNavigationRootDesktop } from '../_pwa-app/helpers'
import { Helmet } from 'react-helmet'
import ResponsiveContainer from '../../components/responsive-container'
import { getConnector } from '../../connector'
import { getNavMenuData } from '../menu/helper'
import { getContentAsset } from '../content-asset/helper'
import { getPageStructure } from '../../pages/home/helper'

const Error404 = (props) => {
    const [categories, setCategories] = useState([])
    const [headerAssets, setHeaderAssets] = useState([])
    const [menuAsset, setMenuAsset] = useState([])
    const [cachedPageStructure, setCachedPageStructure] = useState([])
    const [cachedAllSlotsData, setCachedAllSlotsData] = useState([])

    const [isDefaultError, setIsDefaultError] = useState(false)

    let cachedMeta = null;
    let pageDataPushed = null;
    const [isLoadedFully, setIsLoadedFully] = useState(false)
    const navigationRootDesktop = getNavigationRootDesktop(props)
    const connector = getConnector()
    const [errorTitle, setErrorTitle] = useState('')
    const [errorDescription, setErrorDescription] = useState('')

    const [layout,setLayout] = useState('')


    useEffect(() => {

        ; (async () => {

            const [categories, headerPromo, menuAsset] = await Promise.all([
                getNavMenuData(connector, 'root'),
                getContentAsset(connector, 'header-promo-banner-campaign'),
                getContentAsset(connector, 'mega-nav-main-asset')
            ])
            setMenuAsset(JSON.parse(menuAsset.c_body))

            setCategories(categories)
            const headerAssets = {
                categories,
                headerPromo
            }
            setHeaderAssets(headerAssets)

            //If content not found load the default 404 error page

            let parentContent = await getPageStructure(connector, 'mp-not-found-page')

            if (parentContent?.pageStructureTemp) {
                setIsDefaultError(false)
                let { pageStructureTemp, allSlotsDataTemp, metaTemp, loaded = false } = parentContent
                setCachedPageStructure(pageStructureTemp)
                setCachedAllSlotsData(allSlotsDataTemp)
                setIsLoadedFully(loaded)
                setLayout(pageStructureTemp.layout)
            } else {
                setIsDefaultError(true)
                let defaultContent = await getContentAsset(connector, 'page-not-found-default-error')
                getDefaultErrorAsset(defaultContent)

            }


           
        })()

    }, [])

    const getDefaultErrorAsset = async (defaultContent) => {
        defaultContent = JSON.parse(defaultContent?.c_body)
        setErrorTitle(defaultContent.errorTitle)
        setErrorDescription(defaultContent.errorMessage)
    }



    return (
        <ResponsiveContainer>

            <Helmet
                htmlAttributes={{
                    lang: 'en-US',
                    'data-reactroot': ''
                }}
            >
                <title>Page Not Found</title>
                <meta name="description" content="WHSmith Page Not Found" />
                <meta
                    property="og:image"
                    content={getAssetUrl('static/img/errors/error-404-bg-mobile.jpg')}
                />
                <meta property="og:site_name" content="WHSmith" />
                <meta property="og:title" content="Page Not Found" />
                <meta property="og:description" content="WHSmith Page Not Found" />
                <meta name="keywords" itemProp="keywords" content=" WHSmith" />
                <link
                    rel="image_src"
                    href={getAssetUrl('static/img/errors/error-404-bg-mobile.jpg')}
                />
                <meta name="robots" content="noindex, nofollow"></meta>
            </Helmet>
            <div id="app" className="c-pwa-app content-width data-cs-capture">
                <Header style={{ height: "100px" }}
                    navigationRootDesktop={navigationRootDesktop}
                    categories={categories}
                    headerAssets={headerAssets}
                    contentAll={menuAsset}
                    isError={true}
                    fixedHeader={!isDefaultError}
            
                />
                {!isDefaultError &&
                    <main id="app-main" className="c-pwa-app__main" role="main">
                        <div style={{
                            width: '100%',
                            flex: '1 1 auto'
                        }}  >
                            <div className="pt_storefront wrapper">
                                <div className="catlanding lay-homepage">

                                    {isLoadedFully ? (
                                        <>
                                            <PageTemplateWithData
                                                pageStructure={cachedPageStructure}
                                                allSlotsData={cachedAllSlotsData}
                                                title={"Page Not Found"}
                                                meta={{title:"Page Not Found"}}
                                                pageDataPushed={pageDataPushed}
                                                setIsLoadedFully={setIsLoadedFully}
                                                isError={true}
                                            />
                                        </>
                                    ) : (
                                        <PageTemplateSkeleton
                                            layout={
                                                /new-homepage-\S*/.exec(layout || '')?.length > 0
                                                    ? /new-homepage-\S*/.exec(layout || '')[0]
                                                    : 'homepage'
                                            }
                                            isError={true}
                                        />
                                    )}

                                </div>
                            </div>
                        </div>
                    </main>
                }

                {isDefaultError &&
                    <div id="app" className="t-error">
                        <div className="error-wrapper m-404">

                            <div className="error-message error-message-notfound ">
                                <div className="error-message-body">
                                    <h1 className="error-message-title">{errorTitle}</h1>
                                    <p className="error-message-text">
                                        {errorDescription}
                                    </p>
                                </div>
                            </div>

                            <picture>
                                <source
                                    media="(max-width: 768px)"
                                    srcSet={getAssetUrl('static/img/errors/error-404-bg-mobile.jpg')}
                                />
                                <source
                                    media="(min-width: 1620px) and (max-width: 1620px)"
                                    srcSet={getAssetUrl('static/img/errors/error-404-bg-mobile.jpg')}
                                />
                                <img
                                    alt="error background"
                                    className="error-bg-image"
                                    srcSet={getAssetUrl('static/img/errors/error-404-bg.jpg')}
                                    title="error background"
                                >

                                </img>
                            </picture>
                        </div>
                    </div>
                }

            </div>

        </ResponsiveContainer>
    )
}



export default Error404
