/* global AJS_SLUG, DEBUG */
import {AnalyticsManager} from 'progressive-web-sdk/dist/analytics-integrations/analytics-manager'
import {GoogleTagManagerConnector} from 'progressive-web-sdk/dist/analytics-integrations/connectors/google-tag-manager'

export const getAnalyticsManager = (() => {
    let instance = null
    return () => {
        if (instance !== null) return instance

        return (instance = new AnalyticsManager({
            connectors: [
                new GoogleTagManagerConnector({
                    containerId: process.env.GOOGLE_TAG_CONTAINER_ID
                })
            ],
            debug: DEBUG
        }))
    }
})()
