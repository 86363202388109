import {getAssetUrl} from 'progressive-web-sdk/dist/ssr/universal/utils'
import {start, registerServiceWorker} from 'progressive-web-sdk/dist/ssr/browser/main'
import {getAnalyticsManager} from './analytics'
import {defaultConsentEvent} from './utils/gtmUtils'

import $ from 'jquery'
window.jQuery = $
window.$ = $

// Stylesheet: importing it here compiles the SCSS into CSS. The CSS is actually
// added to the markup in `loader.js`
// eslint-disable-next-line no-unused-vars
import './stylesheet.scss'
import {GET_ENV} from './connector'

if (GET_ENV !== 'development') console.log = () => {}
/**
 * Until the day that the `use` element's cross-domain issues are fixed, we are
 * forced to fetch the SVG Sprite's XML as a string and manually inject it into
 * the DOM. See here for details on the issue with `use`:
 * @URL: https://bugs.chromium.org/p/chromium/issues/detail?id=470601
 */
const fetchSvgSprite = () => {
    return fetch(getAssetUrl('static/svg/sprite-dist/sprite.svg'))
        .then((response) => response.text())
        .then((text) => {
            const div = document.createElement('div')
            div.innerHTML = text
            div.hidden = true
            document.body.appendChild(div)
        })
}

export const initCustomCSS = () => {
    if (typeof window !== 'undefined') {
        let mt = document.createElement('link')
        mt.rel = 'stylesheet'
        if (GET_ENV === 'development') {
            mt.href = `${process.env.DEVELOPMENT_GLOBAL_CSS}`
        } else if (GET_ENV === 'staging') {
            mt.href = `${process.env.STAGING_GLOBAL_CSS}`
        } else if (GET_ENV === 'production') {
            mt.href = `${process.env.PRODUCTION_GLOBAL_CSS}`
        }

        document.getElementsByTagName('head')[0].appendChild(mt)
    }
}

export const initStudentBeansIframe = () => {
    var b = document.getElementById('sbiframehook'),
        a = document.createElement('script')
    if (b) {
        a.setAttribute('data-iframe', 'https://connect.studentbeans.com/v4/whsmith/uk')
        a.setAttribute('data-load', 'connect')
        a.setAttribute('id', 'stb_root')
        a.async = !0
        a.src = 'https://cdn.studentbeans.com/third-party/all.js'
        b.appendChild(a)
    }
}

const main = () => {
    return Promise.all([
        defaultConsentEvent(),
        start(),
        getAnalyticsManager(),
        fetchSvgSprite(),
        initCustomCSS(),
        initStudentBeansIframe(),
        registerServiceWorker('/worker.js')
    ])
}

main()
