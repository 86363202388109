import {PAGEVIEW} from 'progressive-web-sdk/dist/analytics-integrations/types'
import {getAnalyticsManager} from '../../analytics'
import {getFromLocal} from '../../utils/storage-utils'
import {pushDatalayerEvent} from '../../utils/utils'

const analyticsManager = getAnalyticsManager()

export const handleSocialShare = (socialNetwork) => {
    let analyticbasketInfo = ''
    if (typeof window !== 'undefined') {
        analyticbasketInfo = getFromLocal('analyticBasket')
        if (analyticbasketInfo != '') {
            analyticbasketInfo = JSON.parse(analyticbasketInfo)
        } else {
            analyticbasketInfo = {
                cartValue: 0,
                cartItemsNum: 0,
                cartUniqueItemsNum: 0
            }
        }
    } else {
        analyticbasketInfo = {
            cartValue: 0,
            cartItemsNum: 0,
            cartUniqueItemsNum: 0
        }
    }
    const analyticData = {
        event: 'socialEvent',
        socialNetwork: socialNetwork,
        socialButtonType: `Click Through`
    }

    pushDatalayerEvent(analyticData)
}

export const handlePromotionView = () => {
    pushDatalayerEvent({
        event: 'promotionView',
        ecommerce: {
            promoView: {
                promotions: [
                    {
                        id: 'sale-and-offers',
                        name: 'sale-and-offers',
                        creative: 'sale-and-offers',
                        position: 'home-page'
                    }
                ]
            }
        }
    })
}

export const handlePromotionClick = () => {
    analyticsManager.track(PAGEVIEW, {
        event: 'promotionClick',
        templateName: 'home-detail-page',
        ecommerce: {
            promoClick: {
                promotions: [
                    {
                        id: 'sale-and-offers',
                        name: 'sale-and-offers',
                        creative: 'sale-and-offers',
                        position: 'home-page'
                    }
                ]
            }
        }
    })
    return false
}
