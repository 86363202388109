import {useEffect, useState} from 'react'
import {getFromLocal, storeInLocal} from '../utils/storage-utils'

export const useOptanon = () => {
    const [cookiesOptedIn, setCookiesOptedIn] = useState({
        functional:
            typeof window !== 'undefined' && window.OptanonActiveGroups?.indexOf('C0003') > -1,
        targeting:
            typeof window !== 'undefined' && window.OptanonActiveGroups?.indexOf('C0004') > -1,
        performance:
            typeof window !== 'undefined' && window.OptanonActiveGroups?.indexOf('C0002') > -1
    })
    const checkOptanonValues = () => {
        if (typeof window !== 'undefined' && window.OptanonActiveGroups) {
            setCookiesOptedIn({
                functional: window.OptanonActiveGroups?.indexOf('C0003') > -1,
                targeting: window.OptanonActiveGroups?.indexOf('C0004') > -1,
                performance: window.OptanonActiveGroups?.indexOf('C0002') > -1
            })
        }
    }
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window?.OneTrust?.OnConsentChanged(() => {
                checkOptanonValues()
                const cookiesUpdatedCount = parseInt(getFromLocal('cookiesUpdatedCount')) || 0
                storeInLocal('cookiesUpdatedCount', cookiesUpdatedCount + 1)
            })
            //fallback for when the onetrust script is not properly loaded  yet and the needed values are undefined
            document.addEventListener('optanonWrapperLoaded', checkOptanonValues)
        }
        checkOptanonValues()
    }, [])
    return cookiesOptedIn
}