import React from 'react'
import {getAssetUrl} from 'progressive-web-sdk/dist/ssr/universal/utils'

const FooterPaymentLogos = () => {
    return (
        <div className="footer-payment is-tablet">
            <div className="footer-payment-info">
                <span className="footer-block-title">We Accept</span>
                <ul className="payment-list">
                    <li className="payment-item visa" title="Visa"></li>
                    <li className="payment-item mastercard" title="Mastercard"></li>
                    <li className="payment-item maestro" title="Maestro"></li>
                    <li className="payment-item whsmith-giftcard" title="WHSmith GiFTCARD"></li>
                    <li className="payment-item american-express" title="American Express"></li>
                    <li className="payment-item paypal" title="PayPal"></li>
                    <li className="payment-item laybuy-payment">
                        <img
                            alt="LayBuy"
                            src={getAssetUrl('static/sprite-source/logo-payment-laybuy.svg')}
                            title="LayBuy"
                            width="50px"
                            height="12px"
                        />
                    </li>
                    <li className="payment-item revolut" title="Revolut Pay"></li>
                </ul>
            </div>
        </div>
    )
}

export default FooterPaymentLogos
