import React from 'react'

import {getAssetUrl} from 'progressive-web-sdk/dist/ssr/universal/utils'
import Link from 'progressive-web-sdk/dist/components/link'
import ErrorFooter from './error-footer'

const ErrorOverload = () => {
    return (
        <div className="error-wrapper m-404">
            <div className="error-background not-found">
                <picture>
                    <source
                        media="(max-width: 767px)"
                        srcSet={getAssetUrl('static/img/errors/error-overload-bg-mobile.jpg')}
                    />
                    <source
                        media="(min-width: 768) and (max-width: 1023px)"
                        srcSet={getAssetUrl('static/img/errors/error-overload-bg.png')}
                    />
                    <img
                        alt="error background"
                        srcSet={getAssetUrl('static/img/errors/error-overload-bg.png')}
                        title="error background"
                        className="background-image"
                    />
                </picture>
            </div>
            <div className="error-message error-message-top">
                <div className="not-found-logo">
                    <a href="/" title="WHSmith Home" className="header-logo-link">
                        <img
                            alt="WHSmith Home"
                            srcSet={getAssetUrl('static/img/errors/logo.svg')}
                            className="site-error-logo"
                        />
                        <span className="visually-hidden">WHSmith</span>
                    </a>
                </div>
                <div className="error-message-body">
                    <h1 className="error-message-title">Heavy Traffic</h1>
                    <p className="error-message-text">
                        We&apos;ve had a lot more visitors than usual today. Unfortunately, things
                        have got a little over-crowded and we can&apos;t fit any more visitors onto
                        the site!
                        <br />
                        You can try refreshing the page, or maybe come back later when the crowds
                        have died down.
                    </p>
                </div>
            </div>
            <ErrorFooter />
        </div>
    )
}

export default ErrorOverload
