import React from 'react'
import { v4 as uuidv4 } from 'uuid'

export const Arrow = () => (
    <svg className="icon-arrow2">
        <use xlinkHref="#icon-arrow_v2">
            <svg id="icon-arrow_v2" viewBox="0 0 10 10">
                <path
                    id={uuidv4() + 'ic_arrow'}
                    className="cls-1"
                    d="M337.643 713.651h7.176l-3.135 3.215a.677.677 0 0 0 0 .937.629.629 0 0 0 .9 0l4.233-4.343a.669.669 0 0 0 0-.928l-4.227-4.352a.628.628 0 0 0-.905 0 .669.669 0 0 0 0 .929l3.128 3.223h-7.175a.652.652 0 0 0-.642.661.652.652 0 0 0 .647.658z"
                    transform="translate(-337 -708)"
                ></path>
            </svg>
        </use>
    </svg>
)

export const ServiceIconSet_1 = () => (
    <svg className="icon-shipping icon-set">
        <use xlinkHref="#icon-set_1">
            <svg id="icon-set_1" viewBox="0 0 40 29">
                <path
                    id="ic"
                    className="cls-1"
                    d="M529.547 667.229h-5.455V660h-25.455a3.638 3.638 0 0 0-3.637 3.614V683.5h3.637a5.455 5.455 0 0 0 10.909.134v-.134h10.909a5.455 5.455 0 0 0 10.909 0H535v-9.039zm-.91 2.711l3.564 4.519h-8.109v-4.519h4.545zm-24.546 15.365a1.808 1.808 0 1 1 1.818-1.807 1.817 1.817 0 0 1-1.818 1.807zm4.036-5.423a5.412 5.412 0 0 0-7.608-.462 5.335 5.335 0 0 0-.465.462h-1.417v-16.268h21.818v16.268h-12.328zm17.783 5.423a1.808 1.808 0 1 1 1.818-1.807 1.816 1.816 0 0 1-1.818 1.807z"
                    transform="translate(-495 -660)"
                ></path>
            </svg>
        </use>
    </svg>
)

export const ServiceIconSet_2 = () => (
    <svg className="icon-collect icon-set">
        <use xlinkHref="#icon-set_2">
            <svg id="icon-set_2" viewBox="0 0 27 38">
                <path
                    id="ic"
                    className="cls-1"
                    d="M707.887 668.76a13.517 13.517 0 1 0-27.027 0v10.36a13.517 13.517 0 1 0 27.027 0v-10.36zm-3.379 0h-8.444v-10.081a10.3 10.3 0 0 1 8.446 10.081zm-11.825-10.081v10.081h-8.445a10.3 10.3 0 0 1 8.447-10.083zm11.827 20.441a10.138 10.138 0 1 1-20.272 0v-6.906h20.272v6.906z"
                    transform="translate(-680.875 -654.938)"
                ></path>
            </svg>
        </use>
    </svg>
)

export const ServiceIconSet_3 = () => (
    <svg className="icon-free icon-set">
        <use xlinkHref="#icon-set_3">
            <svg id="icon-set_3" viewBox="0 0 30.938 36.969">
                <path
                    id="ic"
                    className="cls-1"
                    d="M881.265 662.3v-5.343a.955.955 0 0 0-.952-.956.967.967 0 0 0-.7.287l-7.373 7.258a.939.939 0 0 0-.019 1.34.17.17 0 0 0 .019.019l7.353 7.258a.99.99 0 0 0 1.388-.013.966.966 0 0 0 .281-.675v-5.344a11.487 11.487 0 1 1-11.525 13.118 1.933 1.933 0 0 0-1.9-1.628 1.941 1.941 0 0 0-1.954 1.929 2.058 2.058 0 0 0 .014.235 15.53 15.53 0 1 0 15.368-17.485z"
                    transform="translate(-865.875 -656)"
                ></path>
            </svg>
        </use>
    </svg>
)

export const ServiceIconSet_4 = () => (
    <svg className="icon-help icon-set">
        <use xlinkHref="#icon-set_4">
            <svg id="icon-set_4" viewBox="0 0 36 36">
                <path
                    id="ic"
                    className="cls-1"
                    d="M1066.19 681.19h3.6v3.6h-3.6v-3.6zm1.8-25.2a18 18 0 1 0 18 18 18.008 18.008 0 0 0-18-17.99zm0 32.393a14.4 14.4 0 1 1 14.4-14.4 14.416 14.416 0 0 1-14.4 14.405zm0-25.2a7.2 7.2 0 0 0-7.2 7.2h3.6a3.6 3.6 0 0 1 7.2 0c0 3.6-5.4 3.15-5.4 9h3.6c0-4.05 5.4-4.5 5.4-9a7.2 7.2 0 0 0-7.2-7.2z"
                    transform="translate(-1050 -656)"
                ></path>
            </svg>
        </use>
    </svg>
)

export const Remove = () => (
    <svg className="icon-product-remove">
        <title>Remove</title>
        <use xlinkHref="#icon-ic_product-remove">
            <svg id="icon-ic_product-remove" viewBox="0 0 12 15">
                <path
                    id="ic_product-remove"
                    className="cls-1"
                    d="M184.857 6218.33a1.7 1.7 0 0 0 1.714 1.67h6.858a1.7 1.7 0 0 0 1.714-1.67v-10h-10.286v10zm1.714-8.33h6.858v8.33h-6.858V6210zm6.429-4.17l-.857-.83h-4.286l-.857.83h-3v1.67h12v-1.67h-3z"
                    transform="translate(-184 -6205)"
                ></path>
            </svg>
        </use>
    </svg>
)
