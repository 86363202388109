import fetch from 'cross-fetch'
import {addInteractionErrorEvent} from '../../utils/gtmUtils'

export const getMenuCategories = async (connector, categoryId, levels) => {
    try {
        if (categoryId !== 'undefined') {
            let responseData = await fetch(
                `${process.env.HOSTNAME}/get-categories/?categoryId=${categoryId}&levels=${
                    typeof levels !== 'undefined' ? levels : 1
                }`,
                {
                    headers: {
                        ...connector.client.defaultHeaders,
                        'content-type': 'application/json'
                    },
                    method: 'GET'
                }
            )
            return await responseData.json()
        }
        return false
    } catch (error) {
        addInteractionErrorEvent(
            'interaction_error',
            'Get Menu Categories Error',
            'Category',
            categoryId,
            '404',
            'menu'
        )
        console.error('Error fetching categories!!getMenuCategories', error)
    }
}

export const getNavMenuData = async (connector, rootId, level = 3) => {
    try {
        const rootCategories = await getMenuCategories(connector, rootId, level).then(
            (res) => res?.categories
        )

        if (rootCategories?.fault?.type === 'SiteOfflineException') {
            return rootCategories
        }

        const recursion = (obj) =>
            obj.map((o) =>
                o?.c_showInMenu
                    ? o?.categories?.length
                        ? {...o, categories: recursion(o.categories)}
                        : o
                    : null
            )

        const oneMoreRecursion = (obj) =>
            obj.filter((o) =>
                o
                    ? o?.categories?.length
                        ? (o.categories = oneMoreRecursion(o.categories))
                        : true
                    : false
            )

        return oneMoreRecursion(recursion(rootCategories))
    } catch (error) {
        addInteractionErrorEvent(
            'interaction_error',
            'Get Nav Menu Data Error',
            'Category',
            rootId,
            '404',
            'menu'
        )
        console.error('Error fetching Menu data!!', error)
    }
}

export const getAllMenuData = async (connector, rootId, level = 3) => {
    try {
        let rootCategories = await getMenuCategories(connector, rootId, level).then(
            (res) => res.categories
        )
        let menuCategories = rootCategories
            ? await Promise.all(
                  await rootCategories?.reduce(async (result, category) => {
                      ;(await result).push({...category})
                      //   if (category.c_showInMenu) {
                      //   }
                      return result
                  }, [])
              )
            : []
        return menuCategories
    } catch (error) {
        addInteractionErrorEvent(
            'interaction_error',
            'Get All Menu Data Error',
            'Category',
            rootId,
            '404',
            'menu'
        )
        console.error('Error fetching Menu data!!', error)
    }
}
