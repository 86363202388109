import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'

const ProductPricing = ({
    productPrice,
    masterProductInfo,
    plpCssClass,
    componentRef,
    pdp = false,
    showSearchSuggestion
}) => {
    const [salesPrice, setSalesPrice] = useState('')
    const [maxPrice, setMaxPrice] = useState(null)
    const [savePrice, setSavePrice] = useState(null)
    const [savePercentage, setSavePercentage] = useState(null)
    const [standardPrice, setStandardPrice] = useState(null)

    useEffect(() => {
        if (productPrice?.c_renderingType === 'magazine-subscription') {
            let variantsCount = productPrice?.variants?.length
            let minPrice = productPrice?.price
            let maxPriceMag = productPrice?.price_max ? productPrice.price_max : productPrice?.price
            if (variantsCount > 1) {
                productPrice?.variants?.map((item) => {
                    if (item.price < minPrice) minPrice = item.price
                    if (item.price > maxPriceMag) maxPriceMag = item.price
                })
            }

            if (minPrice === maxPriceMag) {
                setSalesPrice(minPrice.toFixed(2))
                setMaxPrice(null)
            } else {
                setSalesPrice(minPrice.toFixed(2))
                setMaxPrice(maxPriceMag.toFixed(2))
            }
        } else {
            productPrice?.price
                ? setSalesPrice(productPrice.price.toFixed(2))
                : productPrice?.prices && Object.keys(productPrice?.prices).length > 0
                ? (productPrice.prices['whsmith-gbp-saleprices'] &&
                      setSalesPrice(productPrice.prices['whsmith-gbp-saleprices']?.toFixed(2))) ||
                  (productPrice.prices['whsmith-gbp-listprices'] &&
                      setSalesPrice(productPrice.prices['whsmith-gbp-listprices']?.toFixed(2)))
                : ''
        }
        productPrice &&
            productPrice.prices &&
            productPrice.prices['whsmith-gbp-listprices'] &&
            setStandardPrice(productPrice.prices['whsmith-gbp-listprices'].toFixed(2))
        productPrice &&
            productPrice.prices &&
            productPrice.prices['whsmith-gbp-listprices'] &&
            productPrice.prices['whsmith-gbp-saleprices'] &&
            setSavePrice(
                (
                    productPrice.prices['whsmith-gbp-listprices'] -
                    productPrice.prices['whsmith-gbp-saleprices']
                ).toFixed(2)
            )
    }, [productPrice])

    useEffect(() => {
        if (standardPrice && savePrice) {
            setSavePercentage(Math.floor((savePrice / standardPrice) * 100))
        }
    }, [standardPrice, savePrice])

    return standardPrice ? (
        !pdp ? (
            <div className={`${showSearchSuggestion ? "product-pricing-search" : "product-pricing"}`}>
                <span className="product-sales-price" title="Sale Price">
                    <span
                        id={`${showSearchSuggestion ? 'product-price-value-red':''}`}
                        className={`product-price-value ${
                            standardPrice && salesPrice !== standardPrice && savePrice
                                ? 'dark-red'
                                : ''
                        }`}
                    >
                        {!salesPrice
                            ? null
                            : productPrice &&
                              productPrice.id &&
                              productPrice.id.substring(0, 3) === 'MAG'
                            ? maxPrice
                                ? `£${salesPrice} - £${maxPrice}`
                                : `£${salesPrice}`
                            : `£${salesPrice}`}
                    </span>
                </span>
                <span className={`${plpCssClass} product-standard-price`} title="Standard Price">
                    {standardPrice && salesPrice !== standardPrice && savePrice ? (
                        <span className={`${plpCssClass} ${showSearchSuggestion ? 'product-price-value-search' : 'product-price-value'}`}>
                            <strike>£{standardPrice}</strike>
                        </span>
                    ) : null}
                    {savePrice && salesPrice !== standardPrice ? (
                        <span className={`${plpCssClass} ${showSearchSuggestion ? 'product-price-save-search':'product-price-save'}`}>
                            -{savePercentage}%
                        </span>
                    ) : null}
                </span>
            </div>
        ) : (
            <div className="product-pricing">
                <span className="product-sales-price" title="Sale Price">
                    <span className="product-price-value">
                        {!salesPrice
                            ? null
                            : productPrice &&
                              productPrice.id &&
                              productPrice.id.substring(0, 3) === 'MAG'
                            ? maxPrice
                                ? `£${salesPrice} - £${maxPrice}`
                                : `£${salesPrice}`
                            : `£${salesPrice}`}
                    </span>
                </span>
                <span className={`${plpCssClass} product-standard-price`} title="Standard Price">
                    {standardPrice && salesPrice !== standardPrice && savePrice ? (
                        <span className={`${plpCssClass} product-price-value`}>
                            {masterProductInfo.c_previousPriceLabel}
                            {` `}
                            <span>£{standardPrice}</span>
                        </span>
                    ) : null}
                    {savePrice && salesPrice !== standardPrice ? (
                        <span className={`${plpCssClass} product-price-save`}>
                            Save £{savePrice} ({savePercentage}%)
                        </span>
                    ) : null}
                </span>
            </div>
        )
    ) : null
}

ProductPricing.propTypes = {
    salesPrice: PropTypes.string,
    className: PropTypes.string,
    componentRef: PropTypes.string
}

ProductPricing.defaultProps = {
    componentRef: ''
}

export default ProductPricing
