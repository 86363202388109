import React from 'react'
import InStock from './inStock'
import PropTypes from 'prop-types'

const ProductAvailability = ({pdtAvailability, showSearchSuggestion, componentRef}) => {
    return (
        <div className={`${showSearchSuggestion ? 'availability-msg-search' : 'availability-msg'}`}>
            {pdtAvailability?.inventory?.stock_level > 0 ||
            pdtAvailability?.inventory?.stock_status === 'In Stock' ? (
                <div className="in-stock-msg">
                    {componentRef === 'productDetails' ? <InStock /> : <p>In Stock</p>}
                </div>
            ) : pdtAvailability && pdtAvailability.c_availabilityMsg ? (
                <p className="in-stock-date-msg">{pdtAvailability.c_availabilityMsg}</p>
            ) : pdtAvailability?.inventory?.c_availabilityMsg ? (
                <p className="in-stock-date-msg">{pdtAvailability?.inventory?.c_availabilityMsg}</p>
            ) : null}
        </div>
    )
}

ProductAvailability.propTypes = {
    pdtAvailability: PropTypes.object,
    componentRef: PropTypes.string
}

export default ProductAvailability
