import React from 'react'
import SkeletonBlock from 'progressive-web-sdk/dist/components/skeleton-block'
import SkeletonText from 'progressive-web-sdk/dist/components/skeleton-text'
import {
    Desktop,
    DesktopOrSmaller,
    LargeDesktop,
    TabletOrSmaller
} from '../../../components/media-queries'

export default function Circles({showTabletPart}) {
    return (
        <>
            <Desktop>
                <div className="brand homepage-highlights">
                    <div className="banners-grid-list">
                        {[1, 2, 3].map((el) => {
                            return (
                                <span className="banners-grid-list-box">
                                    <LargeDesktop>
                                        <span
                                            className="banners-grid-item banners-grid-item-upper banners-grid-item-homepage"
                                            style={{marginLeft: 20, marginRight: 20}}
                                        >
                                            <SkeletonBlock
                                                className={'banners-grid-item-upper'}
                                                height={'100%'}
                                                style={{borderRadius: '100%'}}
                                            />
                                            <div className="banners-grid-link">
                                                <SkeletonText width="100px" />
                                                <span> </span>
                                            </div>
                                        </span>
                                        <span
                                            className="banners-grid-item banners-grid-item-lower banners-grid-item-homepage"
                                            style={{marginLeft: 20, marginRight: 20}}
                                        >
                                            <SkeletonBlock
                                                className={'banners-grid-item-lower'}
                                                height={'100%'}
                                                style={{borderRadius: '100%'}}
                                            />
                                            <div className="banners-grid-link">
                                                <SkeletonText width="100px" />
                                                <span> </span>
                                            </div>
                                        </span>
                                    </LargeDesktop>
                                    <DesktopOrSmaller>
                                        <span className="banners-grid-item banners-grid-item-upper banners-grid-item-homepage">
                                            <SkeletonBlock
                                                className={'banners-grid-item-upper'}
                                                height={'100%'}
                                                style={{borderRadius: '100%'}}
                                            />
                                            <div className="banners-grid-link">
                                                <SkeletonText width="100px" />
                                                <span> </span>
                                            </div>
                                        </span>
                                        <span className="banners-grid-item banners-grid-item-lower banners-grid-item-homepage">
                                            <SkeletonBlock
                                                className={'banners-grid-item-lower'}
                                                height={'100%'}
                                                style={{borderRadius: '100%'}}
                                            />
                                            <div className="banners-grid-link">
                                                <SkeletonText width="100px" />
                                                <span> </span>
                                            </div>
                                        </span>
                                    </DesktopOrSmaller>
                                </span>
                            )
                        })}
                    </div>
                </div>
            </Desktop>
            {showTabletPart && (
                <TabletOrSmaller>
                    <div className="feature-tile">
                        <div className="tile-wrap" style={{marginTop: 20}}>
                            {[0, 1, 2, 3, 4, 5].map(() => {
                                return <SkeletonBlock className="small-tile"></SkeletonBlock>
                            })}
                        </div>
                    </div>
                </TabletOrSmaller>
            )}
        </>
    )
}
