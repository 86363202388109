/*Product Tile Component*/
//Consists of sub components - ProductTileTop, ProductTileInfo and ProductTilePromo, in order.

import React from 'react'
import PropTypes from 'prop-types'
import ProductTileTop from './productTileTop'
import ProductTileInfo from './productTileInfo'
import ProductTileBottom from './productTileBottom'
import ProductAvailability from './productAvailability'
import {Mobile, TabletOrHigher} from '../../components/media-queries'
import LazyLoad from '../lazyLoader-product'
import {useEinstein} from '../../hooks/useEinstein'

const ProductTile = (props) => {
    const {
        key,
        cssClass,
        chartNumber,
        product,
        masterProduct,
        plpCssClass,
        position,
        queryString,
        category,
        categoryData,
        categoryName,
        faceoutTitle,
        showOnLazyLoad,
        isFaceout,
        isSearchListing,
        showSearchSuggestion,
        onSearchClose,
        triggerSuggestInteraction = () => {}
    } = props
    //categoryData prop refers to the actual category which is used for GA4 and Einstein events
    //category={product.name} is the initial implementation for UA events which is used and shouldn't be modified as it is used in many places
    const einstein = useEinstein()
    const sendClickAction = () => {
        if (!isFaceout) {
            if (showSearchSuggestion) {
                einstein.sendClickSearch(queryString, product)
            } else {
                einstein.sendClickCategory(categoryData, product)
            }
        }
    }

    return product ? (
        // Removing Lazyload for Chart Pages from here as it is breaking the UI sometimes, note its handled on product image file
        <LazyLoad showContent={chartNumber || isFaceout || showOnLazyLoad}>
            <div
                key={key}
                className={`${cssClass} product-tile ${chartNumber ? 'chart-tile-special' : ''}`}
                onClick={sendClickAction}
            >
                {product && masterProduct?.id && (
                    <ProductTileTop
                        productName={product.name}
                        productImage={product.image_groups}
                        productImageSizes={product.c_images}
                        chartNumber={chartNumber}
                        cssClass={cssClass}
                        productId={masterProduct.id}
                        category={product.name}
                        categoryData={categoryData || category}
                        isFaceout={isFaceout}
                        product={product}
                        masterProduct={masterProduct}
                        position={position}
                        queryString={queryString}
                        categoryName={categoryName}
                        faceoutTitle={faceoutTitle}
                        triggerSuggestInteraction={triggerSuggestInteraction}
                        isSearchListing={isSearchListing}
                    />
                )}
                {product && showSearchSuggestion && (
                    <ProductTileTop
                        productName={product.name}
                        productImage={product.defaultImage}
                        productImageSizes={masterProduct.c_images}
                        cssClass="image-wrapper image-alignment"
                        productId={masterProduct.product_id}
                        isFaceout={isFaceout}
                        product={product}
                        masterProduct={masterProduct}
                        showSearchSuggestion={showSearchSuggestion}
                        onSearchClose={onSearchClose}
                        triggerSuggestInteraction={triggerSuggestInteraction}
                        categoryData={categoryData || category}
                        categoryName={categoryName}
                        isSearchListing={isSearchListing}
                    />
                )}
                {!showSearchSuggestion ? (
                    <>
                        <Mobile>
                            <ProductTileInfo
                                productInfo={product}
                                masterProductInfo={masterProduct}
                                cssClass={cssClass}
                                category={product.name}
                                categoryData={categoryData || category}
                                plpCssClass={plpCssClass}
                                isFaceout={isFaceout}
                                chartNumber={chartNumber}
                                position={position}
                                queryString={queryString}
                                faceoutTitle={faceoutTitle}
                                triggerSuggestInteraction={triggerSuggestInteraction}
                                isSearchListing={isSearchListing}
                            />
                        </Mobile>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '-webkit-fill-available'
                            }}
                        >
                            <TabletOrHigher>
                                <ProductTileInfo
                                    productInfo={product}
                                    masterProductInfo={masterProduct}
                                    cssClass={cssClass}
                                    category={product.name}
                                    categoryData={categoryData || category}
                                    plpCssClass={plpCssClass}
                                    isFaceout={isFaceout}
                                    chartNumber={chartNumber}
                                    position={position}
                                    queryString={queryString}
                                    faceoutTitle={faceoutTitle}
                                    triggerSuggestInteraction={triggerSuggestInteraction}
                                    isSearchListing={isSearchListing}
                                />
                            </TabletOrHigher>

                            {product && (
                                <>
                                    {!chartNumber && (
                                        <ProductAvailability pdtAvailability={product} />
                                    )}
                                    <ProductTileBottom
                                        productBottom={product}
                                        masterProductInfo={masterProduct}
                                        cssClass={cssClass}
                                        showSearchSuggestion={showSearchSuggestion}
                                        plpCssClass={plpCssClass}
                                        position={position}
                                    />
                                </>
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <div
                            className={`${showSearchSuggestion ? 'product-bottom-container' : ''}`}
                            style={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <Mobile>
                                <ProductTileInfo
                                    productInfo={product}
                                    masterProductInfo={masterProduct}
                                    cssClass={cssClass}
                                    category={product.name}
                                    categoryData={categoryData || category}
                                    plpCssClass={plpCssClass}
                                    isFaceout={isFaceout}
                                    chartNumber={chartNumber}
                                    position={position}
                                    queryString={queryString}
                                    faceoutTitle={faceoutTitle}
                                    showSearchSuggestion={showSearchSuggestion}
                                    onSearchClose={onSearchClose}
                                    triggerSuggestInteraction={triggerSuggestInteraction}
                                    isSearchListing={isSearchListing}
                                />
                            </Mobile>
                            <TabletOrHigher>
                                <ProductTileInfo
                                    productInfo={product}
                                    masterProductInfo={masterProduct}
                                    cssClass={cssClass}
                                    category={product.name}
                                    categoryData={categoryData || category}
                                    plpCssClass={plpCssClass}
                                    isFaceout={isFaceout}
                                    chartNumber={chartNumber}
                                    position={position}
                                    queryString={queryString}
                                    faceoutTitle={faceoutTitle}
                                    showSearchSuggestion={showSearchSuggestion}
                                    onSearchClose={onSearchClose}
                                    triggerSuggestInteraction={triggerSuggestInteraction}
                                    categoryName={categoryName}
                                    isSearchListing={isSearchListing}
                                />
                            </TabletOrHigher>

                            {product && (
                                <>
                                    {!chartNumber && (
                                        <ProductAvailability
                                            pdtAvailability={product}
                                            showSearchSuggestion={showSearchSuggestion}
                                        />
                                    )}
                                    <ProductTileBottom
                                        productBottom={product}
                                        masterProductInfo={masterProduct}
                                        cssClass={cssClass}
                                        showSearchSuggestion={showSearchSuggestion}
                                        plpCssClass={plpCssClass}
                                        position={position}
                                    />
                                </>
                            )}
                        </div>
                    </>
                )}
            </div>
        </LazyLoad>
    ) : null
}

ProductTile.propTypes = {
    cssClass: PropTypes.string,
    product: PropTypes.object,
    chartNumber: PropTypes.any,
    dataId: PropTypes.any,
    masterProduct: PropTypes.object,
    plpCssClass: PropTypes.string,
    position: PropTypes.any,
    faceoutTitle: PropTypes.any
}

ProductTile.defaultProps = {
    masterProduct: {c_images: ''}
}

export default ProductTile
