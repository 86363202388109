import React, {Fragment, useState, useEffect} from 'react'
import {Mobile, Tablet, LargeDesktop, Laptop} from '../../components/media-queries'
import ProductTilePro from '../product-tile-pro'
import Slider from '../slider'
import Link from 'progressive-web-sdk/dist/components/link'
import {datalayerImpressionsView} from '../../utils/utils'

const Faceout = (props) => {
    const {
        dataIds,
        slidesPerView,
        faceoutTitle,
        faceoutLink,
        faceoutLinkText,
        faceoutType,
        showPagination,
        loop = true,
        data,
        pageDataPushed,
        setIsLoadedFully,
        category,
        recommenderDetails,
        isEinsteinReco
    } = props
    const [impressionViewPushed, setImpressionViewPushed] = useState([])

    useEffect(() => {
        if (pageDataPushed === true) {
            if (!impressionViewPushed.includes(faceoutType)) {
                setTimeout(() => {
                    datalayerImpressionsView(false, data, data.length, faceoutTitle, faceoutTitle)
                }, 5000)

                setImpressionViewPushed([...impressionViewPushed, faceoutType])
            }
        }
    }, [pageDataPushed])

    const productComponent = (props) => {
        return (
            <ProductTilePro
                {...props}
                showOnLazyLoad={false}
                isFaceout={true}
                categoryData={category}
                recommenderDetails={recommenderDetails}
                isEinsteinReco={isEinsteinReco}
            />
        )
    }

    let skeletonBlock = '*'
    skeletonBlock = skeletonBlock.repeat(slidesPerView)
    skeletonBlock = Array.from(skeletonBlock)

    return (
        <Fragment>
            <div className="carousel-content">
                {faceoutTitle !== undefined && (
                    <h2 className="h2 carousel-title 11">
                        {faceoutTitle}
                        {faceoutLink !== undefined && (
                            <Link href={faceoutLink} className="link-more">
                                {faceoutLinkText ?? 'View More'}
                            </Link>
                        )}
                    </h2>
                )}
                <LargeDesktop>
                    <Fragment>
                        <Slider
                            data={dataIds}
                            products={data}
                            masterProducts={data}
                            slidesPerView={slidesPerView.forLargeDesktop}
                            SlideComp={productComponent}
                            showPagination={showPagination}
                            faceoutType={faceoutType}
                            faceoutTitle={faceoutTitle}
                            loop={loop}
                            setIsLoadedFully={setIsLoadedFully}
                        />
                    </Fragment>
                </LargeDesktop>
                <Laptop>
                    <Fragment>
                        <Slider
                            data={dataIds}
                            products={data}
                            masterProducts={data}
                            slidesPerView={slidesPerView.forDesktop}
                            SlideComp={productComponent}
                            showPagination={showPagination}
                            faceoutType={faceoutType}
                            faceoutTitle={faceoutTitle}
                            loop={loop}
                            setIsLoadedFully={setIsLoadedFully}
                        />
                    </Fragment>
                </Laptop>
                <Tablet>
                    <Fragment>
                        <Slider
                            data={dataIds}
                            products={data}
                            masterProducts={data}
                            slidesPerView={slidesPerView.forTablet}
                            SlideComp={productComponent}
                            showPagination={showPagination}
                            faceoutType={faceoutType}
                            faceoutTitle={faceoutTitle}
                            loop={loop}
                            setIsLoadedFully={setIsLoadedFully}
                        />
                    </Fragment>
                </Tablet>
                <Mobile>
                    <Fragment>
                        <Slider
                            data={dataIds}
                            products={data}
                            masterProducts={data}
                            slidesPerView={slidesPerView.forMobile}
                            SlideComp={productComponent}
                            showPagination={showPagination}
                            faceoutType={faceoutType}
                            faceoutTitle={faceoutTitle}
                            loop={loop}
                            setIsLoadedFully={setIsLoadedFully}
                        />
                    </Fragment>
                </Mobile>
            </div>
        </Fragment>
    )
}

export default Faceout
