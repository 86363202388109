import React from 'react'
import SkeletonBlock from 'progressive-web-sdk/dist/components/skeleton-block'
import SkeletonText from 'progressive-web-sdk/dist/components/skeleton-text'

const ProductTileSkeleton = () => {
    return (
        <div className="product-tile-skeleton-simple-faceout">
            <SkeletonBlock
                width="150px"
                height={'150px'}
                style={{marginLeft: 'auto', marginRight: 'auto'}}
            />
            <SkeletonText width="50%" />
            <SkeletonText width="40%" />
            <SkeletonText width="10%" />
            <SkeletonText width="80%" />
            <SkeletonText width="40%" />
            <SkeletonBlock width="100%" height="20px" />
            <SkeletonText width="30%" />
            <SkeletonBlock width={'100%'} height={'30px'} />
        </div>
    )
}

export default ProductTileSkeleton
