import React from 'react'
import ProductPromo from '../productPromo/productPromo'

const ProductTilePromo = ({productPromo, isEinsteinReco}) => {
    return productPromo ? (
        <div className="product-tile-promo promo-mob-width">
            <ProductPromo promo={productPromo} isEinsteinReco={isEinsteinReco}/>
        </div>
    ) : null
}

export default ProductTilePromo
