import React, {useEffect} from 'react'
import PropTypes from 'prop-types'

import Error404 from './error-404'
import Error410 from './error-410'
import Error500 from './error-500'
import ErrorMaintenance from './error-maintenance'
import ErrorOverload from './error-overload'
import {useLocation} from 'react-router'

export const ERROR_404_TITLE = 'Page Not Found'
export const ERROR_410_TITLE = 'Page Was Gone'
export const ERROR_500_TITLE = 'Internal Server Error'
export const ERROR_COOKIES_DISABLED_TITLE = 'Cookies/Javascript Disabled'
export const ERROR_MAINTENANCE_TITLE = 'Sorry, we are down for maintenance'
export const ERROR_OVERLOAD_TITLE = 'Heavy Traffic'

// <Error> is rendered when:
//
// 1. A user requests a page that is not routable from `app/routes.jsx`.
// 2. A routed component throws an error in `getProps()`.
// 3. A routed component throws an error in `render()`.
//
// It must not throw an error. Keep it as simple as possible.
const Error = (props) => {
    const {status, type} = props
    const location = useLocation()
    const renderError = () => {
        if (status === 404) return <Error404 />
        else if (status === 410)
            return (
                <main id="app-main" className="c-app-wrapper__main" role="main">
                    <Error410 />
                </main>
            )
        else if (status === 500)
            return (
                <div id="app" className="t-error">
                    <main id="app-main" className="c-app-wrapper__main" role="main">
                        <Error500 />
                    </main>
                </div>
            )
        else if (status === 503)
            return (
                <div id="app" className="t-error">
                    <main id="app-main" className="c-app-wrapper__main" role="main">
                        <ErrorMaintenance />
                    </main>
                </div>
            )
        else if (type === 'overload')
            return (
                <div id="app" className="t-error">
                    <main id="app-main" className="c-app-wrapper__main" role="main">
                        <ErrorOverload />
                    </main>
                </div>
            )
        else return <Error404 />
    }
    useEffect(() => {
        if (status && typeof window !== 'undefined') {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                event: 'page_error',
                error_type: props?.message,
                error_description: props?.stack,
                error_code: status,
                referral_url: location?.pathname
            })
        }
    }, [status])

    return renderError()
}

Error.propTypes = {
    // JavaScript error stack trace: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/stack
    stack: PropTypes.string,
    // HTTP status code: https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
    status: PropTypes.number,
    // Types: Maintenance, CookiesDisabled
    type: PropTypes.string
}

export default Error
