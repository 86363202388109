import React, {useContext, useRef, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import Link from 'progressive-web-sdk/dist/components/link'
import {Mobile, Desktop, Tablet} from '../media-queries'
import {GlobalStateContext} from '../global-state'

import {addNavigationInteractionEvent} from '../../utils/gtmUtils'

const NewMenu = ({
    categories,
    setShowOverLay,
    burgerStackToggle,
    isError,
    closeAllSubMeganav,
    closeAllMenuItems,
    pushNavInteraction,
    showParentMobile,
    setShowParentMobile,
    setIsNavopen,
    isNavopen,
    openAccordion_id,
    setOpenAccordion_id,
    closeMobileSubMenu
}) => {
    let history = useHistory()
    const megaNavDesktopTimer = useRef(0)
    const showMenuTimer = useRef(0)
    const globalState = useContext(GlobalStateContext)
    const parentRef = useRef(null)

    useEffect(() => {
        scrollToTopChild()
        scrollToTopParent()
    }, [burgerStackToggle])

    // -------------------New header Change ------------------

    const redirectHref = (href, id) => {
        closeAllMenuItems()
        if (isError) {
            window.location.replace(href)
        } else {
            pushNavInteraction(id)
            const products = globalState?.categoryProductData?.[href] || []
            const getValue = Object.keys(products || {})?.filter((el) => products[el].length)
            const maxValue = Math.min(...(getValue.length > 0 ? getValue : [0]))
            let urlData = ''
            if (href.includes('?')) {
                urlData = new URLSearchParams(href?.split('?')?.[1])
            }
            if (maxValue) {
                urlData = new URLSearchParams()
                urlData.set('p', maxValue)
            }

            window.location.pathname !== href && (isError && window.location.replace(href))
            window.location.pathname !== href &&
                !isError &&
                history.push({
                    pathname: href?.split('?')?.[0] || href,
                    search: urlData.toString()
                })
        }
    }
    const toggleSubMenu = (e, displayId, subNav, href, index) => {
        if (e.type == 'click') {
            redirectHref(href, 'parent-nav-menu' + index)
        } else {
            if (subNav && subNav.gridContent?.length > 0) {
                let ele = document.getElementById(displayId)
                megaNavResetMenuCloseTime()
                clearTimeout(showMenuTimer.current)
                showMenuTimer.current = setTimeout(() => {
                    closeAllSubMeganav()
                    if (ele.classList.contains('show-none')) {
                        setShowOverLay(true)
                        ele.classList.remove('show-none')
                        setIsNavopen(true)
                    } else {
                        ele.classList.add('show-none')
                        setIsNavopen(true)
                    }
                }, 500)
            } else {
                closeAllSubMeganav()
            }
        }
    }

    const megaNavSetMenuCloseTime = () => {
        megaNavDesktopTimer.current = setTimeout(() => {
            closeAllSubMeganav()
            setShowOverLay(false)
        }, 2000)
        clearTimeout(showMenuTimer.current)
    }

    const megaNavResetMenuCloseTime = () => {
        clearTimeout(megaNavDesktopTimer.current)
    }

    const showSubMenuNew = (childId, hasSubMenu, href) => {
        if (hasSubMenu) {
            setShowParentMobile(false)
            scrollToTopParent()
            scrollToTopChild()
            const wrap = document.getElementById('menu-parent-wrap')
            wrap.classList.remove('overflow-active')
            const child = document.getElementById(childId)
            child.classList.add('menu-mob-active')
        } else {
            setShowParentMobile(true)
            scrollToTopChild()
            closeAllMenuItems()
            history.push(href)
        }
    }

    const scrollToTopParent = () => {
        parentRef.current.scroll({
            top: 0,
            behavior: 'smooth'
        })
    }

    const scrollToTopChild = () => {
        let accordionItem = document.getElementsByName('accordion-menu-outer')
        accordionItem?.forEach((item) => (item.scrollTop = 0))
    }

    const hideSubMenu = (childId) => {
        scrollToTopParent()
        scrollToTopChild()

        const wrap = document.getElementById('menu-parent-wrap')
        wrap.classList.add('overflow-active')
        const childEle = document.getElementById(childId)
        childEle.classList.remove('menu-mob-active')
        closeMobileSubMenu()
        setShowParentMobile(true)
    }

    const pushNavEvents = (e) => {
        const eTarget = e.target
        const isImage = eTarget.tagName.toLowerCase() === 'img'
        const navItemCategory = isImage ? 'CTA Link' : eTarget.innerText
        const navItemType = isImage ? 'View More CTA Link' : 'Menu'
        addNavigationInteractionEvent('Header', navItemCategory, navItemType)
    }
    //accordion__header-item-0-0
    // --------------------New header change

    return (
        <>
            <Desktop>
                <DesktopMenuModule
                    categories={categories}
                    toggleSubMenu={toggleSubMenu}
                    redirectHref={redirectHref}
                    megaNavResetMenuCloseTime={megaNavResetMenuCloseTime}
                    megaNavSetMenuCloseTime={megaNavSetMenuCloseTime}
                    isError={isError}
                    isNavopen={isNavopen}
                    pushNavEvents={pushNavEvents}
                />
            </Desktop>

            <MobileMenuModule
                categories={categories}
                burgerStackToggle={burgerStackToggle}
                hideSubMenu={hideSubMenu}
                redirectHref={redirectHref}
                pushNavInteraction={pushNavInteraction}
                showParentMobile={showParentMobile}
                showSubMenuNew={showSubMenuNew}
                isError={isError}
                parentRef={parentRef}
                setOpenAccordion_id={setOpenAccordion_id}
                openAccordion_id={openAccordion_id}
                pushNavEvents={pushNavEvents}
            />
        </>
    )
}

//---------------- meganv Desktop ----------

const DesktopMenuModule = ({
    categories,
    toggleSubMenu,
    redirectHref,
    megaNavResetMenuCloseTime,
    megaNavSetMenuCloseTime,
    isNavopen,
    pushNavEvents
}) => {
    return (
        <div
            className={`${
                isNavopen ? 'meganav-desktop-header-wrap-open' : 'meganav-desktop-header-wrap'
            }`}
            onClick={(e) => pushNavEvents(e)}
        >
            <div className="meganav-desktop-header-parent">
                <div className="meganav-menu-align">
                    {categories?.content?.parentNav &&
                        categories?.content?.parentNav?.map((menuItems, index) => {
                            return (
                                <div key={`parentNav-${index}`}>
                                    <Link
                                        className="parent-nav meganav-parent-heading"
                                        id={'parent-nav-menu-desktop-' + index}
                                        href={menuItems.href}
                                        onClick={(e) => {
                                            toggleSubMenu(
                                                e,
                                                'show-submenu-' + index,
                                                menuItems.subMenuNav,
                                                menuItems.href,
                                                index
                                            )
                                        }}
                                        style={{
                                            ...menuItems.parentStyle?.desktop,
                                            ...categories.CommonStyles.parentTitle?.desktop
                                        }}
                                        onMouseEnter={(e) =>
                                            toggleSubMenu(
                                                e,
                                                'show-submenu-' + index,
                                                menuItems.subMenuNav,
                                                menuItems.href,
                                                index
                                            )
                                        }
                                        onMouseLeave={
                                            menuItems.hasSubMenu && megaNavSetMenuCloseTime
                                        }
                                    >
                                        {menuItems.parentTitle}
                                    </Link>
                                    <div
                                        className="show-none"
                                        name="sub-menu-desktop"
                                        id={'show-submenu-' + index}
                                        onMouseEnter={megaNavResetMenuCloseTime}
                                        onMouseLeave={megaNavSetMenuCloseTime}
                                    >
                                        {menuItems.hasSubMenu && menuItems.subMenuNav && (
                                            <DesktopMenuSubModule
                                                categories={categories}
                                                menuItems={menuItems}
                                                redirectHref={redirectHref}
                                            />
                                        )}
                                    </div>
                                </div>
                            )
                        })}
                </div>
            </div>
        </div>
    )
}

const DesktopMenuSubModule = ({categories, menuItems, redirectHref}) => {
    return (
        <div className="sub-content-full">
            <div className="sub-content-wrap" style={menuItems.subMenuNav?.subMenuStyle}>
                {menuItems?.subMenuNav?.gridContent?.map((gridItems, index) => {
                    return (
                        <div
                            key={`gridContent-${index}`}
                            className="meganav-inner-content"
                            style={gridItems.gridHeight ? {height: gridItems.gridHeight} : {}}
                        >
                            <div
                                className="meganav-submenu-heading"
                                style={{
                                    ...gridItems.style?.desktop,
                                    ...categories.CommonStyles.subCategoryTitle?.desktop
                                }}
                            >
                                {' '}
                                {gridItems.subCategoryHeading}
                            </div>

                            {gridItems.subCategoryContent.map((subContent, index) => {
                                return (
                                    <div key={`subCategoryContent-${index}`}>
                                        {subContent.isImage ? (
                                            <div
                                                href={subContent.href}
                                                id={'sub-nav-menu-desktop-' + index}
                                                onClick={() => {
                                                    redirectHref(
                                                        subContent.href,
                                                        'sub-nav-menu-desktop-' + index
                                                    )
                                                }}
                                                style={{...subContent.style?.desktop}}
                                            >
                                                <picture>
                                                    <source
                                                        media="(max-width: 768px)"
                                                        srcSet={subContent.imageContent.mobile}
                                                    />
                                                    <source
                                                        media="(max-width: 1024px)"
                                                        srcSet={subContent.imageContent.mobile}
                                                    />
                                                    <img
                                                        alt={subContent.subCategoryTitle}
                                                        className="menu-image-alignment"
                                                        srcSet={subContent.imageContent.desktop}
                                                        title={subContent.subCategoryTitle}
                                                    ></img>
                                                </picture>
                                            </div>
                                        ) : (
                                            <div>
                                                {subContent.href ? (
                                                    <Link
                                                        className="meganav-submenu-content sub-content-sm"
                                                        id={'sub-nav-menu-desktop-' + index}
                                                        href={subContent.href}
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            redirectHref(
                                                                subContent.href,
                                                                'sub-nav-menu-desktop-' + index
                                                            )
                                                        }}
                                                        style={{...subContent.style?.desktop}}
                                                    >
                                                        {subContent.subCategoryTitle}
                                                    </Link>
                                                ) : (
                                                    <div
                                                        className="meganav-submenu-content sub-heading-sm"
                                                        id={'sub-nav-menu-desktop-' + index}
                                                        style={{...subContent.style?.desktop}}
                                                    >
                                                        {subContent.subCategoryTitle}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

//------------------- meganav mobile --------

const MobileMenuModule = ({
    categories,
    burgerStackToggle,
    hideSubMenu,
    redirectHref,
    pushNavInteraction,
    showParentMobile,
    showSubMenuNew,
    isError,
    parentRef,
    setOpenAccordion_id,
    openAccordion_id,
    pushNavEvents
}) => {
    return (
        <div className="meganav-mobile-menu-wrapper">
            <div
                ref={parentRef}
                className={`menu-mob parent overflow-active ${
                    burgerStackToggle ? 'menu-mob-active' : ''
                }`}
                id="menu-parent-wrap"
                onClick={(e) => pushNavEvents(e)}
            >
                {categories &&
                    categories.content &&
                    categories.content.parentNav &&
                    categories.content?.parentNav?.map((group, parentIndex) => {
                        return (
                            <div
                                key={`parentNav-${parentIndex}`}
                                className="menu-module"
                                id={`parent-${parentIndex}`}
                            >
                                <div
                                    className="menu-module-parent"
                                    onClick={() =>
                                        showSubMenuNew(
                                            `child-${parentIndex}`,
                                            group.hasSubMenu,
                                            group.href
                                        )
                                    }
                                >
                                    <div className="menu-text menu-text-normal">
                                        {group.parentTitle}{' '}
                                    </div>
                                    <div className="arrow-right"></div>
                                </div>

                                <div
                                    name="menu-mob-child"
                                    className="menu-mob child"
                                    id={`child-${parentIndex}`}
                                >
                                    <div
                                        className="back-to-parent-module"
                                        onClick={() => {
                                            hideSubMenu(`child-${parentIndex}`)
                                        }}
                                    >
                                        <div className="arrow-left"></div>
                                        <div className="back-to-parent-text menu-text-normal">
                                            {' '}
                                            See All Departments
                                        </div>
                                    </div>
                                    <Link
                                        href={group.href}
                                        className="menu-module-parent-home parent-no-border menu-text menu-text-bold"
                                        id={'parent-link-redirect-' + parentIndex}
                                        onClick={() =>
                                            redirectHref(
                                                group.href,
                                                'parent-link-redirect-' + parentIndex
                                            )
                                        }
                                    >
                                        {group.parentTitle + ' Home'}
                                        <div className="arrow-right"></div>
                                    </Link>
                                    <div
                                        name="accordion-menu-outer"
                                        className="accordian-container-menu"
                                    >
                                        <MobileMenuSubModule
                                            group={group}
                                            redirectHref={redirectHref}
                                            pushNavInteraction={pushNavInteraction}
                                            isError={isError}
                                            parentIndex={parentIndex}
                                            setOpenAccordion_id={setOpenAccordion_id}
                                            openAccordion_id={openAccordion_id}
                                        />

                                        <div style={{paddingBottom: '150px'}}></div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                {showParentMobile && (
                    <MenuServiceLinks
                        burgerStackToggle={burgerStackToggle}
                        categories={categories}
                        redirectHref={redirectHref}
                    />
                )}
            </div>
        </div>
    )
}
//------mobile sub menu ------------

const MobileMenuSubModule = ({
    group,
    redirectHref,
    pushNavInteraction,
    isError,
    parentIndex,
    setOpenAccordion_id,
    openAccordion_id
}) => {
    const toggleMobileAccordion = (partialIds) => {
        let parentEle = document.getElementById('item' + partialIds)
        let accordianElement = document.getElementById('accordion__header-item' + partialIds)
        if (parentEle && accordianElement) {
            setOpenAccordion_id([...openAccordion_id, partialIds])
            if (accordianElement.classList.contains('hide-accordion-items')) {
                accordianElement.classList.remove('hide-accordion-items')
                parentEle.classList.add('expanded')
            } else {
                accordianElement.classList.add('hide-accordion-items')
                parentEle.classList.remove('expanded')
            }
        }
    }

    return (
        <>
            {group.subMenuNav &&
                group.subMenuNav?.gridContent?.map((subContent, subContentIndex) => {
                    return (
                        <div
                            key={`subMenuNav-${subContentIndex}`}
                            className="accordion-wrapper-menu"
                        >
                            <div
                                id={'item-' + parentIndex + '-' + subContentIndex}
                                className=""
                                style={
                                    group.subMenuNav.gridContent?.length - 1 == subContentIndex
                                        ? {paddingBottom: '160px'}
                                        : {}
                                }
                            >
                                <div
                                    data-tabswitch-id="tab-description"
                                    className={`toggle-icon js-toggle pw-dropdown__header pw-dropdown__inner-header`}
                                    onClick={() => {
                                        toggleMobileAccordion(
                                            '-' + parentIndex + '-' + subContentIndex
                                        )
                                    }}
                                >
                                    <span>{subContent.subCategoryHeading}</span>
                                </div>

                                <div
                                    id={
                                        'accordion__header-item-' +
                                        parentIndex +
                                        '-' +
                                        subContentIndex
                                    }
                                    className={`hide-accordion-items accordion-item-content ${
                                        group.subMenuNav.gridContent?.length - 1 == subContentIndex
                                            ? 'extra-margin'
                                            : ''
                                    }`}
                                >
                                    {subContent.subCategoryContent.map(
                                        (innerSubModule, innerSubIndex) => {
                                            return (
                                                <div key={`subCategoryContent-${innerSubIndex}`}>
                                                    {innerSubModule.isImage ? (
                                                        <Link
                                                            href={innerSubModule.href}
                                                            id={
                                                                'menu-subcontent-link-' +
                                                                innerSubIndex
                                                            }
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                redirectHref(
                                                                    innerSubModule.href,
                                                                    'menu-subcontent-link-' +
                                                                        innerSubIndex
                                                                )
                                                            }}
                                                        >
                                                            <picture>
                                                                <source
                                                                    media="(max-width: 768px)"
                                                                    srcSet={
                                                                        innerSubModule.imageContent
                                                                            .mobile
                                                                    }
                                                                />
                                                                <source
                                                                    media="(max-width: 1024px)"
                                                                    srcSet={
                                                                        innerSubModule.imageContent
                                                                            .tablet
                                                                    }
                                                                />
                                                                <img
                                                                    alt={
                                                                        innerSubModule.subCategoryTitle
                                                                    }
                                                                    className="accordion-image"
                                                                    srcSet={
                                                                        innerSubModule.imageContent
                                                                            .desktop
                                                                    }
                                                                    title={
                                                                        innerSubModule.subCategoryTitle
                                                                    }
                                                                ></img>
                                                            </picture>
                                                        </Link>
                                                    ) : (
                                                        <div
                                                            id={
                                                                'menu-subcontent-link-' +
                                                                innerSubIndex
                                                            }
                                                        >
                                                            <Tablet>
                                                                <div className="accordion-menu-links">
                                                                    <Link
                                                                        className={`meganav-sub-text ${
                                                                            innerSubModule.href
                                                                                ? ''
                                                                                : 'sub-text-disabled'
                                                                        }`}
                                                                        href={innerSubModule.href}
                                                                        style={
                                                                            innerSubModule?.style
                                                                                ?.tablet
                                                                        }
                                                                        onClick={(e) => {
                                                                            e.preventDefault()
                                                                            if (
                                                                                innerSubModule.href
                                                                            ) {
                                                                                pushNavInteraction(
                                                                                    'menu-subcontent-link-' +
                                                                                        innerSubIndex
                                                                                )
                                                                                if (isError) {
                                                                                    window.location.replace(
                                                                                        innerSubModule.href
                                                                                    )
                                                                                } else {
                                                                                    redirectHref(
                                                                                        innerSubModule.href,
                                                                                        'menu-subcontent-link-' +
                                                                                            innerSubIndex
                                                                                    )
                                                                                }
                                                                            }
                                                                        }}
                                                                    >
                                                                        {
                                                                            innerSubModule.subCategoryTitle
                                                                        }
                                                                    </Link>
                                                                </div>
                                                            </Tablet>
                                                            <Mobile>
                                                                <div className="accordion-menu-links">
                                                                    <Link
                                                                        className={`meganav-sub-text ${
                                                                            innerSubModule.href
                                                                                ? ''
                                                                                : 'sub-text-disabled'
                                                                        }`}
                                                                        href={innerSubModule.href}
                                                                        style={
                                                                            innerSubModule?.style
                                                                                ?.mobile
                                                                        }
                                                                        onClick={(e) => {
                                                                            e.preventDefault()
                                                                            if (
                                                                                innerSubModule.href
                                                                            ) {
                                                                                pushNavInteraction(
                                                                                    'menu-subcontent-link-' +
                                                                                        innerSubIndex
                                                                                )
                                                                                if (isError) {
                                                                                    window.location.replace(
                                                                                        innerSubModule.href
                                                                                    )
                                                                                } else {
                                                                                    redirectHref(
                                                                                        innerSubModule.href,
                                                                                        'menu-subcontent-link-' +
                                                                                            innerSubIndex
                                                                                    )
                                                                                }
                                                                            }
                                                                        }}
                                                                    >
                                                                        {
                                                                            innerSubModule.subCategoryTitle
                                                                        }
                                                                    </Link>
                                                                </div>
                                                            </Mobile>
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        }
                                    )}
                                </div>
                            </div>
                        </div>
                    )
                })}
        </>
    )
}

//---------mobile menu service links----------

const MenuServiceLinks = ({burgerStackToggle, categories, redirectHref}) => {
    return (
        <div id="service-link" className="service-link-wrapper">
            {burgerStackToggle &&
                categories &&
                categories.content?.serviceLinks?.map((servicelink, index) => {
                    return (
                        <Link
                            key={`serviceLinks-${index}`}
                            id={'service-links-' + index}
                            onClick={(e) => {
                                e.preventDefault()
                                redirectHref(servicelink.href, 'service-links-' + index)
                            }}
                            className="service-links menu-text-normal"
                        >
                            {servicelink.title}
                        </Link>
                    )
                })}
        </div>
    )
}

export default NewMenu
