import React from 'react'

const PromotionalMessage = ({promoMessage, multiplePromo}) => {
    return promoMessage.c_calloutMsg ? (
        <div
            className={`${
                multiplePromo === true
                    ? 'promotional-message multiple_promotion'
                    : 'promotional-message '
            } `}
        >
            {promoMessage.c_calloutMsg}
        </div>
    ) : null
}

export default PromotionalMessage
