import React, {useReducer} from 'react'
import PropTypes from 'prop-types'

import GlobalState, {reducer} from '../global-state'
import {getConnector} from '../../connector'
import OneTrust from '../header/OneTrust'

const initialState = {
    cart: null,
    remove_cart: null,
    update_cart_details: null,
    products: {},
    paymentMethods: [],
    categoryProductList: {},
    categories: {},
    masterData: {},
    productDetails: {},
    allSlot: {},
    product: {},
    masterCategories: {},
    isBasketBusy:null,
    totalAmount: 0
}

/**
 * Use the AppConfig component to inject extra arguments into the getProps
 * methods for all Route Components in the app – typically you'd want to do this
 * to inject a connector instance that can be used in all Pages.
 *
 * You can also use the AppConfig to configure a state-management library such
 * as Redux, or Mobx, if you like.
 */
const AppConfig = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    return (
        <>
            <OneTrust />
            <GlobalState initialState={state} dispatch={dispatch}>
                {props.children}
            </GlobalState>
        </>
    )
}

AppConfig.restore = () => undefined

AppConfig.freeze = () => undefined

AppConfig.extraGetPropsArgs = () => {
    return {
        connector: getConnector()
    }
}

AppConfig.propTypes = {
    children: PropTypes.node
}

export default AppConfig
