import React from 'react'
import classNames from 'classnames'
import SkeletonBlock from 'progressive-web-sdk/dist/components/skeleton-block'
import Faceout from '../../../components/faceout'
import {Desktop, Tablet, Mobile, TabletOrHigher} from '../../../components/media-queries'

const Cat3 = ({className}) => {
    return (
        <div style={{marginTop: 40}} className="catlanding clp-page category-container lay-cat2">
            <div id="primary" className="primary-content">
                <div className="pt_storefront wrapper">
                    <div className={`content-width catlanding ${className}`}>
                        <div className="view-categories-container">
                            <SkeletonBlock className="view-categories" />
                            <SkeletonBlock className="view-categories-button" />
                        </div>
                        <SkeletonBlock className="banner-1"></SkeletonBlock>

                        <div className="feature-tile">
                            <div className="feature-tile-heading">
                                <SkeletonBlock width="50px" height="10px" />
                            </div>
                            <div className="feature-tile-wrap">
                                {[0, 1, 2, 3].map((item, i) => {
                                    return (
                                        <div
                                            className={classNames(
                                                'tile-2',
                                                {'tile-full': i === 2},
                                                {'tile-hide': i === 3}
                                            )}
                                        >
                                            <SkeletonBlock className="tile-2-image"></SkeletonBlock>
                                            <SkeletonBlock className="tile-2-text"></SkeletonBlock>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="feature-tile">
                            <div className="feature-tile-heading">
                                <SkeletonBlock width="50px" height="10px" />
                            </div>
                        </div>
                        <div className="faceout-wrap">
                            <Faceout
                                slidesPerView={{forLargeDesktop: 5, forDesktop: 4, forTablet: 3, forMobile: 2}}
                                reference={'2'}
                            />
                        </div>

                        {/* <div className="four-feature-tile">
                    <div className="feature-tile-heading">
                        <SkeletonBlock width="50px" height="10px" />
                    </div>
                    <div className="feature-tile-wrap">
                        {[0, 1, 2, 3].map(() => {
                            return (
                                <div className="tile-2">
                                    <SkeletonBlock className="tile-2-image"></SkeletonBlock>
                                    <SkeletonBlock className="tile-2-text"></SkeletonBlock>
                                </div>
                            )
                        })}
                    </div>
                </div> */}
                        {/* <div className="feature-tile">
                    <div className="feature-tile-heading">
                        <SkeletonBlock width="50px" height="10px" />
                    </div>
                    <div className="feature-tile-wrap">
                        {[0, 1, 2, 3].map((item, i) => {
                            return (
                                <div
                                    className={classNames(
                                        'tile-2',
                                        {'tile-full': i === 2},
                                        {'tile-hide': i === 3}
                                    )}
                                >
                                    <SkeletonBlock className="tile-2-image"></SkeletonBlock>
                                    <SkeletonBlock className="tile-2-text"></SkeletonBlock>
                                </div>
                            )
                        })}
                    </div>
                </div> */}
                        <div className="feature-tile">
                            <div className="feature-tile-heading">
                                <SkeletonBlock width="50px" height="10px" />
                            </div>
                            <div className="feature-tile-wrap">
                                {[0, 1, 2, 3].map((item, i) => {
                                    return (
                                        <>
                                            <Mobile>
                                                <div className={classNames('tile-6')}>
                                                    <SkeletonBlock className="tile-2-image"></SkeletonBlock>
                                                    <SkeletonBlock className="tile-2-text"></SkeletonBlock>
                                                </div>
                                            </Mobile>
                                            <TabletOrHigher>
                                                <div className={classNames('tile-2')}>
                                                    <SkeletonBlock className="tile-2-image"></SkeletonBlock>
                                                    <SkeletonBlock className="tile-2-text"></SkeletonBlock>
                                                </div>
                                            </TabletOrHigher>
                                        </>
                                    )
                                })}
                            </div>
                            <div className="feature-tile">
                                <div className="feature-tile-heading">
                                    <SkeletonBlock width="50px" height="10px" />
                                </div>
                                <SkeletonBlock height={300} className="tile-2-text"></SkeletonBlock>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cat3
