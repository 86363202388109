import {useOptanon} from './useOptanon'
import {initBloomreach} from '../components/_pwa-app/helpers'

export const useBloomreach = () => {
    const cookiesEnabled = useOptanon()
    const bloomreachEnabled = cookiesEnabled.targeting

    /**
     * Creates a Bloomreach product object from the given product data
     * @param {Object} product - the product data
     * @returns {Object} - a Bloomreach product object with the following properties:
     *   - product_id: string - the master product ID
     *   - variant_id: string - the top priority variant ID
     *   - title: string - the product name
     *   - categories: Object - the sorted categories object
     *   - categories_path: string - the concatenated categories path
     *   - discount_value: number - the discount value from the product prices
     *   - original_price: number - the original price from 'whsmith-gbp-listprices' or 0 if not available
     *   - category_id: string - the primary category ID
     *   - price: number - the product price per unit
     *   - price_local_currency: string - the price currency (always 'GBP' for now)
     *   - local_currency: string - the local currency (always 'GBP' for now)
     */
    const createBloomreachProduct = (product, includeCatIds) => {
        const categories = getSortedCategoriesFromProduct(product.c_catTree)
        const categoriesPath = Object.values(categories).join(' > ')
        const discountValue = getDiscountValue(product.prices)
        let brProduct = {
            product_id: product?.master?.master_id || product?.id || product.product_id,
            variant_id: product.c_topPriorityVariantId,
            title: product?.name || '',
            ...categories,
            categories_path: categoriesPath,
            discount_value: discountValue,
            original_price: (product?.prices && product?.prices['whsmith-gbp-listprices']) || 0,
            category_id: product.primary_category_id ?? product?.c_catTree?.item_category ?? '',
            price: product?.price || 0,
            price_local_currency: product?.price || 0,
            original_price_local_currency:
                (product?.prices && product?.prices['whsmith-gbp-listprices']) || 0,
            local_currency: 'GBP'
        }
        if (includeCatIds) {
            brProduct['categories_ids'] = JSON.stringify(Object.values(categories))
        }
        return brProduct
    }

    const getDiscountValue = (prices) => {
        if (!prices) {
            return 0
        }
        const listPrice = prices['whsmith-gbp-listprices'] ? prices['whsmith-gbp-listprices'] : 0
        const salePrice = prices['whsmith-gbp-saleprices']
            ? prices['whsmith-gbp-saleprices']
            : prices['whsmith-gbp-listprices']
        return (listPrice - salePrice).toFixed(2)
    }

    /**
     * Manipulates the c_cattree property of a product for use within bloomreach tracking
     * @param {Object} categories - the input object containing category keys
     * @returns {Object} a sorted object containing the categories
     */
    const getSortedCategoriesFromProduct = (categories) => {
        var transformedObj = {}
        if (!categories) {
            return transformedObj
        }
        var keys = categories ? Object.keys(categories) : []

        for (var i = 0; i < keys.length; i++) {
            var key = `category_level_${i + 1}`
            var value = categories[keys[keys.length - 1 - i]]
            transformedObj[key] = value
        }

        return transformedObj
    }

    /**
     * Returns a plain JS object representing the category hierarchy from the given category array
     * @param {Array<Object>} categoryArray - An array of category objects
     * @returns {Object} A plain JS object representing the category hierarchy
     */
    const getCatHeirarchyFromCategory = (categoryArray) => {
        const catArray = categoryArray.map((category, index) => {
            const key = `category_level_${index + 1}`
            return {
                [key]: category.name
            }
        })
        return catArray.reduce((result, obj) => {
            const [key, value] = Object.entries(obj)[0]
            return {...result, [key]: value}
        }, {})
    }

    /**
     * Returns the master ID of a product variant
     * @param {string} variantId - the ID of the product variant
     * @returns {string} the master ID of the product variant
     */
    const variantToMasterId = (variantId) => {
        return variantId.split('-')[0]
    }

    /**
     * Retrieves voucher details from price adjustments
     * @param {Array} priceAdjustments - Array of price adjustments
     * @returns {Object} - Object containing voucher details: voucherCode, voucherPercentage, and voucherValue
     */
    const getVoucherDetails = (priceAdjustments) => {
        let voucherDetails = {
            voucherCode: '',
            voucherPercentage: '',
            voucherValue: ''
        }

        if (priceAdjustments) {
            const firstVoucher = priceAdjustments.find((adjustment) =>
                adjustment.hasOwnProperty('coupon_code')
            )
            if (firstVoucher) {
                voucherDetails = {
                    voucherCode: firstVoucher.coupon_code,
                    voucherPercentage: firstVoucher.applied_discount?.percentage ?? 0,
                    // convert the negaive value to positive
                    voucherValue: Math.abs(firstVoucher.price)
                }
            }
        }
        return voucherDetails
    }

    return {
        bloomreachEnabled,
        async consentTracking(action, category, message, placement, email) {
            const response = await fetch('/getGeoDB')
            const data = await response.json()
            bloomreachEnabled &&
                window.exponea.track('consent', {
                    action,
                    category,
                    valid_until: 'unlimited',
                    ip: data.IPv4,
                    message,
                    location: window.location,
                    domain: window.location.hostname,
                    language: 'en',
                    timestamp: Date.now(),
                    placement,
                    email
                })
        },
        viewItem(product) {
            const data = createBloomreachProduct(product)
            bloomreachEnabled &&
                product &&
                initBloomreach().then(() => {
                    window.exponea.track('view_item', data)
                })
        },
        viewCategory(category, products, refinements, sortBy) {
            const catHierarchy = getCatHeirarchyFromCategory(category.parent_category_tree)
            const catPath = Object.values(catHierarchy).join(' > ')
            const formattedRefinements = refinements
                .map((refinement) => {
                    return `${refinement.type}=${refinement.label}`
                })
                .join(',')
            bloomreachEnabled &&
                category &&
                initBloomreach().then(() => {
                    window.exponea.track('view_category', {
                        category_id: category.id,
                        category_name: category.name,
                        category_listed_products: products.map((product) => product.product_id),
                        categories_path: catPath,
                        ...catHierarchy,
                        local_currency: 'GBP',
                        filter_by: formattedRefinements,
                        sort_by: sortBy
                    })
                })
        },
        cartUpdate(products, action, cartQtys = []) {
            //cart_update happens when an item/items are added or removed from the cart.
            products &&
                products.forEach((product) => {
                    // if cartQtys does not contain an item with ID = product.id then add it
                    if (action === 'add' && !cartQtys.find((item) => item.id === product.id)) {
                        cartQtys.push({
                            id: product.id,
                            qty: product.quantity
                        })
                    } else if (action === 'remove' || action === 'empty') {
                        // if cartQtys contains an item with ID = product.id then remove it
                        const index = cartQtys.findIndex((item) => item.id === product.id)
                        const qty = product.qty ? product.qty : 0
                        if (qty > 0 && index > -1) {
                            cartQtys[index].qty = qty
                        } else if (index > -1) {
                            cartQtys.splice(index, 1)
                        }
                    }

                    const productData = createBloomreachProduct(product, true)
                    bloomreachEnabled &&
                        window.exponea.track('cart_update', {
                            action: action,
                            ...productData,
                            product_list: cartQtys.map((product) => {
                                return {
                                    product_id: variantToMasterId(product.id),
                                    quantity: product.qty
                                }
                            }),
                            product_ids: cartQtys.map((product) => variantToMasterId(product.id))
                        })
                })
        },
        checkout(stepNumber, stepTitle, basket) {
            const voucherDetails = getVoucherDetails(basket.order_price_adjustments)
            const totalQuantity = basket.product_items.reduce((acc, product) => {
                return acc + product.quantity
            }, 0)
            bloomreachEnabled &&
                basket &&
                window.exponea.track('checkout', {
                    step_number: stepNumber,
                    step_title: stepTitle,
                    voucher_code: voucherDetails.voucherCode,
                    voucher_percentage: voucherDetails.voucherPercentage,
                    voucher_value: voucherDetails.voucherValue, // Amount subtracted by using a voucher
                    payment_type: basket.payment_instruments?.[0]?.payment_method_id ?? '',
                    shipping_type: basket.shipments?.[0]?.shipping_method?.name ?? '',
                    shipping_cost: basket.shipping_total,
                    shipping_country: basket.shipments?.[0]?.shipping_address?.country_code ?? '',
                    shipping_city: basket.shipments?.[0]?.shipping_address?.city ?? '',
                    local_currency: basket.currency ?? '',
                    product_list: basket.product_items.map((product) => {
                        return {
                            product_id: variantToMasterId(product.product_id),
                            quantity: product.quantity
                        }
                    }),
                    product_ids: basket.product_items.map((product) =>
                        variantToMasterId(product.product_id)
                    ),
                    variant_list: basket.product_items.map((product) => {
                        return {
                            product_id: product.product_id,
                            quantity: product.quantity
                        }
                    }),
                    variant_ids: basket.product_items.map((product) => product.product_id),
                    total_quantity: totalQuantity.toString(),
                    total_price: basket.order_total ?? '',
                    total_price_local_currency: 'GBP'
                })
        },
        identify(email) {
            bloomreachEnabled && window.exponea.identify(email)
        },
        anonymize() {
            bloomreachEnabled && window.exponea.anonymize()
        }
    }
}
