import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import Link from 'progressive-web-sdk/dist/components/link'
import {GlobalDispatchContext, SET_PRODUCT} from '../../components/global-state'

const MiniCartProduct = ({ masterId, name, srcSet, src, quantity, price, pageUrl, product, isError }) => {
    const dispatch = useContext(GlobalDispatchContext)
    return (
        <div className="mini-cart-product">
            <div className="mini-cart-image image-aligment" style={{height: '123px'}}>
                <img
                    className="image m-cmp_loaded lazyautosizes lazyloaded"
                    alt={name}
                    sizes="123px"
                    srcSet={srcSet}
                    src={src}
                />
                <meta itemProp="image" content={src} />
            </div>
            <div className="mini-cart-details">
                <div className="mini-cart-name">
                    <Link
                        className="name"
                        href={`/${pageUrl}/${masterId}.html`}
                        onClick={() => {
                            dispatch({type: SET_PRODUCT, payload: {product, masterProduct: product}})
                            if(isError){
                                window.location.replace(`/${pageUrl}/${masterId}.html`)
                            }
                        }}
                        title={name}
                    >
                        {name}
                    </Link>
                    <div className="js-delete-mini-cart-item mini-cart-delete">X</div>
                </div>
                <div className="mini-cart-data">
                    <div className="mini-cart-pricing">
                        <div className="mini-cart-qty">
                            <span className="label">Qty: </span>
                            <span className="value">
                                {''}
                                {quantity}
                            </span>
                        </div>
                        <span className="mini-cart-price">£{price ? price.toFixed(2) : 0}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

MiniCartProduct.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    srcSet: PropTypes.string,
    src: PropTypes.string,
    quantity: PropTypes.number,
    price: PropTypes.number
}

export default MiniCartProduct
