import React from 'react'
import PropTypes from 'prop-types'
import ProductTileTop from './../product-tile/productTileTop'
import ProductTileInfo from './../product-tile/productTileInfo'
import ProductTileBottom from './../product-tile/productTileBottom'
import ProductAvailability from '../product-tile/productAvailability'
import LazyLoad from '../lazyLoader-product'
import {useEinstein} from '../../hooks/useEinstein'

const ProductTilePro = ({
    key,
    cssClass,
    chartNumber,
    product,
    masterProduct,
    plpCssClass,
    position,
    queryString,
    category,
    categoryData,
    categoryName,
    faceoutTitle,
    showOnLazyLoad,
    isFaceout,
    index,
    isSearchListing,
    recommenderDetails,
    isEinsteinReco
}) => {
    //categoryData prop refers to the actual category which is used for GA4 and Einstein events
    //category={product.name} is the initial implementation for UA events which is used and shouldn't be modified as it is used in many places
    const einstein = useEinstein()
    const sendClickAction = () => {
        if (!isFaceout) {
            if (isSearchListing) {
                einstein.sendClickSearch(queryString, product)
            } else {
                einstein.sendClickCategory(categoryData, product)
            }
        }

        if (recommenderDetails) {
            einstein.sendClickReco(recommenderDetails, product)
        }
    }

    return product ? (
        <div className="product-tile-pro">
            <LazyLoad showContent={showOnLazyLoad}>
                <div
                    key={key}
                    className={`${cssClass} product-tile p-tile-pro`}
                    onClick={sendClickAction}
                >
                    {product && masterProduct?.id && (
                        <ProductTileTop
                            productName={product.name}
                            productImage={product.image_groups}
                            productImageSizes={product.c_images}
                            chartNumber={chartNumber}
                            cssClass={cssClass}
                            productId={masterProduct.id}
                            category={product.name}
                            categoryData={categoryData || category}
                            isFaceout={isFaceout}
                            product={product}
                            masterProduct={masterProduct}
                            position={position || index}
                            queryString={queryString}
                            categoryName={categoryName}
                            faceoutTitle={faceoutTitle}
                            isSearchListing={isSearchListing}
                            isEinsteinReco={isEinsteinReco}
                        />
                    )}

                    <ProductTileInfo
                        productInfo={product}
                        masterProductInfo={masterProduct}
                        cssClass={cssClass}
                        productId={masterProduct.id}
                        category={product.name}
                        categoryData={categoryData || category}
                        plpCssClass={plpCssClass}
                        isFaceout={isFaceout}
                        position={position || index}
                        queryString={queryString}
                        faceoutTitle={faceoutTitle}
                        isSearchListing={isSearchListing}
                        isEinsteinReco={isEinsteinReco}
                    />

                    {product && (
                        <>
                            <ProductAvailability pdtAvailability={product} />
                            <ProductTileBottom
                                productBottom={product}
                                masterProductInfo={masterProduct}
                                cssClass={cssClass}
                                plpCssClass={plpCssClass}
                                position={position || index}
                                isEinsteinReco={isEinsteinReco}
                            />
                        </>
                    )}
                </div>
            </LazyLoad>
        </div>
    ) : null
}

ProductTilePro.propTypes = {
    cssClass: PropTypes.string,
    product: PropTypes.object,
    chartNumber: PropTypes.any,
    dataId: PropTypes.any,
    masterProduct: PropTypes.object,
    plpCssClass: PropTypes.string,
    position: PropTypes.any,
    faceoutTitle: PropTypes.any,
    recommenderDetails: PropTypes.object,
    isEinsteinReco: PropTypes.bool
}

ProductTilePro.defaultProps = {
    masterProduct: {c_images: ''}
}

export default ProductTilePro
