// pwa/app/components/global-state/index.js
import React from 'react'
import PropTypes from 'prop-types'
import {
    SET_CART_ITEMS,
    SET_CART_QTYS,
    REMOVE_CART_ITEMS,
    UPDATE_CART_DETAILS,
    SET_PRODUCT_DETAILS,
    SET_PRODUCTS,
    UPDATE_PAYMENT_METHODS,
    SET_PARENT_CATEGORY,
    SET_CATEGORY_PRODUCT_DATA,
    SET_CATEGORY_PRODUCT_LIST,
    SET_CATEGORIES,
    SET_CATEGORY_MASTER_LIST,
    SET_PDP,
    SET_PRODUCT,
    SET_MASTER_CATEGORIES,
    STORE_PROPS,
    UPDATE_BUILDING_NAME,
    REFRESH_BASKET,
    BASKET_STATUS,
    SET_MENU_CATEGORIES,
    SET_HOMEPAGE_LAYOUT,
    GET_USERS_ORDERS,
    BASKET_BUSY,
    SET_TOTAL_AMOUNT,
    SET_EINSTEIN_ENABLED,
    SET_FREE_DEL_REMAINING,
    SET_RECOMMENDERS_ENABLED
} from './actionTypes'

// Set up global contexts
export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()
export {
    SET_CART_ITEMS,
    SET_CART_QTYS,
    REMOVE_CART_ITEMS,
    UPDATE_CART_DETAILS,
    SET_PRODUCT_DETAILS,
    SET_PRODUCTS,
    UPDATE_PAYMENT_METHODS,
    SET_PARENT_CATEGORY,
    SET_CATEGORY_PRODUCT_DATA,
    SET_CATEGORY_PRODUCT_LIST,
    SET_CATEGORIES,
    SET_CATEGORY_MASTER_LIST,
    SET_PDP,
    // SET_HOME_FACEOUT,
    SET_PRODUCT,
    SET_MASTER_CATEGORIES,
    STORE_PROPS,
    UPDATE_BUILDING_NAME,
    REFRESH_BASKET,
    BASKET_STATUS,
    SET_MENU_CATEGORIES,
    SET_HOMEPAGE_LAYOUT,
    GET_USERS_ORDERS,
    BASKET_BUSY,
    SET_TOTAL_AMOUNT,
    SET_EINSTEIN_ENABLED,
    SET_FREE_DEL_REMAINING,
    SET_RECOMMENDERS_ENABLED
}

export const reducer = (state, action) => {
    const {type, payload} = action

    switch (type) {
        case SET_FREE_DEL_REMAINING: {
            return {
                ...state,
                freeDelThresholdRemaining: payload
            }
        }
        case SET_RECOMMENDERS_ENABLED: {
            return {
                ...state,
                recommendersEnabled: payload
            }
        }
        case SET_EINSTEIN_ENABLED: {
            return {
                ...state,
                einsteinEnabled: payload
            }
        }
        case SET_CART_ITEMS: {
            return {
                ...state,
                cart: payload
            }
        }
        case SET_CART_QTYS: {
            return {
                ...state,
                cartQtys: payload
            }
        }
        case BASKET_BUSY: {
            return {
                ...state,
                isBasketBusy: payload
            }
        }
        case REMOVE_CART_ITEMS: {
            return {
                ...state,
                remove_cart: payload
            }
        }
        case UPDATE_CART_DETAILS: {
            return {
                ...state,
                update_cart_details: payload
            }
        }
        case SET_PRODUCT_DETAILS: {
            return {
                ...state,
                product_details: payload.product,
                master_product_details: payload.masterProduct
            }
        }
        case SET_PRODUCTS: {
            return {
                ...state,
                products: payload
            }
        }
        case SET_TOTAL_AMOUNT: {
            return {
                ...state,
                totalAmount: payload
            }
        }
        case UPDATE_PAYMENT_METHODS: {
            return {
                ...state,
                paymentMethods: payload
            }
        }
        case SET_PARENT_CATEGORY: {
            return {
                ...state,
                parentCategory: payload
            }
        }
        case SET_CATEGORY_PRODUCT_DATA: {
            return {
                ...state,
                categoryProductData: {
                    ...state.categoryProductData,
                    ...payload
                }
            }
        }
        case SET_CATEGORY_PRODUCT_LIST: {
            return {
                ...state,
                categoryProductList: {
                    ...state.categoryProductList,
                    ...payload
                }
            }
        }
        case GET_USERS_ORDERS: {
            if (payload) {
                return {
                    ...state,
                    orders: {
                        ...state.orders,
                        ...payload
                    }
                }
            }
            return {
                ...state,
                orders: null
            }
        }
        case SET_CATEGORIES: {
            return {
                ...state,
                categories: {
                    ...state.categories,
                    ...payload
                }
            }
        }
        case SET_MENU_CATEGORIES: {
            return {
                ...state,
                menuCategories: payload
            }
        }
        case SET_CATEGORY_MASTER_LIST: {
            return {
                ...state,
                masterData: {
                    ...state.masterData,
                    ...payload
                }
            }
        }
        case SET_PDP: {
            return {
                ...state,
                productDetails: {
                    ...state.productDetails,
                    ...payload
                }
            }
        }
        case SET_PRODUCT: {
            return {
                ...state,
                product: payload
            }
        }
        case SET_MASTER_CATEGORIES: {
            return {
                ...state,
                masterCategories: {
                    ...payload
                }
            }
        }
        case SET_HOMEPAGE_LAYOUT: {
            return {
                ...state,
                homepageTemplate: payload
            }
        }
        case STORE_PROPS: {
            return {
                ...state,
                ...payload
            }
        }
        case UPDATE_BUILDING_NAME: {
            return {
                ...state,
                ...payload
            }
        }
        case REFRESH_BASKET: {
            return {
                ...state,
                ...payload
            }
        }
        case BASKET_STATUS: {
            return {
                ...state,
                ...payload
            }
        }

        // Add more here!
        default:
            return state
    }
}

function GlobalState(props) {
    const {initialState, dispatch} = props
    return (
        <GlobalStateContext.Provider value={initialState}>
            <GlobalDispatchContext.Provider value={dispatch}>
                {props.children}
            </GlobalDispatchContext.Provider>
        </GlobalStateContext.Provider>
    )
}

GlobalState.propTypes = {
    // The state returned from setting up the reducer using the React Hook `useReducer`.
    initialState: PropTypes.object.isRequired,

    // The dispatch function returned from setting up the reducer using the React Hook `useReducer`.
    dispatch: PropTypes.func.isRequired,

    children: PropTypes.node
}

export default GlobalState
