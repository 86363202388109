import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {useEinstein} from '../../hooks/useEinstein'
import ProductTabItem from '../product-tab/productTabItem'
import Faceout from '../faceout/index'
import {isBrowser} from '../../utils/utils'
import {DEFAULT_SLIDES_PER_VIEW, MODAL_SLIDES_PER_VIEW} from '../../utils/constants'
import SkeletonBlock from 'progressive-web-sdk/dist/components/skeleton-block'

const EinsteinRecommender = ({recommenders, title, viewMoreText, viewMoreLink, isInModal, isBasketPage}) => {
    const slidesPerView = isInModal ? MODAL_SLIDES_PER_VIEW : DEFAULT_SLIDES_PER_VIEW
    const categoryId = recommenders[0]?.catIds?.[0] ?? ''
    const einstein = useEinstein()
    const [isSelectedTab, setIsSelectedTab] = useState(0)
    const [recommendations, setRecommendations] = useState([])
    const [selectedTabNames, setSelectedTabNames] = useState([])
    const getTabNavigation = (pos) => {
        setIsSelectedTab(pos)
    }

    useEffect(() => {
        if (isBrowser() && recommenders) {
            einstein.getZoneRecommendations(recommenders)
        }
    }, [einstein.einsteinEnabled, recommenders])

    useEffect(() => {
        if (einstein.recommendations?.length) {
            const newRecommendations = [...einstein.recommendations]
            //this is to prevent the tab selection being reset on basket page after a product is added to the basket from the slider
            if (!isBasketPage || (isBasketPage && einstein.recommendations.length === 1)) {
                setIsSelectedTab(0)
            }
            einstein.recommendations.forEach((recommender) => {
                if (!recommender.recs?.length) return
                const index = newRecommendations.findIndex(
                    (item) => item.recommenderName === recommender.recommenderName
                )
                if (index > -1) {
                    newRecommendations[index] = recommender
                } else {
                    newRecommendations.push(recommender)
                }
            })
            setRecommendations(newRecommendations)
            setSelectedTabNames([])
        }
    }, [einstein.einsteinEnabled, einstein.recommendations])

    useEffect(() => {
        if (recommendations.length) {
            const recommender = recommendations[isSelectedTab]

            if (selectedTabNames.includes(recommender.recommenderName)) return
            const ids = recommender.recs.map((rec) => {
                const masterId = rec.master?.master_id ?? rec.ean ?? rec.id.split('-')[0]
                const productSku = rec.id
                return {
                    id: masterId,
                    sku: productSku
                }
            })

            const recommData = {
                recommenderName: recommender.recommenderName,
                __recoUUID: recommender.recoUUID
            }

            setSelectedTabNames((prevSelectedTabNames) => {
                const newSelectedTabNames = [...prevSelectedTabNames]
                const index = newSelectedTabNames.findIndex(
                    (item) => item === recommender.recommenderName
                )
                if (index > -1) {
                    newSelectedTabNames[index] = recommender.recommenderName
                } else {
                    newSelectedTabNames.push(recommender.recommenderName)
                }
                return newSelectedTabNames
            })

            einstein.sendViewReco(recommData, ids)
        }
    }, [recommendations, isSelectedTab, selectedTabNames])

    const tabNavigation = recommendations?.map((item, index) => {
        return (
            <label
                key={`recomm-tab-${index}`}
                htmlFor={`recomm-tab-${index}`}
                className={`tabs-control ${isSelectedTab === index ? 'active' : ''}`}
            >
                {' '}
                {isSelectedTab === index ? (
                    <h3>{item.displayMessage}</h3>
                ) : (
                    <h4>{item.displayMessage}</h4>
                )}{' '}
            </label>
        )
    })

    const tabItemContent =
        recommendations?.length &&
        recommendations?.map((item, index) => {
            const ids = item.recs.map((rec) => ({id: rec?.id || rec?.productId}))
            const recommData = {
                recommenderName: item.recommenderName,
                __recoUUID: item.recoUUID
            }

            return recommendations.length > 1 ? (
                <ProductTabItem
                    key={`tabitem-${index}`}
                    tabId={`recomm-tab-${index}`}
                    tabName="recommendations"
                    handleTabClick={() => getTabNavigation(index)}
                    isTabSelected={isSelectedTab === index ? true : false}
                    tabTitle={title ?? item.displayMessage}
                >
                    <Faceout
                        dataIds={ids}
                        data={item.recs}
                        categoryId={categoryId}
                        slidesPerView={slidesPerView}
                        reference={'1'}
                        index={index}
                        key={index}
                        recommenderDetails={recommData}
                        isEinsteinReco={true}
                    />
                </ProductTabItem>
            ) : (
                <Faceout
                    dataIds={ids}
                    data={item.recs}
                    categoryId={categoryId}
                    faceoutTitle={title ?? item.displayMessage}
                    faceoutLink={viewMoreLink}
                    faceoutLinkText={viewMoreText}
                    slidesPerView={slidesPerView}
                    reference={'1'}
                    index={index}
                    key={index}
                    recommenderDetails={recommData}
                    isEinsteinReco={true}
                />
            )
        })
    return recommendations?.length > 0 ? (
        <div className="einstein-recommender">
            <div data-cmp="recommendations" id="recommendations" className="m-cmp_loaded">
                <div data-cmp="recommendation-accordion" className="m-cmp_loaded einstein-tabs">
                    <div className="einstein-tabs__accordion" data-cmp="productRecommendations">
                        {recommendations?.length > 1 ? (
                            <div className="einstein-tabs__accordion">
                                <div className="einstein-tabs__navigation">{tabNavigation}</div>
                                {tabItemContent}
                            </div>
                        ) : (
                            <>{tabItemContent}</>
                        )}
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <>{einstein.isLoading ? <SkeletonBlock width="100%" height="400px" /> : null}</>
    )
}

EinsteinRecommender.propTypes = {
    recommenders: PropTypes.array,
    categoryId: PropTypes.string,
    title: PropTypes.string,
    viewMoreText: PropTypes.string,
    viewMoreLink: PropTypes.string,
    isInModal: PropTypes.bool
}

export default EinsteinRecommender
