import React, {useContext, useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import {getAssetUrl} from 'progressive-web-sdk/dist/ssr/universal/utils'
import Icon from 'progressive-web-sdk/dist/components/icon'
import Button from 'progressive-web-sdk/dist/components/button'
import {useHistory} from 'react-router-dom'
import {
    GlobalStateContext,
    GlobalDispatchContext,
    REFRESH_BASKET,
    UPDATE_CART_DETAILS,
    SET_TOTAL_AMOUNT
} from '../global-state'
import CartProduct from '../../pages/my-basket/cartProduct'
import EinsteinRecommender from '../einstein/einsteinRecommender'
import {generateEinsteinRecommender, getSitePreference} from '../../utils/utils'
import {EINSTEIN_RECOMMENDATION_ZONES} from '../../utils/constants'
import {getBasket, reqRemoveItemBasket} from '../../pages/my-basket/helper'
import {getConnector} from '../../connector'
import {getFromLocal} from '../../utils/storage-utils'
import {useBloomreach} from '../../hooks/useBloomreach'

const AddedToBasketModal = ({product, isOpen, setIsOpen}) => {
    // functionality for view basket button
    const history = useHistory()
    const [recommender, setRecommender] = useState([])
    const bloomreach = useBloomreach()

    const goToCart = () => {
        history.push('/shopping-basket')
        setIsOpen(false)
    }

    // used for free shipping messaging
    const globalState = useContext(GlobalStateContext)
    const [messageEnabled, setMessageEnabled] = useState(false)
    useEffect(() => {
        ;(async () => {
            const atbFreeShippingMessageEnabled = await getSitePreference(
                'atbFreeShippingMessageEnabled',
                'Einstein'
            )
            setMessageEnabled(atbFreeShippingMessageEnabled)
        })()
    }, [globalState.freeDelThresholdRemaining])

    useEffect(() => {
        if (Object.keys(product).length) {
            const productId = product?.master?.master_id || product?.id
            const category =
                product?.primary_category_id ||
                product?.c_primary_category_id ||
                product?.c_parentCategoryTree?.id ||
                ''
            const recommender = generateEinsteinRecommender(
                EINSTEIN_RECOMMENDATION_ZONES.ADD_TO_BASKET_MODAL,
                [productId],
                [category]
            )
            setRecommender([recommender])
        }
    }, [product])

    // close modal on route change
    useEffect(() => {
        const unlisten = history.listen(() => {
            setIsOpen(false)
        })

        return () => {
            unlisten() // Clean up the listener when the component unmounts
        }
    }, [history])

    // calculate quantity
    const [quantity, setQuantity] = useState(1)
    useEffect(() => {
        const cartQtyItem = globalState.cartQtys?.find((item) => item.id === product.id)
        const cartQty = cartQtyItem ? cartQtyItem.qty : 0
        setQuantity(cartQty)
    }, [globalState.cartQtys])

    // remove from basket functionality
    const dispatch = useContext(GlobalDispatchContext)
    const connector = getConnector()
    const basketId = getFromLocal('basket_id')

    const removeItem = async () => {
        const basket = await getBasket(connector, basketId)
        const itemId = basket.product_items.find((item) => item.product_id === product.id).item_id
        const updatedBasket = await reqRemoveItemBasket(basketId, itemId, dispatch, history)
        dispatch({
            type: REFRESH_BASKET,
            payload: {
                shouldBasketRefresh: 'yes'
            }
        })

        dispatch({
            type: UPDATE_CART_DETAILS,
            payload: {
                basketDetails: basket
            }
        })

        dispatch({
            type: SET_TOTAL_AMOUNT,
            payload: updatedBasket.product_total
        })
        dispatch({
            type: 'SET_CART_QTYS',
            payload: updatedBasket['product_items']
                ? updatedBasket['product_items'].map((item) => {
                      return {
                          id: item.product_id,
                          qty: item.quantity
                      }
                  })
                : []
        })
        setIsOpen(false)
        const action = updatedBasket.product_items ? 'remove' : 'empty'
        bloomreach.cartUpdate([product], action, globalState.cartQtys)
    }

    const [isCartError, setIsCartError] = useState(false)

    Modal.setAppElement('body')

    return (
        <>
            <Modal
                isOpen={isOpen}
                shouldCloseOnOverlayClick={true}
                className="atb-modal"
                onRequestClose={() => setIsOpen(false)}
            >
                <div className="atb-modal__header">
                    <div className="atb-modal__header-text">
                        <img
                            alt="Tick"
                            src={getAssetUrl('static/svg/sprite-source/done-green.svg')}
                            title="Tick"
                            width={'30px'}
                            height={'30px'}
                        />
                        <span className="atb-modal__header-title" role="heading" aria-level="2">
                            Added to your basket
                        </span>
                    </div>
                    <div
                        onClick={() => setIsOpen(false)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                setIsOpen(false)
                            }
                        }}
                        tabIndex={0}
                        className="atb-modal__header-close"
                        role="button"
                        aria-label="Close"
                    >
                        <Icon name="close" size="large"></Icon>
                    </div>
                </div>
                <div className="atb-modal__body">
                    <div className="atb-modal__body-product">
                        <CartProduct
                            product={product}
                            setIsCartError={setIsCartError}
                            isAtbModal={true}
                            quantity={quantity}
                            removeProduct={removeItem}
                            cartQtys={globalState.cartQtys}
                        />
                    </div>
                    <div className="atb-modal__body-details">
                        {messageEnabled && (
                            <div className="atb-modal__body-dlvry-msg">
                                <svg className="icon-shipping icon-set">
                                    <use xlinkHref="#icon-set_1">
                                        <svg id="icon-set_1" viewBox="0 0 40 29">
                                            <path
                                                id="ic"
                                                className="cls-1"
                                                d="M529.547 667.229h-5.455V660h-25.455a3.638 3.638 0 0 0-3.637 3.614V683.5h3.637a5.455 5.455 0 0 0 10.909.134v-.134h10.909a5.455 5.455 0 0 0 10.909 0H535v-9.039zm-.91 2.711l3.564 4.519h-8.109v-4.519h4.545zm-24.546 15.365a1.808 1.808 0 1 1 1.818-1.807 1.817 1.817 0 0 1-1.818 1.807zm4.036-5.423a5.412 5.412 0 0 0-7.608-.462 5.335 5.335 0 0 0-.465.462h-1.417v-16.268h21.818v16.268h-12.328zm17.783 5.423a1.808 1.808 0 1 1 1.818-1.807 1.816 1.816 0 0 1-1.818 1.807z"
                                                transform="translate(-495 -660)"
                                            ></path>
                                        </svg>
                                    </use>
                                </svg>
                                {globalState.freeDelThresholdRemaining > 0 ? (
                                    <div className="bskt-msg--01" id="bm_01">
                                        Spend{' '}
                                        <span className="bskt-amnt">
                                            £{globalState.freeDelThresholdRemaining.toString()}
                                        </span>{' '}
                                        more for <span className="go-free">free</span> Standard
                                        Delivery
                                    </div>
                                ) : (
                                    <div
                                        className="bskt-msg--02"
                                        id="bm_02"
                                        style={{display: 'block'}}
                                    >
                                        <strong>Congratulations!</strong> You’ve qualified for{' '}
                                        <span className="go-free">free</span> Standard Delivery
                                    </div>
                                )}
                            </div>
                        )}
                        <div className="atb-modal__body-actions">
                            <Button
                                aria-label="View Basket"
                                className="atb-modal__body-actions-button viewBasket"
                                onClick={goToCart}
                            >
                                View Basket
                            </Button>
                            <Button
                                aria-label="Continue Shopping"
                                className="atb-modal__body-actions-button continue"
                                onClick={() => setIsOpen(false)}
                            >
                                Continue Shopping
                            </Button>
                        </div>
                    </div>
                </div>
                {!isCartError && (
                    <div className="atb-modal__footer">
                        <EinsteinRecommender recommenders={recommender} isInModal={true} />
                    </div>
                )}
            </Modal>
        </>
    )
}

AddedToBasketModal.propTypes = {
    product: PropTypes.object,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func
}

export default AddedToBasketModal
