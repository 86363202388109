import React from 'react'
import {Helmet} from 'react-helmet'
import Link from 'progressive-web-sdk/dist/components/link'
import {getAssetUrl} from 'progressive-web-sdk/dist/ssr/universal/utils'

import ErrorFooter from './error-footer'

const Error410 = () => {
    return (
        <>          
        <Helmet
                htmlAttributes={{
                    lang: "en-US",
                    "data-reactroot": ""
                }}
            >
                    <title>Sites-whsmith-Site</title>
                    <meta name="description" content='WHSmith Page Not Found' />
                    <meta
                        property="og:image"
                        content={getAssetUrl('static/img/errors/error-404-bg-mobile.jpg')}
                    />
                    <meta property="og:site_name" content="WHSmith" />
                    <meta property="og:title" content='Sites-whsmith-Site' />
                    <meta property="og:description" content='WHSmith Page Not Found' />
                    <meta name="keywords" itemProp="keywords" content=" WHSmith" />
                    <link
                        rel="image_src"
                        href={getAssetUrl('static/img/errors/error-404-bg-mobile.jpg')}
                    />
                </Helmet>
        <div className="error-wrapper m-404">
            <div className="error-background not-found">
                <picture>
                    <source
                        media="(max-width: 768px)"
                        srcSet={getAssetUrl('static/img/errors/error-404-bg-mobile.jpg')}
                    />
                    <source
                        media="(min-width: 1620px) and (max-width: 1620px)"
                        srcSet={getAssetUrl('static/img/errors/error-404-bg-mobile.jpg')}
                    />
                    <img
                        alt="error background"
                        srcSet={getAssetUrl('static/img/errors/error-404-bg.jpg')}
                        title="error background"
                    />
                </picture>
            </div>
            <div className="error-message error-message-top">
                <div className="not-found-logo">
                    <a href="/" title="WHSmith Home" className="header-logo-link">
                        <img
                            alt="WHSmith Home"
                            srcSet={getAssetUrl('static/img/errors/logo.svg')}
                            className="site-error-logo"
                        />
                        <span className="visually-hidden">WHSmith</span>
                    </a>
                </div>
                <div className="error-message-body">
                    <h1 className="error-message-title">Page Was Gone</h1>
                    <p className="error-message-text">
                        Sorry, we can&apos;t find that page or something has gone wrong...
                    </p>
                </div>
            </div>
            <ErrorFooter />
            </div>
            </>
    )
}

export default Error410
