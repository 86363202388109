export const getMasterProduct = async (connector, productId) => {
    let responseData = await fetch(
        `${connector.client.basePath}/products/${productId}?expand=availability,prices,images,variations,promotions`,
        {
            headers: {
                ...connector.client.defaultHeaders,
                'content-type': 'application/json'
            },
            method: 'GET'
        }
    )
    return responseData.json()
}

export const getProduct = async (connector, productId) => {
    let responseData = await fetch(
        `${connector.client.basePath}/products/${productId}?expand=availability,prices,images,variations,promotions`,
        {
            headers: {
                ...connector.client.defaultHeaders,
                'content-type': 'application/json'
            },
            method: 'GET'
        }
    )

    let response = await responseData.json()
    let variantId = response.variants[0].product_id

    let responseVariantData = await fetch(
        `${connector.client.basePath}/products/${variantId}?expand=availability,prices,images,variations,promotions`,
        {
            headers: {
                ...connector.client.defaultHeaders,
                'content-type': 'application/json'
            },
            method: 'GET'
        }
    )

    return responseVariantData.json()
    //return responseData.json()
}
