import React from 'react'
import classNames from 'classnames'
import SkeletonBlock from 'progressive-web-sdk/dist/components/skeleton-block'
import {
    Desktop,
    Tablet,
    Mobile,
    TabletOrHigher,
    TabletOrSmaller
} from '../../../components/media-queries'
import Faceout from '../../../components/faceout'

const Cat4 = ({className}) => {
    return (
        <div style={{marginTop: 40}} className="catlanding clp-page category-container lay-cat4">
            <div id="primary" className="primary-content">
                <div className="pt_storefront wrapper">
                    <div className={`content-width catlanding ${className}`}>
                        <div className="view-categories-container">
                            <SkeletonBlock className="view-categories" />
                            <SkeletonBlock className="view-categories-button" />
                        </div>
                        <SkeletonBlock className="banner-1"></SkeletonBlock>

                        <div className="tile-wrap">
                            {[0, 1, 2, 3, 4, 5, 6, 7].map(() => {
                                return <SkeletonBlock className="tile-1"></SkeletonBlock>
                            })}
                        </div>
                        <TabletOrHigher>
                            <div className="feature-tile">
                                <div className="feature-tile-heading">
                                    <SkeletonBlock width="50px" height="10px" />
                                </div>
                                <div className="feature-tile-wrap">
                                    <div className="tile-3-container">
                                        <Desktop>
                                            <SkeletonBlock className="tile-3"></SkeletonBlock>
                                        </Desktop>

                                        <div className="tile-3-half-container">
                                            <Tablet>
                                                <SkeletonBlock className="tile-3-image-type"></SkeletonBlock>
                                            </Tablet>
                                            <SkeletonBlock className="tile-3-half"></SkeletonBlock>
                                            <div className="tile-4-container">
                                                {[0, 1].map((item, i) => (
                                                    <>
                                                        <Mobile>
                                                            <div className="tile-4">
                                                                <SkeletonBlock className="tile-4-image"></SkeletonBlock>
                                                                <SkeletonBlock className="tile-4-text"></SkeletonBlock>
                                                            </div>
                                                        </Mobile>
                                                        <Desktop>
                                                            <div className="tile-4">
                                                                <SkeletonBlock className="tile-4-image"></SkeletonBlock>
                                                                <SkeletonBlock className="tile-4-text"></SkeletonBlock>
                                                            </div>
                                                        </Desktop>
                                                        <Tablet>
                                                            <div className="tile-4">
                                                                <SkeletonBlock className="tile-4-image"></SkeletonBlock>
                                                                <SkeletonBlock className="tile-4-text"></SkeletonBlock>
                                                            </div>
                                                        </Tablet>
                                                    </>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabletOrHigher>
                        <Mobile>
                            <div className="feature-tile">
                                <div className="feature-tile-heading">
                                    <SkeletonBlock width="50px" height="10px" />
                                </div>
                                {[0, 1, 2, 3].map((item, i) => (
                                    <div className="feature-tile-wrap">
                                        <div className={classNames('tile-6')}>
                                            <SkeletonBlock className="tile-2-image"></SkeletonBlock>
                                            <SkeletonBlock className="tile-2-text"></SkeletonBlock>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Mobile>

                        <div className="feature-tile">
                            <div className="feature-tile-heading">
                                <SkeletonBlock width="50px" height="10px" />
                            </div>
                        </div>

                        <div className="faceout-wrap">
                            <Faceout
                                slidesPerView={{forLargeDesktop: 5, forDesktop: 4, forTablet: 3, forMobile: 2}}
                                reference={'2'}
                            />
                        </div>
                        <div className="four-feature-tile">
                            <div className="feature-tile-heading">
                                <SkeletonBlock width="50px" height="10px" />
                            </div>
                            <div className="feature-tile-wrap">
                                {[0, 1, 2, 3].map(() => {
                                    return (
                                        <div className="tile-2">
                                            <SkeletonBlock className="tile-2-image"></SkeletonBlock>
                                            <SkeletonBlock className="tile-2-text"></SkeletonBlock>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="feature-tile">
                            <div className="feature-tile-heading">
                                <SkeletonBlock width="50px" height="10px" />
                            </div>
                            <div className="feature-tile-wrap">
                                {[0, 1, 2, 3].map((item, i) => {
                                    return (
                                        <>
                                            <Desktop>
                                                <div className={classNames('tile-2')}>
                                                    <SkeletonBlock className="tile-2-image"></SkeletonBlock>
                                                    <SkeletonBlock className="tile-2-text"></SkeletonBlock>
                                                </div>
                                            </Desktop>
                                            <Mobile>
                                                <div className={classNames('tile-6')}>
                                                    <SkeletonBlock className="tile-2-image"></SkeletonBlock>
                                                    <SkeletonBlock className="tile-2-text"></SkeletonBlock>
                                                </div>
                                            </Mobile>

                                            <Tablet>
                                                <div className={classNames('tile-2')}>
                                                    <SkeletonBlock className="tile-2-image"></SkeletonBlock>
                                                    <SkeletonBlock className="tile-2-text"></SkeletonBlock>
                                                </div>
                                            </Tablet>
                                        </>
                                    )
                                })}
                            </div>
                            <div className="feature-tile">
                                <div className="feature-tile-heading">
                                    <SkeletonBlock width="50px" height="10px" />
                                </div>
                                <SkeletonBlock height={300} className="tile-2-text"></SkeletonBlock>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cat4
