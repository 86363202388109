import fetch from 'cross-fetch'

export const fetchRedirectDetails = async (connector, currentLocation) => {
    try {
        if (currentLocation !== 'undefined') {
            let bodyForFetchRedirect = {
                currentLocation
            }
            let responseData = await fetch(`${process.env.HOSTNAME}/fetch-redirect-details`, {
                headers: {
                    ...connector.client.defaultHeaders,
                    'content-type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(bodyForFetchRedirect)
            })
            const redirectData = await responseData.json()
            if (Object.keys(redirectData).length == 0) {
                return false
            }
            return redirectData?.to_url
        }
        return false
    } catch (error) {
        console.error('Error fetching categories!!getMenuCategories', error)
    }
}


export const createQueryUrl = (query = {}, search, isSearchListing, isRefinement, removeFilter) => {
    let queryParams = new URLSearchParams(isRefinement ? query : search)

    if (isSearchListing) {
        let newQueryParams = new URLSearchParams(search)
        newQueryParams.has('q') && queryParams.set('q', newQueryParams.get('q'))
        newQueryParams.has('cgid') && queryParams.set('cgid', newQueryParams.get('cgid'))
        newQueryParams.has('category') &&
            queryParams.set('category', newQueryParams.get('category'))
        newQueryParams.has('pmid') && queryParams.set('pmid', newQueryParams.get('pmid'))
        newQueryParams.has('pheader') && queryParams.set('pheader', newQueryParams.get('pheader'))
    }

    Object.keys(query).forEach((key) => {
        Array.isArray(query[key])
            ? query[key].length > 1
                ? queryParams.set(key, query[key].join('|'))
                : queryParams.set(key, query[key][0])
            : queryParams.set(key, query[key])
    })

    removeFilter && queryParams?.has(removeFilter) && queryParams?.delete(removeFilter)
    return queryParams.toString()
}

export const createApiQueryUrl = (query = {}, search) => {
    let queryParams = new URLSearchParams(search)
    queryParams.has('category') && queryParams.delete('category')
    Object.keys(query).forEach((key) => {
        Array.isArray(query[key])
            ? query[key].length > 1
                ? queryParams.set(key, query[key].join('|'))
                : queryParams.set(key, query[key][0])
            : queryParams.set(key, query[key])
    })
    let refinments = []
    let deleteArray = []
    if (queryParams.has('sz')) {
        queryParams.set('count', queryParams.get('sz'))
        queryParams.delete('sz')
    }
    if (queryParams.has('srule')) {
        queryParams.set('sort', queryParams.get('srule'))
        queryParams.delete('srule')
    }
    if (queryParams.has('pmid') && queryParams.has('cgid')) {
        queryParams.delete('cgid')
    }
    queryParams.forEach((_, key) => {
        if (key !== 'start' && key !== 'count' && key !== 'sort' && key !== 'q') {
            if (
                /utm_.*/.test(key) ||
                key === 'obem' ||
                key === 'bc_lcid' ||
                key === 'awc' ||
                key === 'fbclid' ||
                key === 'gclid' ||
                key === 'ds_s_' ||
                key === 'retry' ||
                key === 'p' ||
                (key !== 'cgid' &&
                    key !== 'price' &&
                    key !== 'brand' &&
                    key !== 'pmid' &&
                    !/^c_.*/.test(key))
            ) {
                deleteArray.push(key)
            } else {
                refinments.push(key)
            }
        }
    })
    refinments.forEach((value, key) => {
        queryParams.set(`refine_${key + 1}`, `${value}=${queryParams.get(value)}`)
        queryParams.delete(value)
    })
    deleteArray.forEach((value) => {
        queryParams.delete(value)
    })
    return queryParams.toString()
}
export function validURL(str) {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}
