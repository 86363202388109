export const SET_CART_ITEMS = 'SET_CART_ITEMS'
export const SET_CART_QTYS = 'SET_CART_QTYS'
export const REMOVE_CART_ITEMS = 'REMOVE_CART_ITEMS'
export const UPDATE_CART_DETAILS = 'UPDATE_CART_DETAILS'
export const SET_PRODUCT_DETAILS = 'SET_PRODUCT_DETAILS'
export const SET_PRODUCTS = 'SET_PRODUCTS'
export const UPDATE_PAYMENT_METHODS = 'UPDATE_PAYMENT_METHODS'
export const SET_PARENT_CATEGORY = 'SET_PARENT_CATEGORY'
export const SET_CATEGORY_PRODUCT_LIST = 'SET_CATEGORY_PRODUCT_LIST'
export const SET_CATEGORY_PRODUCT_DATA = 'SET_CATEGORY_PRODUCT_DATA'
export const SET_CATEGORIES = 'SET_CATEGORIES'
export const SET_MENU_CATEGORIES = 'SET_MENU_CATEGORIES'
export const SET_CATEGORY_MASTER_LIST = 'SET_CATEGORY_MASTER_LIST'
export const SET_PDP = 'SET_PDP'
export const SET_PRODUCT = 'SET_PRODUCT'
export const SET_MASTER_CATEGORIES = 'SET_MASTER_CATEGORIES'
export const STORE_PROPS = 'STORE_PROPS'
export const SET_STORE_LOCATION = 'SET_STORE_LOCATION'
export const UPDATE_BUILDING_NAME = 'UPDATE_BUILDING_NAME'
export const REFRESH_BASKET = 'REFRESH_BASKET'
export const BASKET_STATUS = 'BASKET_STATUS'
export const SET_HOMEPAGE_LAYOUT = 'SET_HOMEPAGE_LAYOUT'
export const GET_USERS_ORDERS = 'GET_USERS_ORDERS'
export const BASKET_BUSY = 'BASKET_BUSY'
export const SET_TOTAL_AMOUNT = 'SET_TOTAL_AMOUNT'
export const SET_EINSTEIN_ENABLED = 'SET_EINSTEIN_ENABLED'
export const SET_FREE_DEL_REMAINING = 'SET_FREE_DEL_REMAINING'
export const SET_RECOMMENDERS_ENABLED = 'SET_RECOMMENDERS_ENABLED'
