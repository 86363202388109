import React, {useState, useEffect, useContext, useRef} from 'react'
import PropTypes from 'prop-types'
import {useLocation, useHistory} from 'react-router-dom'
import {
    createBasket,
    getBasket,
    reqAddItemsToBasket,
    reqAddQuantityToBasket
} from '../../pages/my-basket/helper'
import {
    GlobalStateContext,
    GlobalDispatchContext,
    SET_CART_ITEMS,
    REMOVE_CART_ITEMS,
    UPDATE_CART_DETAILS,
    REFRESH_BASKET,
    BASKET_BUSY,
    SET_TOTAL_AMOUNT
} from '../global-state'
import {getFromLocal, storeInLocal, clearUserStorage} from '../../utils/storage-utils'
import MiniCartProduct from './miniCartProduct'
import {isBrowser, getImageSrcFromSrcSet} from '../../utils/utils'
import {getProducts} from '../faceout/helper'
import {getConnector} from '../../connector'
import Link from 'progressive-web-sdk/dist/components/link'
import {addInteractionErrorEvent} from '../../utils/gtmUtils'
import {useBloomreach} from '../../hooks/useBloomreach'

const MiniBasket = ({
    showBasket,
    updateBadgeTotal,
    showBasketMessage,
    clearMiniBasketTimer,
    showMiniBasket,
    setShowMiniBasket,
    removePadding,
    setShowLoader,
    isError
}) => {
    const globalState = useContext(GlobalStateContext)
    const dispatch = useContext(GlobalDispatchContext)
    const history = useHistory()
    const [basketData, setBasketData] = useState([])
    const [productIds, setProductIds] = useState([])
    const [subTotal, setSubTotal] = useState(0.0)
    const [basketDetails, setBasketDetails] = useState({})
    const [promoMessage, setPromoMessage] = useState('')
    const [reverseBasket, setReverseBasket] = useState([])
    const [total, setTotal] = useState(0)
    const connector = getConnector()
    const basketTimerRef = useRef(0)
    const location = useLocation()
    const bloomreach = useBloomreach()

    useEffect(() => {
        const basketId = getFromLocal('basket_id')
        if (basketId && basketId !== 'undefined') {
            getBasketDetails(basketId)
        } else updateBadgeTotal(0)
    }, [])

    useEffect(() => {
        if (globalState?.shouldBasketRefresh === 'yes') {
            const basketId = getFromLocal('basket_id')
            if (basketId && basketId !== 'undefined') {
                getBasketDetails(basketId)
            } else updateBadgeTotal(0)
            if (location.pathname.includes('/shopping-basket')) {
                dispatch({
                    type: REFRESH_BASKET,
                    payload: {shouldBasketRefresh: 'myBasket'}
                })
            } else {
                dispatch({
                    type: REFRESH_BASKET,
                    payload: {shouldBasketRefresh: 'no'}
                })
            }
        }
    }, [globalState?.shouldBasketRefresh])

    const calculateBasketTotalAmount = () => {
        if (
            basketDetails &&
            basketDetails.product_items &&
            basketDetails.product_items.length > 0
        ) {
            //find subtotal
            let price = 0
            basketDetails.product_items.map((item) => {
                price = price + item.price
            })
            price ? setSubTotal(price) : setSubTotal(0)
            dispatch({type: SET_TOTAL_AMOUNT, payload: basketDetails.product_total || 0})
            //find total
            basketDetails.product_total ? setTotal(basketDetails.product_total) : setTotal(0)
            dispatch({
                type: 'SET_CART_QTYS',
                payload: basketDetails['product_items'].map((item) => {
                    return {
                        id: item.product_id,
                        qty: item.quantity
                    }
                })
            })
        }
    }

    useEffect(() => {
        calculateBasketTotalAmount()
    }, [basketDetails])

    useEffect(() => {
        updateBadgeTotal(productIds && productIds.length ? total : 0)
        if (basketData) {
            // Scroll to top
            if (isBrowser() && window && window.innerWidth > 767) {
                window.scrollTo(0, window.scrollY - 1)
                // document.body.scrollTop = 0 // For Safari
                // document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
            }

            const analyticBasket = {
                cartValue: Object.keys(basketDetails).length > 0 ? basketDetails.product_total : 0,
                cartItemsNum:
                    productIds && productIds.length ? productIds.reduce((a, b) => a + b.qty, 0) : 0,
                cartUniqueItemsNum: productIds.length
            }
            if (typeof window !== 'undefined') {
                storeInLocal('analyticBasket', JSON.stringify(analyticBasket))
            }
        }
    }, [productIds, globalState.cartQtys])

    useEffect(() => {
        ;(async () => {
            if (globalState.cart !== null) {
                addToCart()
            }
            if (globalState.remove_cart !== null) {
                updateOrRemoveFromCart()
            }
            if (globalState.update_cart_details !== null) {
                if (globalState.update_cart_details.basketDetails === null) {
                    setBasketDetails({})
                    setBasketData([])
                    setProductIds([])
                    updateBadgeTotal(0)
                }
                setBasketDetails({...globalState.update_cart_details.basketDetails})
                dispatch({type: UPDATE_CART_DETAILS, payload: null})
            }
        })()
    }, [globalState])

    useEffect(() => {
        ;(async () => {
            // if (basketDetails && Object.keys(basketDetails).length === 0) {
            //     updateBadgeCount(0)
            // }
            if (basketDetails && Object.keys(basketDetails).length > 0) {
                if (basketDetails.product_items && basketDetails.product_items.length > 0) {
                    setProductIds([
                        ...basketDetails.product_items.reduce((result, product) => {
                            result.push({id: product.product_id, qty: product.quantity})
                            return result
                        }, [])
                    ])
                } else setProductIds([])
            }
            setSubTotal(
                basketDetails && basketDetails.product_total ? basketDetails.product_total : 0
            )
        })()
    }, [basketDetails])

    useEffect(() => {
        let reverseBaskDet = basketDetails?.product_items?.reverse()
        setReverseBasket(reverseBaskDet)
    }, [basketDetails])

    const getBasketDetails = async (basketID) => {
        if (basketID && getFromLocal('isBasketBeingRefreshed') !== 'yes') {
            let basketResponse = await getBasket(connector, basketID)
            if (basketResponse.error || basketResponse.fault) {
                if (basketResponse && basketResponse.fault) {
                    if (
                        basketResponse.fault?.type === 'BasketNotFoundException' ||
                        basketResponse.fault?.type === 'InvalidCustomerException'
                    ) {
                        clearUserStorage(dispatch)
                        dispatch({type: UPDATE_CART_DETAILS, payload: {basketDetails: null}})

                        if (basketResponse.fault?.type === 'InvalidCustomerException') {
                            history.push({pathname: '/login/'})
                        }
                    }
                }
            } else {
                setBasketDetails({...basketResponse})
                return basketResponse
            }
        }
    }

    useEffect(() => {
        ;(async () => {
            if (productIds && productIds.length > 0 && productIds.length !== basketData.length) {
                let newIds = []
                productIds &&
                    productIds.map((e) => {
                        newIds.push(e.id)
                    })
                fetchProductsDetails(newIds)
            }
        })()
    }, [productIds])

    const fetchProductsDetails = async (productIds) => {
        getProducts(connector, productIds).then((response) => {
            let data = response.data
            setBasketData(data)
        })
    }

    useEffect(() => {
        basketData && basketData.length > 0 && calculatePromoValue(basketData)
    }, [basketData, subTotal])

    useEffect(() => {
        setShowMiniBasket(showBasket)
        if (showBasket) {
            let timer = setTimeout(() => {
                setShowMiniBasket(false)
            }, 3000)
            // setBasketTimer(timer)
            basketTimerRef.current = timer
        }
    }, [showBasket])

    const addToCart = async () => {
        let productDetails = globalState.cart
        let ids = []
        dispatch({type: SET_CART_ITEMS, payload: null})
        let basketId = getFromLocal('basket_id')
        let basketDetails = await getBasket(connector, basketId)
        if (basketDetails && Object.keys(basketDetails).length > 0) {
            if (basketDetails.product_items && basketDetails.product_items.length > 0) {
                basketDetails.product_items.map((product) => {
                    ids.push({id: product.product_id, qty: product.quantity})
                })
            }
            setBasketDetails({...basketDetails})
        }
        //let data = basketDetails
        let idToAdd = productDetails.id
        if (productDetails.subscribedPlan) {
            idToAdd =
                productDetails.subscribedPlan && productDetails.subscribedPlan.productId
                    ? productDetails.subscribedPlan.productId
                    : idToAdd
        }
        let qty = productDetails && productDetails.qty ? parseInt(productDetails.qty) : 1
        let productInCart = ids.reduce((result, {id}, index) => {
            if (id === idToAdd) {
                let oldQty = ids[index].qty
                ids[index].qty += qty
                qty = oldQty + qty
                result = true
            }
            return result
        }, false)
        if (!basketId && !basketDetails) {
            ids.push({id: idToAdd, qty})
            setProductIds([...ids])
            addItemsToBasket([{quantity: qty, ...productDetails}])
        } else if (productInCart) {
            setProductIds([...ids])
            addQuantityToBasket({quantity: qty, product_id: productDetails.id})
        } else {
            let cartObj = {
                id: idToAdd,
                qty
            }
            addItemsToBasket([{quantity: cartObj.qty, ...productDetails}])
        }

        let timer = setTimeout(() => {
            setShowMiniBasket(false)
        }, 3000)
        // setBasketTimer(timer)
        basketTimerRef.current = timer
    }

    const updateOrRemoveFromCart = () => {
        let productDetails = globalState.remove_cart
        dispatch({type: REMOVE_CART_ITEMS, payload: null})
        let data = productDetails.data
        getBasket(connector, getFromLocal('basket_id')).then((response) => {
            setProductIds([
                ...response.product_items.map((item) => ({
                    id: item.product_id,
                    qty: item.quantity
                }))
            ])
            setBasketData([...data])
        })
    }

    const addItemsToBasket = async (data) => {
        const basketID = getFromLocal('basket_id')
        if (basketID) {
            const productDataToAdd = data.map((item) => {
                return {
                    product_id: item.id,
                    quantity: item.quantity
                }
            })
            reqAddItemsToBasket(connector, basketID, productDataToAdd)
                .then((response) => {
                    if (response.fault) {
                        ;(async () => {
                            let basketResponse = await createBasket(connector, {
                                productItems: productDataToAdd
                            })
                            setShowLoader(false)
                            dispatch({type: BASKET_BUSY, payload: 'no'})
                            setShowMiniBasket(false)
                            basketResponse.basket_id &&
                                basketResponse.basket_id !== 'undefined' &&
                                storeInLocal('basket_id', basketResponse.basket_id)
                            setBasketDetails({...basketResponse})
                        })()
                    } else {
                        dispatch({type: BASKET_BUSY, payload: 'no'})
                        setShowLoader(false)
                        setShowMiniBasket(false)
                        setBasketDetails({...response})
                    }
                })
                .catch((e) => {
                    addInteractionErrorEvent(
                        'interaction_error',
                        'Add to Basket Fail',
                        'Basket',
                        basketID,
                        '404',
                        'mini-basket'
                    )
                    dispatch({type: BASKET_BUSY, payload: 'no'})
                    console.error('addItemsToBasket catch', e)
                })
        } else {
            ;(async () => {
                let basketResponse = await createBasket(connector, {
                    productItems: data
                })
                setShowLoader(false)
                dispatch({type: BASKET_BUSY, payload: 'no'})
                basketResponse.basket_id &&
                    basketResponse.basket_id !== 'undefined' &&
                    storeInLocal('basket_id', basketResponse.basket_id)
                setBasketDetails({...basketResponse})
            })()
        }
    }

    const addQuantityToBasket = async (data) => {
        const basketID = getFromLocal('basket_id')
        getBasket(connector, basketID).then((resp) => {
            const matchedItem =
                resp && resp?.product_items?.find((e) => e.product_id === data.product_id)

            let itemID = ''
            if (matchedItem) {
                itemID = matchedItem.item_id
            }
            if (basketID) {
                reqAddQuantityToBasket(basketID, itemID, data)
                    .then((response) => {
                        if (!response.fault) {
                            dispatch({type: BASKET_BUSY, payload: 'no'})
                            setShowLoader(false)
                            setShowMiniBasket(false)
                            setBasketDetails({...response})
                        }
                    })
                    .catch((e) => {
                        addInteractionErrorEvent(
                            'interaction_error',
                            'Add to Basket Fail',
                            'Basket',
                            basketID,
                            '404',
                            'mini-basket'
                        )
                        dispatch({type: BASKET_BUSY, payload: 'no'})
                        console.error('addQuantityToBasket ......', e)
                    })
            }
        })
    }

    const getSrcSet = (data) => {
        let product = {}
        if (basketData) {
            product = basketData.find(
                (e) => e.id === data.product_id || e.name === data.product_name
            )
        }
        return product && product.c_images && product.c_images[0]
            ? product.c_images[0].srcset
            : product && product.images && product.images.link
            ? product.images.link
            : ''
    }

    const getMasterId = (productId) => {
        if (basketData) {
            let data = basketData.find((e) => e.id === productId)
            if (data && data.master && data.master.master_id) {
                return data.master.master_id
            } else {
                return null
            }
        } else {
            return null
        }
    }

    const getPageUrl = (productId) => {
        if (basketData) {
            let data = basketData?.find((e) => e.id === productId)
            if (data && data.c_page_url) {
                return data.c_page_url
            } else {
                return null
            }
        } else {
            return null
        }
    }

    const keepMiniBasketOpen = () => {
        clearMiniBasketTimer()
        if (basketTimerRef.current) {
            clearTimeout(basketTimerRef.current)
            basketTimerRef.current = 0
        }
        setShowLoader(false)
        setShowMiniBasket(true)
        removePadding()
    }
    const closeMiniBasket = () => {
        setShowMiniBasket(false)
    }

    const calculatePromoValue = (total) => {
        //total is basketData here, which is the Product Details

        var freeAmount = 0
        let remdiscValue = 0
        let freeSaverPromo = {}
        if (total && total.length > 0) {
            total.map((item) => {
                let matchedItem = total.find((e) => e.id === item.id)
                if (matchedItem) {
                    if (Object.keys(matchedItem.c_promotionCustomAttributes).length > 0) {
                        for (
                            let i = 0;
                            i < Object.keys(matchedItem.c_promotionCustomAttributes).length;
                            i++
                        ) {
                            const item = Object.keys(matchedItem.c_promotionCustomAttributes)[i]
                            if (
                                matchedItem.c_promotionCustomAttributes[item].c_class ===
                                    'SHIPPING' &&
                                matchedItem.c_promotionCustomAttributes[item].c_threshold &&
                                matchedItem.c_promotionCustomAttributes[item].c_basketCallout
                            ) {
                                freeSaverPromo = matchedItem.c_promotionCustomAttributes[item]
                            }
                        }
                    }
                    if (freeSaverPromo) {
                        freeAmount = freeSaverPromo.c_threshold
                    } else {
                        setPromoMessage('')
                    }
                } else {
                    setPromoMessage('')
                }
            })
        } else {
            setPromoMessage('')
        }
        if (freeAmount > 0 && Object.values(basketDetails).length > 0) {
            if (subTotal >= 0 && subTotal < freeAmount) {
                remdiscValue = (freeAmount - subTotal).toFixed(2)
                dispatch({
                    type: 'SET_FREE_DEL_REMAINING',
                    payload: remdiscValue
                })
                setPromoMessage(
                    <div className="bskt-msg--01" id="bm_01" style={{display: 'block'}}>
                        Spend <span className="bskt-amnt">£{remdiscValue}</span> more for{' '}
                        <span className="go-free">free</span> Standard Delivery
                    </div>
                )
                showBasketMessage('')
            } else {
                dispatch({
                    type: 'SET_FREE_DEL_REMAINING',
                    payload: 0
                })
                setPromoMessage(
                    <div className="bskt-msg--02" id="bm_02" style={{display: 'block'}}>
                        <strong>Congratulations!</strong> You’ve qualified for{' '}
                        <span className="go-free">free</span> Standard Delivery
                    </div>
                )
                showBasketMessage('FREE Delivery')
            }
        }
    }

    const getURL = (c_h1Tag) => {
        if (c_h1Tag) {
            let urlStr = c_h1Tag.replace(/%|\./g, '').toLowerCase()
            return urlStr
        }
    }

    return (
        <div
            className={`mini-cart-content ${
                showMiniBasket && basketData && basketData.length > 0 ? '' : 'collapsed'
            } is-mobile`}
            onMouseEnter={keepMiniBasketOpen}
            onMouseLeave={closeMiniBasket}
        >
            {reverseBasket?.length > 0 && (
                <div className="mini-cart-wrap">
                    <div className="mini-cart-header">My Basket</div>
                    <div className="mini-cart-products">
                        {reverseBasket?.length > 0 &&
                            reverseBasket?.map((data) => {
                                let srcSet = getSrcSet(data)
                                let src = getImageSrcFromSrcSet(srcSet)
                                let pageUrl = getPageUrl(data.product_id)
                                let masterId = getMasterId(data.product_id)
                                return (
                                    <MiniCartProduct
                                        key={`product_${data.product_id}`}
                                        id={data.product_id}
                                        masterId={masterId}
                                        name={data.product_name}
                                        srcSet={srcSet}
                                        src={src}
                                        quantity={data.quantity}
                                        price={data.price}
                                        pageUrl={getURL(pageUrl)}
                                        product={basketData?.find(
                                            (el) =>
                                                el.master.master_id.toLowerCase() ===
                                                masterId?.toLowerCase()
                                        )}
                                        isError={isError}
                                    />
                                )
                            })}
                    </div>
                    <div className="mini-cart-totals">
                        <div className="mini-cart-subtotals">
                            <span className="label">Order Subtotal</span>
                            <span className="value">£{subTotal.toFixed(2)}</span>
                        </div>
                        <div className="mini-cart-slot"></div>
                        {promoMessage && (
                            <div className="bskt-dlvry-msg">
                                <svg className="icon-shipping icon-set">
                                    <use xlinkHref="#icon-set_1">
                                        <svg id="icon-set_1" viewBox="0 0 40 29">
                                            <path
                                                id="ic"
                                                className="cls-1"
                                                d="M529.547 667.229h-5.455V660h-25.455a3.638 3.638 0 0 0-3.637 3.614V683.5h3.637a5.455 5.455 0 0 0 10.909.134v-.134h10.909a5.455 5.455 0 0 0 10.909 0H535v-9.039zm-.91 2.711l3.564 4.519h-8.109v-4.519h4.545zm-24.546 15.365a1.808 1.808 0 1 1 1.818-1.807 1.817 1.817 0 0 1-1.818 1.807zm4.036-5.423a5.412 5.412 0 0 0-7.608-.462 5.335 5.335 0 0 0-.465.462h-1.417v-16.268h21.818v16.268h-12.328zm17.783 5.423a1.808 1.808 0 1 1 1.818-1.807 1.816 1.816 0 0 1-1.818 1.807z"
                                                transform="translate(-495 -660)"
                                            ></path>
                                        </svg>
                                    </use>
                                </svg>
                                {promoMessage}
                            </div>
                        )}
                        <div className="mini-cart-link-cart">
                            <Link
                                className="btn-blue btn-wide"
                                href="/shopping-basket/"
                                title="Go to Cart"
                                onClick={() => {
                                    setShowMiniBasket(false)
                                    if (isError) {
                                        window.location.replace('/shopping-basket/')
                                    }
                                }}
                            >
                                Edit basket and checkout
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

MiniBasket.propTypes = {
    showBasket: PropTypes.bool,
    updateBadgeTotal: PropTypes.func,
    showBasketMessage: PropTypes.func,
    clearMiniBasketTimer: PropTypes.func,
    showMiniBasket: PropTypes.bool,
    setShowMiniBasket: PropTypes.func,
    removePadding: PropTypes.func,
    setShowLoader: PropTypes.func
}

export default MiniBasket
