import fetch from 'cross-fetch'
import {addInteractionErrorEvent} from '../../utils/gtmUtils'

export const getMasterProduct = async (connector, productId) => {
    try {
        let responseData = await fetch(
            `${connector.client.basePath}/products/${productId}?expand=availability,prices,images,variations,promotions`,
            {
                headers: {
                    ...connector.client.defaultHeaders,
                    'content-type': 'application/json'
                },
                method: 'GET'
            }
        )
        return responseData.json()
    } catch (error) {
        addInteractionErrorEvent(
            'interaction_error',
            'Get Master Product Error',
            'Product',
            productId,
            '404',
            'faceout'
        )
        console.error('Error fetching product details!!')
    }
}

export const getProduct = async (connector, productId) => {
    try {
        let responseData = await fetch(
            `${process.env.HOSTNAME}/get-products?productIds=${productId}`,
            {
                headers: {
                    ...connector.client.defaultHeaders,
                    'content-type': 'application/json'
                },
                method: 'GET'
            }
        )

        let response = await responseData.json()
        if (response?.master?.master_id !== productId) return null
        if (response.variants !== undefined && response.variants.length > 0) {
            let variantIds = response.variants.map((item) => {
                return item.product_id
            })

            let variantDetails = await getProducts(connector, variantIds).then(
                (response) => response.data
            )

            let requiredProduct = []
            if (variantDetails) {
                requiredProduct = callSupplierPrecedence(variantDetails)
            }

            const productWithVariationData = requiredProduct
            return {...productWithVariationData, primary_category_id: response.primary_category_id}
        }
    } catch (error) {
        addInteractionErrorEvent(
            'interaction_error',
            'Get Product Error',
            'Product',
            productId,
            '404',
            'faceout'
        )
        console.error('Error fetching product details!!')
    }
}

const callSupplierPrecedence = (variantDetails) => {
    try {
        let supplier = {}
        variantDetails.map((variant) => {
            supplier[parseInt(variant.c_supplierPrecedence)] = variant
        })

        let representedPdt = []
        let i = 0
        let supplierKeys = Object.keys(supplier)
        supplierKeys.sort((a, b) => a - b)
        while (i < supplierKeys.length) {
            let record = supplier[supplierKeys[i]]
            if (record.inventory && record.inventory.orderable) {
                if (record.inventory.stock_level > 0) {
                    representedPdt = supplier[supplierKeys[i]]
                    break
                } else if (
                    record.inventory.ats > 0 &&
                    (record.inventory.backorderable || record.inventory.preorderable)
                ) {
                    representedPdt = supplier[supplierKeys[i]]
                    break
                }
            }
            i++
        }
        return representedPdt
    } catch (error) {
        console.error('Error fetching product details!!')
    }
}

export const getProducts = async (connector, productIds) => {
    if (productIds) {
        try {
            const params = productIds && productIds.join(',')
            let responseData = await fetch(
                `${process.env.HOSTNAME}/get-products?productIds=(${params})`,
                {
                    headers: {
                        ...connector.client.defaultHeaders,
                        'content-type': 'application/json'
                    },
                    method: 'GET'
                }
            )

            let response = await responseData.json()
            return response
        } catch (error) {
            console.error('Error fetching product details!!', error)
        }
    } else {
        console.error('Error fetching product details!!')
    }
}

export const getProductsFromCategory = async (connector, categoryId, count) => {
    try {
        let response = await fetch(
            `${process.env.HOSTNAME}/product-search?&count=${count}&refine_1=cgid=${categoryId}`,
            {
                headers: {
                    ...connector.client.defaultHeaders
                },
                method: 'GET'
            }
        )
        response = await response.json()
        if (response.fault) {
            throw response.fault
        }
        return response
    } catch (e) {
        console.error('error', e)
        throw e
    }
}

export const getShippingMethods = async (connector, productId) => {
    return new Promise((resolve, reject) => {
        fetch(`/shipping-methods?productId=${productId}`, {
            headers: {
                'content-type': 'application/json'
            },
            method: 'GET'
        })
            .then(async (response) => {
                const data = await response.json()
                resolve(data)
            })
            .catch((err) => {
                addInteractionErrorEvent(
                    'interaction_error',
                    'Get Shipping Methods Error',
                    'Product',
                    productId,
                    '404',
                    'faceout'
                )
                console.error(err)
                reject(err)
            })
    })
}
export const getPromotions = async (connector, promotionId) => {
    try {
        let responseData = await fetch(`${connector.client.basePath}/promotions/(${promotionId})`, {
            headers: {
                ...connector.client.defaultHeaders,
                'content-type': 'application/json'
            },
            method: 'GET'
        })

        let response = await responseData.json()

        return response
    } catch (error) {
        addInteractionErrorEvent(
            'interaction_error',
            'Get Promotion Error',
            'Promotion',
            promotionId,
            '404',
            'faceout'
        )
        console.error('Error fetching promotions!!')
    }
}

export const getMetaFallbackRules = async (connector) => {
    let response = await fetch(`${process.env.HOSTNAME}/fetch-meta-fallback`, {
        headers: {
            ...connector.client.defaultHeaders,
            'content-type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({})
    })
    let response_json = await response.json()
    let site_values_response_data = response_json?.hits[0]?.site_values?.whsmith
    let parsedResponse = JSON.parse(site_values_response_data)
    return parsedResponse
}
