import React, {useContext} from 'react'
import PropTypes from 'prop-types'
// import NewHomepage from './Skeleton/newHomepage'
import Homepage from './Skeleton/homepage'
import Cat1 from './Skeleton/cat1'
import Cat2 from './Skeleton/cat3'
// import Cat3 from './Skeleton/cat3'
import Cat4 from './Skeleton/cat4'
import Offers from './Skeleton/offers'
import {useLocation} from 'react-router'
import ProductListSkeleton from '../product-list/ProductListSkeleton'
import {GlobalStateContext} from '../../components/global-state'
import NewHomePageVariant1 from './Skeleton/newHomepageVariant1'
import NewHomePageVariant2 from './Skeleton/newHomepageVariant2'

const PageTemplateSkeleton = ({layout, skeletonAvailable, isError}) => {
    const location = useLocation()
    const globalState = useContext(GlobalStateContext)
    if (typeof globalState?.menuCategories === 'undefined' && !isError) {
        return <div />
    }

    const className = 'category-landing-skeleton'
    // if (location.pathname === '/') {
    //     return <Homepage className={className} />
    // }
    if (layout && layout?.includes('rendering')) {
        return (
            <ProductListSkeleton
                list={[...new Array(parseInt(process.env.PRODUCT_SEARCH_COUNT || 12))]}
            />
        )
    }
    switch (layout) {
        case 'homepage':
            return <Homepage className={className} />
        case 'lay-new-homepage-ver1':
        case 'new-homepage-ver1':
            return <NewHomePageVariant1 className={className} />
        case 'lay-new-homepage-ver2':
        case 'new-homepage-ver2':
            return <NewHomePageVariant2 className={className} />
        case 'cat1':
            return <Cat1 className={className} />
        case 'cat2':
            return <Cat2 className={className} />
        case 'cat4':
            return <Cat4 className={className} />
        case 'offers':
            return <Offers className={className} />
        default:
            if (skeletonAvailable) {
                return <Homepage className={className} />
            }
            return (
                <ProductListSkeleton
                    list={[...new Array(parseInt(process.env.PRODUCT_SEARCH_COUNT || 12))]}
                />
            )
    }
}

PageTemplateSkeleton.propTypes = {
    pageStructure: PropTypes.object,
    allSlotsData: PropTypes.any,
    title: PropTypes.string,
    meta: PropTypes.object
}

export default PageTemplateSkeleton
