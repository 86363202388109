import React from 'react'
import Icon from 'progressive-web-sdk/dist/components/icon'
import {v4 as uuidv4} from 'uuid'

const InStock = () => {
    return (
        <div className="in-stock-msg">
            <svg className="icon-in-stock">
                <Icon id={uuidv4()} name="available_in-stock" />
            </svg>
            <p>In Stock</p>
        </div>
    )
}

export default InStock
