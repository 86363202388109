import fetch from 'cross-fetch'
import {getFromLocal, storeInLocal, getCookie, clearUserStorage} from '../../utils/storage-utils'
import {cancelOrder, addNote} from '../checkout-payment/helper'
import {addInteractionErrorEvent} from '../../utils/gtmUtils'

export const promoMessages = [
    {
        message: '3 For 2',
        actualQuantity: 3,
        applicableQuantity: 2
    }
]

//deleting basket
export const deleteBasket = async (connector, basket_id) => {
    return new Promise((resolve, reject) => {
        fetch('/ocapi-fetch', {
            body: JSON.stringify({
                headers: {
                    'content-type': 'application/json'
                },
                method: 'DELETE',
                url: `/baskets/${basket_id}`
            }),
            method: 'POST',
            credentials: 'include'
        })
            .then((response) => {
                resolve(response.json())
            })
            .catch((err) => {
                addInteractionErrorEvent(
                    'interaction_error',
                    'Delete Basket Error',
                    'Basket',
                    basket_id,
                    '404',
                    'basket-page'
                )
                console.error(err)
                reject(err)
            })
    })
}

// Geting all the basket related to coustomer
export const getAllBasketsforCustomer = async (customer_id) => {
    return new Promise((resolve, reject) => {
        fetch('/ocapi-fetch', {
            body: JSON.stringify({
                headers: {
                    'content-type': 'application/json'
                },
                method: 'GET',
                url: `/customers/${customer_id}/baskets`
            }),
            method: 'POST',
            credentials: 'include'
        })
            .then((response) => {
                resolve(response.json())
            })
            .catch((err) => {
                addInteractionErrorEvent(
                    'interaction_error',
                    'Get Basket Error',
                    'Customer',
                    customer_id,
                    '404',
                    'basket-page'
                )
                console.error(err)
                reject(err)
            })
    })
}

export const createBasket = async (connector, options) => {
    const {productItems} = options

    const body = {
        product_items: productItems.map((product) => {
            return {
                product_id: product.id,
                quantity: product.quantity
            }
        })
    }
    return new Promise((resolve, reject) => {
        ;(async () => {
            const customerId = getCookie('customerId')
            let basket =
                customerId && customerId !== 'undefined' && customerId !== null
                    ? await getAllBasketsforCustomer(customerId)
                    : false

            if (basket && basket.total > 0) {
                let response = basket.baskets[0]

                response.basket_id && localStorage.setItem('basket_id', response.basket_id)

                resolve(response)
            } else {
                fetch('/ocapi-fetch', {
                    body: JSON.stringify({
                        headers: {
                            'content-type': 'application/json'
                        },
                        method: 'POST',
                        body: body,
                        url: `/baskets`
                    }),
                    method: 'POST',
                    credentials: 'include'
                })
                    .then((response) => response.json())
                    .then((response) => {
                        if (response.fault) {
                            if (response.fault.type === 'CustomerBasketsQuotaExceededException') {
                                let basketId = response.fault.arguments.basketIds
                                getBasket(connector, basketId).then((newBasket) => {
                                    resolve(newBasket)
                                    newBasket.basket_id &&
                                        localStorage.setItem('basket_id', newBasket.basket_id)
                                })
                            }
                        } else {
                            resolve(response)
                            response.basket_id && storeInLocal('basket_id', response.basket_id)
                        }
                    })
                    .catch((err) => {
                        addInteractionErrorEvent(
                            'interaction_error',
                            'Create Basket Error',
                            'Basket',
                            response.basket_id,
                            '404',
                            'basket-page'
                        )
                        console.error(err)
                        reject(err)
                    })
            }
        })()
    })
}

export const getNewBasketReference = async (basketID, customerId) => {
    if (basketID && customerId) {
        const bodyForBasket = {
            basket_id: basketID,
            customer_id: customerId
        }
        return new Promise((resolve, reject) => {
            fetch('/ocapi-fetch', {
                body: JSON.stringify({
                    headers: {
                        'content-type': 'application/json'
                    },
                    method: 'POST',
                    body: bodyForBasket,
                    url: '/baskets/reference'
                }),
                method: 'POST',
                credentials: 'include'
            })
                .then(async (response) => {
                    resolve(await response.json())
                })
                .catch((err) => {
                    addInteractionErrorEvent(
                        'interaction_error',
                        'Get New Basket Error',
                        'Customer',
                        customerId,
                        '404',
                        'basket-page'
                    )
                    console.error(err)
                    reject(err)
                })
        })
    }
    return Promise.resolve({error: 'basketId or customerId missing...'})
}

/**
 * Preference of basket
 * Priority 1 - Try to get basket related to basket id.
 * Priority 2 - Get the all customer basket then if found then we have to replace basket_id in localstorage and return the basket.
 * Priority 3 - If there is no basket assigned to customer then we have to create one.
 *
 * @param {*} connector
 * @param {*} basketID
 * @param {*} tokenHeader
 * @returns
 */

export const getBasket = async (connector, basketID) => {
    if (basketID) {
        return new Promise((resolve, reject) => {
            let orderData = localStorage.getItem('order_data')
            let isOrderConfimation = window.location.pathname === '/checkout-confirm/'
            if (orderData) {
                if (!isOrderConfimation) {
                    ;(async () => {
                        orderData = JSON.parse(orderData)
                        await cancelOrder(orderData.order_no)
                        await addNote(orderData?.order_no, 'Order Failed', 'Order Failed.')

                        localStorage.removeItem('order_data')
                        let basketResponse = getBasket(connector, basketID)

                        resolve(basketResponse)
                    })()
                } else {
                    localStorage.removeItem('order_data')
                    localStorage.removeItem('basket_id')
                }
            } else {
                fetch('/ocapi-fetch', {
                    body: JSON.stringify({
                        headers: {
                            'content-type': 'application/json'
                        },
                        url: `/baskets/${basketID}`,
                        method: 'GET',
                        credentials: 'include'
                    }),
                    method: 'POST',
                    credentials: 'include'
                })
                    .then((response) => response.json())
                    .then(async (response) => {
                        if (response.fault) {
                            switch (response.fault.type) {
                                case 'BasketNotFoundException':
                                    ;(async () => {
                                        const customerId = getCookie('customerId')
                                        let basket = await getAllBasketsforCustomer(customerId)

                                        if (basket.total > 0) {
                                            let basketResponse = basket.baskets[0]
                                            localStorage.setItem(
                                                'basket_id',
                                                basketResponse.basket_id
                                            )
                                            resolve(basketResponse)
                                        } else {
                                            let basketResponse = await createBasket(connector, {
                                                productItems: []
                                            })
                                            localStorage.setItem(
                                                'basket_id',
                                                basketResponse.basket_id
                                            )
                                            resolve(basketResponse)
                                        }
                                    })()
                                    break
                                case 'ResourcePathNotFoundException':
                                    ;(async () => {
                                        const customerId = getCookie('customerId')
                                        let basket = await getAllBasketsforCustomer(customerId)

                                        if (basket.total > 0) {
                                            let basketResponse = basket.baskets[0]
                                            localStorage.setItem(
                                                'basket_id',
                                                basketResponse.basket_id
                                            )
                                            resolve(basketResponse)
                                        } else {
                                            let basketResponse = await createBasket(connector, {
                                                productItems: []
                                            })
                                            localStorage.setItem(
                                                'basket_id',
                                                basketResponse.basket_id
                                            )
                                            resolve(basketResponse)
                                        }
                                    })()
                                    break
                                case 'InvalidCustomerException':
                                    resolve({
                                        fault: {
                                            message: 'The customer is invalid.',
                                            type: 'InvalidCustomerException'
                                        }
                                    })
                                    break
                                default:
                                    resolve(response)
                            }
                        } else {
                            response.basket_id &&
                                response.basket_id !== 'undefined' &&
                                localStorage.setItem('basket_id', response.basket_id)
                            resolve(response)
                        }
                    })
                    .catch((err) => {
                        addInteractionErrorEvent(
                            'interaction_error',
                            'Get Basket Error',
                            'Basket',
                            basketID,
                            '404',
                            'basket-page'
                        )
                        console.error(err)
                        reject(err)
                    })
            }
        })
    }
    return Promise.resolve({error: 'basketId not found...'})
}

export const getBasketPaymentMethods = async (callback) => {
    const basketID = getFromLocal('basket_id')
    if (basketID) {
        return new Promise((resolve, reject) => {
            fetch(`/payment-methods?basketID=${basketID}`, {
                headers: {
                    'content-type': 'application/json'
                },
                method: 'GET'
            })
                .then(async (response) => {
                    const data = await response.json()

                    callback(data.applicable_payment_methods.map((el) => el.id.toLowerCase()))
                    resolve(data)
                })
                .catch((err) => {
                    addInteractionErrorEvent(
                        'interaction_error',
                        'Get Basket Payment Methods Error',
                        'Basket',
                        basketID,
                        '404',
                        'basket-page'
                    )
                    console.error(err)
                    reject(err)
                })
        })
    }
    return Promise.resolve({error: 'header not found...'})
}

export const reqAddItemsToBasket = async (connector, basketID, payload) => {
    if (basketID) {
        return new Promise((resolve, reject) => {
            fetch('/ocapi-fetch', {
                body: JSON.stringify({
                    headers: {
                        'content-type': 'application/json'
                    },
                    method: 'POST',
                    body: payload,
                    url: `/baskets/${basketID}/items`
                }),
                method: 'POST',
                credentials: 'include'
            })
                .then((response) => {
                    if (response.status === 404) {
                        resolve(response.json())
                    } else {
                        // content square for add items to basket
                        if (window !== 'undefined') {
                            window._uxa = window._uxa || []
                            window._uxa.push([
                                'ec:cart:add',
                                {
                                    sku:
                                        payload[0]
                                            .product_id /* mandatory - Product code (SKU) (string) */
                                }
                            ])
                        }
                        response.json().then((res) => {
                            res.basket_id &&
                                res.basket_id !== 'undefined' &&
                                localStorage.setItem('basket_id', res.basket_id)
                            resolve(res)
                        })
                    }
                })
                .catch((err) => {
                    addInteractionErrorEvent(
                        'interaction_error',
                        'Add to Basket Error',
                        'Basket',
                        basketID,
                        '404',
                        'basket-page'
                    )
                    console.error(err)
                    reject(err)
                })
        })
    }
    return Promise.resolve({error: 'basketID not found...'})
}

export const reqDeleteBasket = async (basketID) => {
    if (basketID) {
        return new Promise((resolve, reject) => {
            fetch('/ocapi-fetch', {
                body: JSON.stringify({
                    headers: {
                        'content-type': 'application/json'
                    },
                    method: 'DELETE',
                    url: `/baskets/${basketID}`
                }),
                method: 'POST',
                credentials: 'include'
            })
                .then((response) => {
                    resolve({})
                })
                .catch((err) => {
                    addInteractionErrorEvent(
                        'interaction_error',
                        'Delete Basket Error',
                        'Basket',
                        basketID,
                        '404',
                        'basket-page'
                    )
                    console.error(err)
                    reject(err)
                })
        })
    }
    return Promise.resolve({error: 'basketID not found...'})
}

export const applyPromoCode = async (code) => {
    const basketID = getFromLocal('basket_id')
    if (basketID) {
        const body = {code}
        return new Promise((resolve, reject) => {
            fetch('/ocapi-fetch', {
                body: JSON.stringify({
                    headers: {
                        'content-type': 'application/json'
                    },
                    method: 'POST',
                    body: body,
                    url: `/baskets/${basketID}/coupons`
                }),
                method: 'POST',
                credentials: 'include'
            })
                .then((response) => {
                    response.json().then((res) => {
                        res.basket_id &&
                            res.basket_id !== 'undefined' &&
                            localStorage.setItem('basket_id', res.basket_id)
                        resolve(res)
                    })
                })
                .catch((err) => {
                    addInteractionErrorEvent(
                        'interaction_error',
                        'Apply Promo Code Error',
                        'Basket',
                        basketID,
                        '404',
                        'basket-page'
                    )
                    console.error(err)
                    reject(err)
                })
        })
    }
    return Promise.resolve({error: 'basketID not found...'})
}

export const removePromoCode = async (couponItemId) => {
    const basketID = getFromLocal('basket_id')
    if (basketID) {
        return new Promise((resolve, reject) => {
            fetch('/ocapi-fetch', {
                body: JSON.stringify({
                    headers: {
                        'content-type': 'application/json'
                    },
                    method: 'DELETE',
                    url: `/baskets/${basketID}/coupons/${couponItemId}`
                }),
                method: 'POST',
                credentials: 'include'
            })
                .then((response) => {
                    response.json().then((res) => {
                        res.basket_id &&
                            res.basket_id !== 'undefined' &&
                            localStorage.setItem('basket_id', res.basket_id)
                        resolve(res)
                    })
                })
                .catch((err) => {
                    addInteractionErrorEvent(
                        'interaction_error',
                        'Remove Promo Code Error',
                        'Basket',
                        basketID,
                        '404',
                        'basket-page'
                    )
                    console.error(err)
                    reject(err)
                })
        })
    }
    return Promise.resolve({error: 'basketID not found...'})
}

export const reqAddQuantityToBasket = async (basketID, itemId, payload) => {
    if (basketID) {
        return new Promise((resolve, reject) => {
            fetch('/ocapi-fetch', {
                body: JSON.stringify({
                    headers: {
                        'content-type': 'application/json'
                    },
                    method: 'PATCH',
                    body: payload,
                    url: `/baskets/${basketID}/items/${itemId}`
                }),
                method: 'POST',
                credentials: 'include'
            })
                .then((response) => {
                    response.json().then((res) => {
                        res.basket_id &&
                            res.basket_id !== 'undefined' &&
                            localStorage.setItem('basket_id', res.basket_id)
                        resolve(res)
                    })
                })
                .catch((err) => {
                    addInteractionErrorEvent(
                        'interaction_error',
                        'Add Quantity to Basket Error',
                        'Basket',
                        basketID,
                        '404',
                        'basket-page'
                    )
                    console.error(err)
                    reject(err)
                })
        })
    }
    return Promise.resolve({error: 'basketID not found...'})
}

export const reqRemoveItemBasket = async (basketID, itemId, payload, dispatch, history) => {
    if (basketID) {
        return new Promise((resolve, reject) => {
            fetch('/ocapi-fetch', {
                body: JSON.stringify({
                    headers: {
                        'content-type': 'application/json'
                    },
                    method: 'DELETE',
                    body: payload,
                    url: `/baskets/${basketID}/items/${itemId}`
                }),
                method: 'POST',
                credentials: 'include'
            })
                .then((response) => {
                    response.json().then((res) => {
                        if (res.fault) {
                            clearUserStorage(dispatch)
                            history.push('/')
                        } else {
                                   res.basket_id &&
                                       res.basket_id !== 'undefined' &&
                                       localStorage.setItem('basket_id', res.basket_id)
                                   resolve(res)
                               }
                    })
                })
                .catch((err) => {
                    addInteractionErrorEvent(
                        'interaction_error',
                        'Remove Item from Basket Error',
                        'Basket',
                        basketID,
                        '404',
                        'basket-page'
                    )
                    console.error(err)
                    reject(err)
                })
        })
    }
    return Promise.resolve({error: 'basketID not found...'})
}

/**
 * Asynchronously removes payment methods from the specified basket.
 *
 * @param {Object} connector - The connector object used for communication with the backend.
 * @param {string} basketID - The ID of the basket from which the payment methods are to be removed.
 * @param {Object} history - An instance of the useHistory hook
 * @returns {Promise<Object>} A promise that resolves to an empty object if the payment methods are successfully removed, or an object with an error property if the basketID is not found.
 */
export const removePaymentToBasket = async (connector, basketID, history) => {
    if (basketID) {
        return new Promise((resolve, reject) => {
            //we need to check for   is there any payment method already added to the basket
            // If it is there than we have to remove

            ;(async () => {
                let basket_data = await getBasket(connector, basketID)
                if (basket_data.fault) {
                    clearUserStorage()
                    history.push('/login/')
                    reject(new Error('Basket data error'))
                }
                // recursive  function loop
                let i = 0,
                    limit = basket_data.payment_instruments
                        ? basket_data.payment_instruments.length
                        : 0

                function loopCheck() {
                    i++

                    if (i < limit) {
                        loop(i)
                    } else {
                        resolve({})
                    }
                }

                async function loop(i) {
                    let payment_instruments = basket_data.payment_instruments[i]
                    fetch(
                        `/removeAllPaymentMethod/${basketID}/${payment_instruments.payment_instrument_id}`,
                        {
                            headers: {
                                'content-type': 'application/json'
                            },
                            method: 'GET'
                        }
                    )
                        .then((response) => {
                            loopCheck()
                        })
                        .catch((err) => {
                            loopCheck()
                        })
                }
                if (limit > 0) {
                    loop(i)
                } else {
                    resolve({})
                }

                // recursive  function loop ends
            })()
        })
    }
    return Promise.resolve({error: 'basketID not found...'})
}

export const addPaymentToBasket = async (basketID, body) => {
    if (basketID) {
        return new Promise(async (resolve, reject) => {
            fetch(`/add-payment-to-basket/${basketID}`, {
                headers: {
                    'content-type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(body)
            })
                .then((response) => {
                    response.json().then((res) => {
                        res.basket_id &&
                            res.basket_id !== 'undefined' &&
                            localStorage.setItem('basket_id', res.basket_id)
                        resolve(res)
                    })
                })
                .catch((err) => {
                    addInteractionErrorEvent(
                        'interaction_error',
                        'Add payment to Basket Error',
                        'Basket',
                        basketID,
                        '404',
                        'basket-page'
                    )
                    console.error('Add Payment response error:', err)
                    reject(err)
                })
        })
    }
    return Promise.resolve({error: 'basketID not found...'})
}

export const submitBasketOrder = async (basketID) => {
    if (basketID) {
        const body = {
            basket_id: basketID
        }
        return new Promise((resolve, reject) => {
            fetch('/ocapi-fetch', {
                method: 'POST',
                body: JSON.stringify({
                    headers: {
                        'content-type': 'application/json'
                    },
                    method: 'POST',
                    body: body,
                    url: '/orders'
                }),
                credentials: 'include'
            })
                .then((response) => {
                    resolve(response.json())
                })
                .catch((err) => {
                    addInteractionErrorEvent(
                        'interaction_error',
                        'Submit Basket Order Error',
                        'Basket',
                        basketID,
                        '404',
                        'basket-page'
                    )
                    console.error('order submit response error:', err)
                    reject(err)
                })
        })
    }
    return Promise.resolve({error: 'basketID not found...'})
}

export const changeOrderStatus = async (connector, orderID) => {
    if (orderID) {
        return new Promise((resolve, reject) => {
            fetch(`confirmOrder/${orderID}`, {
                headers: {
                    ...connector.client.defaultHeaders,
                    'content-type': 'application/json'
                },
                method: 'GET'
            })
                .then((response) => {
                    resolve(response.json())
                })
                .catch((err) => {
                    addInteractionErrorEvent(
                        'interaction_error',
                        'Change Order Status Error',
                        'Order',
                        orderID,
                        '404',
                        'basket-page'
                    )
                    console.error('order submit updated status error:', err)
                    reject(err)
                })
        })
    }
    return Promise.resolve({error: 'orderID not found...'})
}
