import {SET_TOTAL_AMOUNT} from '../components/global-state'
import {isBrowser, getServerCookie, storeAuthCookies} from './utils'

export const storeInLocal = (key, value) => {
    localStorage.setItem(key, value)
}

/**
 * Gets an authentication token from the server
 * @returns {Promise} - a promise that resolves when the token is retrieved
 */
export const getAuthTokens = async (req) => {
    try {
        let url = `${process.env.HOSTNAME}/get-auth-token`
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                cookie: req.headers.cookie
            },
            body: JSON.stringify({
                isLogout: req.body?.isLogout
            }),
            credentials: 'include' // Needed to parse cookies
        })
        const jsonResponse = await response.json()

        const {token} = jsonResponse

        if (!token) {
            console.error('No auth token returned from getAuthTokens() request')
            return null
        }

        return jsonResponse
    } catch (error) {
        console.error(`Error getting auth token: ${error?.message}`)
    }
}

/**
 * Retrieves the authentication token from the server cookie or fetches a new one
 * @param {Object} req - the request object
 * @param {Object} res - the response object
 * @returns {Promise<string>} - the authentication token
 */
export const getAuthToken = async (req, res) => {
    let authToken = getServerCookie(req)

    if (!authToken || req.body?.isLogout) {
        const authTokens = await getAuthTokens(req)
        authToken = authTokens?.token
        storeAuthCookies(authTokens, res)
    }

    return authToken
}

export const getFromLocal = (key) => {
    try {
        return localStorage.getItem(key) || ''
    } catch (error) {
        return ''
    }
}

export const removeFromLocal = (key) => {
    localStorage.removeItem(key)
}

export const storeInSession = (key, value) => {
    sessionStorage.setItem(key, value)
}

export const removeFromSession = (key) => {
    sessionStorage.removeItem(key)
}

export const getFromSession = (key) => {
    try {
        return sessionStorage.getItem(key) || ''
    } catch (error) {
        return ''
    }
}

export const clearSessionStorage = () => {
    window.sessionStorage.clear()
}

export const clearLocalStorage = () => {
    window.localStorage.clear()
}

export const clearUserStorage = async (dispatch) => {
    dispatch({type: SET_TOTAL_AMOUNT, payload: 0})
    clearLocalStorage()
    eraseCookie('checkout_data')
    eraseCookie('checkout_payment')
    eraseCookie('subscription_decision_made')
    await fetch(`${process.env.HOSTNAME}/logout`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            isLogout: true
        })
    })
}

export const setCookie = (
    name,
    value,
    days,
    document = typeof window !== 'undefined' ? window.document : null
) => {
    var expires = ''
    if (days) {
        var date = new Date()
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
        expires = '; expires=' + date.toUTCString()
    }
    if (document) document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

/**
 * Gets the value of a cookie from the document
 * @param {string} name - the name of the cookie
 * @returns {string} the value of the cookie
 */
export const getCookie = (name) => {
    var nameEQ = name + '='
    if (isBrowser()) {
        var ca = document.cookie.split(';')
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i]
            while (c.charAt(0) == ' ') c = c.substring(1, c.length)
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
        }
    }
    return null
}

// Delete a cookie
export const eraseCookie = (
    name,
    document = typeof window !== 'undefined' ? window.document : null
) => {
    if (document) document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
}
