import React from 'react'

const ErrorFooter = () => {
    return (
        <div className="error-footer">
            <div className="error-footer-text">
                <span className="error-footer-bold">Prefer to call us?</span> Call{' '}
                <a className="error-footer-link" href="tel:0333 6005000">
                    0333 6005000
                </a>
                .
            </div>
            <div className="error-footer-text">
                We&apos;re here Monday to Friday, 9am - 5pm, except for public holidays
            </div>
            <div className="error-footer-text">
                Alternatively, you can email us with your order number at{' '}
                <a
                    className="error-footer-link error-footer-bold"
                    href="mailto:support@whsmith.co.uk"
                >
                    support@whsmith.co.uk
                </a>
            </div>
        </div>
    )
}

export default ErrorFooter
