/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Fragment, useContext} from 'react'
import {
    GlobalDispatchContext,
    SET_PRODUCT
} from '../../components/global-state'
import Link from 'progressive-web-sdk/dist/components/link'
import {getURL} from '../../utils/utils'
import {addSelectItemEvent} from '../../utils/gtmUtils'

const ProductName = (props) => {
    const {
        name,
        category,
        categoryData,
        cssClass,
        masterProduct,
        product,
        chartNumber,
        position,
        queryString,
        faceoutTitle,
        showSearchSuggestion,
        isSearchListing,
        onSearchClose = () => {},
        triggerSuggestInteraction = () => {},
        isEinsteinReco
    } = props
    const dispatch = useContext(GlobalDispatchContext)
    let cPageURL, masterProductId
    if (Object.values(masterProduct).length) {
        cPageURL = masterProduct?.c_page_url || masterProduct?.c_pageURL
        masterProductId = masterProduct?.master?.master_id || masterProduct?.product_id
    }

    const goToProductDetails = () => {
        if (!masterProduct) {
            return
        }

        if (!cPageURL || !masterProductId) {
            return
        }

        if (cPageURL !== 'null') {
            return `${getURL(cPageURL)}/${masterProductId?.toLowerCase()}.html`
        } else {
            return `/products/null/${masterProductId?.toLowerCase()}.html`
        }

    }

    const isTabletOrHigher = () => {
        return typeof window !== 'undefined' && window.innerWidth >= 768
    }

    return (
        <Fragment>
            <div
                className={`${cssClass} product-name`}
                style={
                    chartNumber
                        ? isTabletOrHigher()
                            ? {margin: '20px 0 5px'}
                            : {margin: '0 0 5px'}
                        : {}
                }
            >
                <Link
                    data-cmp="ellipsis"
                    className="name-link m-cmp_loaded"
                    title={`${name}`}
                    onClick={() => {
                        onSearchClose()
                        triggerSuggestInteraction(
                            'Alternative',
                            product.name,
                            `${getURL(cPageURL)}/${masterProductId?.toLowerCase()}.html`
                        )
                        dispatch({type: SET_PRODUCT, payload: {product, masterProduct}})
                        addSelectItemEvent(
                            product,
                            categoryData || category,
                            !!isSearchListing,
                            queryString || faceoutTitle,
                            position,
                            isEinsteinReco
                        )
                    }}
                    href={goToProductDetails()}
                >
                    <h4 className={`${showSearchSuggestion ? 'ellip-search' : 'ellip'}`}>{name}</h4>
                </Link>
            </div>
        </Fragment>
    )
}

export default ProductName
