/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useState, useEffect, useContext} from 'react'
import {Helmet} from 'react-helmet'
import PropTypes from 'prop-types'
import {pages, PAGEEVENTS} from 'progressive-web-sdk/dist/ssr/universal/events'
import {ERROR, OFFLINE} from 'progressive-web-sdk/dist/analytics-integrations/types'
import {getAssetUrl} from 'progressive-web-sdk/dist/ssr/universal/utils'
import {getConnector} from '../../connector'
import {HTTPError} from 'progressive-web-sdk/dist/ssr/universal/errors'
import {getFromSession, storeInSession, getFromLocal, storeInLocal} from '../../utils/storage-utils'
import {isBrowser, watchOnlineStatus} from '../../utils/utils'
import Header from '../../components/header'
import Footer from '../../components/footer'
import OfflineBoundary from '../../components/offline-boundary'
import ResponsiveContainer from '../../components/responsive-container'
import {getAnalyticsManager} from '../../analytics'
import {GET_ENV} from '../../connector'
import {getNavigationRoot, getNavigationRootDesktop} from './helpers'
import ScrollToTop from '../scroll-to-top'

import {getNavMenuData} from '../menu/helper'
import SvgHelper from './svgHelper'
import {getContentAsset} from '../content-asset/helper'
import {useLocation} from 'react-router-dom'
import {GlobalDispatchContext, SET_MENU_CATEGORIES, SET_HOMEPAGE_LAYOUT} from '../global-state'
import {initBloomreach, removeBloomreach} from './helpers'
import {useBloomreach} from '../../hooks/useBloomreach'
import {useOptanon} from '../../hooks/useOptanon'
import {consentUpdateEvent} from '../../utils/gtmUtils'
import {isEqual} from 'lodash'

const analyticsManager = getAnalyticsManager()

export const OfflineBanner = () => (
    <header className="c-pwa-app__offline-banner">
        <p>Currently browsing in offline mode</p>
    </header>
)

const PWAApp = (props) => {
    const {children, categories, footerAssets, headerAssets, contentAll, timetaken} = props
    const dispatch = useContext(GlobalDispatchContext)
    const navigationRootMobile = getNavigationRoot(props)
    const navigationRootDesktop = getNavigationRootDesktop(props)
    const [isOnline, setIsOnline] = useState(true)
    const location = useLocation()
    let connector = getConnector()
    const bloomreach = useBloomreach()
    const cookiesEnabled = useOptanon()

    useEffect(() => {
        if (cookiesEnabled.targeting) {
            initBloomreach()
        } else {
            removeBloomreach()
        }
    }, [cookiesEnabled])

    const getHomePageTemplate = async () => {
        let contentAssetId = 'mp-homepage'
        const layoutResponse = await getContentAsset(connector, contentAssetId)
        dispatch({
            type: SET_HOMEPAGE_LAYOUT,
            payload: JSON.parse(layoutResponse.c_body).layout
        })
    }
    useEffect(() => {
        getHomePageTemplate()
    }, [])

    useEffect(() => {
        document.addEventListener('submit', (event) => {
            const form = event.target
            // check if form has class bloomreach-form
            if (form.classList.contains('bloomreach-form')) {
                event.preventDefault()
                const emailField = form.querySelector('input[type="email"]')
                const emailValue = emailField.value

                ;(async () => {
                    let response = await fetch('/sign-up', {
                        method: 'POST',
                        body: JSON.stringify({
                            email: emailValue,
                            category: form.dataset.brcategory,
                            message: form.dataset.brmessage,
                            timestamp: Math.floor(new Date().getTime() / 1000)
                        })
                    })
                    // TODO: Handle success/error response
                    await response.json()
                })()

                emailField.value = ''
            }
        })

        const handler = (event) => {
            // Check if the clicked element is the button
            const target = event.target
            if (
                target.matches('.save-preference-btn-handler') ||
                target.matches('#accept-recommended-btn-handler')
            ) {
                const cookiesUpdatedCount = parseInt(getFromLocal('cookiesUpdatedCount')) || 0
                storeInLocal('cookiesUpdatedCount', cookiesUpdatedCount + 1)
                if (cookiesUpdatedCount > 0) {
                    window.location.reload()
                }
            }
        }
        document.addEventListener('click', (e) => handler(e))
        return () => {
            document.removeEventListener('click', handler)
        }
    }, [])

    // make sure to re-fire the GTM consent update event each time the cookie preferences are updated
    const [cookiesSentToConsent, setCookiesSentToConsent] = useState({})
    useEffect(() => {
        if (!isEqual(cookiesEnabled, cookiesSentToConsent)) {
            setCookiesSentToConsent(
                consentUpdateEvent(cookiesEnabled, Object.keys(cookiesSentToConsent).length === 0)
            )
        }
    }, [cookiesEnabled])

    const [isCookieEnabled, setIsCookieEnabled] = useState(true)
    const [showHeaderFooter, setShowHeaderFooter] = useState(true)
    const [isDisabledErrorVisible, setIsDisabledErrorVisible] = useState(true)
    const baseUrl = 'https://www.whsmith.co.uk'

    useEffect(() => {
        if (getFromSession('originalLocation') === '') {
            storeInSession('originalLocation', window.location.href)
        }
        document.dispatchEvent(new Event('nav'))
    }, [location.pathname])

    useEffect(() => {
        if (categories.length > 0) {
            dispatch({type: SET_MENU_CATEGORIES, payload: categories})
        }
    }, [categories.length > 0])

    useEffect(() => {
        // Listen for events from the SDK to send analytics for.
        pages.on(PAGEEVENTS.PAGELOAD, (evt) => {
            analyticsManager.trackPageLoad(evt)
        })
        pages.on(PAGEEVENTS.ERROR, (evt) => {
            analyticsManager.track(ERROR, evt)
        })

        // Listen for online status changes to update state and send analytics for.
        watchOnlineStatus((isOnline) => {
            setIsOnline(isOnline)

            analyticsManager.track(OFFLINE, {
                startTime: !isOnline ? new Date().getTime() : null
            })
        })

        setIsCookieEnabled(window.navigator.cookieEnabled)

        if (
            props.location.pathname !== '/checkout-shipping/' &&
            props.location.pathname !== '/checkout-process-payment/' &&
            props.location.pathname !== '/checkout-payment/'
        )
            sessionStorage.removeItem('gift_cards')
    }, [])

    useEffect(() => {
        if (
            location.pathname === '/checkout-login/' ||
            location.pathname === '/checkout-payment/'
        ) {
            setShowHeaderFooter(false)
        }
    }, [showHeaderFooter])

    const renderDisabledError = () =>
        isDisabledErrorVisible ? (
            <div id="browser-check">
                {!isCookieEnabled ? (
                    <div className="cookie-error-mesage">
                        <div className="message-wrapper error-banner">
                            <div className="browser-compatibility-alert error-banner-inner">
                                <p className="error-banner-text">
                                    Your browser is currently not set to accept cookies. Please turn
                                    this functionality on or check if you have another program set
                                    to block cookies.
                                </p>
                                {closeIcon()}
                            </div>
                        </div>
                    </div>
                ) : null}
                <noscript>
                    <div className="message-wrapper error-banner">
                        <div className="browser-compatibility-alert error-banner-inner">
                            <p className="error-banner-text">
                                Your web browser either does not support Javascript, or scripts are
                                being blocked. Please update your browser or enable Javascript to
                                allow our site to run correctly.
                            </p>
                            {closeIcon()}
                        </div>
                    </div>
                </noscript>
            </div>
        ) : null

    const closeIcon = () => (
        <div className="close-btn js-close" onClick={() => setIsDisabledErrorVisible(false)}>
            <svg className="icon-close">
                <title>Close</title>
                <use xlinkHref="#icon-cross">
                    <svg id="icon-cross" viewBox="0 0 18 18">
                        <path
                            id="ic_cross"
                            className="cls-1"
                            d="M1374.12 28.991l-2.11-2.116 15.87-15.869 2.11 2.116zm-2.11-15.869l2.11-2.116 15.87 15.869-2.11 2.116z"
                            transform="translate(-1372 -11)"
                        ></path>
                    </svg>
                </use>
            </svg>
        </div>
    )

    return (
        <ResponsiveContainer>
            <ScrollToTop />
            <div id="app" className="c-pwa-app content-width data-cs-capture">
                {renderDisabledError()}
                {props.location.pathname !== '/checkout-login/' &&
                props.location.pathname !== '/checkout-shipping/' &&
                props.location.pathname !== '/checkout-process-payment/' &&
                props.location.pathname !== '/checkout-payment/' ? (
                    <Header
                        navigationRootMobile={navigationRootMobile}
                        navigationRootDesktop={navigationRootDesktop}
                        categories={categories}
                        headerAssets={headerAssets}
                        contentAll={contentAll}
                        isError={false}
                        fixedHeader={true}
                    />
                ) : null}

                {!isOnline && <OfflineBanner />}

                <main id="app-main" className="c-pwa-app__main" role="main">
                    <div className="c-pwa-app__content">
                        <OfflineBoundary isOnline={isOnline}>{children}</OfflineBoundary>
                    </div>
                </main>

                {props.location.pathname !== '/checkout-login/' &&
                props.location.pathname !== '/checkout-shipping/' &&
                props.location.pathname !== '/checkout-process-payment/' &&
                props.location.pathname !== '/checkout-payment/' ? (
                    <Footer footerAssets={footerAssets} />
                ) : null}

                <SvgHelper />
            </div>
            <Helmet
                htmlAttributes={{
                    lang: 'en-US',
                    'data-reactroot': ''
                }}
            >
                <meta name="theme-color" content="#003087" />
                <meta name="apple-mobile-web-app-title" content="WHSmith" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
                />
            </Helmet>
            {(GET_ENV === 'development' || GET_ENV === 'staging' || GET_ENV === 'production') && (
                <Helmet
                    htmlAttributes={{
                        lang: 'en-US',
                        'data-reactroot': ''
                    }}
                >
                    <meta
                        name="ahrefs-site-verification"
                        content="1edea172fe3445d7c6cfc831b916614f1a266b0d2ea40de50a38f72d232c0d83"
                    ></meta>
                </Helmet>
            )}

            {GET_ENV === 'production' && (
                <Helmet>
                    <meta
                        name="google-site-verification"
                        content="Zpcxy4PXePDyURfsXEed3DzOJe-IHUqF68y4S42osJs"
                    />
                    <meta name="msvalidate.01" content="3941B88D67F7FFC6222AD74CDD722141" />
                    <meta
                        name="google-site-verification"
                        content="b3Lo0K9ueYKM8ubcuqtyhXBBsaiCuZKNY4jH0Z-MONE"
                    />
                    <meta
                        name="facebook-domain-verification"
                        content="1sel7fga1wzj7gq929qvl35ewd13lm"
                    />
                    <meta
                        name="google-site-verification"
                        content="MIIXRPyEflyZg3Es08uAS8fKYjoyk08jfbq8yg2sniI"
                    />
                    <meta
                        name="google-site-verification"
                        content="gpZcxFLmdDpk47Yiemo0NGxh8anEfOeuUNqxNHl0bbM"
                    />
                </Helmet>
            )}

            {GET_ENV === 'staging' && (
                <Helmet>
                    <meta
                        name="google-site-verification"
                        content="gpZcxFLmdDpk47Yiemo0NGxh8anEfOeuUNqxNHl0bbM"
                    />
                </Helmet>
            )}
            <Helmet>
                <link
                    rel="apple-touch-icon"
                    href={getAssetUrl('static/img/global/apple-touch-icon.png')}
                />
                <link rel="manifest" href={getAssetUrl('static/manifest.json')} />

                <link rel="preconnect" href={'https://connect.facebook.net'} />
                <link rel="preconnect" href={'https://prod.whsmith.co.uk'} />
                <link rel="preconnect" href={'https://www.dwin1.com'} />
                <link rel="preconnect" href={'https://static.hotjar.com'} />
                <link rel="preconnect" href={'https://www.google-analytics.com'} />
                <link rel="preconnect" href={'https://2414348.fls.doubleclick.net'} />
                <link rel="preconnect" href={'https://script.hotjar.com'} />
                <link rel="preconnect" href={'https://vars.hotjar.com'} />
                <link rel="preconnect" href={'https://11137246.fls.doubleclick.net'} />
                <link rel="preconnect" href={'https://8178454.fls.doubleclick.net'} />
                <link rel="preconnect" href={'https://adservice.google.com'} />
                <link rel="preconnect" href={'https://stats.g.doubleclick.net'} />

                <link
                    rel="preload"
                    as="font"
                    href={getAssetUrl('static/fonts/Gotham-Book.woff2')}
                    crossOrigin="anonymous"
                />
                <link
                    rel="preload"
                    as="font"
                    href={getAssetUrl('static/fonts/Gotham-Medium.woff2')}
                    crossOrigin="anonymous"
                />
                <link
                    rel="preload"
                    as="font"
                    href={getAssetUrl('static/fonts/Gotham-Bold.woff2')}
                    crossOrigin="anonymous"
                />
                {GET_ENV === 'development' && (
                    <link
                        rel="prefetch"
                        href={process.env.DEVELOPMENT_GLOBAL_CSS}
                        as="style"
                    ></link>
                )}
                {GET_ENV === 'staging' && (
                    <link rel="prefetch" href={process.env.STAGING_GLOBAL_CSS} as="style"></link>
                )}

                {GET_ENV === 'production' && (
                    <link rel="prefetch" href={process.env.PRODUCTION_GLOBAL_CSS} as="style"></link>
                )}
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0"
                ></meta>
                {baseUrl !== null ? (
                    <script type="application/ld+json">
                        {`{"@context": "https://schema.org",
                        "@graph":[
                        {
                            "@type": "WebSite",
                            "@id":"${baseUrl}/#website",
                             "name": "WHSmith",
                            "description":"Books, stationery, gifts and much more.",
                             "url": "${baseUrl}/",
                            "publisher":{"@id":"${baseUrl}/#corporation"},
                            "potentialAction": [
                                {
                                    "@type": "SearchAction",
                                    "target": "${baseUrl}/search?q={q}",
                                    "query-input": "required name=q"
                                }
                            ]
                            } ]}`}
                    </script>
                ) : (
                    ''
                )}
            </Helmet>

            {/* web loayality */}
            <Helmet>
                <script>
                    {`
                        var prodID = { "a": [], "c": 0, "u": "", "r": false }; function _lppr(v){for(var i=0;i<prodID.a.length;i++){if(prodID.a[i].h===v){return i;}}}
                    `}
                </script>
            </Helmet>
        </ResponsiveContainer>
    )
}

PWAApp.shouldGetProps = () => {
    // In this case, we only want to fetch data for the app once, on the server.
    return typeof window === 'undefined'
}

PWAApp.getProps = async ({res, connector}) => {
    const t0 = new Date()
    const [categories, cookiesAcceptance, headerPromo, menuAsset] = await Promise.all([
        getNavMenuData(connector, 'root'),
        getContentAsset(connector, 'cookies-acceptance'),
        getContentAsset(connector, 'header-promo-banner-campaign'),
        getContentAsset(connector, 'mega-nav-main-asset')
    ])
    if (categories?.fault?.type === 'SiteOfflineException') {
        throw new HTTPError(503)
    }

    if (menuAsset?.fault?.type === 'SiteOfflineException') {
        throw new HttpError(503)
    }

    let footerAssets = {
        cookiesAcceptance,
        headerPromo
    }

    let headerAssets = {
        categories,
        headerPromo
    }

    const contentAll = JSON.parse(menuAsset.c_body)
    const t1 = new Date()
    const timetaken = t0 - t1
    return {categories, footerAssets, headerAssets, contentAll, timetaken}
}

PWAApp.propTypes = {
    children: PropTypes.node,
    categories: PropTypes.array,
    footerData: PropTypes.array,
    footerAssets: PropTypes.any,
    headerAssets: PropTypes.any,
    location: PropTypes.any
}

export default PWAApp
