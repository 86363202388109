import humps from 'humps'
export const dcamelize = (data) => {
    return humps.decamelizeKeys({
        ...data
    })
}
export const camelize = (data) => {
    return humps.camelizeKeys({
        ...data
    })
}
export const callSupplierPrecedence = (variantDetails) => {
    let supplier = {}
    variantDetails.forEach((variant) => {
        supplier[parseInt(variant.c_supplierPrecedence)] = variant
    })

    let representedPdt = []
    let i = 0
    let supplierKeys = Object.keys(supplier)
    supplierKeys.sort((a, b) => a - b)
    while (i < supplierKeys.length) {
        let record = supplier[supplierKeys[i]]
        if (record.inventory && record.inventory.orderable) {
            if (record.inventory.stock_level > 0) {
                representedPdt = supplier[supplierKeys[i]]
                break
            } else if (
                record.inventory.ats > 0 &&
                (record.inventory.backorderable || record.inventory.preorderable)
            ) {
                representedPdt = supplier[supplierKeys[i]]
                break
            }
        }
        i++
    }
    return representedPdt
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export function checkIfCategoryExists(menuCategories, categoryId) {
    let category = {}
    menuCategories.some((cat) => {
        cat?.categories?.some((e) => {
            if (e?.id?.toLowerCase() === categoryId?.toLowerCase()) {
                category = e
                return true
            } else {
                return e?.categories?.some((el2, index) => {
                    if (el2?.id?.toLowerCase() === categoryId?.toLowerCase()) {
                        category = el2
                        return true
                    }
                })
            }
        })
    })
    return category
}

export function findCategoryBySlug(category, slug) {
    // category?.c_pageUrl?.toLowerCase()?.replace(/\//g, "") !== (typeof location === 'string' ? location : location.pathname)?.toLowerCase()?.replace(/\//g, "")
    if (category?.c_pageUrl?.toLowerCase()?.replace(/\//g, '') === slug?.replace(/\//g, '')) {
        return category
    }

    for (var i = 0; i < category?.categories?.length; i++) {
        var foundCategory = findCategoryBySlug(category?.categories[i], slug)
        if (foundCategory) {
            return foundCategory
        }
    }
    return null
}

export function findCategoryById (category, newCategoryId) {
    // category?.c_pageUrl?.toLowerCase()?.replace(/\//g, "") !== (typeof location === 'string' ? location : location.pathname)?.toLowerCase()?.replace(/\//g, "")
    if(category?.id?.toLowerCase() === newCategoryId?.toLowerCase()) { return category; }
    
    for (var i = 0; i< category?.categories?.length; i++) {
        var foundCategory = findCategoryById(category?.categories[i], newCategoryId);
        if(foundCategory) { return foundCategory; }
    }
    return null;
};

export const toDate = (data) => {
    let d = new Date(data)
    let result = `${d.toLocaleString('default', {
        month: 'short'
    })} ${d.getDate()}, ${d.getYear() + 1900}`
    return result
}
