/*Product Tile TOP Component*/
//This is the top part of the Product Tile. It contains the Product Image (image is also a link) and its name as a link.//
//Child components are : ProductImage//

import React, {Fragment, useState, useEffect} from 'react'
//import {Desktop, TabletOrSmaller} from '../media-queries'
import ProductImage from './productImage'
import {datalayerImpressionsClick} from '../../utils/utils'
import {addSelectItemEvent} from '../../utils/gtmUtils'

const ProductTileTop = ({
    productName,
    productImage,
    productImageSizes,
    chartNumber,
    cssClass,
    productId,
    category,
    categoryData,
    product,
    masterProduct,
    position,
    queryString,
    categoryName,
    faceoutTitle,
    isFaceout,
    showSearchSuggestion,
    isSearchListing,
    onSearchClose = () => {},
    triggerSuggestInteraction,
    isEinsteinReco
}) => {
    const [imageAttribute, setImageAttribute] = useState([])

    useEffect(() => {
        productImage && productImage[0].images && setImageAttribute(productImage[0].images)
    }, [productImage])

    const [gtmSelectItemSent, setGtmSelectItemSent] = useState(false)
    const goToProductDetails = () => {
        if (gtmSelectItemSent) {
            return
        }
        setGtmSelectItemSent(true)
        addSelectItemEvent(
            product,
            categoryData || category,
            !!isSearchListing,
            queryString || faceoutTitle,
            position,
            isEinsteinReco
        )
        datalayerImpressionsClick(
            masterProduct,
            position,
            queryString || faceoutTitle,
            categoryName,
            category
        )
    }

    return (
        <Fragment>
            <div
                className={
                    showSearchSuggestion
                        ? cssClass
                        : `${cssClass} product-tile-top ${
                              !chartNumber ? 'full-width text-align non-chart' : ''
                          }`
                }
                onClick={goToProductDetails}
            >
                <ProductImage
                    image={imageAttribute}
                    imageSizes={productImageSizes}
                    isFaceout={isFaceout}
                    chartNumber={chartNumber}
                    pdtName={productName}
                    cssClass={cssClass}
                    productId={productId}
                    category={category}
                    product={product}
                    masterProduct={masterProduct}
                    type={faceoutTitle ? 'Faceout' : ''}
                    showSearchSuggestion={showSearchSuggestion}
                    onSearchClose={onSearchClose}
                    triggerSuggestInteraction={triggerSuggestInteraction}
                />
            </div>
        </Fragment>
    )
}

export default ProductTileTop
