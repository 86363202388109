import {useEffect, useRef} from 'react'
import fetch from 'cross-fetch'
import {getFromLocal, getFromSession, getCookie} from './storage-utils'
import {deleteMissingValues} from './gtmUtils'
import {PAYMENT_METHOD_LABELS} from './constants'
import {getAssetUrl} from 'progressive-web-sdk/dist/ssr/universal/utils'
import jwtDecode from 'jwt-decode'

/**
 * Call requestIdleCallback in supported browsers.
 *
 * https://developers.google.com/web/updates/2015/08/using-requestidlecallback
 * http://caniuse.com/#feat=requestidlecallback
 */
export const requestIdleCallback = (fn) => {
    if ('requestIdleCallback' in window) {
        return window.requestIdleCallback(fn)
    } else {
        return setTimeout(() => fn(), 1)
    }
}

export const watchOnlineStatus = (callback, win = window) => {
    const off = () => callback(false)
    const on = () => callback(true)
    win.addEventListener('offline', off)
    win.addEventListener('online', on)
    const unsubscribe = () => {
        win.removeEventListener('offline', off)
        win.removeEventListener('online', on)
    }
    return unsubscribe
}

export const isBrowser = () => typeof window !== 'undefined'

export const isIframe = () => typeof window?.parent !== 'undefined'

export const isDesktop = () => window.innerWidth >= 1024

const getModifiedURL = (url) => {
    let urlStr = url
        .replace(/\s+/g, '-')
        .replace(/(, | )/g, '-')
        .replace(/&/g, 'and')
        .replace(/,/g, '')
        .replace(/,/g, '')
        .replace(/(\(|\)|')/g, '')
        .toLowerCase()
    return urlStr
}
const baseUrl = isBrowser() ? window.location.origin : 'https://www.whsmith.co.uk'
export const breadcrumbList = (breadcrumb) => {
    let urlArray = {}
    let index = 1
    if (
        typeof breadcrumb?.parent_category_tree !== 'undefined' &&
        breadcrumb?.parent_category_tree?.length > 0
    ) {
        breadcrumb.parent_category_tree.map((child, count) => {
            if (count < breadcrumb.parent_category_tree.length - 1) {
                let url = (
                    baseUrl +
                        `${
                            count == 0
                                ? '/' + getModifiedURL(breadcrumb.parent_category_tree[0].name)
                                : count == 1
                                ? '/' +
                                  getModifiedURL(breadcrumb.parent_category_tree[0].name) +
                                  getURL(breadcrumb.parent_category_tree[1].name) +
                                  '/' +
                                  breadcrumb.parent_category_tree[1].id
                                : '/' +
                                  getModifiedURL(breadcrumb.parent_category_tree[0].name) +
                                  getURL(breadcrumb.parent_category_tree[1].name) +
                                  getURL(breadcrumb.parent_category_tree[2].name) +
                                  '/' +
                                  breadcrumb.parent_category_tree[2].id
                        }/` || ''
                ).toLowerCase()
                urlArray['level' + index] = child.name
                urlArray['l' + index + 'URL'] = url
                index++
            }
        })
        if (
            typeof breadcrumb?.parent_category_tree !== 'undefined' &&
            breadcrumb.parent_category_tree.length > 0
        ) {
            let url =
                baseUrl +
                `${'/' +
                    getModifiedURL(breadcrumb.parent_category_tree[0].name) +
                    (breadcrumb.parent_category_tree.length > 1
                        ? '/' + getModifiedURL(breadcrumb.parent_category_tree[1].name)
                        : '') +
                    getURL(breadcrumb.c_h1Tag) +
                    '/' +
                    breadcrumb.id}`
            urlArray['level' + index] = breadcrumb.name
            urlArray['l' + index + 'URL'] = url
        }
    }
    return Object.keys(urlArray).length === 0 ? null : urlArray
}

export const breadcrumbListPLP = (isSearchListing, queryString, breadcrumb) => {
    let urlArray = {}
    let index = 1
    if (
        typeof breadcrumb?.parent_category_tree !== 'undefined' &&
        breadcrumb.parent_category_tree.length > 0
    ) {
        breadcrumb.parent_category_tree.map((child, count) => {
            if (count < breadcrumb.parent_category_tree.length - 1) {
                let url =
                    baseUrl +
                    `${(count == 0
                        ? isSearchListing
                            ? `/search/?q=${queryString}&cgid=${breadcrumb.parent_category_tree[0].id}&category=${breadcrumb.parent_category_tree[0].name}`
                            : '/' + getModifiedURL(breadcrumb.parent_category_tree[0].name)
                        : count == 1
                        ? isSearchListing
                            ? `/search/?q=${queryString}&cgid=${breadcrumb.parent_category_tree[1].id}&category=${breadcrumb.parent_category_tree[1].name}`
                            : '/' +
                              getModifiedURL(breadcrumb.parent_category_tree[0].name) +
                              getURL(breadcrumb.parent_category_tree[1].name) +
                              '/' +
                              breadcrumb.parent_category_tree[1].id
                        : isSearchListing
                        ? `/search/?q=${queryString}&cgid=${breadcrumb.parent_category_tree[2].id}&category=${breadcrumb.parent_category_tree[2].name}`
                        : '/' +
                          getModifiedURL(breadcrumb.parent_category_tree[0].name) +
                          getURL(breadcrumb.parent_category_tree[1].name) +
                          getURL(breadcrumb.parent_category_tree[2].name) +
                          '/' +
                          breadcrumb.parent_category_tree[2].id
                    ).toLowerCase() + '/'}`
                urlArray['level' + index] = child.name
                urlArray['l' + index + 'URL'] = url
                index++
            }
        })
        if (
            breadcrumb.parent_category_tree !== undefined &&
            breadcrumb.parent_category_tree.length > 0
        ) {
            let url =
                baseUrl +
                `${'/' +
                    getModifiedURL(breadcrumb.parent_category_tree[0].name) +
                    (breadcrumb.parent_category_tree.length > 1
                        ? '/' + getModifiedURL(breadcrumb.parent_category_tree[1].name)
                        : '') +
                    getURL(breadcrumb.c_h1Tag) +
                    '/' +
                    breadcrumb.id}`
            urlArray['level' + index] = breadcrumb.name
            urlArray['l' + index + 'URL'] = url
        }
    }

    return urlArray
}

export const breadcrumbArray = () => {
    let urlArray = {}
    if (typeof window !== 'undefined') {
        if (
            typeof document !== 'undefined' &&
            document.getElementsByClassName('breadcrumb').length > 0
        ) {
            const breadcrumbLink = document.getElementsByClassName('breadcrumb')[0].children
            let index = 1
            for (let i = 0; i < breadcrumbLink.length; i++) {
                let item = breadcrumbLink[i]
                if (item.querySelector('a') !== null) {
                    let text = item.getElementsByTagName('a')[0].getElementsByTagName('span')[0]
                        .innerText
                    let url = baseUrl + '/' + item.getElementsByTagName('a')[0].getAttribute('href')
                    urlArray['level' + index] = text
                    urlArray['l' + index + 'URL'] = url
                } else {
                    if (item.getElementsByClassName('breadcrumb-leaf').length > 0) {
                        urlArray['level' + index] = item.getElementsByClassName(
                            'breadcrumb-leaf'
                        )[0].innerText
                        urlArray['l' + index + 'URL'] = window.location.href
                    }
                }
                index++
            }
        }
    }
    return urlArray
}

export const usePrevious = (value) => {
    const ref = useRef()
    useEffect(() => {
        ref.current = value
    })
    return ref.current
}

export const getURL = (value) => {
    const baseURL = '/'
    if (!value) {
        return baseURL
    }
    let strURL = value
        .replace(/\s+/g, '-')
        .replace(/(, | )/g, '-')
        .replace(/&/g, 'and')
        .replace(/,/g, '')
        .replace(/:/g, '')
        .replace(/%/g, '')
        .replace(/(\(|\)|')/g, '')
        .replace(/\?/g, '')
        .toLowerCase()
    return `${baseURL}${strURL}`
}

export const datalayerImpressionsClick = (
    product,
    position = 1,
    queryString,
    categoryName,
    category
) => {
    if (isBrowser()) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            event: 'impressionsClick',
            ecommerce: {
                click: {
                    actionField: {
                        list: `${categoryName || queryString}`
                    },
                    products: [
                        {
                            name: product ? product.name : '',
                            id: product ? product?.id?.split('-')[0] || product?.id : '',
                            price: product ? product.price : '',
                            brand: product && product.brand ? product.brand : 'WHSmith',
                            category: category ? category.id : '',
                            // variant: product && product.variants ? product.variants[0]['link'] : '',
                            position: position ? position : '1'
                            //     dimension1:
                            //         product && product.inventory && product.inventory.ats > 0
                            //             ? 'In Stock'
                            //             : 'Out of Stock',
                            //     dimension15: 'No',
                            //     metric1: product && product.inventory ? product.inventory.ats : 0,
                            //     metric4:
                            //         product && product.long_description
                            //             ? product.long_description.length
                            //             : 0,
                            //     metric2: product && product.c_images ? product.c_images.length : 0,
                            //     metric5: 0
                        }
                    ]
                }
            }
        })
    }
}

export const datalayerImpressionsView = (
    status = true,
    products = [],
    perPage,
    sortBy,
    filters,
    category = '',
    list = '',
    pageType = ''
) => {
    try {
        if (isBrowser()) {
            let selectedFilter =
                typeof filters !== 'string' && filters.length > 0
                    ? filters.reduce(function(r, a) {
                          r[a.type] = r[a.type] || []
                          r[a.type].push(a.label)
                          return r
                      }, Object.create(null))
                    : filters.length > 0
                    ? filters.replace(/\s+/g, '-').toLowerCase()
                    : ''

            if (typeof selectedFilter === 'object') {
                let x = ''
                Object.keys(selectedFilter).map((key) => {
                    x += `${key}:${selectedFilter[key]}|`
                })

                selectedFilter = x.slice(0, -1)
            }

            let productArr = []

            products.forEach((product, id) => {
                const data = {
                    name: product ? product.name : '',
                    id: product ? product?.id?.split('-')[0] || product?.id : '',
                    price: product ? product.price : '0.00',
                    brand: product && product.brand ? product.brand : 'WHSmith',
                    category:
                        category && category.hasOwnProperty('name')
                            ? category.name
                            : product && product.hasOwnProperty('primary_category_id')
                            ? product.primary_category_id
                            : 'All',
                    position: id + 1,
                    list: list || selectedFilter
                }
                productArr.push(data)
            })

            window.dataLayer = window.dataLayer || []
            const dataToPush = {
                event: 'impressionsView',
                ecommerce: {
                    impressions: productArr
                }
            }
            if (status) {
                dataToPush['filterSelected'] = selectedFilter ? selectedFilter : ''
                dataToPush['sortBy'] = sortBy ? sortBy.replace(/\s+/g, '-').toLowerCase() : ''
                dataToPush['perPage'] = perPage || products.length
            }
            if (pageType && pageType !== '') {
                dataToPush['page_type'] = pageType
            }
            window.dataLayer.push(dataToPush)
        }
    } catch (error) {
        console.error('Error', error)
    }
}

export const datalayerAddToCart = (product, quantity, actionType, action = 'addToCart') => {
    if (isBrowser()) {
        window.dataLayer = window.dataLayer || []
        if (action === 'addToCart') {
            const dataLayerCartEvent = {
                event: action,
                ecommerce: {
                    currencyCode: 'GBP',
                    add: {
                        products: [
                            {
                                name: product ? product.name : '',
                                id: product ? product.id?.split('-')[0] : '',
                                price: product ? product.price : '0.00',
                                brand: product && product.brand ? product.brand : 'WHSmith',
                                category: product ? product.primary_category_id : '',
                                quantity: quantity || '1',
                                dimension16: actionType,
                                dimension17: false
                            }
                        ]
                    }
                }
            }
            window.dataLayer.push(dataLayerCartEvent)
        } else {
            const dataLayerActionEvent = {
                event: action,
                ecommerce: {
                    remove: {
                        products: [
                            {
                                name: product ? product.name : '',
                                id: product ? product.id.split('-')[0] : '',
                                price: product ? product.price : '0.00',
                                brand: product && product.brand ? product.brand : 'WHSmith',
                                category: product ? product.primary_category_id : '',
                                quantity: quantity || '1'
                            }
                        ]
                    }
                }
            }
            window.dataLayer.push(dataLayerActionEvent)
        }
    }
}

export const setUserAddress = async (payload, connector, isEdit = false) => {
    if (isBrowser()) {
        return await fetch('/ocapi-fetch', {
            body: JSON.stringify({
                headers: {
                    'content-type': 'application/json'
                },
                method: isEdit ? 'PATCH' : 'POST',
                body: payload,
                url: `/customers/${getCookie('customerId')}/addresses`
            }),
            method: 'POST',
            credentials: 'include'
        })
    }
}

export const pushDatalayerEvent = (eventObj) => {
    if (isBrowser()) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push(eventObj)
    } else if (isIframe()) {
        window.parent.dataLayer = window.parent.dataLayer || []
        window.parent.dataLayer.push(eventObj)
    }
}

export const consolidateSimilarPromos = (basketDetails) => {
    let promotionText = []
    let computedPromotion = []
    if (basketDetails && basketDetails.product_items) {
        basketDetails.product_items.map((item) => {
            if (item.price_adjustments && item.price_adjustments.length > 0) {
                promotionText = promotionText.concat(item.price_adjustments)
            }
        })
        let promo = {}

        for (let i = 0; i < promotionText.length; i++) {
            let key = promotionText[i].promotion_id
            let samePromo = []
            for (let j = 0; j < promotionText.length; j++) {
                if (key === promotionText[j].promotion_id) {
                    samePromo.push(promotionText[j])
                }
            }
            promo[key] = samePromo
        }

        for (let key in promo) {
            let price = promo[key].reduce((sum, item) => {
                sum = sum + item.price
                return sum
            }, 0)
            computedPromotion.push({
                promotion_id: key,
                price: price,
                items: promo[key]
            })
        }
        return computedPromotion
    }
}

export const pageView = (pageTitle, pageType, contentGroup, categories, callback) => {
    if (isBrowser()) {
        const analyticBasket = getFromLocal('analyticBasket')
        const analyticbasketInfo = analyticBasket ? JSON.parse(analyticBasket) : null
        const locale = document.querySelector('[data-locale]')
        const isUserLoggedIn = getCookie('isUserLoggedIn') === 'true'
        const originalLocation = getFromSession('originalLocation')
        const prevURL = getFromSession('previousLocation')
        const datalayerEvent = {
            event: 'page_view',
            market: 'UK',
            timestamp: Date.now(),
            page: {
                page_title: pageTitle || document.title,
                page_type: pageType,
                page_referrer: prevURL ? baseUrl + prevURL : document?.referrer,
                original_location: originalLocation,
                category: categories?.level1,
                category_2: categories?.level2,
                category_3: categories?.level3,
                content_group: contentGroup
            },
            user: {
                ...(isUserLoggedIn && {user_id: getCookie('customerId')}),
                locale: locale && locale.getAttribute('data-locale'),
                visitor_type: isUserLoggedIn ? 'regular - logged in' : 'guest',
                logged_in_status: isUserLoggedIn ? 'Logged-in' : 'Guest'
            },
            cart: {
                cart_value: analyticbasketInfo?.cartValue || '0',
                item_count: analyticbasketInfo?.cartItemsNum || '0',
                unique_items: analyticbasketInfo?.cartUniqueItemsNum || '0'
            }
        }
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push(function() {
            this.reset()
        })
        window.dataLayer.push(deleteMissingValues(datalayerEvent))
    }
    if (callback && typeof callback === 'function') {
        callback()
    }
}

export const isEqual = (prop1, prop2) => {
    if (JSON.stringify(prop1) === JSON.stringify(prop2)) return true
    else return false
}

export const uniq = (newIds) => {
    let uniqueNewIds = newIds.filter((item, index, idsArr) => idsArr.indexOf(item) === index)
    return uniqueNewIds
}

export const largest = (arr) => {
    let i
    let max = arr[0]
    for (i = 1; i < arr.length; i++) {
        if (arr[i] > max) max = arr[i]
    }
    return max
}

const SAFE_URL_PATTERN = /^(?:(?:https?|mailto|ftp|tel|file|sms):|[^&:/?#]*(?:[/?#]|$))/gi

/** A pattern that matches safe data URLs. It only matches image, video, and audio types. */
const DATA_URL_PATTERN = /^data:(?:image\/(?:bmp|gif|jpeg|jpg|png|tiff|webp)|video\/(?:mpeg|mp4|ogg|webm)|audio\/(?:mp3|oga|ogg|opus));base64,[a-z0-9+\/]+=*$/i

function _sanitizeUrl(url) {
    if (url.match(SAFE_URL_PATTERN) || url.match(DATA_URL_PATTERN))
        return url.replace(/<script.*/, '').replace('"/>', '')

    return `unsafe:${url}`
}

export const sanitizeUrl = (url) => {
    return _sanitizeUrl(url?.trim())
}

/**
 * Returns the display name of the given payment method
 * @param {Object} paymentInstrument - the payment instrument object
 * @returns {string} - the display name of the payment method
 */
export const getPaymentMethodDisplayName = (paymentInstrument) => {
    let displayName = ''
    if (paymentInstrument?.payment_method_id) {
        displayName = PAYMENT_METHOD_LABELS[paymentInstrument.payment_method_id]
    }
    return displayName
}

/**
 * Returns an array of objects that have a true value for one of the given keys
 * @param {Object} object - The object to search through
 * @param {Array} keysToCheck - The keys to check for a true value
 * @returns {Array} objectsWithTrueValue - An array of objects with a true value for one of the given keys
 */
export const getObjectsWithSpecificKeys = (object, keysToCheck) => {
    const objectsWithTrueValue = []

    for (const objKey in object) {
        const obj = object[objKey]
        let hasTrueValue = false

        for (const key of keysToCheck) {
            if (key in obj && obj[key] === true) {
                hasTrueValue = true
                break
            }
        }

        if (hasTrueValue) {
            objectsWithTrueValue.push(obj)
        }
    }
    return objectsWithTrueValue
}

/**
 * Merges duplicate objects in an array of objects by a given key and updates a given key with the sum of the values
 * @param {Array} arrayOfObjs - Array of objects to be merged
 * @param {string} keyToSearch - Key to search for duplicates
 * @param {string} keyToUpdate - Key to update with the sum of the values
 * @returns {Array} - Array of merged objects
 */
export const mergeDuplicateObjectsByKey = (arrayOfObjs, keyToSearch, keyToUpdate) => {
    return Object.values(
        arrayOfObjs.reduce((acc, obj) => {
            if (!acc[obj[keyToSearch]]) {
                acc[obj[keyToSearch]] = {...obj}
            } else {
                acc[obj[keyToSearch]][keyToUpdate] += obj[keyToUpdate]
            }
            return acc
        }, {})
    )
}

/**
 * Gets an access token from the Demandware SSO API
 * @param {string} clientId - the client ID for the application
 * @param {string} acdwPassword - the password associated with the client ID
 * @returns {Object} - the response from the API containing the access token
 */
export const getAuthAccessToken = async (clientId, acdwPassword) => {
    const basicAuth = 'Basic ' + global.btoa(`${clientId}:${acdwPassword}`)
    let responseData = await fetch(
        `https://account.demandware.com/dwsso/oauth2/access_token?client_id=${clientId}`,

        {
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                Authorization: basicAuth
            },
            method: 'POST',
            body: new URLSearchParams({
                grant_type: 'client_credentials'
            })
        }
    )
    const creds = await responseData.json()
    return creds
}

/**
 * Stores the auth tokens in the response header
 * @param {Object} jsonResponse - The response from the auth service
 * @param {Object} res - The response object
 */
export const storeAuthCookies = async (jsonResponse, res) => {
    try {
        if (!jsonResponse || typeof jsonResponse !== 'object') {
            console.error('Missing jsonResponse data')
            return
        }
        const {
            access_token,
            expires_in,
            refresh_token,
            refresh_token_expires_in,
            customer_id
        } = jsonResponse

        if (!access_token || !customer_id) {
            console.error('Missing auth token data')
            return
        }
        const decoded = jwtDecode(access_token)
        // Example: {"isb": "uido:slas::upn:Guest::uidn:Guest User::gcid:abkucXkXI1krIRk0w0kWYYmbdG::chid:whsmith"}
        const isGuest = decoded.isb ? decoded.isb.split('::').some((e) => e === 'upn:Guest') : false
        // Example: {"sub": "cc-slas::bcnb_dev::scid:5e182e63-fa00-4d44-a39a-638adb029095::usid:1a339719-3f63-481b-9a7d-c5dc4e5cc436"}
        const usid = decoded.sub
            ?.split('::')
            ?.find((e) => e.startsWith('usid'))
            ?.split(':')[1]
        const refreshExpirationDateUTCString = addSecondsToNow(refresh_token_expires_in)
        const accessTokenExpirationDateUTCString = addSecondsToNow(expires_in)
        if (res) {
            res.setHeader('Set-Cookie', [
                `authToken=Bearer ${access_token}; Path=/; Expires=${accessTokenExpirationDateUTCString}; HttpOnly; Secure; SameSite=Strict`,
                `refreshToken=${refresh_token ??
                    ''}; Path=/; Expires=${refreshExpirationDateUTCString}; HttpOnly; Secure; SameSite=Strict`,
                `customerId=${customer_id}; Path=/; Expires=${refreshExpirationDateUTCString}; Secure`,
                `isUserLoggedIn=${!isGuest}; Path=/; Expires=${refreshExpirationDateUTCString}; Secure`,
                `usid=${usid}; Path=/; Expires=${refreshExpirationDateUTCString}; Secure`
            ])
        }
    } catch (error) {
        console.error(`Error storing auth token: ${error}`)
    }
}

/**
 * Adds a given number of seconds to the current time
 * @param {number} seconds - The number of seconds to add
 * @returns {string} - The new UTC time as a string
 */
const addSecondsToNow = (seconds = 3600) => {
    const now = new Date()
    now.setSeconds(now.getSeconds() + seconds)
    return now.toUTCString()
}

/**
 * Gets the server cookie from the request headers
 * @param {Object} req - The request object
 * @param {string} cookieName - The name of the cookie to retrieve (defaults to 'authToken')
 * @returns {string} The value of the cookie, or an empty string if not found
 */
export const getServerCookie = (req, cookieName = 'authToken') => {
    try {
        const cookieHeaders = req.headers.cookie

        if (!cookieHeaders) {
            console.error(
                `No cookie headers found in getServerCookie request for cookie ${cookieName}`
            )
            return null
        }
        const cookie = cookieHeaders
            .split(';')
            .find((cookie) => cookie.trim().startsWith(cookieName + '='))

        const cookieValue = cookie ? cookie.split('=')[1] : null

        return cookieValue
    } catch (error) {
        console.error(
            `Error getting cookie ${cookieName}: ${error.message} : ${req.headers.cookie}`
        )
    }
}

/**
 * Generates an Einstein Recommendor object
 * @param {string} zoneName - the zone name for the recommender
 * @param {array} prodIds - array of strings containing the ids of the products
 * @param {array} catIds - array of strings containing the ids of the categories
 * @returns {Object} - an object containing the zoneName, prodIds and catIds
 */
export const generateEinsteinRecommender = (zoneName, prodIds, catIds) => {
    return {
        zoneName,
        prodIds,
        catIds
    }
}

/**
 * Returns the image source from a srcset string
 * @param {string} srcSet - the srcset string
 * @returns {string} - the image source
 */
export const getImageSrcFromSrcSet = (srcSet) => {
    if (srcSet) {
        let srcs = srcSet.split(', ')
        return srcs.reduce((result, src) => {
            if (src.includes('extra-small')) {
                result = src.split(' ')[0]
            }
            return result
        }, '')
    } else return getAssetUrl('static/img/global/noimagelarge.png')
}

/**
 * Fetches a site preference from the server
 * @param {string} prefName - the name of the preference
 * @param {string} groupName - the name of the group the preference belongs to
 * @returns {Object} - the site preference as a JSON object
 */
export const getSitePreference = async (prefName, groupName) => {
    try {
        const params = new URLSearchParams()
        params.append('attributeId', prefName)
        params.append('groupId', groupName)
        const prefUrl = `${process.env.HOSTNAME}/getSitePreference?${params.toString()}`

        const response = await fetch(prefUrl)
        if (!response.ok) {
            throw new Error('Failed to fetch site preference')
        }

        const data = await response.json()
        return data
    } catch (error) {
        console.error('Error fetching site preference:', error)
        throw error
    }
}
