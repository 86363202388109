import React, {useState, useRef, useEffect} from 'react'
import LazyLoad from 'react-lazyload'

import {isBrowser} from '../../utils/utils'

export default function LazyLoadParent(props) {
    const showContent = props.showContent ? props.showContent : false

    return isBrowser() && !showContent ? (
        <LazyLoad offset={200}>{props.children}</LazyLoad>
    ) : (
        props.children
    )
}
