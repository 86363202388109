import React, {useEffect} from 'react'
import Link from 'progressive-web-sdk/dist/components/link'
import {getURL} from '../../utils/utils'

const ShowSearchSuggestions = ({
    suggestion,
    products,
    isSearchTyped,
    searchPhraseSuggestions,
    handleSearchClick,
    topLevelCategoryId = '',
    topLevelCategory = 'All',
    triggerSuggestInteraction,
    isError,
    onSearchClose
 }) => {
    let searchPhraseSuggestionsProducts = (searchPhraseSuggestions || [])?.filter((_, index) => index < 3) 

    useEffect(() => {
        if(isSearchTyped.length > 2) {


            // searchPhraseSuggestions.map(suggestion =>{
            //     suggestedPhrase + boldSearchPhrase(suggestion.phrase, isSearchTyped)
            // })
            searchPhraseSuggestionsProducts.forEach((suggestion,index) => {
                let searchPhraseEl = document.getElementById("search-phrase-"+index);
                if(searchPhraseEl?.innerHTML) {
                    searchPhraseEl.innerHTML = `${boldSearchPhrase(suggestion, isSearchTyped)}`
                }
            })

            // suggestedPhrase;
        }
    }, [searchPhraseSuggestions]);

    const escapeRegExp = (searchPhrase) => {
        return searchPhrase.replace(/[-[\]{}()*+?.,\\^$|#\\s]/g, '\\$&');
    }

    const boldSearchPhrase = (searchSuggestion, searchPhrase) => {

        const regExString = escapeRegExp(searchPhrase.trim())
        const regex = new RegExp(regExString, 'gi');
        let output = searchSuggestion.replace(regex, `<b>${searchPhrase}</b>`);
        return output;
    }

    return(
        <div id="search" className="search-phrase-suggestion">
            
            <span className="search-phase-text">
                Suggested Searches :
            </span>

            <span className="ss-word">
                {products && products.length > 1 && searchPhraseSuggestionsProducts && searchPhraseSuggestionsProducts.length > 0 &&
                    searchPhraseSuggestionsProducts.map((suggestion,index) =>{
                        return (
                            <span>
                                <Link
                                    href={`/search/?${(new URLSearchParams(`q=${suggestion}&cgid=${topLevelCategoryId}&category=${topLevelCategory}`)).toString()}`}
                                    onClick={() =>{
                                        onSearchClose()
                                        triggerSuggestInteraction(
                                            'Suggested Searches',
                                            suggestion,
                                            `/search/?q=${suggestion}&cgid=${topLevelCategoryId}&category=${topLevelCategory}`
                                        )
                                        if(isError){
                                            window.location.replace(`/search/?${(new URLSearchParams(`q=${suggestion}&cgid=${topLevelCategoryId}&category=${topLevelCategory}`)).toString()}`)
                                        }
                                    }
                                    }
                                    className="search-link"
                                >
                                    <span id={'search-phrase-'+index} className="original ss-word">{suggestion}</span>
                                </Link>
                                {index !== (searchPhraseSuggestionsProducts.length - 1) && <span className='comma-ss'>{`, `}</span>}
                            </span>
                        )
                    })

                }
                {!products || products.length === 0 ? (
                    <Link
                        onClick={() => {
                            classToggle(false)
                            handleSearchClick()
                        }}
                        className="search-link"
                    >
                        <span className="original ss-word">&nbsp;{isSearchTyped}</span>
                    </Link>
                ) : (
                    ''
                )}
                {products && products[0] && products.length === 1 && (
                    <Link
                        onClick={() => {
                            onSearchClose()
                            classToggle(false)
                            if(isError){
                                window.location.replace(`${getURL(products[0].c_pageURL)}/${products[0].product_id}.html`)
                            }
                        }}
                        className="search-link"
                        href={`${getURL(products[0].c_pageURL)}/${products[0].product_id}.html`}
                    >
                        <span className="original ss-word">{isSearchTyped}</span>
                    </Link>
                )}
            </span>
        </div>
    )
}

export default ShowSearchSuggestions
