export const DEFAULT_SORTING_OPTIONS = {product: '', charts: 'chart-position'}
export const DEFAULT_SORTBY_OBJECT = {
    Bestseller: 'top-sellers',
    'Highest Price': 'price-high-to-low',
    'Lowest Price': 'price-low-to-high',
    Popularity: 'most-popular',
    'Title (A - Z)': 'product-name-ascending',
    'Title (Z - A)': 'product-name-descending'
}

export const PAYMENT_METHOD_LABELS = Object.freeze({
    GIFT_CERTIFICATE: 'Gift Card ',
    MC_CREDIT: 'Debit / Credit Card ',
    MC_DEBIT: 'Debit / Credit Card ',
    PAYPAL: 'PayPal',
    'Visa Checkout': 'Pay With Visa ',
    PAY_BY_BANK: 'Pay By Bank ',
    LAYBUY: 'Laybuy ',
    StoreCredit: 'Store Credit',
    VISA_CREDIT: 'Debit / Credit Card',
    PAY360_PAYMENT_HOSTED_PAGE: 'Debit / Credit Card',
    PayPal: 'PayPal',
    VISA_CHECKOUT: 'Visa Checkout',
    Revolut: 'Revolut'
})

export const PAGE_TITLES = Object.freeze({
    HOME: 'Books, stationery, gifts and much more | WHSmith',
    SEARCH: 'Your Search Results | WHSmith',
    BASKET: 'My Basket | WHSmith',
    CHECKOUT: 'Checkout | WHSmith',
    ORDERS: 'My Orders | WHSMith',
    ORDER_CONFIRMATION: 'Order Confirmation | WHSmith',
    ORDER_TRACK: 'Track Your Order | WHSmith',
    STORES: 'Store Locator | WHSmith',
    SECURITY: 'My Security | WHSmith',
    ACCOUNT: 'My Account | WHSmith',
    ACCOUNT_ADDRESS: 'My Address | WHSmith',
    ACCOUNT_DETAILS: 'My Details | WHSmith',
    ACCOUNT_SECURITY: 'My Security | WHSmith',
    ACCOUNT_WALLET: 'Payment Details | WHSmith',
    REGISTER: 'Account Registration | WHSmith',
    LOGIN: 'Login | WHSmith',
    ERROR: 'Error',
    TERMS: 'Website Terms and Conditions',
})
export const EINSTEIN_RECOMMENDATION_ZONES = Object.freeze({
    ADD_TO_BASKET_MODAL: 'AddToBasketModal',
    BASKET_ONE: 'Basket-ONE',
    BASKET_TWO: 'Basket-TWO',
    CLP: 'CLP',
    PDP_ONE: 'PDP-ONE',
    PDP_TWO: 'PDP-TWO'
})

export const DEFAULT_SLIDES_PER_VIEW = Object.freeze({
    forLargeDesktop: 5,
    forDesktop: 4,
    forTablet: 3,
    forMobile: 2
})

export const MODAL_SLIDES_PER_VIEW = Object.freeze({
    forLargeDesktop: 4,
    forDesktop: 4,
    forTablet: 3,
    forMobile: 2
})